import { moneyCharts } from "../reports/charts";
const peopleKpiMoneyKeys = ['total_revenue_per_employee', 'operating_expenses_net_income_per_employee', 'average_wages_and_salaries', 'average_benefits', 'cost_of_turnover', 'number_of_nurses', 'number_of_assistants', 'number_of_office_employees', 'number_of_doctors', 'number_of_hygienists', 'number_of_dentists']
const peopleKpiNoFormatKeys = ['total_number_of_employees', 'number_of_employees_who_left']
const healthCareRevenueChildKeys = ['gross_charges', 'adjustments', 'net_practice_charges', 'collections']
export default class AccountTree {
  constructor({ tree, page = '', pad = '', sheet = '', displayByView = 'yearsView', selectedMetrics = [] }) {
    this.tree = tree;
    this.page = page;
    this.pad = pad;
    this.pageUnspecifed = !page
    this.i = 0;
    this.sheet = sheet;
    this.displayByView = displayByView;
    this.selectedMetrics = selectedMetrics;
  }

  copy = () => {
    const copyHash = {};
    this.forEach(({ key, copy }) => {
      copyHash[key] = copy;
    })
    return copyHash;
  }

  forEach = (callback) => {
    this.callback = callback;
    if (this.tree) {
      const keys = Object.keys(this.tree.children).sort((a, b) => this.tree.children[a].index - this.tree.children[b].index);
      keys.forEach(key => {
        this.traverse(key, this.tree.children[key], 0);
      });

    }
  }

  callbackWrapper = (args) => {
    if (this.pageUnspecifed || args[this.page]) this.callback(args);
  }

  getAllDescendantChartKeys = (arg, descendantsList = []) => {
    let descendants = descendantsList;
    if ((arg.children)) {
      const keys = Object.keys(arg.children).sort((a, b) => arg.children[a].index - arg.children[b].index);
      keys.forEach((k) => {
        let child = arg.children[k]
        if (child.createChart) {
          descendants.push(k)
          if (arg.children[k].children) {
            this.getAllDescendantChartKeys(arg.children[k], descendants)
          }
        }
      })
      return descendants;
    } else {
      return descendants;
    }
  }

  traverse = (key, tree, n, parentKey = '') => {
    if (healthCareRevenueChildKeys.includes(tree.real_key)) { return }
    const padding = tree.no_left_padding ? '' : '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'.repeat(n);
    let copy = tree.copy;
    const pageOptions = tree[this.page] || {};
    const continueTraversing = this.pageUnspecifed || !pageOptions.stop || pageOptions.stop === 'false';
    const chart = tree.chartType ? tree.chartType : this.sheet === 'pnl' ? 'pnlSnapShot' : 'balSnapShot';
    let selectedMetric = this.selectedMetrics.find(c => c.key === key)
    let relatedCheckedKey = tree.relatedCheckedKey || key;

    if (tree[this.displayByView] || this.sheet === 'pnl' || this.sheet === 'balance_sheet' || this.sheet === '') {
      let defaultLayout = null;
      let newLayout = [];
      let relatedCharts = null;
      let noCheckKeys = ['total_costs', 'total_people_costs', 'total_customer_costs', 'total_operations', 'total_operating_expenses', 'total_other_costs', 'total_assets', 'total_current_assets', 'total_fixed_assets', 'total_current_liabilities', 'net_worth', 'total_liabilities_and_equity', 'operating_activities', 'investing_activities', 'financing_activities', 'free_cash_flow_section', 'liquidity_title', 'profitability_title', 'activity_title', 'leverage_title', 'cash_flow_title', 'ebitda_title']
      if (key === 'inventory_turnover_ratio') {
        relatedCharts = moneyCharts['money'].filter(c => c['relatedCheckedKey'] === 'inventory')
        relatedCheckedKey = 'inventory'
        defaultLayout = [...relatedCharts, { checked: false, copy: 'Impact Box', key: 'inventory_impact_box', relatedCheckedKey: 'inventory' }, { checked: false, copy: 'Notes', key: 'inventory_notes', relatedCheckedKey: 'inventory' }]
      } else if (key === 'ar_days') {
        relatedCharts = moneyCharts['money'].filter(c => c['relatedCheckedKey'] === 'accounts_receivable')
        relatedCheckedKey = 'accounts_receivable'
        defaultLayout = [...relatedCharts, { checked: false, copy: 'Impact Box', key: 'accounts_receivable_impact_box', relatedCheckedKey: 'accounts_receivable' }, { checked: false, copy: 'Notes', key: 'accounts_receivable_notes', relatedCheckedKey: 'accounts_receivable' }]
      } else if (key === 'ap_days') {
        relatedCharts = moneyCharts['money'].filter(c => c['relatedCheckedKey'] === 'accounts_payable')
        relatedCheckedKey = 'accounts_payable'
        defaultLayout = [...relatedCharts, { checked: false, copy: 'Impact Box', key: 'accounts_payable_impact_box', relatedCheckedKey: 'accounts_payable' }, { checked: false, copy: 'Notes', key: 'accounts_payable_notes', relatedCheckedKey: 'accounts_payable' }]
      } else if (key === 'gross_profit_common_size_revenue') {
        relatedCharts = moneyCharts['money'].filter(c => c['relatedCheckedKey'] === 'gross_profit')
        relatedCheckedKey = 'gross_profit'
        defaultLayout = [...relatedCharts, { checked: false, copy: 'Impact Box', key: 'gross_profit_impact_box', relatedCheckedKey: 'gross_profit' }, { checked: false, copy: 'Notes', key: 'gross_profit_notes', relatedCheckedKey: 'gross_profit' }]
      } else if (key === 'operating_profit_common_size_revenue') {
        relatedCheckedKey = 'operating_profit'
        defaultLayout = [{ checked: false, copy: 'Chart', key: 'operating_profit_bar_chart', relatedCheckedKey: 'operating_profit' }, { checked: false, copy: 'Impact Box', key: 'operating_profit_impact_box', relatedCheckedKey: 'operating_profit' }, { checked: false, copy: 'Notes', key: 'operating_profit_notes', relatedCheckedKey: 'operating_profit' }]
      } else {
        let anyRelatedCharts = moneyCharts['money'].filter(c => c['relatedCheckedKey'] === key)
        if (anyRelatedCharts.length > 0) {
          defaultLayout = [...anyRelatedCharts, { checked: false, copy: 'Impact Box', key: key + '_impact_box', relatedCheckedKey: key }, { checked: false, copy: 'Notes', key: key + '_notes', relatedCheckedKey: key }]
        } else {
          defaultLayout = [{ checked: false, copy: 'Chart', key: key + '_bar_chart', relatedCheckedKey: key }, { checked: false, copy: 'Impact Box', key: key + '_impact_box', relatedCheckedKey: key }, { checked: false, copy: 'Notes', key: key + '_notes', relatedCheckedKey: key }]
        }
      }

      if (selectedMetric && selectedMetric.layout) {
        defaultLayout.forEach((dl) => {
          let existingChildLayout = selectedMetric.layout.find(l => l.key === dl.key)
          existingChildLayout ? newLayout.push(existingChildLayout) : newLayout.push(dl)
        })
      } else {
        newLayout = defaultLayout;
      }

      let finalFormat = tree.format || 'percent'

      if (this.sheet === 'kpi_people') {
        if (peopleKpiMoneyKeys.includes(key)) {
          finalFormat = 'money'
        } else if (peopleKpiNoFormatKeys.includes(key)) {
          finalFormat = ''
        }
      } else if (this.sheet !== 'kpi_people') {
        if (tree.copy === 'EBITDA') { finalFormat = 'money' }
      }

      if (tree.children && continueTraversing) {
        const keys = Object.keys(tree.children).sort((a, b) => tree.children[a].index - tree.children[b].index);
        // handle parent header
        if (!tree.no_header) {
          // tree.pad = tree.pad && !tree.children;
          let finalKey = key === 'total_operating_expenses' || key === 'net_worth' ? key + '_header' : key
          this.callbackWrapper({
            key: finalKey,
            relatedCheckedKey,
            parentKey,
            padding,
            header: true,
            dropDown: true,
            ...tree,
            ...pageOptions,
            i: this.i,
            copy: this.sheet === 'kfis' && this.displayByView === 'monthlyView' && tree.trailing12Metric ? tree.copy + ' (12)' : tree.copy,
            checked: selectedMetric && selectedMetric.checked,
            chartType: chart,
            format: finalFormat,
            // layout: key === 'net_change_cash' ? newLayout : null,
            layout: tree.displayChart ? newLayout : [],
            sheet: this.sheet
          });
        };

        this.i = this.i + 1;
        // sort by index
        // const keys = Object.keys(tree.children).sort((a, b) => tree.children[a].index - tree.children[b].index);
        keys.forEach(k => {
          this.traverse(k, tree.children[k], (tree.no_header || !continueTraversing ? n : n + 1), key)
        });

        // handle parent total
        if (tree.total || pageOptions.total) {
          this.callbackWrapper({
            key, padding,
            relatedCheckedKey,
            header: false,
            ...tree,
            ...tree.total,
            ...pageOptions,
            ...pageOptions.total,
            i: this.i,
            copy: this.sheet === 'kfis' && this.displayByView === 'monthlyView' && tree.trailing12Metric ? tree.total.copy + ' (12)' : tree.total.copy,
            checked: selectedMetric && selectedMetric.checked,
            displayChart: tree.displayTotalOnChart,
            chartType: chart,
            format: finalFormat,
            layout: tree.displayTotalOnChart ? newLayout : [],
            sheet: this.sheet
          });
          this.i = this.i + 1;
        };

      } else if (tree.copy) {
        if (parentKey) {
          this.callbackWrapper({
            key, parentKey,
            relatedCheckedKey,
            padding,
            header: false,
            ...tree,
            ...pageOptions,
            i: this.i,
            copy: this.sheet === 'kfis' && this.displayByView === 'monthlyView' && tree.trailing12Metric ? tree.copy + ' (12)' : tree.copy,
            checked: selectedMetric && selectedMetric.checked,
            chartType: chart,
            format: finalFormat,
            layout: tree.displayChart ? newLayout : [],
            sheet: this.sheet
          });
          this.i = this.i + 1;
        } else {
          this.callbackWrapper({
            key,
            relatedCheckedKey,
            padding,
            header: false,
            ...tree,
            ...pageOptions,
            i: this.i,
            copy: this.sheet === 'kfis' && this.displayByView === 'monthlyView' && tree.trailing12Metric ? tree.copy + ' (12)' : tree.copy,
            checked: selectedMetric && selectedMetric.checked,
            chartType: chart,
            format: finalFormat,
            layout: tree.displayChart ? newLayout : [],
            sheet: this.sheet
          });
          this.i = this.i + 1;
        }
      };

      // handle pad row
      if (this.pad && (tree.total || tree.pad) && !tree.no_pad) {
        this.callbackWrapper({
          padRow: true,
          relatedCheckedKey,
          i: this.i,
          ...tree,
          chartType: chart,
          copy: this.sheet === 'kfis' && this.displayByView === 'monthlyView' && tree.trailing12Metric ? tree.copy + ' (12)' : tree.copy,
          format: finalFormat,
          layout: tree.displayChart ? newLayout : [],
          sheet: this.sheet
        });
        this.i = this.i + 1;
      }
    }
  }
}
