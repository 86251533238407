import React from 'react';
import { ratioCalculations, ratioCalculationsMoney, calculateEBITDA, netWorkingCapitalCalculation, netWorkingCapitalRatioCalculation, trailing12MonthCalculations } from '../monthly/monthy_analysis_table_trailing12_calculations';

// Used by Monthly and YTD Sheets/Tables
let monthArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const today = new Date();
let currentMonth = today.getMonth();

const CommonSizeArrowsHelper = (props) => {
  let arrowCellClass = props.pdf ? 'common-size-arrow-pdf' : 'common-size-arrow';
  if (!_.isEmpty(props.passedCalcs)) {
    let arrow
    const reducedArrayYou = props.display === 'monthly' ? props.arrayYou.reduce((a, b) => a + b, 0) : props.valueYou;
    const reducedArrayComparedTo = props.display === 'monthly' ? props.arrayCompareTo.slice(0, props.monthsAvailable).reduce((a, b) => a + b, 0) : props.valueCompareTo;

    if (props.sheet == 'pnl') {
      const totalRevenueYou = Object.values(props.youTotRev).reduce((a, b) => a + b, 0)
      const totalRevenueCompareTo = Object.values(props.passedCalcs['total_revenue']).slice(0, props.monthsAvailable).reduce((a, b) => a + b, 0)
      const you = (reducedArrayYou / totalRevenueYou) * 100
      const compareTo = (reducedArrayComparedTo / totalRevenueCompareTo) * 100
      arrow = CommonSizeArrows(you, compareTo, props.keyArrBuilder)
    } else {
      const totalAssetsYou = Object.values(props.youTotAssets).filter(val => _.isNumber(val))
      let numberOfValues = totalAssetsYou.length;
      const youValue = numberOfValues > 0 && props.display === 'monthly' ? props.arrayYou[numberOfValues - 1] : props.valueYou;
      const totalAssetsYouValue = numberOfValues > 0 ? totalAssetsYou[numberOfValues - 1] : null;
      const totalAssetsCompareTo = Object.values(props.passedCalcs['total_assets']).filter(val => _.isNumber(val))
      const totalAssetsCompareVal = totalAssetsCompareTo.length > 0 ? totalAssetsCompareTo[props.monthsAvailable - 1] : null;
      const compareValue = numberOfValues > 0 && props.display === 'monthly' ? props.arrayCompareTo[props.monthsAvailable - 1] : props.valueCompareTo;

      const you = ((youValue / totalAssetsYouValue) * 100)
      const compareTo = ((compareValue / totalAssetsCompareVal) * 100)
      arrow = CommonSizeArrows(you, compareTo, props.keyArrBuilder)
    }

    if (arrow === 'better' || arrow === 'best') {
      arrow = <p className='common-size-arrow-green' style={{ margin: 0, color: 'green' }}>&#9650;</p>
    } else if (arrow === 'worse' || arrow == 'worst') {
      arrow = <p className='common-size-arrow-red' style={{ margin: 0, color: 'red' }}>&#9660;</p>
    } else {
      arrow = ''
    }

    return (<td className={`${arrowCellClass} ${props.cssClass}`} style={props.style}>{arrow}</td>)
  } else {
    return (<td className={`${arrowCellClass} ${props.cssClass}`} style={props.style}></td>)
  }
}

const CommonSizeArrows = (you, avg, key, parentKey = '') => {
  // Common sized values
  const goodUpArrowKeys = ['total_revenue', 'total_revenue_gross_charges', 'gross_profit', 'operating_profit', 'operating_expenses_net_income', 'total_current_assets', 'total_fixed_assets', 'total_assets', 'ebitda'];
  const goodUpBalanceSheetParents = ['total_current_assets', 'total_fixed_assets', 'total_assets'];
  const ingoreArrowKeys = ['total_revenue_collections', 'net_worth', 'total_liabilities_and_equity'];
  let keyIsGoodUp = goodUpArrowKeys.includes(key);
  if (goodUpBalanceSheetParents.includes(parentKey)) {
    keyIsGoodUp = true;
  }
  if (ingoreArrowKeys.includes(key) || ingoreArrowKeys.includes(parentKey)) {
    return 'maintain';
  }
  let rangeWorst, rangeWorse, rangeBetter, rangeBest;
  if (avg !== null) {
    rangeWorst = avg - 10;
    rangeWorse = avg - 5;
    rangeBetter = avg + 5;
    rangeBest = avg + 10;
  }

  if (rangeWorse < you && you < rangeBetter) {
    return 'maintain';
  } else if (rangeWorst < you && you < rangeWorse) {
    return keyIsGoodUp ? 'worse' : 'better';
  } else if (you < rangeWorst) {
    return keyIsGoodUp ? 'worst' : 'best';
  } else if (rangeBest > you && you > rangeBetter) {
    return keyIsGoodUp ? 'better' : 'worse';
  } else if (you > rangeBest) {
    return keyIsGoodUp ? 'best' : 'worst';
  } else {
    return 'n/a';
  }
}

const YTDPnlandBalCommonSizeValue = (value, sheet, calcs) => {
  if (sheet == 'pnl') {
    if (_.isNumber(value)) {
      const totalRevenue = calcs['total_revenue'] ? Object.values(calcs['total_revenue']).reduce((a, b) => a + b, 0) : 0
      return totalRevenue ? value / totalRevenue : 0
    } else {
      return undefined
    }
  } else if (sheet === 'financial_detail' || sheet === 'kpi') {
    if (_.isNumber(value)) {
      const totalRevenue = calcs['total_revenue'] ? Object.values(calcs['total_revenue']).splice(0, currentMonth).reduce((a, b) => a + b, 0) : 0
      return totalRevenue ? value / totalRevenue : 0
    } else {
      return undefined
    }
  } else {
    if (_.isNumber(value)) {
      let totalAssets = Object.values(calcs['total_assets']);
      let indexOfNullValue = totalAssets.indexOf(null);
      const totalAssetsValue = indexOfNullValue >= 0 ? totalAssets[indexOfNullValue - 1] : totalAssets[totalAssets.length - 1];
      return value / totalAssetsValue
    } else {
      return undefined
    }
  }
}

const totalNAICSYTD = (key, calcs, rows) => {
  let valuesArr = []
  let childrenObjs = rows.filter(r => r.parentKey === key)
  let childrenValues = []
  childrenObjs.forEach((c) => {
    let totalVal = 0;
    c.map_keys.forEach((mk) => {
      totalVal += calcs[mk] ? Object.values(calcs[mk]).reduce((a, b) => a + b, 0) : 0
    })
    childrenValues.push(totalVal)
  })

  return childrenValues.reduce((a, b) => a + b, 0)
}

const YTDNAICSFocusedVals = (key, map_keys, allCalcs, rows = '') => {
  let finalValue = null;
  let values = []
  if (key === 'operating_expenses_net_income') {
    let totalDirectCosts = totalNAICSYTD('total_direct_costs', allCalcs, rows)
    let totalAdminCosts = totalNAICSYTD('total_administrative_costs', allCalcs, rows)
    let totalRevenue = allCalcs['total_revenue'] ? Object.values(allCalcs['total_revenue']).reduce((a, b) => a + b, 0) : 0
    finalValue = totalRevenue - totalDirectCosts - totalAdminCosts
  } else if (key === 'gross_profit') {
    let totalDirectCosts = totalNAICSYTD('total_direct_costs', allCalcs, rows)
    let totalRevenue = allCalcs['total_revenue'] ? Object.values(allCalcs['total_revenue']).reduce((a, b) => a + b, 0) : 0
    finalValue = totalRevenue - totalDirectCosts
  } else if (key === 'total_direct_costs' || key === 'total_administrative_costs') {
    finalValue = totalNAICSYTD(key, allCalcs, rows)
  } else {
    map_keys.forEach(mk => {
      let filteredVals = allCalcs[mk] ? Object.values(allCalcs[mk]).filter(val => _.isNumber(val)) : [];
      values = [...values, ...filteredVals]
    })
    finalValue = values.reduce((a, b) => a + b, 0)
  }
  return finalValue
}

// YTD PNL and Balance Sheet Values
const YTDPnlandBalValues = (sheet, key, metricValues, allCalcs) => {
  let finalValue = null;
  let values;
  const netWorth = sheet == 'balance_sheet' && key == 'net_worth'
  const totalLiabilitiesAndEquity = sheet == 'balance_sheet' & key == 'total_liabilities_and_equity'

  if (metricValues && !finalValue) {
    if (sheet === 'pnl') {
      values = Object.values(metricValues).filter(val => _.isNumber(val));
      finalValue = values.reduce((a, b) => a + b, 0)
    } else if (sheet === 'financial_detail' || sheet === 'kpi') {
      values = Object.values(metricValues).splice(0, currentMonth).filter(val => _.isNumber(val));
      finalValue = values.reduce((a, b) => a + b, 0)
    } else {
      let values = Object.values(metricValues).filter(val => _.isNumber(val));
      if (key === 'operating_expenses_net_income') {
        finalValue = values.reduce((a, b) => a + b, 0)
      } else if (netWorth) {
        let arrayOfTheMetricsMonthsAvailable = Object.keys(allCalcs['roa'])
        let lastMonthAvailable = arrayOfTheMetricsMonthsAvailable[arrayOfTheMetricsMonthsAvailable.length - 1]
        let memory = 0
        let netWorthVals = Object.values(metricValues)
        for (let i = 1; i <= lastMonthAvailable; i++) {
          memory += _.isNumber(allCalcs['operating_expenses_net_income'][i]) ? allCalcs['operating_expenses_net_income'][i] : 0;
          let capitalStock = _.isNumber(allCalcs['capital_stock'][i]) ? allCalcs['capital_stock'][i] : null;
          let retainedEarnings = _.isNumber(allCalcs['retained_earnings'][i]) ? allCalcs['retained_earnings'][i] : null;
          let ownerDistributions = _.isNumber(allCalcs['owner_distributions'][i]) ? allCalcs['owner_distributions'][i] : null;
          netWorthVals[i - 1] = _.isNumber(allCalcs['operating_expenses_net_income'][i]) ? memory + capitalStock + retainedEarnings + ownerDistributions : null;
        }
        let filteredNetWorthVals = netWorthVals.filter(val => _.isNumber(val))
        finalValue = filteredNetWorthVals[filteredNetWorthVals.length - 1]
      } else if (totalLiabilitiesAndEquity) {
        let arrayOfTheMetricsMonthsAvailable = Object.keys(allCalcs['roa'])
        let lastMonthAvailable = arrayOfTheMetricsMonthsAvailable[arrayOfTheMetricsMonthsAvailable.length - 1]
        let totalLiabAndEqVals = Object.values(metricValues)
        for (let i = 1; i <= lastMonthAvailable; i++) {
          totalLiabAndEqVals[i - 1] = allCalcs['total_assets'][i]
        }
        let filteredTotalLiabAndEqVals = totalLiabAndEqVals.filter(val => _.isNumber(val));
        finalValue = filteredTotalLiabAndEqVals[filteredTotalLiabAndEqVals.length - 1]
      } else {
        finalValue = values[values.length - 1];
      }
    }
  }
  return finalValue

}

const totalNAICSFocusedMonthly = (monthsAvailable, key, calcs, rows) => {
  let valuesArr = []
  let childrenObjs = rows.filter(r => r.parentKey === key)
  for (let i = 1; i < monthsAvailable + 1; i++) {
    let youSum = 0;
    childrenObjs.forEach((c) => {
      c.map_keys.forEach((mk) => {
        youSum += calcs[mk] && calcs[mk][i] ? calcs[mk][i] : 0
      })
    })
    valuesArr[i - 1] = youSum
  }
  return valuesArr
}

const MonthlyNAICSFocusedVals = (monthsAvailable, key, map_keys, allCalcs, rows) => {
  let valuesArr = []

  if (key === 'operating_expenses_net_income') {
    let totalDirectCosts = totalNAICSFocusedMonthly(monthsAvailable, 'total_direct_costs', allCalcs, rows)
    let totalAdminCosts = totalNAICSFocusedMonthly(monthsAvailable, 'total_administrative_costs', allCalcs, rows)

    valuesArr = totalDirectCosts.map((t, i) => {
      return allCalcs['total_revenue'] && allCalcs['total_revenue'][i + 1] ? allCalcs['total_revenue'][i + 1] - t - totalAdminCosts[i] : 0;
    })
  } else if (key === 'gross_profit') {
    let totalDirectCosts = totalNAICSFocusedMonthly(monthsAvailable, 'total_direct_costs', allCalcs, rows)

    valuesArr = totalDirectCosts.map((t, i) => {
      return allCalcs['total_revenue'] && allCalcs['total_revenue'][i + 1] ? allCalcs['total_revenue'][i + 1] - t : 0;
    })
  } else if (key === 'total_direct_costs' || key === 'total_administrative_costs') {
    valuesArr = totalNAICSFocusedMonthly(monthsAvailable, key, allCalcs, rows)
  } else {
    for (let i = 1; i < monthsAvailable + 1; i++) {
      let youSum = 0;
      map_keys.forEach(mk => {
        youSum += allCalcs[mk] && allCalcs[mk][i] ? allCalcs[mk][i] : 0
      })
      valuesArr[i - 1] = youSum
    }
  }

  return valuesArr;
}

// Monthly PNL and Balance Sheet Values
const MonthlyPNLandBalValues = (monthsAvailable, sheet, key, metricValues, allCalcs) => {
  let valuesArr = []
  const bsNetIncome = sheet == 'balance_sheet' && key == 'operating_expenses_net_income';
  const netWorth = sheet == 'balance_sheet' && key == 'net_worth'
  const totalLiabilitiesAndEquity = sheet == 'balance_sheet' & key == 'total_liabilities_and_equity'

  if (metricValues && valuesArr.length === 0) {
    monthArr.forEach((month, index) => {
      if (index < monthsAvailable) {
        let val = _.isNumber(metricValues[month]) ? metricValues[month] : 0;
        valuesArr.push(val)
      } else {
        valuesArr.push(null)
      }
    })
  }

  if (metricValues && bsNetIncome) {
    for (let i = 1; i < monthsAvailable + 1; i++) {
      if (i !== monthsAvailable) {
        valuesArr[i] += valuesArr[i - 1]
      }
    }
  }

  if (metricValues && netWorth) {
    let memory = 0
    for (let i = 1; i < monthsAvailable + 1; i++) {
      memory += allCalcs['operating_expenses_net_income'][i]
      valuesArr[i - 1] = memory + allCalcs['capital_stock'][i] + allCalcs['retained_earnings'][i] + allCalcs['owner_distributions'][i]
    }
  }

  if (metricValues && totalLiabilitiesAndEquity) {
    for (let i = 1; i < monthsAvailable + 1; i++) {
      valuesArr[i - 1] = allCalcs['total_assets'][i]
    }
  }

  return valuesArr;
}

const MonthlyPNLandBalCommonSized = (sheet, metricValsArr, allCalcs) => {
  let commonSizedVal = null;
  if (sheet === 'pnl' || sheet === 'financial_detail' || sheet === 'kpi') {
    let filteredValues = metricValsArr.filter(val => _.isNumber(val));
    const reducedArray = filteredValues.reduce((a, b) => a + b, 0)
    if (_.isNumber(reducedArray)) {
      const totalRevenue = Object.values(allCalcs['total_revenue']).slice(0, filteredValues.length).reduce((a, b) => a + b, 0)
      commonSizedVal = reducedArray / totalRevenue
    }
  }
  if (sheet === 'balance_sheet') {
    let filteredValues = metricValsArr.filter(val => _.isNumber(val));
    const totalAssets = Object.values(allCalcs['total_assets']).slice(0, filteredValues.length).filter(val => _.isNumber(val))
    let numberOfValues = totalAssets.length;
    const totalAssetsValue = numberOfValues > 0 ? totalAssets[numberOfValues - 1] : null;
    if (_.isNumber(metricValsArr[0]) && _.isNumber(totalAssetsValue)) {
      let lastValue = numberOfValues > 0 ? metricValsArr[numberOfValues - 1] : null;
      commonSizedVal = lastValue / totalAssetsValue
    }
  }

  return commonSizedVal;
}

const PreviousMonthMinusCurrentMonthAmount = (netIncome, currentYear, previousYear, ytdView = false) => {
  let prevMinusCurrent = {};
  for (let i = 1; i <= 12; i++) {
    if (netIncome && _.isNumber(netIncome[i])) {
      if (i === 1 && previousYear && currentYear) {
        prevMinusCurrent[i] = previousYear[12] - currentYear[1]
      } else if (currentYear) {
        prevMinusCurrent[i] = currentYear[i - 1] - currentYear[i]
      } else {
        prevMinusCurrent[i] = null
      }
    } else {
      prevMinusCurrent[i] = null
    }
  }

  if (ytdView) {
    prevMinusCurrent = Object.values(prevMinusCurrent).reduce((a, b) => a + b);
  }

  return prevMinusCurrent
}

const CurrentMonthMinusPreviousMonthAmount = (netIncome, currentYear, previousYear, ytdView = false) => {
  let currentMinusPrev = {}
  for (let i = 1; i <= 12; i++) {
    if (netIncome && _.isNumber(netIncome[i])) {
      if (i === 1 && previousYear && currentYear) {
        currentMinusPrev[i] = currentYear[1] - previousYear[12]
      } else if (currentYear) {
        currentMinusPrev[i] = currentYear[i] - currentYear[i - 1]
      } else {
        currentMinusPrev[i] = null
      }
    } else {
      currentMinusPrev[i] = null
    }
  }

  if (ytdView) {
    currentMinusPrev = Object.values(currentMinusPrev).reduce((a, b) => a + b);
  }

  return currentMinusPrev
}

const netOperatingActivitiesValuesMonths = (calcs, previousYearCalcs, ytdView = false) => {
  let netOperatingActivities = null;
  let netIncomeVals = calcs.operating_expenses_net_income;
  let deprAmorVals = calcs.depreciation_and_amortization;
  let accountsReceivable = PreviousMonthMinusCurrentMonthAmount(netIncomeVals, calcs['accounts_receivable'], previousYearCalcs['accounts_receivable'], ytdView)
  let inventory = PreviousMonthMinusCurrentMonthAmount(netIncomeVals, calcs['inventory'], previousYearCalcs['inventory'], ytdView)
  let underBillings = PreviousMonthMinusCurrentMonthAmount(netIncomeVals, calcs['under_billings'], previousYearCalcs['under_billings'], ytdView)
  let prepaidAssets = PreviousMonthMinusCurrentMonthAmount(netIncomeVals, calcs['prepaid_and_other_current_assets'], previousYearCalcs['prepaid_and_other_current_assets'], ytdView)
  let accountsPayable = CurrentMonthMinusPreviousMonthAmount(netIncomeVals, calcs['accounts_payable'], previousYearCalcs['accounts_payable'], ytdView)
  let accruedLiabilities = CurrentMonthMinusPreviousMonthAmount(netIncomeVals, calcs['accrued_liabilities'], previousYearCalcs['accrued_liabilities'], ytdView)
  let overBillings = CurrentMonthMinusPreviousMonthAmount(netIncomeVals, calcs['over_billings'], previousYearCalcs['over_billings'], ytdView)
  let otherCurrentLiabilities = CurrentMonthMinusPreviousMonthAmount(netIncomeVals, calcs['other_current_liabilities'], previousYearCalcs['other_current_liabilities'], ytdView)
  if (ytdView) {
    let netIncome = netIncomeVals ? Object.values(netIncomeVals).reduce((a, b) => a + b, 0) : 0;
    let deprAmor = deprAmorVals ? Object.values(deprAmorVals).reduce((a, b) => a + b, 0) : 0;

    netOperatingActivities = Math.round(netIncome + deprAmor +
      accountsReceivable +
      inventory +
      underBillings +
      prepaidAssets +
      accountsPayable +
      accruedLiabilities +
      overBillings +
      otherCurrentLiabilities)
  } else {
    netOperatingActivities = {}
    for (let i = 1; i <= 12; i++) {
      let netIncome = netIncomeVals && _.isNumber(netIncomeVals[i]) ? netIncomeVals[i] : null;
      let deprAmor = deprAmorVals && _.isNumber(deprAmorVals[i]) ? deprAmorVals[i] : null;

      netOperatingActivities[i] = Math.round(netIncome + deprAmor +
        accountsReceivable[i] +
        inventory[i] +
        underBillings[i] +
        prepaidAssets[i] +
        accountsPayable[i] +
        accruedLiabilities[i] +
        overBillings[i] +
        otherCurrentLiabilities[i])
    }
  }
  return netOperatingActivities;
}

const netInvestingActivitiesMonths = (calcs, previousYearCalcs, ytdView = false) => {
  let netInvestingActivities = null;
  let netIncomeVals = calcs.operating_expenses_net_income;
  let propertyAndEquipment = PreviousMonthMinusCurrentMonthAmount(netIncomeVals, calcs['property_and_equipment'], previousYearCalcs['property_and_equipment'], ytdView)
  let otherLongTermAssets = PreviousMonthMinusCurrentMonthAmount(netIncomeVals, calcs['other_long_term_assets'], previousYearCalcs['other_long_term_assets'], ytdView)
  if (ytdView) {
    netInvestingActivities = Math.round(propertyAndEquipment + otherLongTermAssets)
  } else {
    netInvestingActivities = {}
    for (let i = 1; i <= 12; i++) {
      netInvestingActivities[i] = Math.round(propertyAndEquipment[i] + otherLongTermAssets[i])
    }
  }
  return netInvestingActivities;
}

const equityMonths = (calcs, previousYearCalcs, ytdView = false) => {
  let newEquity = {}
  let netIncomeVals = calcs.operating_expenses_net_income;
  let prevNetIncomeVals = previousYearCalcs.operating_expenses_net_income
  // Current month net worth - current month net income - previous month net worth
  // Need to go through last year's adding up previous month's operating_expenses_net_income for each net worth like balance sheet
  let currentYearNetWorth = {};
  let lastYearNetWorth = {};
  let currentMemory = 0;
  let lastMemory = 0;
  for (let i = 1; i <= 12; i++) {
    currentMemory += netIncomeVals && netIncomeVals[i] ? netIncomeVals[i] : 0;
    let capitalStock = calcs['capital_stock'] && _.isNumber(calcs['capital_stock'][i]) ? calcs['capital_stock'][i] : null;
    let retainedEarnings = calcs['retained_earnings'] && _.isNumber(calcs['retained_earnings'][i]) ? calcs['retained_earnings'][i] : null;
    let ownerDistributions = calcs['owner_distributions'] && _.isNumber(calcs['owner_distributions'][i]) ? calcs['owner_distributions'][i] : null;
    currentYearNetWorth[i] = netIncomeVals && netIncomeVals[i] === null ? null : currentMemory + capitalStock + retainedEarnings + ownerDistributions;

    lastMemory += prevNetIncomeVals && _.isNumber(prevNetIncomeVals[i]) ? prevNetIncomeVals[i] : 0;
    let prevCapitalStock = previousYearCalcs['capital_stock'] && _.isNumber(previousYearCalcs['capital_stock'][i]) ? previousYearCalcs['capital_stock'][i] : null;
    let prevRetainedEarnings = previousYearCalcs['retained_earnings'] && _.isNumber(previousYearCalcs['retained_earnings'][i]) ? previousYearCalcs['retained_earnings'][i] : null;
    let prevOwnerDistributions = previousYearCalcs['owner_distributions'] && _.isNumber(previousYearCalcs['owner_distributions'][i]) ? previousYearCalcs['owner_distributions'][i] : null;
    lastYearNetWorth[i] = prevNetIncomeVals && _.isNumber(prevNetIncomeVals[i]) === null ? null : lastMemory + prevCapitalStock + prevRetainedEarnings + prevOwnerDistributions;
  }
  for (let i = 1; i <= 12; i++) {
    if (i === 1) {
      let currentNetWorth = currentYearNetWorth && _.isNumber(currentYearNetWorth[1]) ? currentYearNetWorth[1] : null;
      let netIncome = netIncomeVals && _.isNumber(netIncomeVals[1]) ? netIncomeVals[1] : null;
      let lastNetWorth = lastYearNetWorth && _.isNumber(lastYearNetWorth[12]) ? lastYearNetWorth[12] : null;
      newEquity[1] = netIncome === null ? null : currentNetWorth - netIncome - lastNetWorth;
    } else {
      let currentNetWorth = currentYearNetWorth && _.isNumber(currentYearNetWorth[i]) ? currentYearNetWorth[i] : null;
      let netIncome = netIncomeVals && _.isNumber(netIncomeVals[i]) ? netIncomeVals[i] : null;
      let lastNetWorth = currentYearNetWorth && _.isNumber(currentYearNetWorth[i - 1]) ? currentYearNetWorth[i - 1] : null;
      newEquity[i] = netIncome === null ? null : currentNetWorth - netIncome - lastNetWorth;
    }
  }
  if (ytdView) {
    newEquity = Object.values(newEquity).reduce((a, b) => a + b, 0)
  }
  return newEquity;
}

const netFinancingActivitiesValuesMonths = (calcs, previousYearCalcs, ytdView = false) => {
  let netFinancingActivities = null;
  let netIncomeVals = calcs.operating_expenses_net_income;
  let lineOfCredit = CurrentMonthMinusPreviousMonthAmount(netIncomeVals, calcs['line_of_credit'], previousYearCalcs['line_of_credit'], ytdView)
  let currentPortionOfLTD = CurrentMonthMinusPreviousMonthAmount(netIncomeVals, calcs['current_portion_of_ltd'], previousYearCalcs['current_portion_of_ltd'], ytdView)
  let longTermDebt = CurrentMonthMinusPreviousMonthAmount(netIncomeVals, calcs['long_term_debt'], previousYearCalcs['long_term_debt'], ytdView)
  let equity = equityMonths(calcs, previousYearCalcs, ytdView);
  if (ytdView) {
    netFinancingActivities = Math.round(lineOfCredit + currentPortionOfLTD + longTermDebt + equity)
  } else {
    netFinancingActivities = {}
    for (let i = 1; i <= 12; i++) {
      netFinancingActivities[i] = Math.round(lineOfCredit[i] +
        currentPortionOfLTD[i] +
        longTermDebt[i] +
        equity[i])
    }
  }
  return netFinancingActivities;
}

const netChangeInCashValuesMonths = (netOperatingActivities, netInvestingActivities, netFinancingActivities) => {
  const netChangeInCash = {}
  for (let i = 1; i <= 12; i++) {
    netChangeInCash[i] = netOperatingActivities[i] + netInvestingActivities[i] + netFinancingActivities[i]
  }
  return netChangeInCash;
}

const beginCashPeriodMonths = (calcs, previousYearCalcs) => {
  const cashAtBeginning = {}
  let checkPreviousYearExists = Object.keys(previousYearCalcs);
  cashAtBeginning[1] = checkPreviousYearExists.length > 0 ? previousYearCalcs.cash[12] : null;
  for (let i = 2; i <= 12; i++) {
    // Don't want to show beginning cash for months that we don't have data for
    if (calcs.cash && [i] === null || calcs.cash && calcs.cash[i] === undefined) {
      cashAtBeginning[i] = null;
    } else {
      cashAtBeginning[i] = calcs.cash ? calcs.cash[i - 1] : null;
    }
  }
  return cashAtBeginning;
}

// YTD MonthlyCashFlowValues
const YTDCashFlowValues = (key, calcs, prevYearCalcs, compareCalcs, prevYearCompareCalcs) => {
  let getNormalYearKeys = ['operating_expenses_net_income', 'depreciation_and_amortization']
  let previousYearFirstKeys = ['accounts_receivable', 'inventory', 'under_billings', 'prepaid_and_other_current_assets', 'property_and_equipment', 'other_long_term_assets']
  let nextYearFirstKeys = ['accounts_payable', 'accrued_liabilities', 'over_billings', 'other_current_liabilities', 'line_of_credit', 'current_portion_of_ltd', 'long_term_debt']
  let youValue = null;
  let compareValue = null;
  if (previousYearFirstKeys.includes(key)) {
    let netIncome = calcs.operating_expenses_net_income;
    youValue = PreviousMonthMinusCurrentMonthAmount(netIncome, calcs[key], prevYearCalcs[key], true)
    if (compareCalcs) { compareValue = PreviousMonthMinusCurrentMonthAmount(netIncome, compareCalcs[key], prevYearCompareCalcs[key], true) }
  } else if (nextYearFirstKeys.includes(key)) {
    let netIncome = calcs.operating_expenses_net_income;
    youValue = CurrentMonthMinusPreviousMonthAmount(netIncome, calcs[key], prevYearCalcs[key], true)
    if (compareCalcs) { compareValue = CurrentMonthMinusPreviousMonthAmount(netIncome, compareCalcs[key], prevYearCompareCalcs[key], true) }
  } else if (getNormalYearKeys.includes(key)) {
    youValue = calcs.hasOwnProperty(key) ? Object.values(calcs[key]).reduce((a, b) => a + b) : 0;
    if (compareCalcs) { compareValue = compareCalcs.hasOwnProperty(key) ? Object.values(compareCalcs[key]).reduce((a, b) => a + b) : 0 }
  } else if (key === 'net_operating_activities') {
    youValue = netOperatingActivitiesValuesMonths(calcs, prevYearCalcs, true)
    if (compareCalcs) { compareValue = netOperatingActivitiesValuesMonths(compareCalcs, prevYearCompareCalcs, true) }
  } else if (key === 'net_investing_activities') {
    youValue = netInvestingActivitiesMonths(calcs, prevYearCalcs, true)
    if (compareCalcs) { compareValue = netInvestingActivitiesMonths(compareCalcs, prevYearCompareCalcs, true) }
  } else if (key === 'equity') {
    youValue = equityMonths(calcs, prevYearCalcs, true)
    if (compareCalcs) { compareValue = equityMonths(compareCalcs, prevYearCompareCalcs, true) }
  } else if (key === 'net_financing_activities') {
    youValue = netFinancingActivitiesValuesMonths(calcs, prevYearCalcs, true)
    if (compareCalcs) { compareValue = netFinancingActivitiesValuesMonths(compareCalcs, prevYearCompareCalcs, true) }
  } else if (key === 'net_change_cash') {
    let netOperatingActivities = netOperatingActivitiesValuesMonths(calcs, prevYearCalcs, true)
    let netInvestingActivities = netInvestingActivitiesMonths(calcs, prevYearCalcs, true)
    let netFinancingActivities = netFinancingActivitiesValuesMonths(calcs, prevYearCalcs, true)
    youValue = Math.round(netOperatingActivities + netInvestingActivities + netFinancingActivities, true)
    if (compareCalcs) {
      let netOperatingActivitiesCompare = netOperatingActivitiesValuesMonths(compareCalcs, prevYearCompareCalcs, true)
      let netInvestingActivitiesCompare = netInvestingActivitiesMonths(compareCalcs, prevYearCompareCalcs, true)
      let netFinancingActivitiesCompare = netFinancingActivitiesValuesMonths(compareCalcs, prevYearCompareCalcs, true)
      compareValue = Math.round(netOperatingActivitiesCompare + netInvestingActivitiesCompare + netFinancingActivitiesCompare)
    }
  } else if (key === 'cash_beginning_period') {
    youValue = _.isNumber(prevYearCalcs.cash[12]) ? prevYearCalcs.cash[12] : null;
    if (compareCalcs) { compareValue = prevYearCompareCalcs.cash && _.isNumber(prevYearCompareCalcs.cash[12]) ? prevYearCompareCalcs.cash[12] : null }
  } else if (key === 'cash_end_period') {
    let getAllCashVals = Object.values(calcs.cash);
    let indexOfNullValue = getAllCashVals.indexOf(null);
    let endCashYou = indexOfNullValue >= 0 ? getAllCashVals[indexOfNullValue - 1] : getAllCashVals[getAllCashVals.length - 1];
    youValue = endCashYou;
    if (compareCalcs) {
      let getAllCashValsCompare = compareCalcs.cash ? Object.values(compareCalcs.cash) : [];
      let indexOfNullValueCompare = getAllCashValsCompare.indexOf(null);
      let endCashCompare = indexOfNullValue >= 0 ? getAllCashValsCompare[indexOfNullValue - 1] : getAllCashValsCompare[getAllCashValsCompare.length - 1];
      compareValue = endCashCompare;
    }
  }
  return { youValue, compareValue }
}

// Monthly Cash Flow Values
const MonthlyCashFlowValues = (key, calcs, prevYearCalcs, compareCalcs, prevYearCompareCalcs) => {
  let getNormalYearKeys = ['operating_expenses_net_income', 'depreciation_and_amortization']
  let previousYearFirstKeys = ['accounts_receivable', 'inventory', 'under_billings', 'prepaid_and_other_current_assets', 'property_and_equipment', 'other_long_term_assets']
  let nextYearFirstKeys = ['accounts_payable', 'accrued_liabilities', 'over_billings', 'other_current_liabilities', 'line_of_credit', 'current_portion_of_ltd', 'long_term_debt']
  let youValues = null;
  let compareValues = null;
  let returnObj = null;
  if (previousYearFirstKeys.includes(key)) {
    let netIncome = calcs.operating_expenses_net_income;
    youValues = PreviousMonthMinusCurrentMonthAmount(netIncome, calcs[key], prevYearCalcs[key])
    if (compareCalcs) { compareValues = PreviousMonthMinusCurrentMonthAmount(netIncome, compareCalcs[key], prevYearCompareCalcs[key]) }
  } else if (nextYearFirstKeys.includes(key)) {
    let netIncome = calcs.operating_expenses_net_income;
    youValues = CurrentMonthMinusPreviousMonthAmount(netIncome, calcs[key], prevYearCalcs[key])
    if (compareCalcs) { compareValues = CurrentMonthMinusPreviousMonthAmount(netIncome, compareCalcs[key], prevYearCompareCalcs[key]) }
  } else if (getNormalYearKeys.includes(key)) {
    youValues = calcs[key]
    if (compareCalcs) { compareValues = compareCalcs[key] }
  } else if (key === 'net_operating_activities') {
    youValues = netOperatingActivitiesValuesMonths(calcs, prevYearCalcs)
    if (compareCalcs) { compareValues = netOperatingActivitiesValuesMonths(compareCalcs, prevYearCompareCalcs) }
  } else if (key === 'net_investing_activities') {
    youValues = netInvestingActivitiesMonths(calcs, prevYearCalcs)
    if (compareCalcs) { compareValues = netInvestingActivitiesMonths(compareCalcs, prevYearCompareCalcs) }
  } else if (key === 'equity') {
    youValues = equityMonths(calcs, prevYearCalcs)
    if (compareCalcs) { compareValues = equityMonths(compareCalcs, prevYearCompareCalcs) }
  } else if (key === 'net_financing_activities') {
    youValues = netFinancingActivitiesValuesMonths(calcs, prevYearCalcs)
    if (compareCalcs) { compareValues = netFinancingActivitiesValuesMonths(compareCalcs, prevYearCompareCalcs) }
  } else if (key === 'net_change_cash') {
    let netOperatingActivities = netOperatingActivitiesValuesMonths(calcs, prevYearCalcs)
    let netInvestingActivities = netInvestingActivitiesMonths(calcs, prevYearCalcs)
    let netFinancingActivities = netFinancingActivitiesValuesMonths(calcs, prevYearCalcs)
    youValues = netChangeInCashValuesMonths(netOperatingActivities, netInvestingActivities, netFinancingActivities)
    if (compareCalcs) {
      let netOperatingActivitiesCompare = netOperatingActivitiesValuesMonths(compareCalcs, prevYearCompareCalcs)
      let netInvestingActivitiesCompare = netInvestingActivitiesMonths(compareCalcs, prevYearCompareCalcs)
      let netFinancingActivitiesCompare = netFinancingActivitiesValuesMonths(compareCalcs, prevYearCompareCalcs)
      compareValues = netChangeInCashValuesMonths(netOperatingActivitiesCompare, netInvestingActivitiesCompare, netFinancingActivitiesCompare)
    }
  } else if (key === 'cash_beginning_period') {
    youValues = beginCashPeriodMonths(calcs, prevYearCalcs)
    if (compareCalcs) { compareValues = beginCashPeriodMonths(compareCalcs, prevYearCompareCalcs) }
  } else if (key === 'cash_end_period') {
    youValues = calcs.cash;
    if (compareCalcs) { compareValues = compareCalcs.cash }
  }
  return { youValues, compareValues };
}

// Annual Cash Flow Values
const CashFlowCommonSizeArrows = (you, compare, key) => {
  // Common sized values
  let youVal = you * 100
  let compareVal = compare * 100
  const goodUpArrowKeys = ['operating_expenses_net_income', 'under_billings', 'prepaid_and_other_current_assets', 'property_and_equipment', 'other_long_term_assets', 'net_operating_activities', 'net_investing_activities', 'net_financing_activities', 'net_change_cash', 'cash', 'operating_cash_flow', 'capital_expenditure', 'free_cash_flow', 'equity'];
  let keyIsGoodUp = goodUpArrowKeys.includes(key)
  let rangeWorst = compareVal - 10;
  let rangeWorse = compareVal - 5
  let rangeBetter = compareVal + 5
  let rangeBest = compareVal + 10;

  if (rangeWorse < youVal && youVal < rangeBetter) {
    return 'maintain';
  } else if (rangeWorst < youVal && youVal < rangeWorse) {
    return keyIsGoodUp ? 'worse' : 'better';
  } else if (youVal < rangeWorst) {
    return keyIsGoodUp ? 'worst' : 'best';
  } else if (rangeBest > youVal && youVal > rangeBetter) {
    return keyIsGoodUp ? 'better' : 'worse';
  } else if (youVal > rangeBest) {
    return keyIsGoodUp ? 'best' : 'worst';
  } else {
    return 'n/a';
  }
}

const CashFlowYearAmounts = (years, you, avg, metric, subtract = '') => {
  const returnObject = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youTotalRevenue = you['total_revenue']
  const avgTotalRevenue = avgExists ? avg['total_revenue'] : null;
  let metricFinal = metric === 'cash_beginning_period' || metric === 'cash_end_period' ? 'cash' : metric;

  years.forEach((year) => {
    if (_.isNumber(you.operating_expenses_net_income[year]) && you[metricFinal]) {
      // Check if previous year outside of yearRange exists
      let youPrevVal = you[metricFinal] && _.isNumber(you[metricFinal][year - 1]) ? you[metricFinal][year - 1] : 0;
      let youCurrentVal = you[metricFinal] && _.isNumber(you[metricFinal][year]) ? you[metricFinal][year] : 0;
      if (years.indexOf(year) === 0 && subtract) {
        if (you[metricFinal][year - 1] !== null && subtract === 'previousYearMinusNextYear') {
          returnObject[year] = youPrevVal - youCurrentVal
          returnObject['you' + year] = (returnObject[year] / youTotalRevenue[year])
        } else if (you[metricFinal][year - 1] !== null && subtract === 'nextYearMinusPreviousYear') {
          returnObject[year] = youCurrentVal - youPrevVal
          returnObject['you' + year] = (returnObject[year] / youTotalRevenue[year])
        } else {
          returnObject[year] = null
          returnObject['you' + year] = null
        }
      } else if (subtract === 'previousYearMinusNextYear') {
        returnObject[year] = youPrevVal - youCurrentVal
        returnObject['you' + year] = (returnObject[year] / youTotalRevenue[year])
      } else if (subtract === 'nextYearMinusPreviousYear') {
        returnObject[year] = youCurrentVal - youPrevVal
        returnObject['you' + year] = (returnObject[year] / youTotalRevenue[year])
      } else {
        returnObject[year] = you[metricFinal][year]
        returnObject['you' + year] = (returnObject[year] / youTotalRevenue[year])
      }
    } else {
      returnObject[year] = null
      returnObject['you' + year] = null
    }

    if (avgExists && _.isNumber(avg.operating_expenses_net_income[year])) {
      let avgPrevVal = avg[metricFinal] && _.isNumber(avg[metricFinal][year - 1]) ? avg[metricFinal][year - 1] : 0
      let avgCurrentVal = avg[metricFinal] && _.isNumber(avg[metricFinal][year]) ? avg[metricFinal][year] : 0
      // Check if previous year outside of yearRange exists
      if (years.indexOf(year) === 0 && subtract) {
        if (avg[metricFinal][year - 1] !== null && subtract === 'previousYearMinusNextYear') {
          returnObject['avgVal' + year] = avgPrevVal - avgCurrentVal
          returnObject['avg' + year] = (returnObject['avgVal' + year] / avgTotalRevenue[year])
        } else if (avg[metricFinal][year - 1] !== null && subtract === 'nextYearMinusPreviousYear') {
          returnObject['avgVal' + year] = avgCurrentVal - avgPrevVal
          returnObject['avg' + year] = (returnObject['avgVal' + year] / avgTotalRevenue[year])
        } else {
          returnObject['avgVal' + year] = null
          returnObject['avg' + year] = null
        }
      } else if (subtract === 'previousYearMinusNextYear') {
        returnObject['avgVal' + year] = avgPrevVal - avgCurrentVal
        returnObject['avg' + year] = (returnObject['avgVal' + year] / avgTotalRevenue[year])
      } else if (subtract === 'nextYearMinusPreviousYear') {
        returnObject['avgVal' + year] = avgCurrentVal - avgPrevVal
        returnObject['avg' + year] = (returnObject['avgVal' + year] / avgTotalRevenue[year])
      } else {
        returnObject['avgVal' + year] = avg[metricFinal][year]
        returnObject['avg' + year] = (returnObject['avgVal' + year] / avgTotalRevenue[year])
      }
    } else {
      returnObject['avgVal' + year] = null
      returnObject['avg' + year] = null
    }

    if (returnObject['you' + year] !== null && returnObject['avg' + year] !== null) {
      returnObject['status' + year] = CashFlowCommonSizeArrows(returnObject['you' + year], returnObject['avg' + year], metricFinal)
    } else {
      returnObject['status' + year] = 'n/a'
    }
  })
  return returnObject
}

const netOperatingActivitiesValues = (years, you, avg) => {
  const netOperatingActivities = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youTotalRevenue = you['total_revenue']
  const avgTotalRevenue = avgExists ? avg['total_revenue'] : null;
  let netIncome = CashFlowYearAmounts(years, you, avg, 'operating_expenses_net_income')
  let deprAmor = CashFlowYearAmounts(years, you, avg, 'depreciation_and_amortization')
  let accountsReceivable = CashFlowYearAmounts(years, you, avg, 'accounts_receivable', 'previousYearMinusNextYear')
  let inventory = CashFlowYearAmounts(years, you, avg, 'inventory', 'previousYearMinusNextYear')
  let underBillings = CashFlowYearAmounts(years, you, avg, 'under_billings', 'previousYearMinusNextYear')
  let prepaidOtherCurrentAssets = CashFlowYearAmounts(years, you, avg, 'prepaid_and_other_current_assets', 'previousYearMinusNextYear')
  let accountsPayable = CashFlowYearAmounts(years, you, avg, 'accounts_payable', 'nextYearMinusPreviousYear')
  let accruedLiabilities = CashFlowYearAmounts(years, you, avg, 'accrued_liabilities', 'nextYearMinusPreviousYear')
  let overBillings = CashFlowYearAmounts(years, you, avg, 'over_billings', 'nextYearMinusPreviousYear')
  let otherCurrentLiabilities = CashFlowYearAmounts(years, you, avg, 'other_current_liabilities', 'nextYearMinusPreviousYear')

  years.forEach((year) => {
    if (!netIncome[year] && !deprAmor[year] && !accountsReceivable[year] && !inventory[year] && !underBillings[year] && !prepaidOtherCurrentAssets[year] && !accountsPayable[year] && !accruedLiabilities[year] && !overBillings[year] && !otherCurrentLiabilities[year]) {
      netOperatingActivities[year] = null
    } else {
      netOperatingActivities[year] = Math.round(netIncome[year] + deprAmor[year] + accountsReceivable[year] + inventory[year] + underBillings[year] + prepaidOtherCurrentAssets[year] + accountsPayable[year] + accruedLiabilities[year] + overBillings[year] + otherCurrentLiabilities[year])
      netOperatingActivities['you' + year] = (netOperatingActivities[year] / youTotalRevenue[year])
    }

    if (!netIncome['avgVal' + year] && !deprAmor['avgVal' + year] && !accountsReceivable['avgVal' + year] && !inventory['avgVal' + year] && !underBillings['avgVal' + year] && !prepaidOtherCurrentAssets['avgVal' + year] && !accountsPayable['avgVal' + year] && !accruedLiabilities['avgVal' + year] && !overBillings['avgVal' + year] && !otherCurrentLiabilities['avgVal' + year]) {
      netOperatingActivities['avgVal' + year] = null
    } else {
      netOperatingActivities['avgVal' + year] = Math.round(netIncome['avgVal' + year] + deprAmor['avgVal' + year] + accountsReceivable['avgVal' + year] + inventory['avgVal' + year] + underBillings['avgVal' + year] + prepaidOtherCurrentAssets['avgVal' + year] + accountsPayable['avgVal' + year] + accruedLiabilities['avgVal' + year] + overBillings['avgVal' + year] + otherCurrentLiabilities['avgVal' + year])
      netOperatingActivities['avg' + year] = (netOperatingActivities['avgVal' + year] / avgTotalRevenue[year])
    }

    if (netOperatingActivities['you' + year] !== null && netOperatingActivities['avg' + year] !== null) {
      netOperatingActivities['status' + year] = CashFlowCommonSizeArrows(netOperatingActivities['you' + year], netOperatingActivities['avg' + year], 'net_operating_activities')
    } else {
      netOperatingActivities['status' + year] = 'n/a'
    }
  })
  return netOperatingActivities
}

const netInvestingActivitiesValues = (years, you, avg) => {
  const netInvestingActivities = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youTotalRevenue = you['total_revenue']
  const avgTotalRevenue = avgExists ? avg['total_revenue'] : null;
  let propEquip = CashFlowYearAmounts(years, you, avg, 'property_and_equipment', 'previousYearMinusNextYear')
  let otherLongTermAssets = CashFlowYearAmounts(years, you, avg, 'other_long_term_assets', 'previousYearMinusNextYear')
  years.forEach((year) => {
    if (propEquip[year] === null && otherLongTermAssets[year] === null) {
      netInvestingActivities[year] = null
    } else {
      netInvestingActivities[year] = Math.round(propEquip[year] + otherLongTermAssets[year])
      netInvestingActivities['you' + year] = (netInvestingActivities[year] / youTotalRevenue[year])
    }
    if (propEquip['avgVal' + year] === null && otherLongTermAssets['avgVal' + year] === null) {
      netInvestingActivities['avgVal' + year] = null
    } else {
      netInvestingActivities['avgVal' + year] = Math.round(propEquip['avgVal' + year] + otherLongTermAssets['avgVal' + year])
      netInvestingActivities['avg' + year] = (netInvestingActivities['avgVal' + year] / avgTotalRevenue[year])
    }

    if (netInvestingActivities['you' + year] !== null && netInvestingActivities['avg' + year] !== null) {
      netInvestingActivities['status' + year] = CashFlowCommonSizeArrows(netInvestingActivities['you' + year], netInvestingActivities['avg' + year], 'net_investing_activities')
    } else {
      netInvestingActivities['status' + year] = 'n/a'
    }
  })
  return netInvestingActivities
}

const netFinancingActivitiesValues = (years, you, avg) => {
  const netFinancingActivities = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youTotalRevenue = you['total_revenue']
  const avgTotalRevenue = avgExists ? avg['total_revenue'] : null;
  let lineOfCredit = CashFlowYearAmounts(years, you, avg, 'line_of_credit', 'nextYearMinusPreviousYear')
  let currentPortionOfLTD = CashFlowYearAmounts(years, you, avg, 'current_portion_of_ltd', 'nextYearMinusPreviousYear')
  let longTermDebt = CashFlowYearAmounts(years, you, avg, 'long_term_debt', 'nextYearMinusPreviousYear')
  let equity = createEquity(years, you, avg)
  years.forEach((year) => {
    if (lineOfCredit[year] === null && currentPortionOfLTD[year] === null && longTermDebt[year] === null && equity[year] === null) {
      netFinancingActivities[year] = null
    } else {
      netFinancingActivities[year] = Math.round(lineOfCredit[year] + currentPortionOfLTD[year] + longTermDebt[year] + equity[year])
      netFinancingActivities['you' + year] = (netFinancingActivities[year] / youTotalRevenue[year])
    }

    if (lineOfCredit['avgVal' + year] === null && currentPortionOfLTD['avgVal' + year] === null && longTermDebt['avgVal' + year] === null && equity['avgVal' + year] === null) {
      netFinancingActivities['avgVal' + year] = null
    } else {
      netFinancingActivities['avgVal' + year] = Math.round(lineOfCredit['avgVal' + year] + currentPortionOfLTD['avgVal' + year] + longTermDebt['avgVal' + year] + equity['avgVal' + year])
      netFinancingActivities['avg' + year] = (netFinancingActivities['avgVal' + year] / avgTotalRevenue[year])
    }

    if (netFinancingActivities['you' + year] !== null && netFinancingActivities['avg' + year] !== null) {
      netFinancingActivities['status' + year] = CashFlowCommonSizeArrows(netFinancingActivities['you' + year], netFinancingActivities['avg' + year], 'net_financing_activities')
    } else {
      netFinancingActivities['status' + year] = 'n/a'
    }
  })

  return netFinancingActivities
}

const netChangeInCashValues = (years, you, avg, netOperatingActivities, netInvestingActivities, netFinancingActivities) => {
  const netChangeInCash = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youTotalRevenue = you['total_revenue']
  const avgTotalRevenue = avgExists ? avg['total_revenue'] : null;
  years.forEach((year) => {
    if (netOperatingActivities[year] === null && netInvestingActivities[year] === null && netFinancingActivities[year] === null) {
      netChangeInCash[year] = null
    } else {
      netChangeInCash[year] = netOperatingActivities[year] + netInvestingActivities[year] + netFinancingActivities[year]
      netChangeInCash['you' + year] = (netChangeInCash[year] / youTotalRevenue[year])
    }

    if (netOperatingActivities['avgVal' + year] === null && netInvestingActivities['avgVal' + year] === null && netFinancingActivities['avgVal' + year] === null) {
      netChangeInCash['avgVal' + year] = null
    } else {
      netChangeInCash['avgVal' + year] = netOperatingActivities['avgVal' + year] + netInvestingActivities['avgVal' + year] + netFinancingActivities['avgVal' + year]
      netChangeInCash['avg' + year] = (netChangeInCash['avgVal' + year] / avgTotalRevenue[year])
    }

    if (netChangeInCash['you' + year] !== null && netChangeInCash['avg' + year] !== null) {
      netChangeInCash['status' + year] = CashFlowCommonSizeArrows(netChangeInCash['you' + year], netChangeInCash['avg' + year], 'net_change_cash')
    } else {
      netChangeInCash['status' + year] = 'n/a'
    }
  })

  return netChangeInCash
}

const createEquity = (years, you, avg) => {
  const returnObject = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youTotalRevenue = you['total_revenue']
  const avgTotalRevenue = avgExists ? avg['total_revenue'] : null;
  years.forEach(year => {
    if (you['operating_expenses_net_income'][year] !== null && you['net_worth'][year] !== null) {
      if (you['net_worth'][year - 1] !== null) {
        returnObject[year] = you['net_worth'][year] - you['operating_expenses_net_income'][year] - you['net_worth'][year - 1]
        returnObject['you' + year] = (returnObject[year] / youTotalRevenue[year])
      } else {
        returnObject[year] = null
        returnObject['you' + year] = null
      }
    } else {
      returnObject[year] = null
    }

    if (avgExists && you['operating_expenses_net_income'][year] !== null && you['net_worth'][year] !== null) {
      if (avg['net_worth'][year - 1] !== null) {
        returnObject['avgVal' + year] = avg['net_worth'][year] - avg['operating_expenses_net_income'][year] - avg['net_worth'][year - 1]
        returnObject['avg' + year] = (returnObject['avgVal' + year] / avgTotalRevenue[year])
      } else {
        returnObject['avgVal' + year] = null
        returnObject['avg' + year] = null
      }
    } else {
      returnObject['avgVal' + year] = null
    }

    if (returnObject['you' + year] !== null && returnObject['avg' + year] !== null) {
      returnObject['status' + year] = CashFlowCommonSizeArrows(returnObject['you' + year], returnObject['avg' + year], 'equity')
    } else {
      returnObject['status' + year] = 'n/a'
    }
  })
  return returnObject
}

const createBeginCashPeriod = (years, you, avg) => {
  const cashAtBeginning = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youTotalRevenue = you['total_revenue']
  const avgTotalRevenue = avgExists ? avg['total_revenue'] : null;
  years.forEach((year) => {
    // Don't want to show beginning cash for previous year outside of yearRange that we don't have data for
    if (years.indexOf(year) === 0) {
      if (you.cash[year - 1] !== null) {
        cashAtBeginning[year] = you.cash[year - 1]
        cashAtBeginning['you' + year] = (cashAtBeginning[year] / youTotalRevenue[year])
      } else {
        cashAtBeginning[year] = null
        cashAtBeginning['you' + year] = null
      }

      if (avgExists && avg.cash[year - 1] !== null) {
        cashAtBeginning['avgVal' + year] = avg.cash[year - 1]
        cashAtBeginning['avg' + year] = (cashAtBeginning['avgVal' + year] / avgTotalRevenue[year])
      } else {
        cashAtBeginning['avgVal' + year] = null
        cashAtBeginning['avg' + year] = null
      }
    } else {
      cashAtBeginning[year] = you.cash[year - 1]
      cashAtBeginning['avgVal' + year] = avgExists ? avg.cash[year - 1] : null;
      cashAtBeginning['you' + year] = (cashAtBeginning[year] / youTotalRevenue[year])
      cashAtBeginning['avg' + year] = avgExists ? (cashAtBeginning['avgVal' + year] / avgTotalRevenue[year]) : null;
    }

    if (cashAtBeginning['you' + year] !== null && cashAtBeginning['avg' + year] !== null) {
      cashAtBeginning['status' + year] = CashFlowCommonSizeArrows(cashAtBeginning['you' + year], cashAtBeginning['avg' + year], 'cash')
    } else {
      cashAtBeginning['status' + year] = 'n/a'
    }
  })
  return cashAtBeginning;
}

const freeCashFlowValues = (years, you, avg, operatingCashFlow) => {
  // Capital Expenditure uses same percentages from Property & Equipment
  const freeCashFlow = {}
  let capitalExpenditure = CashFlowYearAmounts(years, you, avg, 'property_and_equipment', 'previousYearMinusNextYear')
  years.forEach((year) => {
    freeCashFlow['you' + year] = capitalExpenditure['you' + year] + operatingCashFlow['you' + year]
    freeCashFlow['avg' + year] = avg ? capitalExpenditure['avg' + year] + operatingCashFlow['avg' + year] : null
    if (freeCashFlow['you' + year] !== null && freeCashFlow['avg' + year] !== null) {
      freeCashFlow['status' + year] = CashFlowCommonSizeArrows(freeCashFlow['you' + year], freeCashFlow['avg' + year], 'free_cash_flow')
    } else {
      freeCashFlow['status' + year] = 'n/a'
    }
  })
  return freeCashFlow
}

const AnnualCashFlowValues = (calcs, calcsAvgType, years, key) => {
  const you = calcs.you;
  const avg = calcsAvgType ? calcs[calcsAvgType] : null;
  let row;
  let getNormalYearKeys = ['operating_expenses_net_income', 'depreciation_and_amortization']
  let previousYearFirstKeys = ['accounts_receivable', 'inventory', 'under_billings', 'prepaid_and_other_current_assets', 'property_and_equipment', 'other_long_term_assets', 'capital_expenditure']
  let nextYearFirstKeys = ['accounts_payable', 'accrued_liabilities', 'over_billings', 'other_current_liabilities', 'line_of_credit', 'current_portion_of_ltd', 'long_term_debt']

  if (previousYearFirstKeys.includes(key)) {
    // no values for capital expenditure but it is the same as property and equipment
    if (key === 'capital_expenditure') {
      row = CashFlowYearAmounts(years, you, avg, 'property_and_equipment', 'previousYearMinusNextYear')
    } else {
      row = CashFlowYearAmounts(years, you, avg, key, 'previousYearMinusNextYear')
    }
  } else if (nextYearFirstKeys.includes(key)) {
    row = CashFlowYearAmounts(years, you, avg, key, 'nextYearMinusPreviousYear')
  } else if (getNormalYearKeys.includes(key) || key === 'cash_end_period') {
    row = CashFlowYearAmounts(years, you, avg, key)
  } else if (key === 'net_operating_activities' || key === 'operating_cash_flow') {
    row = netOperatingActivitiesValues(years, you, avg)
  } else if (key === 'net_investing_activities') {
    row = netInvestingActivitiesValues(years, you, avg)
  } else if (key === 'equity') {
    row = createEquity(years, you, avg)
  } else if (key === 'net_financing_activities') {
    row = netFinancingActivitiesValues(years, you, avg)
  } else if (key === 'net_change_cash') {
    let netOperatingActivities = netOperatingActivitiesValues(years, you, avg)
    let netInvestingActivities = netInvestingActivitiesValues(years, you, avg)
    let netFinancingActivities = netFinancingActivitiesValues(years, you, avg)
    row = netChangeInCashValues(years, you, avg, netOperatingActivities, netInvestingActivities, netFinancingActivities)
  } else if (key === 'cash_beginning_period') {
    row = createBeginCashPeriod(years, you, avg)
  } else if (key === 'free_cash_flow') {
    let operatingCashFlow = netOperatingActivitiesValues(years, you, avg)
    row = freeCashFlowValues(years, you, avg, operatingCashFlow)
  }
  return row;
}

// Annual KFI's
const KFIsCommonSizeArrows = (you, avg, key, parentKey) => {
  let youVal = you
  let avgVal = avg
  let rangeWorst, rangeWorse, rangeBetter, rangeBest;
  const percentKeys = ['total_revenue_growth_rate', 'cash_flow_margin', 'roa', 'roe', 'roce', 'gross_profit', 'operating_profit', 'net_profit', 'gross_profit_common_size_revenue', 'operating_profit_common_size_revenue', 'operating_expenses_net_income_common_size_revenue']
  if (percentKeys.includes(key) && youVal) {
    youVal = you * 100
  }
  if (percentKeys.includes(key) && avgVal) {
    avgVal = avg * 100
  }
  // Only ar_days, inventory_turnover_ratio, debt_to_equity are good with lower values than peers avg
  const goodDownArrowKeys = ['debt_ratio', 'ar_days', 'inventory_turnover_ratio', 'debt_to_equity', 'maintenace_per_golf_hole'];
  const keyIsGoodDown = goodDownArrowKeys.includes(key);
  const ignoreArrowKeys = ['ebitda'];
  if (ignoreArrowKeys.includes(key) || ignoreArrowKeys.includes(parentKey) || !youVal || !avgVal) {
    return 'maintain'
  }

  if (key === 'debt_to_equity' && youVal < 0) {
    return 'worse'
  }

  if (!percentKeys.includes(key) && avgVal) {
    let fivePercent = avgVal * 0.05
    let tenPercent = avgVal * 0.1
    rangeWorst = avgVal - tenPercent;
    rangeWorse = avgVal - fivePercent;
    rangeBetter = avgVal + fivePercent;
    rangeBest = avgVal + tenPercent;
  } else {
    rangeWorst = avgVal - 10;
    rangeWorse = avgVal - 5;
    rangeBetter = avgVal + 5;
    rangeBest = avgVal + 10;
  }

  if (rangeWorse < youVal && youVal < rangeBetter) {
    return 'maintain'
  } else if (rangeWorst < youVal && youVal < rangeWorse) {
    return keyIsGoodDown ? 'better' : 'worse'
  } else if (youVal < rangeWorst) {
    return keyIsGoodDown ? 'best' : 'worst'
  } else if (rangeBest > youVal && youVal > rangeBetter) {
    return keyIsGoodDown ? 'worse' : 'better'
  } else if (youVal > rangeBest) {
    return keyIsGoodDown ? 'worst' : 'best'
  } else {
    return 'n/a'
  }
}

const calculateNetWorkingCapitalYears = (years, you, avg) => {
  const returnObject = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  // Need to common size compare with you total_assets
  const youTotalAssets = you['total_assets'];
  const youTotalCurrentAssets = you['total_current_assets'];
  const youTotalCurrentLiabilities = you['total_current_liabilities'];
  const avgTotalCurrentAssetsCommonSize = avgExists ? avg['total_current_assets_common_size_assets'] : null;
  const avgTotalCurrentLiabilitiesCommonSize = avgExists ? avg['total_current_liabilities_common_size_assets'] : null;
  years.forEach((y) => {
    if (_.isNumber(youTotalCurrentAssets[y]) && _.isNumber(youTotalCurrentLiabilities[y])) {
      returnObject[y] = youTotalCurrentAssets[y] - youTotalCurrentLiabilities[y];
    } else {
      returnObject[y] = null;
    }

    if (avgExists && _.isNumber(avgTotalCurrentAssetsCommonSize[y]) && _.isNumber(avgTotalCurrentLiabilitiesCommonSize[y])) {
      returnObject['avg' + y] = (avgTotalCurrentAssetsCommonSize[y] * youTotalAssets[y]) - (avgTotalCurrentLiabilitiesCommonSize[y] * youTotalAssets[y]);
    } else {
      returnObject['avg' + y] = null;
    }

    returnObject['status' + y] = KFIsCommonSizeArrows(returnObject[y], returnObject['avg' + y], 'net_working_capital')

  })

  return returnObject;
}

// Need to calculate the ebitda correctly with taxes for you and avg values and calulations that use it
const calculateEBITDAYears = (years, you, avg) => {
  let ebitda = {}
  // Need to common size compare with you total_assets
  const youTotalRevenue = you['total_revenue'];
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youNetIncome = you['operating_expenses_net_income'];
  const youInterest = you['interest_and_other_expenses'];
  const youTaxesFS = you['total_other_costs_taxes_- federal & state'];
  const youDeprAmor = you['depreciation_and_amortization'];
  const avgNetIncomeCS = avgExists ? avg['operating_expenses_net_income_common_size_revenue'] : null;
  const avgInterestCS = avgExists ? avg['interest_and_other_expenses_common_size_revenue'] : null;
  const avgTaxesFSCS = avgExists ? avg['total_other_costs_taxes_- federal & state_common_size_revenue'] : null;
  const avgDeprAmorCS = avgExists ? avg['depreciation_and_amortization_common_size_revenue'] : null;

  years.forEach((y) => {
    let youNetIncomeVal = youNetIncome && _.isNumber(youNetIncome[y]) ? youNetIncome[y] : null
    let youInterestVal = youInterest && _.isNumber(youInterest[y]) ? youInterest[y] : null
    let youTaxesFSVal = youTaxesFS && _.isNumber(youTaxesFS[y]) ? youTaxesFS[y] : null;
    let youDeprAmorVal = youDeprAmor && _.isNumber(youDeprAmor[y]) ? youDeprAmor[y] : null
    ebitda[y] = youNetIncomeVal + youInterestVal + youTaxesFSVal + youDeprAmorVal;

    if (avgExists) {
      let avgNetIncomeVal = avgNetIncomeCS && _.isNumber(avgNetIncomeCS[y]) ? avgNetIncomeCS[y] * youTotalRevenue[y] : null
      let avgInterestVal = avgInterestCS && _.isNumber(avgInterestCS[y]) ? avgInterestCS[y] * youTotalRevenue[y] : null
      let avgTaxesFSVal = avgTaxesFSCS && _.isNumber(avgTaxesFSCS[y]) ? avgTaxesFSCS[y] * youTotalRevenue[y] : null;
      let avgDeprAmorVal = avgDeprAmorCS && _.isNumber(avgDeprAmorCS[y]) ? avgDeprAmorCS[y] * youTotalRevenue[y] : null
      ebitda['avg' + y] = avgNetIncomeVal + avgInterestVal + avgTaxesFSVal + avgDeprAmorVal;
    } else {
      ebitda['avg' + y] = null;
    }


    ebitda['status' + y] = KFIsCommonSizeArrows(ebitda[y], ebitda['avg' + y], 'ebitda')

  })
  return ebitda;
}

const calculateSeniorDebtToEBITDAYears = (years, you, avg) => {
  let seniorDebtToEBITDA = {}
  let ebitda = calculateEBITDAYears(years, you, avg);
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youLongTermDebt = you['long_term_debt'];
  const youLineOfCredit = you['line_of_credit'];
  const youCurrentPortionOfLTD = you['current_portion_of_ltd'];
  const avgLongTermDebt = avgExists ? avg['long_term_debt'] : null;
  const avgLineOfCredit = avgExists ? avg['line_of_credit'] : null;
  const avgCurrentPortionOfLTD = avgExists ? avg['current_portion_of_ltd'] : null;

  years.forEach((y) => {
    if (ebitda[y]) {
      seniorDebtToEBITDA[y] = (youLongTermDebt[y] + youLineOfCredit[y] + youCurrentPortionOfLTD[y]) / ebitda[y]
    } else {
      seniorDebtToEBITDA[y] = null;
    }

    if (avgExists && ebitda['avg' + y]) {
      seniorDebtToEBITDA['avg' + y] = (avgLongTermDebt[y] + avgLineOfCredit[y] + avgCurrentPortionOfLTD[y]) / ebitda['avg' + y]
    } else {
      seniorDebtToEBITDA['avg' + y] = null;
    }

    seniorDebtToEBITDA['status' + y] = KFIsCommonSizeArrows(seniorDebtToEBITDA[y], seniorDebtToEBITDA['avg' + y], 'senior_debt_to_ebitda')

  })
  return seniorDebtToEBITDA;
}

const calculateEBITDAtoAnnualInterestYears = (years, you, avg) => {
  let ebitdaToAnnualInterest = {}
  let ebitda = calculateEBITDAYears(years, you, avg);
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youInterest = you['interest_and_other_expenses'];
  const avgInterest = avgExists ? avg['interest_and_other_expenses'] : null;

  years.forEach((y) => {
    if (youInterest[y]) {
      ebitdaToAnnualInterest[y] = ebitda[y] / youInterest[y]
    } else {
      ebitdaToAnnualInterest[y] = null;
    }

    if (avgExists && avgInterest[y]) {
      ebitdaToAnnualInterest['avg' + y] = ebitda['avg' + y] / avgInterest[y]
    } else {
      ebitdaToAnnualInterest['avg' + y] = null;
    }

    ebitdaToAnnualInterest['status' + y] = KFIsCommonSizeArrows(ebitdaToAnnualInterest[y], ebitdaToAnnualInterest['avg' + y], 'ebitda_to_annual_interest')
  })
  return ebitdaToAnnualInterest
}

const calcDaysCashOnHandYears = (years, you, avg) => {
  // Cash / ((Total Operating Expenses + Interest & Other Expense + Taxes - Federal & State + Other (Income) Expense) / 365)
  let daysCashOnHand = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youOpEx = you['total_operating_expenses'];
  const avgOpEx = avgExists ? avg['total_operating_expenses'] : null;

  years.forEach((y) => {
    if (youOpEx[y]) {
      daysCashOnHand[y] = you['cash'][y] / ((youOpEx[y] + you['interest_and_other_expenses'][y] + you['total_other_costs_taxes_- federal & state'][y] + you['other_income_expenses'][y]) / 365)
    } else {
      daysCashOnHand[y] = null;
    }

    if (avgExists && avgOpEx[y]) {
      daysCashOnHand['avg' + y] = avg['cash'][y] / ((avgOpEx[y] + avg['interest_and_other_expenses'][y] + avg['total_other_costs_taxes_- federal & state'][y] + avg['other_income_expenses'][y]) / 365)
    } else {
      daysCashOnHand['avg' + y] = null;
    }

    daysCashOnHand['status' + y] = KFIsCommonSizeArrows(daysCashOnHand[y], daysCashOnHand['avg' + y], 'days_cash_on_hand')
  })
  return daysCashOnHand
}

const calcQuickRatioYears = (years, you, avg) => {
  // (Current Assets - Inventory)/Current Liabilities
  let quickRatio = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youCL = you['total_current_liabilities'];
  const avgCL = avgExists ? avg['total_current_liabilities'] : null;

  years.forEach((y) => {
    if (youCL[y]) {
      quickRatio[y] = (you['total_current_assets'][y] - you['inventory'][y]) / youCL[y]
    } else {
      quickRatio[y] = null;
    }

    if (avgExists && avgCL[y]) {
      quickRatio['avg' + y] = (avg['total_current_assets'][y] - avg['inventory'][y]) / avgCL[y]
    } else {
      quickRatio['avg' + y] = null;
    }

    quickRatio['status' + y] = KFIsCommonSizeArrows(quickRatio[y], quickRatio['avg' + y], 'quick_ratio')
  })
  return quickRatio
}

const calcDebtRatioYears = (years, you, avg) => {
  // (Total Current Liabilities + Long Term Debt) / Total Aassets
  let debtRatio = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youTA = you['total_assets'];
  const avgTA = avgExists ? avg['total_assets'] : null;

  years.forEach((y) => {
    if (youTA[y]) {
      debtRatio[y] = (you['total_current_liabilities'][y] + you['long_term_debt'][y]) / youTA[y]
    } else {
      debtRatio[y] = null;
    }

    if (avgExists && avgTA[y]) {
      debtRatio['avg' + y] = (avg['total_current_liabilities'][y] + avg['long_term_debt'][y]) / avgTA[y]
    } else {
      debtRatio['avg' + y] = null;
    }

    debtRatio['status' + y] = KFIsCommonSizeArrows(debtRatio[y], debtRatio['avg' + y], 'debt_ratio')
  })
  return debtRatio
}

const calcOperatingCashFlowYears = (years, you, avg) => {
  // 	Net cash flow from operating activities / Current Liabilities
  let operatingCashFlow = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youCL = you['total_current_liabilities'];
  const avgCL = avgExists ? avg['total_current_liabilities'] : null;
  const opCashFLow = netOperatingActivitiesValues(years, you, avg)

  years.forEach((y) => {
    if (youCL[y]) {
      operatingCashFlow[y] = opCashFLow[y] / youCL[y]
    } else {
      operatingCashFlow[y] = null;
    }

    if (avgExists && avgCL[y]) {
      operatingCashFlow['avg' + y] = opCashFLow['avgVal' + y] / avgCL[y]
    } else {
      operatingCashFlow['avg' + y] = null;
    }

    operatingCashFlow['status' + y] = KFIsCommonSizeArrows(operatingCashFlow[y], operatingCashFlow['avg' + y], 'operating_cash_flow_kfi')
  })
  return operatingCashFlow
}

const calcCashFlowMarginYears = (years, you, avg) => {
  // 	Net cash flow from operating activities / Net Revenue (Total Revenue)
  let cashFlowMargin = {}
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;
  const youTR = you['total_revenue'];
  const avgTR = avgExists ? avg['total_revenue'] : null;
  const opCashFLow = netOperatingActivitiesValues(years, you, avg)

  years.forEach((y) => {
    if (youTR[y]) {
      cashFlowMargin[y] = opCashFLow[y] / youTR[y]
    } else {
      cashFlowMargin[y] = null;
    }

    if (avgExists && avgTR[y]) {
      cashFlowMargin['avg' + y] = opCashFLow['avgVal' + y] / avgTR[y]
    } else {
      cashFlowMargin['avg' + y] = null;
    }

    cashFlowMargin['status' + y] = KFIsCommonSizeArrows(cashFlowMargin[y], cashFlowMargin['avg' + y], 'cash_flow_margin')
  })
  return cashFlowMargin
}

const AnnualKFIsValues = (calcs, calcsAvgType = 'avg', years, key, report = 'money') => {
  const you = report === 'money' ? calcs.you : calcs.avg;
  const avg = calcsAvgType ? calcs[calcsAvgType] : null;
  let row;
  const avgExists = avg && Object.keys(avg).length > 0 ? true : false;

  if (key === 'net_working_capital') {
    row = calculateNetWorkingCapitalYears(years, you, avg)
  } else if (key === 'ebitda') {
    row = calculateEBITDAYears(years, you, avg)
  } else if (key === 'senior_debt_to_ebitda') {
    row = calculateSeniorDebtToEBITDAYears(years, you, avg)
  } else if (key === 'ebitda_to_annual_interest') {
    row = calculateEBITDAtoAnnualInterestYears(years, you, avg)
  } else if (key === 'days_cash_on_hand') {
    row = calcDaysCashOnHandYears(years, you, avg)
  } else if (key === 'quick_ratio') {
    row = calcQuickRatioYears(years, you, avg)
  } else if (key === 'debt_ratio') {
    row = calcDebtRatioYears(years, you, avg)
  } else if (key === 'operating_cash_flow_kfi') {
    row = calcOperatingCashFlowYears(years, you, avg)
  } else if (key === 'cash_flow_margin') {
    row = calcCashFlowMarginYears(years, you, avg)
  } else if (key === 'maintenace_per_golf_hole') {
    let yearVals = {};
    years.forEach(y => {
      yearVals[y] = _.isNumber(you['total_operations_ops_- golf']) && _.isNumber(you['number_of_holes_on_golf_course']) ? (you['total_operations_ops_- golf'][y] / you['number_of_holes_on_golf_course'][y]) : null;;
      yearVals['avg' + y] = avgExists && _.isNumber(avg['total_operations_ops_- golf']) && _.isNumber(avg['number_of_holes_on_golf_course']) ? (avg['total_operations_ops_- golf'][y] / avg['number_of_holes_on_golf_course'][y]) : null;
      yearVals['status'] = KFIsCommonSizeArrows(yearVals[y], yearVals['avg' + y], 'maintenace_per_golf_hole')
    })
    row = yearVals;
  } else if (['operating_expenses_net_income', 'interest_and_other_expenses', 'total_other_costs_taxes_- federal & state', 'depreciation_and_amortization'].includes(key)) {
    let youTotalRevenue = you['total_revenue']
    let youVals = you[key] ? you[key] : null;
    let compareKey = key + '_common_size_revenue'
    let avgVals = avgExists && avg[compareKey] ? avg[compareKey] : null;
    let yearVals = {};

    if (youVals || avgVals) {
      years.forEach(y => {
        yearVals[y] = youVals && _.isNumber(youVals[y]) ? youVals[y] : null;
        yearVals['avg' + y] = avgExists && avgVals && _.isNumber(avgVals[y]) ? avgVals[y] * youTotalRevenue[y] : null;
        yearVals['status' + y] = KFIsCommonSizeArrows(yearVals[y], yearVals['avg' + y], key)
      })
    }
    row = yearVals;
  } else {
    let finalKey = key
    if (key === 'gross_profit') {
      finalKey = 'gross_profit_common_size_revenue'
    } else if (key === 'operating_profit') {
      finalKey = 'operating_profit_common_size_revenue'
    } else if (key === 'net_profit') {
      finalKey = 'operating_expenses_net_income_common_size_revenue'
    }

    let youVals = you[finalKey] ? you[finalKey] : null;
    let avgVals = avgExists && avg[finalKey] ? avg[finalKey] : null;
    let yearVals = {};

    if (youVals || avgVals) {
      years.forEach(y => {
        yearVals[y] = youVals && _.isNumber(youVals[y]) ? youVals[y] : null;
        yearVals['avg' + y] = avgExists && avgVals && _.isNumber(avgVals[y]) ? avgVals[y] : null;
        yearVals['status' + y] = KFIsCommonSizeArrows(yearVals[y], yearVals['avg' + y], finalKey)
      })
    }

    row = yearVals;
  }
  return row;
}

// const calculateNetWorkingCapitalYTD = (calcs, yearRange) => {
//   let netWorkingCapital = {};
//   yearRange.forEach((y) => {
//     netWorkingCapital[y] = objReducer(calcs[y][youOrAvg]['total_current_assets']) - objReducer(whichCalcs[y][youOrAvg]['total_current_liabilities']);
//   })
//   return netWorkingCapital;
// }

// // YTD KFIs
// const YTDKFIsValues = (row, calcs, prevYearCalcs, yearRange) => {
//   let youValues = null;
//   let compareValues = null;
//   if (row.key) {

//   } else if (row.key === 'net_working_capital') {
//     youValues = calculateNetWorkingCapitalYTD(calcs,)
//     compareValues = calculateNetWorkingCapitalYTD()
//   }
// }

const monthlyNetProfit = (calcs, numberOfMonths) => {
  const netProfit = {}

  for (let i = 1; i <= numberOfMonths; i++) {
    let numerator = calcs['operating_expenses_net_income'][i]
    let denominator = calcs['total_revenue'][i]

    if (denominator) {
      netProfit[i] = (numerator / denominator)
    } else {
      netProfit[i] = null
    }
  }

  return netProfit
}

const monthlyDaysCashOnHand = (calcs, numberOfMonths) => {
  const daysCashOnHand = {}

  for (let i = 1; i <= numberOfMonths; i++) {
    let numerator = calcs['cash'][i]
    let denominator = calcs['total_operating_expenses'][i]

    if (denominator) {
      daysCashOnHand[i] = numerator / ((denominator + calcs['interest_and_other_expenses'][i] + calcs['total_other_costs_taxes_- federal & state'][i] + calcs['other_income_expenses'][i]) / 365)
    } else {
      daysCashOnHand[i] = null
    }
  }

  return daysCashOnHand
}

const monthlyQuickRatio = (calcs, numberOfMonths) => {
  const quickRatio = {}

  for (let i = 1; i <= numberOfMonths; i++) {
    let denominator = calcs['total_current_liabilities'][i]

    if (denominator) {
      quickRatio[i] = (calcs['total_current_assets'][i] - calcs['inventory'][i]) / denominator
    } else {
      quickRatio[i] = null
    }
  }

  return quickRatio
}

const monthlyDebtRatio = (calcs, numberOfMonths) => {
  const debtRatio = {}

  for (let i = 1; i <= numberOfMonths; i++) {
    let denominator = calcs['total_assets'][i]

    if (denominator) {
      debtRatio[i] = (calcs['total_current_liabilities'][i] + calcs['long_term_debt'][i]) / denominator
    } else {
      debtRatio[i] = null
    }
  }

  return debtRatio
}

const monthlyOperatingCashFlow = (key, calcs, prevYearCalcs, numberOfMonths) => {
  const opCashFlowMetric = {}
  // Net cash flow from operating activities
  let netOperatingActivities = netOperatingActivitiesValuesMonths(calcs, prevYearCalcs)

  for (let i = 1; i <= numberOfMonths; i++) {
    let denominator = key === 'operating_cash_flow_kfi' ? calcs['total_current_liabilities'][i] : calcs['total_revenue'][i]

    if (denominator) {
      opCashFlowMetric[i] = netOperatingActivities[i] / denominator
    } else {
      opCashFlowMetric[i] = null
    }
  }

  return opCashFlowMetric
}

// Monthly KFIs
const MonthlyKFIsValues = (row, numberOfMonths, compareWith, calcs, prevYearCalcs, yoyCalcs, prevYoyCalcs, forecastVals = null) => {
  let youValues = null;
  let compareValues = null;
  let ytdYou = null;
  let ytdCompare = null;
  const trailing12MonthsKeys = ['roa', 'roe', 'roce', 'asset_turnover_ratio', 'inventory_turnover_ratio', 'ar_days', 'ap_days', 'senior_debt_to_ebitda', 'ebitda_to_annual_interest', 'debt_to_equity'];
  const ebitdaKeys = ['operating_expenses_net_income', 'interest_and_other_expenses', 'total_other_costs_taxes_- federal & state', 'depreciation_and_amortization']

  if (row.key === 'total_revenue_growth_rate') {
    youValues = monthlyTotalRevenueGRCalc(calcs['you'], prevYearCalcs['you'])

    if (compareWith === 'Default:YOY') {
      compareValues = monthlyTotalRevenueGRCalc(prevYearCalcs['you'], prevYoyCalcs['you'])
    } else if (compareWith === 'Default:Peers') {
      compareValues = monthlyTotalRevenueGRCalc(calcs['avg'], prevYearCalcs['avg'])
    } else if (compareWith.includes('Forecast')) {
      compareValues = monthlyTotalRevenueGRCalc(forecastVals, prevYearCalcs['you'])
    }

    let youCalcsLength = Object.values(youValues).length
    ytdYou = youValues[youCalcsLength]
    ytdCompare = compareValues ? compareValues[youCalcsLength] : null;
  } else if (row.key === 'current_ratio') {
    youValues = ratioCalculations('total_current_assets', calcs['you'])

    if (compareWith === 'Default:YOY') {
      compareValues = ratioCalculations('total_current_assets', prevYearCalcs['you'])
    } else if (compareWith === 'Default:Peers') {
      compareValues = ratioCalculations('total_current_assets', calcs['avg'])
    } else if (compareWith.includes('Forecast')) {
      compareValues = ratioCalculations('total_current_assets', forecastVals)
    }
    let youCalcsLength = Object.values(youValues).length
    ytdYou = youValues[youCalcsLength]
    ytdCompare = compareValues ? compareValues[youCalcsLength] : null;
  } else if (row.key === 'gross_profit') {
    youValues = ratioCalculationsMoney('gross_profit', calcs['you'], prevYearCalcs['you'], numberOfMonths)

    if (compareWith === 'Default:YOY') {
      compareValues = ratioCalculationsMoney('gross_profit', prevYearCalcs['you'], prevYoyCalcs['you'], numberOfMonths)
    } else if (compareWith === 'Default:Peers') {
      compareValues = ratioCalculationsMoney('gross_profit', calcs['avg'], prevYearCalcs['avg'], numberOfMonths)
    } else if (compareWith.includes('Forecast')) {
      compareValues = ratioCalculationsMoney('gross_profit', forecastVals, prevYearCalcs['you'], numberOfMonths)
    }
    let youCalcsLength = Object.values(youValues).length
    ytdYou = youValues[youCalcsLength]
    ytdCompare = compareValues ? compareValues[youCalcsLength] : null;
  } else if (row.key === 'operating_profit') {
    youValues = ratioCalculationsMoney('operating_profit', calcs['you'], prevYearCalcs['you'], numberOfMonths)

    if (compareWith === 'Default:YOY') {
      compareValues = ratioCalculationsMoney('operating_profit', prevYearCalcs['you'], prevYoyCalcs['you'], numberOfMonths)
    } else if (compareWith === 'Default:Peers') {
      compareValues = ratioCalculationsMoney('operating_profit', calcs['avg'], prevYearCalcs['avg'], numberOfMonths)
    } else if (compareWith.includes('Forecast')) {
      compareValues = ratioCalculationsMoney('operating_profit', forecastVals, prevYearCalcs['you'], numberOfMonths)
    }

    let youCalcsLength = Object.values(youValues).length
    ytdYou = youValues[youCalcsLength]
    ytdCompare = compareValues ? compareValues[youCalcsLength] : null;

  } else if (row.key === 'net_profit') {
    // Just use a month's values
    youValues = monthlyNetProfit(calcs['you'], numberOfMonths)
    if (compareWith === 'Default:YOY') {
      compareValues = monthlyNetProfit(prevYearCalcs['you'], numberOfMonths)
    } else if (compareWith === 'Default:Peers') {
      compareValues = monthlyNetProfit(calcs['avg'], numberOfMonths)
    } else if (compareWith.includes('Forecast')) {
      compareValues = monthlyNetProfit(forecastVals, numberOfMonths)
    }

    let youCalcsLength = Object.values(youValues).length
    ytdYou = youValues[youCalcsLength]
    ytdCompare = compareValues ? compareValues[youCalcsLength] : null;

  } else if (row.key === 'days_cash_on_hand') {
    // Just use a month's values
    youValues = monthlyDaysCashOnHand(calcs['you'], numberOfMonths)
    if (compareWith === 'Default:YOY') {
      compareValues = monthlyDaysCashOnHand(prevYearCalcs['you'], numberOfMonths)
    } else if (compareWith === 'Default:Peers') {
      compareValues = monthlyDaysCashOnHand(calcs['avg'], numberOfMonths)
    } else if (compareWith.includes('Forecast')) {
      compareValues = monthlyDaysCashOnHand(forecastVals, numberOfMonths)
    }

    let youCalcsLength = Object.values(youValues).length
    ytdYou = youValues[youCalcsLength]
    ytdCompare = compareValues ? compareValues[youCalcsLength] : null;

  } else if (row.key === 'quick_ratio') {
    // Just use a month's values
    youValues = monthlyQuickRatio(calcs['you'], numberOfMonths)
    if (compareWith === 'Default:YOY') {
      compareValues = monthlyQuickRatio(prevYearCalcs['you'], numberOfMonths)
    } else if (compareWith === 'Default:Peers') {
      compareValues = monthlyQuickRatio(calcs['avg'], numberOfMonths)
    } else if (compareWith.includes('Forecast')) {
      compareValues = monthlyQuickRatio(forecastVals, numberOfMonths)
    }

    let youCalcsLength = Object.values(youValues).length
    ytdYou = youValues[youCalcsLength]
    ytdCompare = compareValues ? compareValues[youCalcsLength] : null;

  } else if (row.key === 'debt_ratio') {
    // Just use a month's values
    youValues = monthlyDebtRatio(calcs['you'], numberOfMonths)
    if (compareWith === 'Default:YOY') {
      compareValues = monthlyDebtRatio(prevYearCalcs['you'], numberOfMonths)
    } else if (compareWith === 'Default:Peers') {
      compareValues = monthlyDebtRatio(calcs['avg'], numberOfMonths)
    } else if (compareWith.includes('Forecast')) {
      compareValues = monthlyDebtRatio(forecastVals, numberOfMonths)
    }

    let youCalcsLength = Object.values(youValues).length
    ytdYou = youValues[youCalcsLength]
    ytdCompare = compareValues ? compareValues[youCalcsLength] : null;
  } else if (row.key === 'operating_cash_flow_kfi' || row.key === 'cash_flow_margin') {
    // Just use a month's values
    youValues = monthlyOperatingCashFlow(row.key, calcs['you'], prevYearCalcs['you'], numberOfMonths)
    //  Not sure here...
    if (compareWith === 'Default:YOY') {
      compareValues = monthlyOperatingCashFlow(row.key, yoyCalcs['you'], prevYoyCalcs['you'], numberOfMonths)
    } else if (compareWith === 'Default:Peers') {
      compareValues = monthlyOperatingCashFlow(row.key, calcs['avg'], prevYearCalcs['avg'], numberOfMonths)
    } else if (compareWith.includes('Forecast')) {
      compareValues = monthlyOperatingCashFlow(row.key, forecastVals, prevYearCalcs['you'], numberOfMonths)
    }

    let youCalcsLength = Object.values(youValues).length
    ytdYou = youValues[youCalcsLength]
    ytdCompare = compareValues ? compareValues[youCalcsLength] : null;

  } else if (row.key === 'net_working_capital') {
    // Given net working capital ratio values are not correct and need to be recalculated
    youValues = netWorkingCapitalCalculation(calcs['you'])

    if (compareWith === 'Default:YOY') {
      compareValues = netWorkingCapitalCalculation(prevYearCalcs['you'])
    } else if (compareWith === 'Default:Peers') {
      compareValues = netWorkingCapitalCalculation(calcs['avg'])
    } else if (compareWith.includes('Forecast')) {
      compareValues = netWorkingCapitalCalculation(forecastVals)
    }
    let youCalcsLength = Object.values(youValues).length
    ytdYou = youValues[youCalcsLength]
    ytdCompare = compareValues ? compareValues[youCalcsLength] : null;
  } else if (row.key === 'net_working_capital_ratio') {
    youValues = netWorkingCapitalRatioCalculation(calcs['you'])

    if (compareWith === 'Default:YOY') {
      compareValues = netWorkingCapitalRatioCalculation(prevYearCalcs['you'])
    } else if (compareWith === 'Default:Peers') {
      compareValues = netWorkingCapitalRatioCalculation(calcs['avg'])
    } else if (compareWith.includes('Forecast')) {
      compareValues = netWorkingCapitalRatioCalculation(forecastVals)
    }
    let youCalcsLength = Object.values(youValues).length
    ytdYou = youValues[youCalcsLength]
    ytdCompare = compareValues ? compareValues[youCalcsLength] : null;
  } else if (trailing12MonthsKeys.includes(row.key)) {
    youValues = trailing12MonthCalculations(row.copy, calcs['you'], prevYearCalcs['you'])

    if (compareWith === 'Default:YOY') {
      compareValues = trailing12MonthCalculations(row.copy, prevYearCalcs['you'], prevYoyCalcs['you'])
    } else if (compareWith === 'Default:Peers') {
      compareValues = trailing12MonthCalculations(row.copy, calcs['avg'], prevYearCalcs['avg'])
    } else if (compareWith.includes('Forecast')) {
      compareValues = trailing12MonthCalculations(row.copy, forecastVals, prevYearCalcs['you'])
    }
    if (youValues) {
      let youCalcsLength = Object.values(youValues).length
      ytdYou = youValues[youCalcsLength]
      ytdCompare = compareValues ? compareValues[youCalcsLength] : null;
    } else {
      ytdYou = null
      ytdCompare = null
    }
  } else if (row.key === 'ebitda') {
    youValues = calculateEBITDA(calcs['you'])

    if (compareWith === 'Default:YOY') {
      compareValues = calculateEBITDA(prevYearCalcs['you'])
    } else if (compareWith === 'Default:Peers') {
      compareValues = calculateEBITDA(calcs['avg'])
    } else if (compareWith.includes('Forecast')) {
      compareValues = calculateEBITDA(forecastVals)
    }
    let youCalcsLength = Object.values(youValues).length
    let arrayOfComparisonCalcedVals = [];
    for (let i = 1; i <= youCalcsLength; i++) {
      compareValues ? arrayOfComparisonCalcedVals.push(compareValues[i]) : arrayOfComparisonCalcedVals.push(null)
    }
    ytdYou = Object.values(youValues).reduce((a, b) => a + b, 0)
    ytdCompare = compareValues ? arrayOfComparisonCalcedVals.reduce((a, b) => a + b, 0) : null;
  } else if (ebitdaKeys.includes(row.key)) {
    youValues = calcs['you'][row.key]
    if (youValues) {
      if (compareWith === 'Default:YOY') {
        compareValues = prevYearCalcs['you'][row.key]
      } else if (compareWith === 'Default:Peers') {
        compareValues = calcs['avg'][row.key]
      } else if (compareWith.includes('Forecast')) {
        compareValues = forecastVals[row.key]
      }
      let youCalcsLength = 0
      Object.values(youValues).forEach(value => value !== null ? youCalcsLength += 1 : null)
      let arrayOfComparisonCalcedVals = [];
      if (compareValues) {
        for (let i = 1; i <= youCalcsLength; i++) {
          arrayOfComparisonCalcedVals.push(compareValues[i])
        }
      }
      ytdYou = Object.values(youValues).reduce((a, b) => a + b, 0)
      ytdCompare = compareValues ? arrayOfComparisonCalcedVals.reduce((a, b) => a + b, 0) : null;
    }
  }

  return { youValues, compareValues, ytdYou, ytdCompare };
}

const cashFlowTitleKeys = [
  {
    title: 'Cash flow from operating activities:',
    paddingLeft: 0,
    fontStyle: 'none',
  },
  {
    title: 'Net Income',
    key: 'operating_expenses_net_income',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Depreciation & Amortization',
    key: 'depreciation_and_amortization',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Accounts Receivable',
    key: 'accounts_receivable',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Inventory',
    key: 'inventory',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Under-Billings',
    key: 'under_billings',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Prepaid & Other Current Assets',
    key: 'prepaid_and_other_current_assets',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Accounts Payable',
    key: 'accounts_payable',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Accrued Liabilities',
    key: 'accrued_liabilities',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Over-Billings',
    key: 'over_billings',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Other Current Liabilities',
    key: 'other_current_liabilities',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Net cash flow from operating activities',
    key: 'net_operating_activities',
    paddingLeft: 0,
    fontStyle: 'italic',
  },
  {
    title: 'Cash flow from investing activities:',
    paddingLeft: 0,
    fontStyle: 'none',
  },
  {
    title: 'Property & Equipment',
    key: 'property_and_equipment',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Other Long Term Assets',
    key: 'other_long_term_assets',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Net cash flow from investing activities',
    key: 'net_investing_activities',
    paddingLeft: 0,
    fontStyle: 'italic',
  },
  {
    title: 'Cash flow from financing activities:',
    paddingLeft: 0,
    fontStyle: 'none',
  },
  {
    title: 'Line of Credit',
    key: 'line_of_credit',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Current Portion of LTD',
    key: 'current_portion_of_ltd',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Long Term Debt',
    key: 'long_term_debt',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Equity',
    key: 'equity',
    paddingLeft: '18px',
    fontStyle: 'none',
  },
  {
    title: 'Net cash flow from financing activities',
    key: 'net_financing_activities',
    paddingLeft: 0,
    fontStyle: 'italic',
  },
  {
    title: 'Net change in cash and cash equivalents',
    key: 'net_change_cash',
    paddingLeft: 0,
    fontStyle: 'none',
  },
  {
    title: 'Cash at beginning of period',
    key: 'cash',
    paddingLeft: 0,
    fontStyle: 'none',
  },
  {
    title: 'Cash at end of period',
    key: 'cash',
    paddingLeft: 0,
    fontStyle: 'none',
  },
  {
    title: 'Free Cash Flow:',
    paddingLeft: 0,
    fontStyle: 'none',
  },
  {
    title: 'Operating cash flow',
    key: 'operating_cash_flow',
    paddingLeft: 0,
    fontStyle: 'none',
  },
  {
    title: 'Capital Expenditure',
    key: 'capital_expenditure',
    paddingLeft: 0,
    fontStyle: 'none',
  },
  {
    title: 'Free Cash Flow',
    key: 'free_cash_flow',
    paddingLeft: 0,
    fontStyle: 'italic',
  }
];

const objReducer = (obj) => {
  let reducedObject = undefined;
  if (obj) {
    reducedObject = Object.values(obj).reduce((a, b) => a + b);
  }
  return reducedObject;
}
// 2 Year YTD calculations not trailing 12 calcs! We use monthly values for the 2 yeat YTD.
const calculateCurrentRatio = (calcs) => {
  let totalCurrentAssets = objReducer(calcs.total_current_assets);
  let totalCurrentLiabilities = objReducer(calcs.total_current_liabilities);
  return totalCurrentLiabilities ? (totalCurrentAssets / totalCurrentLiabilities) : null;
}

const calculateNetWorkingCapital = (calcs) => {
  let totalCurrentAssets = objReducer(calcs.total_current_assets);
  let totalCurrentLiabilities = objReducer(calcs.total_current_liabilities);
  return totalCurrentAssets - totalCurrentLiabilities;
}

const calculateNetWorkingCapitalRatio = (calcs) => {
  let totalAssets = objReducer(calcs.total_assets);
  let totalCurrentAssets = objReducer(calcs.total_current_assets);
  let totalCurrentLiabilities = objReducer(calcs.total_current_liabilities);
  return totalAssets ? ((totalCurrentAssets - totalCurrentLiabilities) / totalAssets) : null;
}
const calculateROA = (calcs) => {
  let totalAssets = objReducer(calcs.total_assets);
  let netIncome = objReducer(calcs.operating_expenses_net_income);
  return totalAssets ? netIncome / totalAssets : null;
}
const calculateROE = (calcs) => {
  let netWorth = objReducer(calcs.net_worth);
  let netIncome = objReducer(calcs.operating_expenses_net_income);
  return netWorth ? netIncome / netWorth : null;
}
const calculateROCE = (calcs) => {
  let netWorth = objReducer(calcs.net_worth);
  let longTermDebt = objReducer(calcs.long_term_debt);
  let operatingProfit = objReducer(calcs.operating_profit);
  let denominator = (netWorth + longTermDebt)
  return denominator ? operatingProfit / denominator : null;
}

const calculateROI = (calcs) => {
  let netIncome = objReducer(calcs.operating_expenses_net_income);
  let capitalStock = objReducer(calcs.capital_stock);
  return capitalStock ? netIncome / capitalStock : null;
}

const calculateNetProfit = (calcs) => {
  let totalRevenue = objReducer(calcs.total_revenue);
  let netIncome = objReducer(calcs.operating_expenses_net_income);
  return totalRevenue ? (netIncome / totalRevenue) : null;
}

const calculateDaysCash = (calcs) => {
  let totalOpEx = objReducer(calcs.total_operating_expenses);
  let cash = objReducer(calcs.cash);
  let interestAndOtherExpenses = objReducer(calcs.interest_and_other_expenses)
  let taxes = objReducer(calcs['total_other_costs_taxes_- federal & state'])
  let otherIncomeExpenses = objReducer(calcs.other_income_expenses)
  return totalOpEx ? cash / ((totalOpEx + interestAndOtherExpenses + taxes + otherIncomeExpenses) / 365) : null;
}

const calculateQuickRatio = (calcs) => {
  let totalCL = objReducer(calcs.total_current_liabilities);
  let totalAssets = objReducer(calcs.total_current_assets);
  let inventory = objReducer(calcs.inventory);
  return totalCL ? (totalAssets - inventory) / totalCL : null;
}

const calculateDebtRatio = (calcs) => {
  let totalAssets = objReducer(calcs.total_assets);
  let totalCL = objReducer(calcs.total_current_liabilities);
  let longTermDebt = objReducer(calcs.long_term_debt);
  return totalAssets ? (totalCL + longTermDebt) / totalAssets : null;
}

const calculateOperatingCashFlow = (calcs, previousYearCalcs) => {
  let totalCL = objReducer(calcs.total_current_liabilities);
  let opCashFLow = netOperatingActivitiesValuesMonths(calcs, previousYearCalcs, true);
  return totalCL ? (opCashFLow / totalCL) : null;
}

const calculateCashFlowMargin = (calcs, previousYearCalcs) => {
  let totalRevenue = objReducer(calcs.total_revenue);
  let opCashFLow = netOperatingActivitiesValuesMonths(calcs, previousYearCalcs, true);
  return totalRevenue ? (opCashFLow / totalRevenue) : null;
}

const calculateGrossProfit = (calcs) => {
  let totalRevenue = objReducer(calcs.total_revenue);
  let grossProfit = objReducer(calcs.gross_profit);
  return totalRevenue ? (grossProfit / totalRevenue) : null;
}

const calculateOperatingProfit = (calcs) => {
  let totalRevenue = objReducer(calcs.total_revenue);
  let operatingProfit = objReducer(calcs.operating_profit);
  return totalRevenue ? (operatingProfit / totalRevenue) : null;
}

const calculateAssetTurnoverRatio = (calcs) => {
  let totalAssets = objReducer(calcs.total_assets);
  let totalRevenue = objReducer(calcs.total_revenue);
  return totalAssets ? totalRevenue / totalAssets : null;
}

const calculateInventoryTurnoverRatio = (calcs, previousYearCalcs) => {
  let currentYearInventory = objReducer(calcs.inventory);
  let previousYearInventory = objReducer(previousYearCalcs.inventory);
  let materialCosts = objReducer(calcs.materials_costs);
  let denominator = (currentYearInventory + previousYearInventory);
  return denominator ? (materialCosts / ((denominator) / 2)) : null;
}

const calculateARDays = (calcs) => {
  let accountsReceivable = objReducer(calcs.accounts_receivable);
  let totalRevenue = objReducer(calcs.total_revenue);
  return totalRevenue ? ((360 * accountsReceivable) / totalRevenue) : null;
}

const calculateAPDays = (calcs) => {
  let accountsPayable = objReducer(calcs.accounts_payable);
  let totalCosts = objReducer(calcs.total_costs);
  let laborCosts = objReducer(calcs.labor_costs);
  let totalOpEx = objReducer(calcs.total_operating_expenses);
  let taxBenies = objReducer(calcs.total_taxes_and_benefits);
  let totalCompensation = objReducer(calcs.total_compensation);
  let denominator = (totalCosts - laborCosts + totalOpEx - taxBenies - totalCompensation);
  return denominator ? ((360 * accountsPayable) / denominator) : null;
}

const calculateDebtToEquity = (calcs) => {
  let netWorth = objReducer(calcs.net_worth);
  let totalCurrentLiabilities = objReducer(calcs.total_current_liabilities);
  let longTermDebt = objReducer(calcs.long_term_debt);
  return netWorth ? ((totalCurrentLiabilities + longTermDebt) / netWorth) : null;
}

const calculateEBITDAnormal = (calcs) => {
  let ebitda = 0;
  if (calcs.operating_expenses_net_income || calcs.interest_and_other_expenses || calcs['total_other_costs_taxes_- federal & state'] || calcs.depreciation_and_amortization) {
    ebitda = (objReducer(calcs.operating_expenses_net_income) +
      objReducer(calcs.interest_and_other_expenses) +
      objReducer(calcs['total_other_costs_taxes_- federal & state']) +
      objReducer(calcs.depreciation_and_amortization))
  }
  return ebitda;
}

const calculateSeniorDebtToEBITDA = (calcs) => {
  let seniorDebtToebitda = 0;
  let ebitda = calculateEBITDAnormal(calcs);
  if (ebitda === 0 || ebitda === null) {
    seniorDebtToebitda = null
  } else {
    seniorDebtToebitda = (objReducer(calcs.long_term_debt) +
      objReducer(calcs.line_of_credit) +
      objReducer(calcs.current_portion_of_ltd)) / ebitda;
  }
  return seniorDebtToebitda;
}

const calculateEBITDAtoAnnualInterest = (calcs) => {
  let ebitdaToAnnualInterest = 0;
  let ebitda = calculateEBITDAnormal(calcs);
  if (objReducer(calcs['interest_and_other_expenses']) === 0 || objReducer(calcs['interest_and_other_expenses']) === null) {
    ebitdaToAnnualInterest = null
  } else {
    ebitdaToAnnualInterest = ebitda / objReducer(calcs['interest_and_other_expenses'])
  }
  return ebitdaToAnnualInterest;
}

// Calulations for 2 Year to Date KFI metrics. Not trailing 12 months.
const calculateKFIMetric = (key, calcs, prevYearCalcsMonthly, prevYearCalcsMonthlyFullYear) => {
  let finalValue;
  switch (key) {
    case ('total_revenue_growth_rate'):
      finalValue = ytdTotalRevenueGRCalc(calcs, prevYearCalcsMonthly)
      break;
    case ('net_profit'):
      finalValue = calculateNetProfit(calcs)
      break;
    case ('days_cash_on_hand'):
      finalValue = calculateDaysCash(calcs)
      break;
    case ('quick_ratio'):
      finalValue = calculateQuickRatio(calcs)
      break;
    case ('debt_ratio'):
      finalValue = calculateDebtRatio(calcs)
      break;
    case ('operating_cash_flow_kfi'):
      finalValue = calculateOperatingCashFlow(calcs, prevYearCalcsMonthlyFullYear)
      break;
    case ('cash_flow_margin'):
      finalValue = calculateCashFlowMargin(calcs, prevYearCalcsMonthlyFullYear)
      break;
    case ('current_ratio'):
      finalValue = calculateCurrentRatio(calcs);
      break;
    case ('net_working_capital'):
      finalValue = calculateNetWorkingCapital(calcs);
      break;
    case ('net_working_capital_ratio'):
      finalValue = calculateNetWorkingCapitalRatio(calcs);
      break;
    case ('roa'):
      finalValue = calculateROA(calcs);
      break;
    case ('roe'):
      finalValue = calculateROE(calcs);
      break;
    case ('roce'):
      finalValue = calculateROCE(calcs);
      break;
    case ('mkt_roi'):
      finalValue = calculateROI(calcs);
      break;
    case ('gross_profit'):
      finalValue = calculateGrossProfit(calcs);
      break;
    case ('operating_profit'):
      finalValue = calculateOperatingProfit(calcs);
      break;
    case ('asset_turnover_ratio'):
      finalValue = calculateAssetTurnoverRatio(calcs);
      break;
    case ('inventory_turnover_ratio'):
      finalValue = calculateInventoryTurnoverRatio(calcs, prevYearCalcsMonthly);
      break;
    case ('ar_days'):
      finalValue = calculateARDays(calcs);
      break;
    case ('ap_days'):
      finalValue = calculateAPDays(calcs);
      break;
    case ('debt_to_equity'):
      finalValue = calculateDebtToEquity(calcs);
      break;
    case ('senior_debt_to_ebitda'):
      finalValue = calculateSeniorDebtToEBITDA(calcs);
      break;
    case ('ebitda_to_annual_interest'):
      finalValue = calculateEBITDAtoAnnualInterest(calcs);
      break;
    case ('ebitda'):
      finalValue = calculateEBITDAnormal(calcs);
      break;
    default:
      finalValue = objReducer(calcs[key]);
      break;
  }
  return finalValue;
}
// Testing Revenue per Month Percentage of Total Revenue of given year 
const monthlyTotalRevenueGRCalc = (calcs, previousYearCalcs) => {
  let returnObject = {};
  let currentYearTotalRevenue = objReducer(calcs.total_revenue);
  let currentYearMonthlyTotalRevenue = _.pickBy(calcs.total_revenue, _.isNumber);
  for (let month in currentYearMonthlyTotalRevenue) {
    returnObject[month] = (currentYearMonthlyTotalRevenue[month] / currentYearTotalRevenue)
  }
  return returnObject;
  // Old Monthly Revenue Growth Rate formula
  // (previous month / current month) - 1;
  // let previousYearTotalRevenue = previousYearCalcs.total_revenue;
  // if (previousYearTotalRevenue[12]) {
  //   returnObject[1] = (currentYearTotalRevenue[1] / previousYearTotalRevenue[12]) - 1;
  // } else {
  //   returnObject[1] = 0;
  // }
  // for (let i = 2; i < 13; i++) {
  //   returnObject[i] = (currentYearTotalRevenue[i] / currentYearTotalRevenue[i-1]) - 1;
  // }
}
// YTD Revenue Growth Rate
const ytdTotalRevenueGRCalc = (calcs, previousYearCalcs) => {
  let finalValue = 0;
  let pYRevs = Object.values(previousYearCalcs.total_revenue).slice(0, currentMonth)
  let previousYTDTotalRevenue = pYRevs.reduce((a, b) => a + b);
  let ytdTotalRevenue = objReducer(calcs.total_revenue);
  if (previousYTDTotalRevenue) {
    finalValue = (ytdTotalRevenue / previousYTDTotalRevenue) - 1;
  }

  return finalValue;
}

// Monthly % of Revenue Metric calculations
const monthlyPercentOfRevenueCalc = (percentOfRevenueKey, key, calcs) => {
  let returnObj = {};
  let currentYearTotalRevenue = calcs.total_revenue;
  let metricValues = calcs[percentOfRevenueKey] ? _.pickBy(calcs[percentOfRevenueKey], _.isNumber) : { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 };

  for (let month in metricValues) {
    if (key === 'gross_margin') {
      returnObj[month] = (currentYearTotalRevenue[month] - metricValues[month]) / currentYearTotalRevenue[month];
    } else {
      returnObj[month] = metricValues[month] / currentYearTotalRevenue[month];
    }
  }
  return returnObj;
}

// YTD % of Revenue Metric calculations
const ytdPercentOfRevenueCalc = (percentOfRevenueKey, key, calcs) => {
  let finalValue = 0;
  let ytdTotalRevenue = objReducer(calcs.total_revenue);
  let metricValues = objReducer(calcs[percentOfRevenueKey]);

  if (key === 'gross_margin') {
    finalValue = (ytdTotalRevenue - metricValues) / ytdTotalRevenue;
  } else {
    finalValue = metricValues / ytdTotalRevenue;
  }
  return finalValue;
}


export { CommonSizeArrowsHelper, CommonSizeArrows, CashFlowCommonSizeArrows, YTDNAICSFocusedVals, YTDPnlandBalCommonSizeValue, YTDPnlandBalValues, MonthlyPNLandBalValues, MonthlyPNLandBalCommonSized, YTDCashFlowValues, MonthlyNAICSFocusedVals, MonthlyCashFlowValues, AnnualCashFlowValues, MonthlyKFIsValues, AnnualKFIsValues, KFIsCommonSizeArrows, cashFlowTitleKeys, objReducer, calculateKFIMetric, calculateSeniorDebtToEBITDA, calculateEBITDAtoAnnualInterest, monthlyTotalRevenueGRCalc, monthlyPercentOfRevenueCalc, ytdPercentOfRevenueCalc, ytdTotalRevenueGRCalc };
