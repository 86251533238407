import React from "react";

const reportColors = {
	money: "#7DB057",
	people: "#00AEEF",
	customers: "#542667"
}

const LinesChart = ({ report }) => {
	let reportColor = reportColors[report]
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="100%" viewBox="0 0 188 136" enableBackground="new 0 0 188 136">
			<path fill={reportColor} opacity="1.000000" stroke="none"
				d="
M27.295794,81.319824 
	C24.452448,84.795372 21.818714,87.981636 18.905653,91.505836 
	C16.349735,89.439217 14.070033,87.595940 11.269946,85.331902 
	C28.466131,64.115234 45.511650,43.084461 63.058865,21.434696 
	C82.519592,37.602100 101.778702,53.602001 121.386269,69.891388 
	C137.875534,51.029739 154.078644,32.495411 170.591232,13.607096 
	C173.090317,15.731390 175.433578,17.723230 178.096985,19.987204 
	C159.521973,41.362137 141.219101,62.423897 122.614319,83.833076 
	C103.235840,67.742592 84.054565,51.815849 64.463631,35.548954 
	C52.015118,50.868362 39.760262,65.949448 27.295794,81.319824 
z"/>
			<path fill="#C9C6C6" opacity="1.000000" stroke="none"
				d="
M49.718327,73.705627 
	C55.133095,66.986275 60.307663,60.532337 65.837799,53.634918 
	C75.571793,61.804359 84.926926,69.726730 94.361153,77.553764 
	C103.803589,85.387619 113.325058,93.126190 123.196716,101.222145 
	C139.281250,82.795044 155.196335,64.562050 171.024017,46.429184 
	C171.672882,46.652893 172.040192,46.678452 172.263153,46.871075 
	C179.104385,52.781734 178.947754,52.649387 172.919373,59.551147 
	C158.265533,76.328026 143.766098,93.239777 129.214783,110.106125 
	C127.712059,111.847908 126.279007,113.649811 124.704445,115.556023 
	C105.206436,99.332108 86.456680,83.730789 67.275391,67.770409 
	C52.370350,86.098793 37.722313,104.111137 22.776920,122.489143 
	C20.143593,120.536118 17.805490,118.802055 14.853397,116.612625 
	C26.526011,102.237389 38.002068,88.104225 49.718327,73.705627 
z"/>
		</svg>
	)
}

const ColumnsChart = ({ report }) => {
	let reportColor = reportColors[report]
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="100%" viewBox="0 0 208 180" enableBackground="new 0 0 208 180">
			<path fill={reportColor} opacity="1.000000" stroke="none"
				d="
M67.996277,169.735046 
	C58.714600,169.734924 49.932053,169.734924 40.237373,169.734924 
	C40.237373,163.677444 40.237392,158.212021 40.237366,152.746582 
	C40.237221,120.614082 40.199642,88.481506 40.260998,56.349121 
	C40.278145,47.372093 40.521435,38.393688 40.785572,29.419582 
	C40.960926,23.461952 44.852543,19.251543 50.821026,19.159397 
	C64.136879,18.953819 77.459869,18.967674 90.776291,19.153854 
	C96.669205,19.236246 100.949799,23.522179 100.965775,29.346989 
	C101.091827,75.295395 101.098312,121.244133 101.125664,167.192780 
	C101.125938,167.661285 100.911926,168.129913 100.521454,169.735184 
	C89.987885,169.735184 79.241646,169.735184 67.996277,169.735046 
z"/>
			<path fill="#C8C4C4" opacity="1.000000" stroke="none"
				d="
M113.001244,75.000000 
	C113.009926,65.671677 112.961678,56.842819 113.044312,48.015190 
	C113.117821,40.162418 117.236710,36.079971 125.154533,36.036232 
	C136.980850,35.970898 148.807800,36.005409 160.634460,36.022274 
	C169.474548,36.034882 172.944626,38.769947 173.042053,47.723061 
	C173.480301,87.996162 173.573334,128.273026 173.784454,168.548569 
	C173.785278,168.706635 173.693253,168.865189 173.506989,169.471375 
	C153.763336,169.471375 133.873016,169.471375 113.001244,169.471375 
	C113.001244,138.257812 113.001244,106.878906 113.001244,75.000000 
z"/>
		</svg>
	)
}

const LineColChart = ({ report }) => {
	let reportColor = reportColors[report]
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="100%" viewBox="0 0 202 172" enableBackground="new 0 0 202 172">

			<path fill="#C9C5C5" opacity="1.000000" stroke="none"
				d="
M135.016327,80.056831 
	C135.368958,79.011703 135.994049,77.976334 136.031860,76.919945 
	C136.388336,66.959053 136.696381,56.995686 136.912018,47.030735 
	C137.060410,40.173882 139.077972,37.962257 145.758224,37.930725 
	C155.420792,37.885117 165.084030,37.874989 174.746429,37.937355 
	C180.673782,37.975609 183.810226,40.803688 183.833359,46.815388 
	C183.957123,78.968529 183.957962,111.122414 183.876465,143.275726 
	C183.866455,147.226715 183.110641,151.175827 182.656036,155.553619 
	C167.496277,155.553619 152.260956,155.553619 136.043213,155.553619 
	C136.043213,134.093643 136.043213,112.524155 136.348999,90.599350 
	C143.642654,94.961342 150.630508,99.678658 158.008102,104.659081 
	C159.581131,102.370102 160.828415,100.555092 162.283447,98.437813 
	C152.875534,92.095863 143.945923,86.076347 135.016327,80.056831 
z"/>
			<path fill="#C8C4C4" opacity="1.000000" stroke="none"
				d="
M65.713776,126.783714 
	C65.713776,136.239426 65.713776,145.695129 65.713776,155.562836 
	C50.391544,155.562836 35.171436,155.562836 19.298815,155.562836 
	C19.213045,153.975250 19.037025,152.228546 19.036388,150.481781 
	C19.024126,116.862045 19.021080,83.242302 19.044697,49.622581 
	C19.050392,41.513130 21.678434,38.972885 29.887264,38.967419 
	C38.874737,38.961433 47.862488,38.924809 56.849609,38.981743 
	C63.113052,39.021423 65.960464,41.854870 65.978439,48.172840 
	C66.038109,69.143349 66.025154,90.114059 65.930717,111.880684 
	C57.108826,125.123611 48.396267,137.570557 39.506374,150.270828 
	C41.947044,151.988159 43.767273,153.268921 45.542843,154.518250 
	C52.467312,144.997269 59.090542,135.890488 65.713776,126.783714 
z"/>
			<path fill="#C8C4C4" opacity="1.000000" stroke="none"
				d="
M124.989464,82.726967 
	C124.989464,106.844139 124.989464,130.961319 124.989464,155.528595 
	C109.308342,155.528595 93.974007,155.528595 77.656052,155.528595 
	C77.656052,140.498550 77.656052,125.588562 77.477470,110.046448 
	C86.729576,96.228760 96.160263,83.043213 105.875427,69.459930 
	C112.504845,74.061401 118.747154,78.394180 124.989464,82.726967 
z"/>
			<path fill="#C8C4C4" opacity="1.000000" stroke="none"
				d="
M78.054573,95.062172 
	C78.022270,69.957634 77.956192,44.853058 77.987175,19.748600 
	C77.993118,14.930431 80.940384,12.038130 85.771294,11.991996 
	C95.580284,11.898323 105.390869,11.931278 115.200577,11.968553 
	C121.949585,11.994197 124.854805,14.852747 124.881287,21.761702 
	C124.945656,38.554535 124.890152,55.347828 124.557335,72.499344 
	C117.419167,68.207230 110.606606,63.556709 103.738564,58.868313 
	C95.030014,71.140396 86.542297,83.101288 78.054573,95.062172 
z"/>
			<path fill={reportColor} opacity="1.000000" stroke="none"
				d="
M77.939148,95.381165 
	C86.542297,83.101288 95.030014,71.140396 103.738564,58.868313 
	C110.606606,63.556709 117.419167,68.207230 124.546738,72.973312 
	C128.042084,75.308807 131.222412,77.528740 134.709534,79.902756 
	C143.945923,86.076347 152.875534,92.095863 162.283447,98.437813 
	C160.828415,100.555092 159.581131,102.370102 158.008102,104.659081 
	C150.630508,99.678658 143.642654,94.961342 136.337509,90.132050 
	C132.569412,87.662094 129.118622,85.304123 125.328644,82.836563 
	C118.747154,78.394180 112.504845,74.061401 105.875427,69.459930 
	C96.160263,83.043213 86.729576,96.228760 77.210281,109.736603 
	C73.389709,115.415710 69.657730,120.772522 65.819763,126.456528 
	C59.090542,135.890488 52.467312,144.997269 45.542843,154.518250 
	C43.767273,153.268921 41.947044,151.988159 39.506374,150.270828 
	C48.396267,137.570557 57.108826,125.123611 65.917648,112.350800 
	C69.950516,106.583344 73.887123,101.141747 77.939148,95.381165 
z"/>
		</svg>
	)
}

const ColLineChart = ({ report }) => {
	let reportColor = reportColors[report]
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="100%" viewBox="0 0 192 174" enableBackground="new 0 0 192 174">
			<path fill={reportColor} opacity="1.000000" stroke="none"
				d="
M131.837921,84.875732 
	C131.897812,73.719528 131.937042,62.563160 132.024902,51.407177 
	C132.082657,44.075573 135.003723,41.184464 142.397629,41.154217 
	C151.390274,41.117432 160.383835,41.080116 169.375748,41.169525 
	C175.540588,41.230816 178.858734,44.306969 178.883850,50.268623 
	C179.036011,86.399582 179.089615,122.530952 179.164505,158.662201 
	C179.165512,159.152420 179.015717,159.642960 178.875565,160.508545 
	C163.515198,160.508545 148.121841,160.508545 131.931381,160.508545 
	C131.931381,139.040207 131.931381,117.479324 132.313538,95.471802 
	C137.906693,98.514664 143.118210,102.003380 148.328522,105.493874 
	C153.994003,109.289284 154.263916,109.206665 157.455078,102.609840 
	C148.923996,96.703995 140.380966,90.789864 131.837921,84.875732 
z"/>
			<path fill={reportColor} opacity="1.000000" stroke="none"
				d="
M60.704998,132.208542 
	C60.704998,141.516159 60.704998,150.823776 60.704998,160.509415 
	C44.868088,160.509415 29.319401,160.509415 13.363580,160.509415 
	C13.268340,159.169525 13.077919,157.736084 13.077525,156.302582 
	C13.068036,121.823906 13.068735,87.345215 13.094081,52.866550 
	C13.099538,45.442917 15.982486,42.425316 23.242706,42.357067 
	C32.070000,42.274094 40.899147,42.280663 49.726593,42.355038 
	C57.110878,42.417255 59.903370,45.312111 59.995010,52.732361 
	C60.251762,73.521996 60.508072,94.311623 60.561546,115.789253 
	C58.120346,119.955902 55.982899,123.504532 53.624672,126.899818 
	C47.109612,136.279984 40.502327,145.596069 33.790089,155.138550 
	C36.182186,156.712540 38.043480,157.937256 39.983124,159.213531 
	C46.164227,150.537643 51.922249,142.388855 57.785912,134.316803 
	C58.456169,133.394104 59.717655,132.900894 60.704998,132.208542 
z"/>
			<path fill={reportColor} opacity="1.000000" stroke="none"
				d="
M120.094681,87.041283 
	C120.094681,111.486473 120.094681,135.931656 120.094681,160.631653 
	C104.042618,160.631653 88.605431,160.631653 72.248253,160.631653 
	C72.248253,145.708572 72.248253,130.784714 72.450912,115.158707 
	C76.407364,108.659760 80.041283,102.780823 83.944000,97.086098 
	C89.331841,89.224335 94.922394,81.501495 100.840279,73.133537 
	C107.602310,78.017853 113.848495,82.529572 120.094681,87.041283 
z"/>
			<path fill={reportColor} opacity="1.000000" stroke="none"
				d="
M72.264297,98.799667 
	C72.442589,73.854797 72.604500,48.909779 72.810966,23.965134 
	C72.861015,17.919378 75.545143,15.219842 81.698914,15.159501 
	C91.016815,15.068134 100.336861,15.074247 109.654907,15.158839 
	C116.377403,15.219867 118.986977,17.909967 118.993767,24.587879 
	C119.010872,41.394955 118.992332,58.202072 118.627991,75.403046 
	C111.672302,71.469383 105.076698,67.141853 98.402542,62.762775 
	C91.832695,72.312393 85.560417,81.582130 79.105171,90.722649 
	C77.077690,93.593529 74.558456,96.117126 72.264297,98.799667 
z"/>
			<path fill="#C5C6BE" opacity="1.000000" stroke="none"
				d="
M72.152176,99.167358 
	C74.558456,96.117126 77.077690,93.593529 79.105171,90.722649 
	C85.560417,81.582130 91.832695,72.312393 98.402542,62.762775 
	C105.076698,67.141853 111.672302,71.469383 118.599297,75.866951 
	C123.029625,78.836945 127.128555,81.736916 131.532715,84.756302 
	C140.380966,90.789864 148.923996,96.703995 157.455078,102.609840 
	C154.263916,109.206665 153.994003,109.289284 148.328522,105.493874 
	C143.118210,102.003380 137.906693,98.514664 132.344452,95.002747 
	C128.257614,92.340004 124.522041,89.699684 120.440567,87.050323 
	C113.848495,82.529572 107.602310,78.017853 100.840279,73.133537 
	C94.922394,81.501495 89.331841,89.224335 83.944000,97.086098 
	C80.041283,102.780823 76.407364,108.659760 72.432587,114.703674 
	C68.456352,120.454231 64.701111,125.957672 60.825439,131.834824 
	C59.717655,132.900894 58.456169,133.394104 57.785912,134.316803 
	C51.922249,142.388855 46.164227,150.537643 39.983124,159.213531 
	C38.043480,157.937256 36.182186,156.712540 33.790089,155.138550 
	C40.502327,145.596069 47.109612,136.279984 53.624672,126.899818 
	C55.982899,123.504532 58.120346,119.955902 60.604851,116.258896 
	C64.580803,110.538712 68.310432,105.036880 72.152176,99.167358 
z"/>
		</svg>
	)
}

const CheckMark = () => {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="100%" viewBox="0 0 150 126" enableBackground="new 0 0 150 126">
			<path fill="#008000" opacity="1.000000" stroke="none"
				d="
   M147.868942,29.817406 
	   C115.378960,61.098713 83.141907,92.130936 50.536846,123.517410 
	   C35.981411,106.059479 21.728609,88.964516 6.978036,71.272530 
	   C13.643435,65.684036 20.063070,60.301605 26.871078,54.593544 
	   C35.493092,64.889618 43.890938,74.918007 52.653442,85.381859 
	   C79.340981,59.710266 105.796661,34.261703 131.062408,9.957773 
	   C137.226181,15.312545 143.178452,20.362478 148.899567,25.661936 
	   C149.409210,26.134022 148.416718,28.227741 147.868942,29.817406 
   z"/>
		</svg>
	)
}

const XMark = () => {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="100%" viewBox="0 0 136 130" enableBackground="new 0 0 136 130">
			<path fill="#ff0200" opacity="1.000000" stroke="none"
				d="
   M46.174202,111.174217 
	   C40.302402,117.100624 34.680614,122.777039 29.454174,128.054260 
	   C23.282606,121.858551 17.357359,115.910118 11.297919,109.826973 
	   C24.685331,96.524216 38.811878,82.487000 53.244114,68.146027 
	   C38.525932,53.473179 24.439625,39.430256 10.649444,25.682549 
	   C16.970215,19.369787 22.826893,13.520530 28.613775,7.740980 
	   C42.166191,21.313738 56.225361,35.394016 70.297119,49.486893 
	   C85.087280,34.673954 99.148056,20.591524 112.897926,6.820479 
	   C119.450142,13.421305 125.374443,19.389559 131.386337,25.446047 
	   C117.843788,38.988647 103.751205,53.081287 89.463753,67.368790 
	   C104.056198,81.949005 118.173676,96.054649 132.155258,110.024521 
	   C125.944107,116.243523 120.073357,122.121696 114.104500,128.098099 
	   C100.265190,114.062790 86.279846,99.879372 71.943932,85.340416 
	   C62.867428,94.439720 54.645821,102.681976 46.174202,111.174217 
   z"/>
		</svg>
	)
}

const ThumbsUp = () => {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="100%" viewBox="0 0 84 76" enable-background="new 0 0 84 76">
			<path fill="#FCFDFC" opacity="1.000000" stroke="none"
				d="
M63.000290,77.000000 
	C42.000237,77.000000 21.500250,77.000000 1.000196,77.000000 
	C1.000131,51.666771 1.000131,26.333538 1.000065,1.000230 
	C28.999880,1.000154 56.999760,1.000154 84.999725,1.000077 
	C84.999817,26.333199 84.999817,51.666397 84.999908,76.999802 
	C77.833450,77.000000 70.666901,77.000000 63.000290,77.000000 
M71.244385,64.048462 
	C74.916542,61.439617 77.109177,58.241253 75.336266,53.585670 
	C81.455856,49.110287 81.976501,46.779995 79.162170,40.522606 
	C80.355019,37.651234 81.854248,34.493206 81.330948,31.716328 
	C80.601974,27.848055 77.041267,26.720207 73.148361,26.799236 
	C66.620743,26.931753 60.088490,26.835478 53.445793,26.835478 
	C54.877544,22.807747 56.300049,19.447865 57.259571,15.960595 
	C58.808502,10.331215 56.793186,6.397850 52.165730,5.351177 
	C47.850029,4.375018 44.973225,7.023507 43.058235,12.490544 
	C41.721634,16.306376 40.228592,20.769348 37.338673,23.197756 
	C30.655239,28.813871 28.102648,35.405636 28.947176,43.829678 
	C29.277386,47.123493 28.931145,50.479691 29.029469,53.803440 
	C29.180624,58.913067 31.520777,62.732891 35.324455,66.245842 
	C43.523586,73.818268 53.414574,72.594139 63.161018,72.471306 
	C68.118027,72.408844 70.837875,69.840462 71.244385,64.048462 
M24.164875,53.496807 
	C24.164873,49.507931 24.164867,45.519058 24.164865,41.530182 
	C24.164858,28.844688 24.106909,28.903824 11.384171,29.108646 
	C6.667469,29.184580 4.780109,31.290104 4.817711,35.797108 
	C4.884265,43.774300 4.872182,51.752663 4.826822,59.730156 
	C4.753111,72.693497 3.627358,72.806961 18.618311,72.494362 
	C22.646605,72.410355 24.204355,70.237991 24.169374,66.460823 
	C24.132435,62.472301 24.163530,58.483158 24.164875,53.496807 
z"/>
			<path fill="#3A7F25" opacity="1.000000" stroke="none"
				d="
M71.169006,64.431053 
	C70.837875,69.840462 68.118027,72.408844 63.161018,72.471306 
	C53.414574,72.594139 43.523586,73.818268 35.324455,66.245842 
	C31.520777,62.732891 29.180624,58.913067 29.029469,53.803440 
	C28.931145,50.479691 29.277386,47.123493 28.947176,43.829678 
	C28.102648,35.405636 30.655239,28.813871 37.338673,23.197756 
	C40.228592,20.769348 41.721634,16.306376 43.058235,12.490544 
	C44.973225,7.023507 47.850029,4.375018 52.165730,5.351177 
	C56.793186,6.397850 58.808502,10.331215 57.259571,15.960595 
	C56.300049,19.447865 54.877544,22.807747 53.445793,26.835478 
	C60.088490,26.835478 66.620743,26.931753 73.148361,26.799236 
	C77.041267,26.720207 80.601974,27.848055 81.330948,31.716328 
	C81.854248,34.493206 80.355019,37.651234 79.162170,40.522606 
	C81.976501,46.779995 81.455856,49.110287 75.336266,53.585670 
	C77.109177,58.241253 74.916542,61.439617 71.169006,64.431053 
z"/>
			<path fill="#397F25" opacity="1.000000" stroke="none"
				d="
M24.164890,53.995544 
	C24.163530,58.483158 24.132435,62.472301 24.169374,66.460823 
	C24.204355,70.237991 22.646605,72.410355 18.618311,72.494362 
	C3.627358,72.806961 4.753111,72.693497 4.826822,59.730156 
	C4.872182,51.752663 4.884265,43.774300 4.817711,35.797108 
	C4.780109,31.290104 6.667469,29.184580 11.384171,29.108646 
	C24.106909,28.903824 24.164858,28.844688 24.164865,41.530182 
	C24.164867,45.519058 24.164873,49.507931 24.164890,53.995544 
z"/>
		</svg>
	)
}

const ThumbsDown = () => {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="100%" viewBox="0 0 86 76" enable-background="new 0 0 86 76">
			<path fill="#FFFDFD" opacity="1.000000" stroke="none"
				d="
M54.000000,77.000000 
	C36.000004,77.000000 18.500010,77.000000 1.000012,77.000000 
	C1.000008,51.666679 1.000008,26.333359 1.000004,1.000029 
	C29.666647,1.000019 58.333294,1.000019 86.999954,1.000010 
	C86.999969,26.333305 86.999969,51.666611 86.999985,76.999954 
	C76.166664,77.000000 65.333336,77.000000 54.000000,77.000000 
M55.002079,72.561165 
	C62.503189,65.671265 55.335190,59.141525 54.368561,52.163719 
	C60.635551,52.163719 66.766380,52.108608 72.895599,52.184319 
	C76.592972,52.229988 80.613853,51.835178 81.536667,47.904560 
	C82.249649,44.867706 80.894753,41.345367 80.245720,36.418110 
	C82.705261,32.418922 82.109863,29.979334 76.071396,25.460499 
	C76.330971,22.638786 71.683281,10.300943 69.502541,8.442632 
	C68.649086,7.715364 67.518661,7.076302 66.431664,6.896176 
	C54.158997,4.862472 42.463799,5.332494 33.187805,15.237983 
	C27.294613,21.531105 27.475077,45.964729 33.984383,51.552284 
	C39.306683,56.120930 42.587284,61.431255 44.354656,68.105148 
	C45.716568,73.247978 49.010941,74.588394 55.002079,72.561165 
M20.802452,16.081654 
	C18.472822,16.049398 16.143192,15.990080 13.813560,15.989910 
	C6.535283,15.989381 5.440244,17.069254 5.423437,24.351955 
	C5.402307,33.508209 5.390332,42.664669 5.428179,51.820816 
	C5.453619,57.975742 6.938787,59.452793 13.044525,59.575779 
	C14.708588,59.609299 16.377460,59.646687 18.038090,59.562798 
	C23.244862,59.299786 24.820333,57.704315 24.843252,52.509087 
	C24.886580,42.687065 25.052357,32.857815 24.708872,23.047617 
	C24.628792,20.760456 22.713379,18.537552 20.802452,16.081654 
z"/>
			<path fill="#EB3626" opacity="1.000000" stroke="none"
				d="
M54.654118,72.736755 
	C49.010941,74.588394 45.716568,73.247978 44.354656,68.105148 
	C42.587284,61.431255 39.306683,56.120930 33.984383,51.552284 
	C27.475077,45.964729 27.294613,21.531105 33.187805,15.237983 
	C42.463799,5.332494 54.158997,4.862472 66.431664,6.896176 
	C67.518661,7.076302 68.649086,7.715364 69.502541,8.442632 
	C71.683281,10.300943 76.330971,22.638786 76.071396,25.460499 
	C82.109863,29.979334 82.705261,32.418922 80.245720,36.418110 
	C80.894753,41.345367 82.249649,44.867706 81.536667,47.904560 
	C80.613853,51.835178 76.592972,52.229988 72.895599,52.184319 
	C66.766380,52.108608 60.635551,52.163719 54.368561,52.163719 
	C55.335190,59.141525 62.503189,65.671265 54.654118,72.736755 
z"/>
			<path fill="#EC3B2C" opacity="1.000000" stroke="none"
				d="
M21.222435,16.183369 
	C22.713379,18.537552 24.628792,20.760456 24.708872,23.047617 
	C25.052357,32.857815 24.886580,42.687065 24.843252,52.509087 
	C24.820333,57.704315 23.244862,59.299786 18.038090,59.562798 
	C16.377460,59.646687 14.708588,59.609299 13.044525,59.575779 
	C6.938787,59.452793 5.453619,57.975742 5.428179,51.820816 
	C5.390332,42.664669 5.402307,33.508209 5.423437,24.351955 
	C5.440244,17.069254 6.535283,15.989381 13.813560,15.989910 
	C16.143192,15.990080 18.472822,16.049398 21.222435,16.183369 
z"/>
		</svg>
	)
}

export { LinesChart, ColumnsChart, LineColChart, ColLineChart, CheckMark, XMark, ThumbsUp, ThumbsDown };