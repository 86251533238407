import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const EditParentChildDashboard = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [formMetrics, setFormMetrics] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    setFormMetrics((prevState) => {
      // We need the rows and get the charts and grid items associated with each row
      // The Peeview Metircs are just on their own
      let formSections = props.editDashboard.map(s => {
        switch (s['sectionName']) {
          case 'Peerview Metrics':
            s['childrenArr'] = props.allMetrics['peerview_charts']
            break;
          case 'Profit & Loss':
            s['childrenArr'] = props.allMetrics['pnl']
            break;
          case 'Balance Sheet':
            s['childrenArr'] = props.allMetrics['balance_sheet']
            break;
          case 'Cash Flow':
            s['childrenArr'] = props.allMetrics['cash_flow']
            break;
          case `KFI's`:
            s['childrenArr'] = props.allMetrics['kfis']
            break;
          case 'Financial Detail':
            s['childrenArr'] = props.allMetrics['financial_detail']
            break;
          case 'KPI':
            s['childrenArr'] = props.allMetrics['kpi']
            break;
          default:
            break;
        }

        return s
      })
      return formSections;
    })
  }, [props])

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  const Checkbox = (props) => {
    let itemName = ''
    let padding = '';
    if (props.parent) {
      itemName = props.chart['copy'] ? props.chart['copy'] : props.chart['key']
      let relatedKey = props.chart.relatedCheckedKey || props.parent.key
      if (itemName === "Total Revenue Growth Rate Chart") {
        itemName = "Revenue Growth Rate Chart"
      }

      return (
        <div className='dashboard-form-check'>
          <label className='dashboard-form-check-label'>
            <input
              type='checkbox'
              checked={props.chart.checked}
              onChange={() => props.handleSelectAndLayout(props.page, props.sectionData['sheet'], relatedKey, props.chart.key)}
              className='form-check-input'
            />
            <span>{itemName}</span>
          </label>
        </div>
      )
    } else {
      itemName = props.chart['copy'] ? props.chart['copy'] : props.chart['key']
      if (!itemName.includes(':') && props.addColon) { itemName += ': ' }
      padding = props.chart['padding']
      return (
        <div className='dashboard-form-check'>
          <label className='dashboard-form-check-label'>
            <span>{padding}</span>
            {props.chart.displayChart
              ? (<input
                type='checkbox'
                checked={props.chart.checked}
                onChange={() => props.handleSelectAndLayout(props.page, props.sectionData['sheet'], props.chart.relatedCheckedKey)}
                className='form-check-input'
              />)
              : null}
            <span style={{ 'fontSize': '15px' }}>{itemName}</span>
          </label>
        </div>
      )
    }

  }

  const buildChildren = (sectionData) => {
    let children = sectionData['childrenArr']
    return children.map((c, i) => {
      let checkSectionStyle = {}

      if (c['layout']) {
        return (
          <div className='checkbox-with-children' key={`${c.key}-${i}`} style={checkSectionStyle}>
            <Checkbox key={`${c.key}-${i}-check`} addColon={true} sectionData={sectionData} chart={c} handleSelectAndLayout={props.handleSelectAndLayout} page={props.page} />
            <div key={`${c.key}-${i}-children-checks-container`} className='children-checks-container'>
              {
                c['layout'].map(l => {
                  return <Checkbox key={l.key + '-check'} sectionData={sectionData} chart={l} parent={c} handleSelectAndLayout={props.handleSelectAndLayout} page={props.page} />
                })
              }
            </div>
          </div>)
      } else {
        return (
          <div className='checkbox-with-children' key={`${c.key}-${i}`}>
            <Checkbox key={`${c.key}-${i}-check`} sectionData={sectionData} chart={c} handleSelectAndLayout={props.handleSelectAndLayout} page={props.page} />
          </div>
        )
      }
    })
  }

  const buildForm = () => {
    return formMetrics.map(section => {
      return <Dropdown key={`dropdown-${section['sheet']}`} sectionData={section} />
    })
  }

  const Dropdown = ({ sectionData }) => {
    let styleClassParent = props.page === 'money' ? 'dropdown-parent-money' : props.page === 'people' ? 'dropdown-parent-people' : 'dropdown-parent-customers'
    let styleClassChildren = props.page === 'money' ? 'dropdown-children-money' : props.page === 'people' ? 'dropdown-children-people' : 'dropdown-children-customers'
    return (
      <div>
        <div className={styleClassParent} onClick={() => props.handleEditDashBoard(sectionData['sheet'])}>
          <span style={{ 'marginRight': '5px' }}>{sectionData['sectionName']}</span>
          {sectionData['showDropdown'] ? <FontAwesomeIcon key={`${sectionData.sheet}-chevup`} icon={faChevronUp} /> : <FontAwesomeIcon key={`${sectionData.sheet}-chevdown`} icon={faChevronDown} />}
        </div>
        {sectionData['showDropdown'] ?
          <div className={styleClassChildren} style={{ display: sectionData['showDropdown'] ? 'block' : 'none' }}>
            {buildChildren(sectionData)}
          </div> : null}
      </div>
    );
  }

  return (
    <>
      <div className='edit-dashboard-btn' onClick={openModal}>
        <i className='fa fa-bar-chart' aria-hidden='true'></i>
      </div>
      <Modal
        className={{
          base: 'dashboard-modal-content hide-on-print',
          afterOpen: 'dashboard-modal-content_after-open hide-on-print',
          beforeClose: 'dashboard-modal-content_before-close hide-on-print',
        }}
        overlayClassName={{
          base: 'dashboard-overlay-base hide-on-print',
          afterOpen: 'dashboard-overlay-base_after-open hide-on-print',
          beforeClose: 'dashboard-overlay-base_before-close hide-on-print',
        }}
        ariaHideApp={false}
        closeTimeoutMS={0}
        contentLabel='Edit Dashboard Modal'
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnOverlayClick={true}
      >
        <h3>Select Charts to Display on Dashboard</h3>
        <p>Click Anywhere Outside of this Pop-up to Close</p>
        <form id='parent-child-dashboard-form' onSubmit={(e) => props.handleEditMetrics(e, true)}>
          <div className='dashboard-form-btn-section'>
            {props.allMetrics.noChangesMade ? null : <button className='btn dashboard-save-btn'>Save Changes</button>}
          </div>
          {buildForm()}
        </form>
      </Modal>
    </>
  )
}
export default EditParentChildDashboard;