import React, { useContext, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import Modal from 'react-modal';

const IndustryPDFandPrint = (props) => {
    const naicsFocusedReport = props.naicsCode && props.naicsCode.startsWith('621') ? 'Doctor' : null;
    const navbar = window.navbar.navbar;
    const pdfIcon = <FontAwesomeIcon icon={faFilePdf} />


    const closePDFPrintModal = () => {
        props.setPDFPrintModalOpen(false);
    }

    const getPDFButton = () => {
        if (props.planAccess.print_and_pdf || navbar.user_type === 'Admin') {
            return (
                <span className='hide-on-print'>
                    <button id='industryPdfButton' className='print-pdf-btn' onClick={() => props.setPDFPrintModalOpen(true)} aria-hidden='true' >{pdfIcon}</button>
                </span>
            )
        }
    }

    const printPDFExport = () => {
        if (props.planAccess.print_and_pdf || navbar.user_type === 'Admin') {
            return (
                <Modal
                    className={{
                        base: 'pdf-print-modal-content hide-on-print',
                        afterOpen: 'pdf-print-modal-content_after-open hide-on-print',
                        beforeClose: 'pdf-print-modal-content_before-close hide-on-print',
                    }}
                    overlayClassName={{
                        base: 'overlay-base hide-on-print',
                        afterOpen: 'overlay-base_after-open hide-on-print',
                        beforeClose: 'overlay-base_before-close hide-on-print',
                    }}
                    isOpen={props.PDFPrintModalOpen}
                    onRequestClose={closePDFPrintModal}
                    shouldCloseOnOverlayClick={true}
                    closeTimeoutMS={0}
                    contentLabel='Industry PDF and Print Preview'
                    ariaHideApp={false}
                >
                    <div className='report-builder-container'>
                        <h2>Report Builder</h2>
                        <p>Include: </p>
                        <div className='report-builder-all-checkboxes'>
                            <div>
                                <div key={`cover_page-checkbox`} className='page-checkbox-input'>
                                    <input id={`cover_page-checked`} type='checkbox' name={'cover_page'} value={'cover_page'} checked={props.displayPages['cover_page']['checked']} onChange={(e) => props.handlePDFandPrintChecked(e.target.value)} />
                                    <label htmlFor={`cover_page-checked`}>{props.displayPages['cover_page']['label']}</label>
                                </div>
                            </div>
                            <div className='industry-report-pages-checkboxes'>
                                {Object.keys(props.displayPages).map((key) => {
                                    if (key !== 'cover_page') {
                                        return (
                                            <div key={`${key}-checkbox`} className='page-checkbox-input'>
                                                <input id={`${key}-checked`} type='checkbox' name={key} value={key} checked={props.displayPages[key]['checked']} onChange={(e) => props.handlePDFandPrintChecked(e.target.value)} />
                                                <label htmlFor={`${key}-checked`}>{`${props.displayPages[key]['label']}:`}</label>
                                                <div key={`${key}-pages-div`} className='industry-pages-div'>
                                                    {
                                                        Object.keys(props.displayPages[key]['pages']).map((pageKey) => {
                                                            if (pageKey === 'doctor_pnl' && !naicsFocusedReport) {
                                                                return null
                                                            } else {
                                                                return (
                                                                    <div key={`${pageKey}`}>
                                                                        <input id={`${key}-${pageKey}-checked`} type='checkbox' name={`${key}-${pageKey}`} value={`${key}-${pageKey}`} checked={props.displayPages[key]['pages'][pageKey]['checked']} onChange={(e) => props.handlePDFandPrintChecked(key, pageKey)} />
                                                                        <label htmlFor={`${key}-${pageKey}-checked`}>{props.displayPages[key]['pages'][pageKey]['label']}</label>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </div>
                        </div>
                        <ReactToPrint
                            trigger={() => <button id="industryPdfPrintButton" className='run-report-btn pdf-print-action-btn' aria-hidden='true' onClick={() => preparePDFPrint()}>Print my Client Report</button>}
                            content={() => props.componentRefs.current}
                            documentTitle={`PeerviewDataIndustryReport.pdf`}
                        />
                    </div>
                </Modal>
            )
        }
    }

    return (
        <>
            {getPDFButton()}
            {printPDFExport()}
        </>
    )
};
export default IndustryPDFandPrint;
