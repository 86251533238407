import React, { useState, useEffect, useRef } from 'react';
import workflows from './workflow_data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';

const Workflow = (props) => {
    const pageRefs = useRef();
    const [workflow, setWorkflow] = useState()

    useEffect(() => {
        let selectedWorkflow = workflows.find(w => w.id === Number(props.id))
        if (selectedWorkflow) {
            setWorkflow(selectedWorkflow)
        }
    }, [])

    const handleDisplays = (sectionTitle, header) => {
        const updatedSections = workflow.sections.map(s => {
            if (s.title === sectionTitle && header) {
                return {
                    ...s,
                    displayTasks: !s.displayTasks
                }
            } else {
                return s
            }
        });

        setWorkflow({
            ...workflow,
            sections: updatedSections
        });
    }

    const generateWordDoc = () => {
        if (!workflow) return;

        // Word document structure
        const doc = new Document({
            styles: {
                paragraphStyles: [
                    {
                        id: "Normal",
                        name: "Normal",
                        run: {
                            font: "Arial", // Default font for the document
                        },
                    },
                ],
            },
            sections: [
                {
                    children: [
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: workflow.title,
                                    bold: true,
                                    size: 40, // Font size in half-points
                                }),
                            ],
                            alignment: "center"
                        }),
                        ...workflow.sections.map((section, i) => [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${i + 1}. ${section.title}`,
                                        bold: true,
                                        size: 28,
                                    }),
                                ],
                                spacing: { before: 300, after: 200 },
                            }),
                            section.objective
                                ? new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: `Objective:`,
                                            bold: true,
                                            size: 24,
                                        }),
                                        new TextRun({
                                            text: ` ${section.objective}`,
                                            size: 24,
                                        }),
                                    ],
                                    spacing: { after: 150 },
                                })
                                : null,
                            ...section.tasks.map((task, k) => {
                                if (task.description) {
                                    // Task with a description
                                    return new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: `${task.name}`,
                                                bold: true,
                                                size: 24,
                                            }),
                                            new TextRun({
                                                break: 1, // Inserts a new line
                                            }),
                                            new TextRun({
                                                text: `${task.description}`,
                                                size: 22,
                                            }),
                                        ],
                                        spacing: { after: 150 },
                                    });
                                } else if (task.workflow) {
                                    // Task with a workflow
                                    return [
                                        new Paragraph({
                                            children: [
                                                new TextRun({
                                                    text: `${task.name}`,
                                                    bold: true,
                                                    size: 24,
                                                }),
                                            ],
                                            spacing: { after: 100 },
                                        }),
                                        ...task.workflow.map(
                                            (step, j) =>
                                                new Paragraph({
                                                    children: [
                                                        new TextRun({
                                                            text: `${j + 1}. ${step}`,
                                                            size: 22,
                                                        }),
                                                    ],
                                                    spacing: { after: 100 },
                                                })
                                        ),
                                    ].flat();
                                }
                            }),
                        ]).flat().filter(Boolean),
                        workflow.summary
                            ? new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `Summary: `,
                                        bold: true,
                                        size: 24,
                                    }),
                                    new TextRun({
                                        text: `${workflow.summary}`,
                                        size: 24,
                                    }),
                                ],
                                spacing: { before: 200 },
                            })
                            : null,
                    ].flat().filter(Boolean),
                },
            ],
        });

        // Convert to Blob and trigger download
        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, `Peerview Data ${workflow.title}.docx`);
        });
    };

    if (workflow) {
        return (
            <div>
                <div id='sticky-topnav-left-btns'>
                    <ReactToPrint
                        trigger={() => <button id='printIcon' className='print-pdf-btn' aria-hidden='true'><FontAwesomeIcon icon={faFilePdf} /></button>}
                        content={() => pageRefs.current}
                        documentTitle={`Workflow ${workflow.title}.pdf`}
                    />
                    <button onClick={generateWordDoc} style={{ 'marginLeft': '6px' }}><FontAwesomeIcon icon={faFileWord} /></button>
                </div>
                <div ref={pageRefs} className='workflow-container'>
                    <h1 className='workflow-title'>{workflow.title}</h1>
                    <ul className='workflow-list'>
                        {
                            workflow.sections.map((s, i) => {
                                return (
                                    <li className='workflow-section' key={`${i}-section`}>
                                        <h2 onClick={() => handleDisplays(s.title, true)}>{i + 1}. {s.title}</h2>
                                        {s.displayTasks && s.objective ? <h3><span>Objective:</span> {s.objective}</h3> : null}
                                        {s.displayTasks
                                            ? (
                                                <ul className='workflow-list-inner'>
                                                    {s.tasks.map((t, k) => {
                                                        if (t.description) {
                                                            return (
                                                                <li key={`${k}-task`}>
                                                                    <h4>{t.name}</h4>
                                                                    <div>{t.description}</div>
                                                                </li>
                                                            )
                                                        } else {
                                                            return (
                                                                <li key={`${k}-task`}>
                                                                    <h4>{t.name}</h4>
                                                                    <ul>
                                                                        {t.workflow.map((w, j) => {
                                                                            return <li key={`${k}-task-${j}`}>{j + 1}. {w}</li>
                                                                        })}
                                                                    </ul>
                                                                </li>
                                                            )
                                                        }
                                                    })}
                                                </ul>
                                            )
                                            : null
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                    {
                        workflow.summary
                            ? <div className='workflow-summary'><span>Summary:</span> {workflow.summary}</div>
                            : null
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div>Please select a workflow.</div>
        )
    }
}

export default Workflow;