import React, { useState, useEffect, Fragment } from 'react';
import DropdownFilter from '../utils/DropdownFilter';
import LoaderGraphic from '../utils/LoaderGraphic';
import { Data, Filters } from './contexts';
import { capitalize } from '../utils';
import AccountTree from '../utils/AccountTree';
import { LinesChart, ColumnsChart, LineColChart, ColLineChart } from '../utils/SVGIcons';
import { financialDetailRows, customersKPIsFormat, updateCustomersRows, addMetricRow } from '../utils/CustomerSheets';
import { defaultLayouts, moneyCharts, peopleCharts, customerCharts, mergeItems, layoutMaker, pageCharts } from './charts';
import { SurveyCharts, MoneySurvey, PeopleSurvey, CustomerSurvey } from './SurveyHelper';
import { ToastContainer, toast } from 'react-toastify';
import ahoy from 'ahoy.js';
import DashboardViewModal from './utils/DashboardViewModal';
import Modal from 'react-modal';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts.js';

const ReportContainer = (props) => {
  // set default report period to annual/years options
  const [state, setState] = useState({
    report: props.page || 'money',
    pageTitle: capitalize(props.page) || '',
    clientName: props.client_name || '',
    calendarYearEnd: props.calendarYearEnd,
    fiscalYearStartMonth: props.fiscalYearStartMonth || 1,
    peerviewMetricsYears: props.peerviewMetricsYears || {},
    calcs: props.calcs,
    previousYearCalcs: {},
    yoy_calcs: {},
    previous_yoy_calcs: {},
    calcs_3_years_back: {},
    baseCalc: {},
    monthlyForecastCalcs: {},
    fetch: !props.calcs,
    loading: false,
    filters: props.filters || {},
    filterOptions: props.filterOptions || {},
    currentNewFilters: props.current_new_filters || {},
    newFilterOptions: props.new_filter_options || {},
    youAnnualOptions: {},
    youMonthlyOptions: {},
    peerAnnualOptions: {},
    peerMonthlyOptions: {},
    monthlyCompareWithOptions: [],
    tree: props.tree,
    yearRange: props.yearRange || [],
    n: props.n,
    docs_n: 0,
    forecast_scenarios: [],
    insufficient_data: false,
    insufficient_peer_data: false,
    reportSpecific: props.reportSpecific || {},
    isMonthlyAvailable: props.isMonthlyAvailable,
    fiscalYearEnd: props.fiscal_year_end,
    companyYTDDate: null,
    aggregateYTDDate: null,
    companyReportSurvey: {},
    surveyTaken: false,
    surveyResults: {},
    pnlTree: {},
    balTree: {},
  });
  const [filtersModalIsOpen, setFiltersModalIsOpen] = useState(false)
  const [chatLoading, setChatLoading] = useState(false)
  const [chatLoader, setChatLoader] = useState({
    progress: 0,
    message: 'Initializing analysis process...',
    error: null
  })
  const [jobs, setJobs] = useState({
    id: null, subscribe: false
  })
  const [chat, setChat] = useState(props.chats.length > 0 ? props.chats[0] : {})
  const [allChats, setAllChats] = useState(props.chats)
  const [dashboard, setDashboard] = useState({ id: null, selectedItems: [], layout: [], noChangesMade: true });
  const [editDashboard, setEditDashboard] = useState([{ sectionName: 'Peerview Metrics', sheet: 'peerview_charts', showDropdown: false }, { sectionName: 'Profit & Loss', sheet: 'pnl', showDropdown: false }, { sectionName: 'Balance Sheet', sheet: 'balance_sheet', showDropdown: false }, { sectionName: 'Cash Flow', sheet: 'cash_flow', showDropdown: false }, { sectionName: `KFI's`, sheet: 'kfis', showDropdown: false }])
  const [surveyModalIsOpen, setSurveyModalOpen] = useState(false);
  const [dashboardViewModalState, setDashboardViewModalState] = useState({
    isOpen: false,
    action: 'close',
    edit_view: {},
    current_view: {},
    user_views: [],
    firms_views: []
  });
  const [surveyData, setSurveyData] = useState({
    sections: [],
    surveyLayoutData: {},
    surveyCharts: [],
  })
  const [reportMetrics, setReportMetrics] = useState({
    id: null,
    editMetrics: false,
    layout: [],
    peerview_charts: [],
    pnl: [],
    balance_sheet: [],
    cash_flow: [],
    kfis: [],
    financial_detail: [],
    kpi: [],
    allSelectedMetrics: [],
    allSheetMetrics: [],
    noChangesMade: true,
  });

  const [moneyNotes, setMoneyNotes] = useState({
    userNotes: 'Edit Me! Add your own notes here...'
  })
  const [editChartModel, setEditChartModel] = useState({
    isOpen: false,
    chartDetails: null,
    childItem: false,
    highChartOptions: null,
    oldStyles: { seriesOne: { type: 'column' }, seriesTwo: { type: 'column' } },
    newStyles: { seriesOne: { type: 'column' }, seriesTwo: { type: 'column' } }
  })

  const moneyTreesBuilder = (selectedItems, tree, displayByView = 'yearsView') => {
    let pnlOnePageRows = [];
    let balOnePageRows = [];
    let cashFlowRows = [];
    let kfisRows = [];
    const pnlTree = new AccountTree({
      tree: tree['pnl'],
      page: 'money',
      sheet: 'pnl',
      displayByView: displayByView,
      selectedMetrics: selectedItems['pnl']
    })
    pnlTree.forEach((row) => {
      pnlOnePageRows.push(row)
    })
    pnlOnePageRows = pnlOnePageRows.filter(Boolean)
    const balTree = new AccountTree({
      tree: tree['balance_sheet'],
      page: 'money',
      sheet: 'balance_sheet',
      displayByView: displayByView,
      selectedMetrics: selectedItems['balance_sheet']
    })
    balTree.forEach((row) => {
      balOnePageRows.push(row)
    })
    balOnePageRows = balOnePageRows.filter(Boolean)

    const cashFlowTree = new AccountTree({
      tree: tree['cash_flow'],
      page: 'money',
      sheet: 'cash_flow',
      displayByView: displayByView,
      selectedMetrics: selectedItems['cash_flow']
    })
    cashFlowTree.forEach((row) => {
      cashFlowRows.push(row)
    })
    cashFlowRows = cashFlowRows.filter(Boolean)

    const kfisTree = new AccountTree({
      tree: tree['kfis'],
      page: 'money',
      sheet: 'kfis',
      displayByView: displayByView,
      selectedMetrics: selectedItems['kfis']
    })
    kfisTree.forEach((row) => {
      kfisRows.push(row)
    })
    kfisRows = kfisRows.filter(Boolean)

    return { pnlOnePageRows, balOnePageRows, cashFlowRows, kfisRows }
  }

  const auditTreesBuilder = (tree, displayByView = 'yearsView') => {
    let pnlOnePageRows = [];
    let balOnePageRows = [];
    let kfisRows = [];
    const pnlTree = new AccountTree({
      tree: tree['pnl'],
      page: 'money',
      sheet: 'pnl',
      displayByView: displayByView
    })
    pnlTree.forEach((row) => {
      pnlOnePageRows.push(row)
    })
    pnlOnePageRows = pnlOnePageRows.filter(Boolean)

    const balTree = new AccountTree({
      tree: tree['balance_sheet'],
      page: 'money',
      sheet: 'balance_sheet',
      displayByView: displayByView
    })
    balTree.forEach((row) => {
      balOnePageRows.push(row)
    })
    balOnePageRows = balOnePageRows.filter(Boolean)

    const kfisTree = new AccountTree({
      tree: tree['kfis'],
      page: 'money',
      sheet: 'kfis',
      displayByView: displayByView
    })
    kfisTree.forEach((row) => {
      kfisRows.push(row)
    })
    kfisRows = kfisRows.filter(Boolean)

    return { pnlOnePageRows, balOnePageRows, kfisRows }
  }

  const peopleTreesBuilder = (selectedItems, tree, displayByView = 'yearsView') => {
    let financialDetailRows = [];
    let kpiRows = [];

    const pnlTree = new AccountTree({
      tree: tree['pnl'],
      page: 'people',
      sheet: 'pnl',
      displayByView: displayByView,
      selectedMetrics: selectedItems['financial_detail']
    })
    pnlTree.forEach((row) => {
      financialDetailRows.push(row)
    })
    financialDetailRows = financialDetailRows.filter(Boolean)

    const kpiTree = new AccountTree({
      tree: tree['kpi_people'],
      page: 'people',
      sheet: 'kpi_people',
      displayByView: displayByView,
      selectedMetrics: selectedItems['kpi']
    })
    kpiTree.forEach((row) => {
      kpiRows.push(row)
    })
    kpiRows = kpiRows.filter(Boolean)

    return { financialDetailRows, kpiRows }
  }

  const handleEditDashBoard = (sheet) => {
    setEditDashboard((prevState) => {
      let newEditSections = _.cloneDeep(prevState)
      let finalEditSections = newEditSections.map(section => {
        if (section['sheet'] === sheet) {
          section['showDropdown'] = !section['showDropdown']
        }
        return section;
      })

      return finalEditSections;
    })
  }

  const handleMoneyNotes = (newNotes) => {
    setMoneyNotes(() => {
      return {
        userNotes: newNotes
      }
    })
    setReportMetrics(prevState => {
      let currentView = dashboardViewModalState.current_view
      let noChangesVal = currentView.permission_details === 'view_only' && !dashboardViewModalState.user_views.find(v => v.id === currentView.id)
      return {
        ...prevState,
        noChangesMade: noChangesVal
      }
    })
  }

  const editingParentAndChildLayouts = (report, metricKey, childLayoutKey, selectedMetrics, oldDashboardLayout, oldAllSheetMetrics) => {
    let newLayout = []
    let newSheetRows = null;

    // Toggling a child key in a child/nested layout
    if (childLayoutKey) {
      let childLayout, childMetric = null;
      // Look for parent of child key in previous selected metrics
      let parentMetricIndex = selectedMetrics.findIndex(checked => checked['relatedCheckedKey'] === metricKey);
      if (selectedMetrics[parentMetricIndex]) {
        if (!selectedMetrics[parentMetricIndex].layout) {
          selectedMetrics.splice(parentMetricIndex, 1)
        }
      }
      // update the rows and selection pop up for checked metrics
      // add or remove any metrics for the new check metrics to build the dashboard layout
      newSheetRows = oldAllSheetMetrics.map(m => {
        // Look for parent of child key in previous selected metrics
        let parentMetricIndex = selectedMetrics.findIndex(checked => checked['relatedCheckedKey'] === metricKey);
        if (m['relatedCheckedKey'] === metricKey && m['displayChart']) {
          let childCheckedItemsNumber = 0;
          let childItem = null;

          if (m.layout.length > 0) {
            m.layout = m.layout.map(i => {
              i.checked = i.key === childLayoutKey ? !i.checked : i.checked;

              if (i.checked) {
                childCheckedItemsNumber += 1
              }
              return i;
            })
          }

          if (childCheckedItemsNumber) {
            m['checked'] = true
            // if parent doesn't exist in selectedMetrics
            if (parentMetricIndex < 0) {
              let newMetric = _.cloneDeep(m)
              newMetric.layout = newMetric.layout.filter(item => item.checked)
              // 1 child doesn't need child layout
              if (newMetric.layout.length === 1) {
                let soloChild = newMetric.layout[0]
                soloChild.metricRow = m
                if (!Object.hasOwnProperty('relatedCheckedKey')) { soloChild['relatedCheckedKey'] = soloChild.metricRow['relatedCheckedKey'] }
                selectedMetrics.push(soloChild)
              } else {
                selectedMetrics.push(newMetric)
              }
            } else {
              // if parent exists
              let newMetric = _.cloneDeep(m)
              let newChildLayout = newMetric.layout.filter(item => item.checked)
              // 1 child doesn't need child layout
              if (newChildLayout.length === 1) {
                selectedMetrics.splice(parentMetricIndex, 1)
                let soloChild = newChildLayout[0]
                soloChild.metricRow = m
                if (!Object.hasOwnProperty('relatedCheckedKey')) { soloChild['relatedCheckedKey'] = soloChild.metricRow['relatedCheckedKey'] }
                selectedMetrics.push(soloChild)
              } else {
                // handle parent metrics with child layouts
                if (selectedMetrics[parentMetricIndex].layout) {
                  selectedMetrics[parentMetricIndex].layout = newChildLayout
                } else {
                  // More than 1 child will need a parent
                  selectedMetrics.splice(parentMetricIndex, 1)
                  newMetric.layout = newChildLayout;
                  selectedMetrics.push(newMetric)
                }
              }
            }
          } else {
            // if no child keys are checked remove the parent metric if exists
            m['checked'] = false
            if (parentMetricIndex > -1) { selectedMetrics.splice(parentMetricIndex, 1) }
          }
        }
        return m;
      })
    } else {
      // Deals with metrics not in child layouts
      let oneAlreadyFound = 0
      let allChecked = null;

      newSheetRows = oldAllSheetMetrics.map(m => {
        let checkedMetricIndex = selectedMetrics.findIndex(checked => checked['relatedCheckedKey'] === metricKey)

        if ((m['relatedCheckedKey'] === metricKey && m['displayChart']) || (report === 'customers' && m['relatedCheckedKey'] === metricKey && m['displayChart'])) {
          m['checked'] = oneAlreadyFound ? allChecked : !m['checked']
          oneAlreadyFound += 1
          if (oneAlreadyFound === 1) { allChecked = m['checked'] }

          if (m.layout && m.layout.length > 0) {
            m.layout = m.layout.map(i => {
              if (!i.key.includes('_notes') && m['checked']) {
                i.checked = m['checked'];
              } else if (!m['checked']) {
                i.checked = false
              }
              return i;
            })
          }

          if (m['checked']) {
            if (checkedMetricIndex < 0) {
              selectedMetrics.push(m)
            }
          } else {
            if (checkedMetricIndex > -1) {
              selectedMetrics.splice(checkedMetricIndex, 1)
            }
          }
        }
        return m
      })
    }

    selectedMetrics.forEach((s, i) => {
      let oldParentItem = null;
      let parentLayout = [];
      oldParentItem = oldDashboardLayout.find(o => o['i'] === s.key);
      if (oldParentItem && s.layout) {
        let parentH = 2;
        let parentW = 3;
        let widthCounter = 0
        s.layout.forEach((c) => {
          let oldChildItem = oldParentItem.layout.find(l => l.i === c.key)
          if (oldChildItem && c['checked']) {
            parentLayout.push(oldChildItem)
            if (widthCounter >= 3) {
              parentH += oldChildItem.h;
              widthCounter = 0
            }
            parentW += oldChildItem.w;
            widthCounter += oldChildItem.w;
          } else if (c['checked']) {
            let newChildItem = layoutMaker(c, i)
            parentLayout.push(newChildItem)
            if (widthCounter >= 3) {
              parentH += newChildItem.h;
              widthCounter = 0
            }
            parentW += newChildItem.w;
            widthCounter += newChildItem.w;
          }
        })

        oldParentItem.layout = parentLayout;
        oldParentItem.w = parentW > 3 ? 3 : parentW
        oldParentItem.h = parentH
        newLayout.push(oldParentItem)
      } else if (oldParentItem) {
        newLayout.push(oldParentItem)
      } else {
        // parent with kids
        if (s['layout']) {
          let parentH = 2;
          let parentW = 3;
          let widthCounter = 0
          let miniLayout = s['layout'].filter(child => child['checked']).map((l, i) => {
            let layoutItem = layoutMaker(l, i)
            if (widthCounter >= 3) {
              parentH += layoutItem.h;
              widthCounter = 0
            }
            parentW += layoutItem.w;
            widthCounter += layoutItem.w;
            return layoutItem;
          })
          newLayout.push({
            i: s.key,
            isBounded: false,
            isDraggable: true,
            isResizable: true,
            x: ((i * 3) % (6)),
            y: Infinity,
            w: parentW > 3 ? 3 : parentW,
            h: parentH,
            minW: 1,
            minH: 1,
            maxW: 6,
            maxH: 12,
            resizeHandles: ['se'],
            layout: miniLayout,
            metricRow: s
          })
        } else {
          // no kids
          let layoutItem = layoutMaker(s, metricKey)
          newLayout.push(layoutItem);
        }
      }
    })

    return { newRows: newSheetRows, newLayout: newLayout };
  }

  const handleSelectAndLayout = (report, sheet, metricKey, childLayoutKey = '') => {
    let newLayout = []
    let selectedMetrics = null;

    setReportMetrics((prevState) => {
      selectedMetrics = _.cloneDeep(prevState.allSelectedMetrics);
      let newSheetRows = null;
      let oldDashboardLayout = _.cloneDeep(prevState.layout)
      let oldAllSheetMetrics = _.cloneDeep(prevState.allSheetMetrics)

      let { newRows, newLayout } = editingParentAndChildLayouts(report, metricKey, childLayoutKey, selectedMetrics, oldDashboardLayout, oldAllSheetMetrics)
      let allKeys = newRows.map(n => n.key)
      newLayout = newLayout.filter(l => allKeys.includes(l.i))

      if (report === 'money') {
        return {
          ...prevState,
          peerview_charts: newRows.filter(m => m.sheet === 'peerview_charts'),
          pnl: newRows.filter(m => m.sheet === 'pnl'),
          balance_sheet: newRows.filter(m => m.sheet === 'balance_sheet'),
          cash_flow: newRows.filter(m => m.sheet === 'cash_flow'),
          kfis: newRows.filter(m => m.sheet === 'kfis'),
          allSelectedMetrics: selectedMetrics,
          allSheetMetrics: newRows,
          layout: newLayout,
          noChangesMade: false
        }
      }

      if (report === 'people' || report === 'customers') {
        // People follows chart of account tree and Customers follows arrays
        let financialDetailRows = report === 'people' ? newRows.filter(m => m.sheet === 'pnl') : newRows.filter(m => m.sheet === 'financial_detail')
        let kpiRows = report === 'people' ? newRows.filter(m => m.sheet === 'kpi_people') : newRows.filter(m => m.sheet === 'kpi')

        return {
          ...prevState,
          peerview_charts: newRows.filter(m => m.sheet === 'peerview_charts'),
          financial_detail: financialDetailRows,
          kpi: kpiRows,
          allSelectedMetrics: selectedMetrics,
          allSheetMetrics: newRows,
          layout: newLayout,
          noChangesMade: false
        }
      }
    })

  }

  const handleDashboardSelections = (item, parentItemInfo = null) => {
    let itemsCheckedTrue = [];
    setDashboard((prevState) => {
      let oldDashInfo = _.cloneDeep(prevState);
      if (parentItemInfo) {
        let parentItem = oldDashInfo.selectedItems.find((c) => c.key === parentItemInfo.key);
        if (parentItem.children) {
          let childItem = parentItem.children.find((child) => child.key === item.key);
          childItem.checked = !childItem.checked
          let numberOfChildrenChecked = parentItem.children.filter(child => child.checked)
          parentItem.checked = numberOfChildrenChecked.length > 0
        }
      } else {
        let selectedChart = oldDashInfo.selectedItems.find((c) => c.key === item.key);
        let checkStatus = !selectedChart.checked
        if (selectedChart.children) {
          selectedChart.children = selectedChart.children.map((c) => {
            c.checked = checkStatus
            return c;
          })
        }
        selectedChart.checked = checkStatus;
      }

      oldDashInfo.selectedItems.forEach((c) => {
        if (c.checked) {
          itemsCheckedTrue.push(c.key)
        }
      })

      let chartsLength = itemsCheckedTrue.length;
      let newLayout = itemsCheckedTrue.map((c, i) => {
        let itemLayout = oldDashInfo.layout.find((d) => d['i'] === c);
        if (itemLayout) {
          return itemLayout
        } else {
          if (c === 'peerview_gauges') {
            return { i: 'peerview_gauges', isBounded: false, isDraggable: true, isResizable: false, x: 0, y: 0, w: 6, h: 1, minW: 6, minH: 1, maxW: 6, maxH: 12, resizeHandles: ['se'] }
          } else if (c.includes('pie')) {
            return {
              i: c,
              isBounded: false,
              isDraggable: true,
              isResizable: true,
              x: ((i * 2) % (6)),
              y: ((i * 2) % (3)),
              w: 2,
              h: 2,
              minW: 1,
              minH: 1,
              maxW: 6,
              maxH: 12,
              resizeHandles: ['se']
            }
          } else {
            return {
              i: c,
              isBounded: false,
              isDraggable: true,
              isResizable: true,
              x: ((i * 2) % (6)),
              y: Infinity,
              w: 2,
              h: 1,
              minW: 1,
              minH: 1,
              maxW: 6,
              maxH: 12,
              resizeHandles: ['se']
            }
          }
        }
      })

      return { ...prevState, selectedItems: oldDashInfo.selectedItems, layout: newLayout, noChangesMade: false }
    })
  }

  const handleSelectAll = (select) => {
    setDashboard((prevState) => {
      let newLayout = []
      let oldDashInfo = _.cloneDeep(prevState);
      let allItems = oldDashInfo.selectedItems.map((item) => {
        if (item.children) {
          item.children = item.children.map((c) => {
            c.checked = select
            return c;
          })
        }
        item.checked = select;
        return item;
      })

      allItems.forEach((c, i) => {
        if (c.checked) {
          let itemLayout = oldDashInfo.layout.find((d) => d['i'] === c.key);
          if (itemLayout) {
            newLayout.push(itemLayout)
          } else {
            if (c.key === 'peerview_gauges') {
              newLayout.push({ i: 'peerview_gauges', isBounded: false, isDraggable: true, isResizable: false, x: 0, y: 0, w: 6, h: 1, minW: 6, minH: 1, maxW: 6, maxH: 12, resizeHandles: ['se'] })
            } else if (c.key.includes('pie')) {
              newLayout.push({
                i: c.key,
                isBounded: false,
                isDraggable: true,
                isResizable: true,
                x: ((i * 2) % (6)),
                y: ((i * 2) % (3)),
                w: 2,
                h: 2,
                minW: 1,
                minH: 1,
                maxW: 6,
                maxH: 12,
                resizeHandles: ['se']
              })
            } else {
              newLayout.push({
                i: c.key,
                isBounded: false,
                isDraggable: true,
                isResizable: true,
                x: ((i * 2) % (6)),
                y: Infinity,
                w: 2,
                h: 1,
                minW: 1,
                minH: 1,
                maxW: 6,
                maxH: 12,
                resizeHandles: ['se']
              })
            }
          }
        }
      })

      return { ...prevState, selectedItems: allItems, layout: newLayout, noChangesMade: false }
    })
  }

  const createDashBoardView = async (newDashView) => {
    let newView = {
      name: newDashView.name,
      private: newDashView.viewVisibilty === 'only_me',
      report_type: report,
      permission_details: newDashView.viewPermission,
      view_apply: newDashView.viewApply
    }

    if (report === 'money') {
      newView['selected_items'] = {
        peerview_charts: reportMetrics['peerview_charts'],
        pnl: reportMetrics['pnl'],
        balance_sheet: reportMetrics['balance_sheet'],
        cash_flow: reportMetrics['cash_flow'],
        kfis: reportMetrics['kfis'],
        money_all_selected_items: reportMetrics['allSelectedMetrics'],
        money_user_notes: moneyNotes['userNotes'],
      }
      newView['dashboard_layout'] = reportMetrics['layout']
    } else if (report === 'customers') {
      newView['selected_items'] = {
        peerview_charts: reportMetrics['peerview_charts'],
        pnl: reportMetrics['pnl'],
        balance_sheet: reportMetrics['balance_sheet'],
        cash_flow: reportMetrics['cash_flow'],
        kfis: reportMetrics['kfis'],
        all_selected_items: reportMetrics['allSelectedMetrics']
      }
      newView['dashboard_layout'] = reportMetrics['layout']
    } else {
      newView['selected_items'] = dashboard.selectedItems
      newView['dashboard_layout'] = dashboard.layout
    }

    try {
      const res = await fetch(`/dashboard_view`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newView),
      })
      const dashViewRes = await res.json();

      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        if (report === 'money' || report === 'customers') {
          setReportMetrics(prevState => {
            return {
              ...prevState,
              id: dashViewRes.current_view.id,
              noChangesMade: true
            }
          })

          setDashboardViewModalState(prevState => {
            return {
              ...prevState,
              current_view: dashViewRes.current_view,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          })
        } else {
          setDashboard((prevState) => {
            return {
              ...prevState,
              id: dashViewRes.current_view.id,
              noChangesMade: true
            }
          })
          setDashboardViewModalState(prevState => {
            return {
              ...prevState,
              current_view: dashViewRes.current_view,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          })
        }
        toast.success('New view created!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      let errorMessage = dashViewRes.name ? `Name ${dashViewRes.name}` : 'New view was not created properly'
      toast.error(`Sorry something went wrong. ${errorMessage}.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const getDashBoardView = async (dashViewId) => {
    try {
      const res = await fetch(`/dashboard_view/${dashViewId}`, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        }
      })
      const dashViewRes = await res.json();

      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        let currentDash = dashViewRes['current_view']
        if (report === 'money' || report === 'people') {
          let layout = [];
          let peerviewCharts = null;
          let peerviewChartsSelected = [];
          if (currentDash['selected_items']['peerview_charts'].length) {
            let { selectedItems } = mergeItems(report, currentDash['selected_items']['peerview_charts'], []);
            peerviewCharts = selectedItems;
            peerviewCharts.forEach((chart) => {
              if (chart.checked) {
                peerviewChartsSelected.push(chart)
              }
            })

            let peerviewChartKeys = currentDash['selected_items']['peerview_charts'].map(p => p.key)
            let pnlKeys = currentDash['selected_items']['pnl'].map(p => p.key)
            let balKeys = currentDash['selected_items']['balance_sheet'].map(b => b.key)
            let cashKeys = currentDash['selected_items']['cash_flow'].map(c => c.key)
            let kfisKeys = currentDash['selected_items']['kfis'].map(k => k.key)
            let kpiKeys = currentDash['selected_items']['kpi'].map(k => k.key)
            let allSelectedKeys = [...peerviewChartKeys, ...pnlKeys, ...balKeys, ...cashKeys, ...kfisKeys, ...kpiKeys]
            let currentDashLayout = currentDash['dashboard_layout'].filter(l => allSelectedKeys.includes(l.i))
            layout = currentDashLayout
          } else {
            peerviewCharts = report === 'money' ? moneyCharts['peerview_charts'] : peopleCharts['peerview_charts']
            peerviewCharts.forEach((chart) => {
              if (chart.checked) {
                let defaultLayout = report === 'money' ? defaultLayouts['money_peerview_charts'].find((l) => l.i === chart.key) : defaultLayouts['people_peerview_charts'].find((l) => l.i === chart.key)
                if (defaultLayout) {
                  layout.push(defaultLayout)
                  peerviewChartsSelected.push(chart)
                }
              }
            })
          }

          let displayByView = 'yearsView'
          if (currentNewFilters['display_columns_by'] === 'Years' && currentNewFilters['report_period'] === 'Year to Date') {
            displayByView = 'ytdView'
          }
          if (currentNewFilters['display_columns_by'] === 'Months') {
            displayByView = 'monthsView'
          }

          if (report === 'money') {
            let { pnlOnePageRows, balOnePageRows, cashFlowRows, kfisRows } = moneyTreesBuilder(currentDash['selected_items'], state.tree, displayByView)
            let selectedItems = currentDash['selected_items']['money_all_selected_items'].length > 0 ? currentDash['selected_items']['money_all_selected_items'] : peerviewChartsSelected;
            currentDash.selected_items = selectedItems
            currentDash.dashboard_layout = layout

            setDashboardViewModalState((prevState) => {
              return {
                ...prevState,
                current_view: currentDash
              }
            })
            setReportMetrics(prevState => {
              return {
                ...prevState,
                id: currentDash.id,
                layout: layout,
                peerview_charts: peerviewCharts,
                pnl: pnlOnePageRows,
                balance_sheet: balOnePageRows,
                cash_flow: cashFlowRows,
                kfis: kfisRows,
                allSelectedMetrics: selectedItems,
                allSheetMetrics: [...peerviewCharts, ...pnlOnePageRows, ...balOnePageRows, ...cashFlowRows, ...kfisRows],
                noChangesMade: true
              }
            })
          } else if (report === 'people') {
            let { financialDetailRows, kpiRows } = peopleTreesBuilder(currentDash['selected_items'], state.tree, displayByView)
            let selectedItems = currentDash['selected_items']['all_selected_items'].length > 0 ? currentDash['selected_items']['all_selected_items'] : peerviewChartsSelected;
            currentDash.selected_items = selectedItems
            currentDash.dashboard_layout = layout

            setDashboardViewModalState((prevState) => {
              return {
                ...prevState,
                current_view: currentDash
              }
            })
            setReportMetrics(prevState => {
              return {
                ...prevState,
                id: currentDash.id,
                layout: layout,
                peerview_charts: peerviewCharts,
                financial_detail: financialDetailRows,
                kpi: kpiRows,
                allSelectedMetrics: selectedItems,
                allSheetMetrics: [...peerviewCharts, ...financialDetailRows, ...kpiRows],
                noChangesMade: true
              }
            })
          }
        } else if (report === 'customers') {
          let finalLayout = [];
          let peerviewCharts = null;
          let peerviewChartsSelected = [];
          if (currentDash['selected_items'] && currentDash['selected_items']['peerview_charts']) {
            let { selectedItems } = mergeItems('customers', currentDash['selected_items']['peerview_charts'], []);
            peerviewCharts = selectedItems;
            peerviewCharts.forEach((chart) => {
              if (chart.checked) {
                peerviewChartsSelected.push(chart)
              }
            })
            finalLayout = [...currentDash['dashboard_layout']]
          } else {
            peerviewCharts = customerCharts['peerview_charts']
            peerviewCharts.forEach((chart) => {
              if (chart.checked) {
                let defaultLayout = defaultLayouts['money_peerview_charts'].find((l) => l.i === chart.key)
                if (defaultLayout) {
                  finalLayout.push(defaultLayout)
                  peerviewChartsSelected.push(chart)
                }
              }
            })
          }

          let kpiRows = customersKPIsFormat(currentNewFilters['display_columns_by'], currentNewFilters['report_period']);
          let finalSelectedItems = []
          if (currentDash['selected_items'] && !currentDash['selected_items']['all_selected_items']) {
            let currentSelectedItems = currentDash['selected_items']
            let { layout, selectedItems } = mergeItems('customers', currentSelectedItems, dashBoardRes['current_view']['dashboard_layout'])
            finalLayout = layout
            finalSelectedItems = selectedItems
          } else {
            finalSelectedItems = currentDash['selected_items']['all_selected_items']
            finalLayout = currentDash['dashboard_layout']
          }
          let finalFinancialDetailRows = updateCustomersRows(finalSelectedItems, financialDetailRows)
          let finalKpiRows = updateCustomersRows(finalSelectedItems, kpiRows)
          finalLayout = addMetricRow(finalLayout)

          setDashboardViewModalState((prevState) => {
            return {
              ...prevState,
              current_view: currentDash
            }
          })

          setReportMetrics(prevState => {
            return {
              ...prevState,
              id: currentDash.id,
              layout: finalLayout,
              peerview_charts: peerviewCharts,
              financial_detail: finalFinancialDetailRows,
              kpi: finalKpiRows,
              allSelectedMetrics: finalSelectedItems,
              allSheetMetrics: [...peerviewCharts, ...finalFinancialDetailRows, ...finalKpiRows],
              noChangesMade: true
            }
          })

        } else {
          setDashboardViewModalState(prevState => {
            return {
              ...prevState,
              current_view: currentDash
            }
          })
          setDashboard(() => {
            return {
              id: currentDash.id,
              selectedItems: currentDash.selected_items,
              layout: currentDash.dashboard_layout,
              noChangesMade: true
            }
          })
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(`Sorry something went wrong.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const updateDashBoardView = async (e = false, dashId = null, dashViewInfo = null) => {
    if (e) { e.preventDefault() }
    let dashViewId = dashId
    let updatedView = { report_type: state.report }
    if (dashViewInfo) {
      updatedView['name'] = dashViewInfo.name
      updatedView['private'] = dashViewInfo.viewVisibilty === 'only_me'
      updatedView['permission_details'] = dashViewInfo.viewPermission
    }

    if (report === 'money') {
      if (!dashViewId) dashViewId = reportMetrics.id
      updatedView['selected_items'] = {
        peerview_charts: reportMetrics['peerview_charts'],
        pnl: reportMetrics['pnl'],
        balance_sheet: reportMetrics['balance_sheet'],
        cash_flow: reportMetrics['cash_flow'],
        kfis: reportMetrics['kfis'],
        financial_detail: reportMetrics['financial_detail'],
        kpi: reportMetrics['kpi'],
        money_all_selected_items: reportMetrics['allSelectedMetrics'],
        money_user_notes: moneyNotes['userNotes'],
      }
      updatedView['dashboard_layout'] = reportMetrics['layout']
      setReportMetrics((prevState) => {
        return { ...prevState, noChangesMade: true }
      })
    } else if (report === 'people' || report === 'customers') {
      if (!dashViewId) dashViewId = reportMetrics.id
      updatedView['selected_items'] = {
        peerview_charts: reportMetrics['peerview_charts'],
        financial_detail: reportMetrics['financial_detail'],
        kpi: reportMetrics['kpi'],
        all_selected_items: reportMetrics['allSelectedMetrics'],
      }
      updatedView['dashboard_layout'] = reportMetrics['layout']
      setReportMetrics((prevState) => {
        return { ...prevState, noChangesMade: true }
      })
    } else {
      if (!dashViewId) dashViewId = dashboard.id
      updatedView['selected_items'] = dashboard.selectedItems
      updatedView['dashboard_layout'] = dashboard.layout
      setDashboard((prevState) => {
        return { ...prevState, noChangesMade: true }
      })
    }
    try {
      const res = await fetch(`/dashboard_view/${dashViewId}`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedView),
      })
      const dashViewRes = await res.json()

      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        setDashboardViewModalState(prevState => {
          if (reportMetrics.id !== dashViewId && report === 'money') {
            return {
              ...prevState,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          } else if (dashboard.id !== dashViewId && report !== 'money') {
            return {
              ...prevState,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          } else {
            return {
              ...prevState,
              current_view: dashViewRes.current_view,
              user_views: dashViewRes.user_views,
              firms_views: dashViewRes.firms_views
            }
          }
        })
        toast.success('Changes to view saved!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Sorry something went wrong. Changes to view could not be saved.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const deleteDashBoardView = async (e, dashViewId) => {
    e.preventDefault()
    try {
      const res = await fetch(`/dashboard_view/${dashViewId}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        }
      })

      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        let defaultView = dashboardViewModalState.firms_views.find(v => v.name === 'Default')
        let currentViewDeleted = dashViewId === dashboardViewModalState.current_view.id
        setDashboardViewModalState(prevState => {
          let oldUserViews = prevState.user_views
          let newUserViews = oldUserViews.filter(v => v.id !== dashViewId)
          let oldFirmsViews = prevState.firms_views
          let newFirmsViews = oldFirmsViews.filter(v => v.id !== dashViewId)

          if (currentViewDeleted) {
            return {
              ...prevState,
              current_view: defaultView,
              user_views: newUserViews,
              firms_views: newFirmsViews
            }
          } else {
            return {
              ...prevState,
              user_views: newUserViews,
              firms_views: newFirmsViews
            }
          }
        })

        if (report === 'audit' && currentViewDeleted) {
          setDashboard((prevState) => {
            return {
              id: defaultView.id,
              selectedItems: defaultView.selected_items,
              layout: defaultView.dashboard_layout,
              noChangesMade: true
            }
          })
        }

        if ((report === 'money' && currentViewDeleted) || (report === 'people' && currentViewDeleted)) {
          let layout = [];
          let peerviewCharts = null;
          let peerviewChartsSelected = [];
          if (defaultView['selected_items']['peerview_charts'].length > 0) {
            let { selectedItems } = mergeItems(report, defaultView['selected_items']['peerview_charts'], []);
            peerviewCharts = selectedItems;
            peerviewCharts.forEach((chart) => {
              if (chart.checked) {
                peerviewChartsSelected.push(chart)
              }
            })
            let peerviewChartKeys = defaultView['selected_items']['peerview_charts'].map(p => p.key)
            let pnlKeys = defaultView['selected_items']['pnl'].map(p => p.key)
            let balKeys = defaultView['selected_items']['balance_sheet'].map(b => b.key)
            let cashKeys = defaultView['selected_items']['cash_flow'].map(c => c.key)
            let kfisKeys = defaultView['selected_items']['kfis'].map(k => k.key)
            let kpiKeys = defaultView['selected_items']['kpi'].map(k => k.key)
            let allSelectedKeys = [...peerviewChartKeys, ...pnlKeys, ...balKeys, ...cashKeys, ...kfisKeys, ...kpiKeys]
            let currentDashLayout = defaultView['dashboard_layout'].filter(l => allSelectedKeys.includes(l.i))
            layout = currentDashLayout
          } else {
            peerviewCharts = report === 'money' ? moneyCharts['peerview_charts'] : peopleCharts['peerview_charts']
            peerviewCharts.forEach((chart) => {
              if (chart.checked) {
                let defaultLayout = report === 'money' ? defaultLayouts['money_peerview_charts'].find((l) => l.i === chart.key) : defaultLayouts['people_peerview_charts'].find((l) => l.i === chart.key)
                if (defaultLayout) {
                  layout.push(defaultLayout)
                  peerviewChartsSelected.push(chart)
                }
              }
            })
          }

          let displayByView = 'yearsView'
          if (currentNewFilters['display_columns_by'] === 'Years' && currentNewFilters['report_period'] === 'Year to Date') {
            displayByView = 'ytdView'
          }
          if (currentNewFilters['display_columns_by'] === 'Months') {
            displayByView = 'monthsView'
          }

          if (report === 'money') {
            let { pnlOnePageRows, balOnePageRows, cashFlowRows, kfisRows } = moneyTreesBuilder(defaultView['selected_items'], state.tree, displayByView)
            let selectedItems = defaultView['selected_items']['money_all_selected_items'].length > 0 ? defaultView['selected_items']['money_all_selected_items'] : peerviewChartsSelected;

            setReportMetrics(prevState => {
              return {
                ...prevState,
                id: defaultView.id,
                layout: layout,
                peerview_charts: peerviewCharts,
                pnl: pnlOnePageRows,
                balance_sheet: balOnePageRows,
                cash_flow: cashFlowRows,
                kfis: kfisRows,
                allSelectedMetrics: selectedItems,
                allSheetMetrics: [...peerviewCharts, ...pnlOnePageRows, ...balOnePageRows, ...cashFlowRows, ...kfisRows],
                noChangesMade: true
              }
            })
            defaultView.selected_items = selectedItems
            defaultView.dashboard_layout = layout
            setDashboardViewModalState((prevState) => {
              return {
                ...prevState,
                current_view: defaultView
              }
            })
          } else if (report === 'people') {
            let { financialDetailRows, kpiRows } = peopleTreesBuilder(currentDash['selected_items'], state.tree, displayByView)
            let selectedItems = currentDash['selected_items']['all_selected_items'].length > 0 ? currentDash['selected_items']['all_selected_items'] : peerviewChartsSelected;
            currentDash.selected_items = selectedItems
            currentDash.dashboard_layout = layout

            setDashboardViewModalState((prevState) => {
              return {
                ...prevState,
                current_view: currentDash
              }
            })
            setReportMetrics(prevState => {
              return {
                ...prevState,
                id: currentDash.id,
                layout: layout,
                peerview_charts: peerviewCharts,
                financial_detail: financialDetailRows,
                kpi: kpiRows,
                allSelectedMetrics: selectedItems,
                allSheetMetrics: [...peerviewCharts, ...financialDetailRows, ...kpiRows],
                noChangesMade: true
              }
            })
          }
        }

        if (report === 'customers' && currentViewDeleted) {
          let finalLayout = [];
          let peerviewCharts = null;
          let peerviewChartsSelected = [];
          if (defaultView['selected_items']['peerview_charts'].length > 0) {
            let { selectedItems } = mergeItems('customers', defaultView['selected_items']['peerview_charts'], []);
            peerviewCharts.forEach((chart) => {
              if (chart.checked) {
                peerviewChartsSelected.push(chart)
              }
            })
            finalLayout = [...defaultView['dashboard_layout']]
          } else {
            peerviewCharts = customerCharts['peerview_charts']
            peerviewCharts.forEach((chart) => {
              if (chart.checked) {
                let defaultLayout = defaultLayouts['customers_peerview_charts'].find((l) => l.i === chart.key)
                if (defaultLayout) {
                  finalLayout.push(defaultLayout)
                  peerviewChartsSelected.push(chart)
                }
              }
            })
          }

          let kpiRows = customersKPIsFormat(currentNewFilters['display_columns_by'], currentNewFilters['report_period']);

          let finalSelectedItems = []
          if (defaultView['selected_items'] && !defaultView['selected_items']['all_selected_items']) {
            let currentSelectedItems = defaultView['selected_items']
            let { layout, selectedItems } = mergeItems('customers', currentSelectedItems, dashBoardRes['current_view']['dashboard_layout'])
            finalLayout = layout
            finalSelectedItems = selectedItems
          } else {
            finalSelectedItems = defaultView['selected_items']['all_selected_items']
            finalLayout = dashBoardRes['current_view']['dashboard_layout']
          }
          let finalFinancialDetailRows = updateCustomersRows(finalSelectedItems, financialDetailRows)
          let finalKpiRows = updateCustomersRows(finalSelectedItems, kpiRows)
          finalLayout = addMetricRow(finalLayout)

          setReportMetrics(prevState => {
            return {
              ...prevState,
              id: defaultView.id || 0,
              layout: finalLayout,
              peerview_charts: peerviewCharts,
              financial_detail: finalFinancialDetailRows,
              kpi: finalKpiRows,
              allSelectedMetrics: finalSelectedItems,
              allSheetMetrics: [...peerviewCharts, ...finalFinancialDetailRows, ...finalKpiRows],
              noChangesMade: true
            }
          })

          defaultView.selected_items = finalSelectedItems
          defaultView.dashboard_layout = finalLayout
          setDashboardViewModalState((prevState) => {
            return {
              ...prevState,
              current_view: defaultView
            }
          })
        }


        toast.success('View was deleted!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Sorry something went wrong. Try again later.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const handleDashboardViewModal = (action, info = null) => {
    setDashboardViewModalState(prevState => {
      if (action === 'close') {
        return { ...prevState, isOpen: false, action: 'close' }
      } else if (action === 'edit_view') {
        let currentView = prevState.current_view
        let noViewEdit = currentView.permission_details !== 'view_only' || prevState.user_views.find(v => v.id === currentView.id)
        let currentInfo = !info ? currentView : info
        if (!noViewEdit) {
          toast.error(`Sorry You Are NOT Allowed to Edit this Dashboard View. You need to be the owner of this dashboard view in order to edit.`, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          return prevState
        } else {
          return { ...prevState, isOpen: true, action: action, edit_view: currentInfo }
        }
      } else {
        return { ...prevState, isOpen: true, action: action, edit_view: {} }
      }
    })
  }

  const handleSurveyModal = (closeOpenSurvey) => {
    setSurveyModalOpen(closeOpenSurvey);
  };

  const handleOnLayoutChange = (dashBoardType, newLayout, parentLayout = null) => {
    let currentView = dashboardViewModalState.current_view
    let noChangesVal = currentView.permission_details === 'view_only' && !dashboardViewModalState.user_views.find(v => v.id === currentView.id)

    if (dashBoardType === 'normalDashboard') {
      setDashboard((prevState) => {
        let oldLayout = prevState.layout;
        let modifiedLayout = oldLayout.map((ol) => {
          let findNewItem = newLayout.find(item => item.i === ol.i)
          if (findNewItem) {
            return { ...ol, ...findNewItem }
          } else {
            return ol;
          }
        })

        return { ...prevState, layout: modifiedLayout, noChangesMade: noChangesVal }
      })
    }

    if (dashBoardType === 'parentMyDashBoard') {
      setReportMetrics((prevState) => {
        let oldLayout = prevState.layout;
        let modifiedLayout = oldLayout.map((ol) => {
          let findNewItem = newLayout.find(item => item.i === ol.i)
          if (findNewItem) {
            return { ...ol, ...findNewItem }
          } else {
            return ol;
          }
        })
        return { ...prevState, layout: modifiedLayout, noChangesMade: noChangesVal }
      })
    }

    if (dashBoardType === 'childMyDashBoard') {
      setReportMetrics((prevState) => {
        let modifiedLayout = _.cloneDeep(prevState.layout);
        let findParentLayout = modifiedLayout.find(ol => ol.i === parentLayout.key)
        let findParentLayoutIndex = modifiedLayout.findIndex(ol => ol.i === parentLayout.key)
        if (findParentLayout) {
          findParentLayout.layout = findParentLayout.layout.map(pl => {
            let findNewItem = newLayout.find(item => item.i === pl.i)
            if (findNewItem) {
              return { ...pl, ...findNewItem }
            } else {
              return pl;
            }
          })

          modifiedLayout[findParentLayoutIndex] = findParentLayout
        }
        return { ...prevState, layout: modifiedLayout, noChangesMade: noChangesVal }
      })
    }
  }

  const handleToggleDraggable = (key, dashBoardType = '', parentInfo = null) => {
    let currentView = dashboardViewModalState.current_view
    let noChangesVal = currentView.permission_details === 'view_only' && !dashboardViewModalState.user_views.find(v => v.id === currentView.id)
    if (dashBoardType === 'moneyDashboard') {
      setReportMetrics((prevState) => {
        let newLayout = _.cloneDeep(prevState.layout);

        if (parentInfo) {
          let parentIndex = newLayout.findIndex((l) => l.i === parentInfo.key);
          if (parentIndex > -1) {
            let childIndex = parentInfo.layout.findIndex((child) => child.key === key)
            if (childIndex > -1) {
              newLayout[parentIndex].isDraggable = !newLayout[parentIndex].isDraggable
              newLayout[parentIndex]['layout'][childIndex].isDraggable = newLayout[parentIndex].isDraggable
            }
          }
        } else {
          let itemIndex = newLayout.findIndex((l) => l.i === key);
          if (itemIndex > -1) {
            newLayout[itemIndex].isDraggable = !newLayout[itemIndex].isDraggable
          }
        }
        return { ...prevState, layout: newLayout, noChangesMade: noChangesVal }
      })
    } else {
      setDashboard((prevState) => {
        let newLayout = _.cloneDeep(prevState.layout);
        let itemIndex = newLayout.findIndex((l) => l.i === key);
        if (itemIndex > -1) {
          newLayout[itemIndex].isDraggable = !newLayout[itemIndex].isDraggable
        }
        return { ...prevState, layout: newLayout, noChangesMade: noChangesVal }
      })
    }
  }

  const handleEditMetrics = (e = false, selectionEditForm = false) => {
    if (e) { e.preventDefault() }

    setReportMetrics(prevState => {
      if (selectionEditForm) {
        updateDashBoardView()
        return {
          ...prevState,
          noChangesMade: true,
        }
      } else {
        if (prevState.editMetrics) {
          updateDashBoardView()
        }
        return {
          ...prevState,
          editMetrics: !prevState.editMetrics,
          noChangesMade: true,
        }
      }
    })
  }

  const handleSurveySubmit = async (e, surveyState) => {
    e.preventDefault();
    let answers = {}
    let numberYearAnswers = {}
    surveyData.sections.forEach((section, i) => {
      surveyState.surveyLayoutData[section]['sectionData'].forEach((data => {
        if (data.inputType === 'radio' || data.inputType === 'ranking') {
          data.questions.forEach(q => {
            answers[q.key] = q.answer
          })
        } else {
          answers[data.key] = data.answer
          numberYearAnswers[data.key] = data.answer
        }
      }))
    })

    try {
      const res = await fetch(`/api/v1/surveys/${state.companyReportSurvey.survey.id}`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answers: answers,
          report: state.report,
          year: state.companyReportSurvey.survey.year,
          filters: state.filters
        }),
      })

      const surveyRes = await res.json();

      if (!res.ok) {
        throw new Error('Network response was not ok.');
      } else {
        setState(prevState => {
          return {
            ...prevState,
            companyReportSurvey: {
              survey: surveyRes.companyReportSurvey,
              number_year_inputs: numberYearAnswers
            },
            surveyResults: surveyRes.surveyResults,
            surveyTaken: surveyRes.surveyTaken,
          }
        });
        setSurveyModalOpen(false)
        ahoy.track('Survey Submitted', { survey: `${props.client_name} (NAICS: ${props.code}): ${state.pageTitle} ${state.companyReportSurvey.survey.year}` });
      }
    } catch (error) {
      console.log(error);
      alert(
        'Sorry something went wrong. Changes were not saved properly.'
      );
    }
  }

  const getMonthlyForecastScenario = async (data) => {
    setState((prevState) => {
      return {
        ...prevState,
        loading: true
      }
    })
    let scenarioData = data.split(':')
    if (data !== 'YOY:Default' && data !== 'Peers:Default') {
      const json = await fetch(`/api/v1/monthly_calculations/?monthly_forecast_scenario=${scenarioData[0]}&year=${scenarioData[1]}`, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': window.token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      const res = await json.json()
      setState(prevState => {
        return {
          ...prevState,
          baseCalc: res.baseCalc,
          monthlyForecastCalcs: res.monthlyForecastCalcs,
          currentNewFilters: { ...prevState.currentNewFilters, compare_with: data },
          loading: false
        }
      })
    }
    setState((prevState) => {
      return {
        ...prevState,
        currentNewFilters: { ...prevState.currentNewFilters, compare_with: data },
        loading: false
      }
    })
  }

  const changeChat = (e, chat) => {
    if (chat.id) {
      let newChat = allChats.find((c) => c.id === chat.id)
      newChat.messages = newChat.messages.map(m => {
        m.typewriter = false
        return m
      })
      setChat(newChat)
    } else {
      setChat({})
    }
  }

  const handleOpenAiCall = (e) => {
    fetchOpenAi(e)
    subscribeOpenai()
  }

  const fetchOpenAi = async (e) => {
    setChatLoading(true)
    e.preventDefault()
    setChat({
      id: null,
      title: "Analyze my Data!",
      messages: [
        {
          role: 'user',
          message_type: 'question',
          display_content: "Analyze my Data!"
        }
      ]
    })

    try {
      const response = await fetch('/api/v1/openai', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': window.token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filters: state.filters
        }),
      })
      const res = await response.json()
      console.log(res, 'response')
      setJobs(prevState => {
        return {
          subscribe: true,
          id: res.id
        }
      })

      subscribeOpenai(res.id)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const subscribeOpenai = async (chat_id) => {
    // setJobs(prevState => {
    //   return {
    //     ...prevState,
    //     subscribe: true
    //   }
    // })
    console.log(chat_id, 'chat_id')
    App[`openai_${chat_id}`] = App.cable.subscriptions.create(
      {
        channel: 'JobsChannel',
        job_type: 'OpenaiWorker',
      },
      {
        received: (res) => {
          console.log(res, 'res1')
          if (res.error) {
            setJobs(prevState => {
              return {
                ...prevState,
                subscribe: false
              }
            })
            setChatLoading(false)
            setChatLoader(
              {
                progress: 0,
                message: 'Initializing analysis process...',
                error: res.error
              }
            )
            console.log(res, 'res.error');
          } else if (res.progress) {
            setChatLoader(
              {
                progress: res.progress,
                message: res.message,
                error: null
              }
            )
          } else {
            //&& res.data.chat_id == chat_id
            if (res.finish) {
              setChat(res.data)
              setChatLoading(false)
              setChatLoader(
                {
                  progress: 0,
                  message: 'Initializing analysis process...',
                  error: null
                }
              )
              console.log(res, 'res2')
              App[`openai_${chat_id}`].unsubscribe();
              setJobs(prevState => {
                return {
                  ...prevState,
                  subscribe: false
                }
              })
              setAllChats((prevChats) => {
                let newData = res.data
                const updatedChats = [newData, ...prevChats];
                // Remove duplicates based on `id`
                const uniqueChats = updatedChats.filter((chat, index, self) => index === self.findIndex((c) => c.id === chat.id));
                return uniqueChats;
              });
              // if (res.finish) {
              //   setChat(res.data)
              //   App[`openai_${jobs.id}`].unsubscribe();
              //   setJobs(prevState => {
              //     return {
              //       ...prevState,
              //       subscribe: false
              //     }
              //   })
              // }
            }
          }
        }
      }
    )
  }

  // Fetch Dashboard layouts based on report type, years vs monthly/ytd, displaying which charts on dashboard
  const fetchCalcs = async (args, { filters, currentNewFilters, report, ...prevState }) => {
    // save any checked metrics and dashboard changes
    if (reportMetrics.editMetrics) { handleEditMetrics() }
    let previousFilterOptions, previousNaicsCode, previousRegion, previousRevenueBand, previousYear;
    let survey = null;
    let surveyCharts = [];
    if (Object.keys(prevState.filterOptions).length > 0) {
      previousFilterOptions = prevState.filterOptions;
      previousNaicsCode = previousFilterOptions.code;
      previousRegion = previousFilterOptions.region;
      previousRevenueBand = previousFilterOptions.revenue_band;
      previousYear = previousFilterOptions.year;
    }

    try {
      const [calculations, dashBoard] = await Promise.all([
        fetch('/api/v1/calculations', {
          method: 'POST',
          headers: {
            'X-CSRF-Token': window.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            company_id: props.id,
            filters,
            currentNewFilters,
            report,
            ...args // over write current state with new args
          }),
        }),
        fetch(`/dashboard_view/info/?report_type=${report}`, {
          headers: {
            'X-CSRF-Token': window.token,
          }
        })
      ]);
      const calcsRes = await calculations.json();
      const dashBoardRes = await dashBoard.json();
      const { reportSpecific, filterOptions, ...newState } = calcsRes
      const finalFilterOptions = {};
      let currentDash = dashBoardRes['current_view'];
      if (filterOptions && filterOptions['number_of_pros']) { finalFilterOptions['number_of_pros'] = filterOptions['number_of_pros'] }
      let finalNaicsCode, finalRegion, finalRevenueBand, finalYear;
      let naicsCode = filterOptions.code;
      let region = filterOptions.region;
      let revenueBand = filterOptions.revenue_band;
      let year = filterOptions.year;

      if (Object.keys(prevState.filterOptions).length > 0) {
        let mergedNaicsCodes = [...previousNaicsCode, ...naicsCode]
        let previousCodeNumbers = previousNaicsCode.map((n) => n[0])
        let newCodeNumbers = naicsCode.map((n) => n[0])
        let allNaicsCodeNumbers = previousCodeNumbers.concat(newCodeNumbers.filter((n) => !previousCodeNumbers.includes(n)))
        let sortedNaicsCodes = allNaicsCodeNumbers.sort()
        finalNaicsCode = sortedNaicsCodes.map((n) => {
          let naicsCode = mergedNaicsCodes.find((mc) => mc[0] === n)
          return naicsCode
        })
        finalRegion = previousRegion.concat(region.filter((r) => !previousRegion.includes(r)))
        finalRevenueBand = previousRevenueBand.concat(revenueBand.filter((rb) => !previousRevenueBand.includes(rb)))
        finalYear = previousYear.concat(year.filter((y) => !previousYear.includes(y)))
        finalFilterOptions.code = finalNaicsCode
        finalFilterOptions.region = finalRegion;
        finalFilterOptions.revenue_band = finalRevenueBand;
        finalFilterOptions.year = finalYear;
      } else {
        finalFilterOptions.code = naicsCode;
        finalFilterOptions.region = region;
        finalFilterOptions.revenue_band = revenueBand;
        finalFilterOptions.year = year;
      }

      if (report === 'money') {
        let layout = [];
        let peerviewCharts = null;
        let peerviewChartsSelected = [];

        if (currentDash['selected_items'] && currentDash['selected_items']['peerview_charts'].length > 0) {
          let { selectedItems } = mergeItems('money', currentDash['selected_items']['peerview_charts'], []);
          peerviewCharts = selectedItems;
          peerviewCharts.forEach((chart) => {
            if (chart.checked) {
              peerviewChartsSelected.push(chart)
            }
          })

          let peerviewChartKeys = currentDash['selected_items']['peerview_charts'].map(p => p.key)
          let pnlKeys = currentDash['selected_items']['pnl'].map(p => p.key)
          let balKeys = currentDash['selected_items']['balance_sheet'].map(b => b.key)
          let cashKeys = currentDash['selected_items']['cash_flow'].map(c => c.key)
          let kfisKeys = currentDash['selected_items']['kfis'].map(k => k.key)
          let allSelectedKeys = [...peerviewChartKeys, ...pnlKeys, ...balKeys, ...cashKeys, ...kfisKeys]
          let currentDashLayout = currentDash['dashboard_layout'].filter(l => allSelectedKeys.includes(l.i))

          layout = currentDashLayout
        } else {
          peerviewCharts = moneyCharts['peerview_charts']
          peerviewCharts.forEach((chart) => {
            if (chart.checked) {
              let defaultLayout = defaultLayouts['money_peerview_charts'].find((l) => l.i === chart.key)
              if (defaultLayout) {
                layout.push(defaultLayout)
                peerviewChartsSelected.push(chart)
              }
            }
          })
        }

        let { pnlOnePageRows, balOnePageRows, cashFlowRows, kfisRows } = moneyTreesBuilder(currentDash['selected_items'], newState.tree)
        let selectedItems = currentDash['selected_items']['money_all_selected_items'].length > 0 ? currentDash['selected_items']['money_all_selected_items'] : peerviewChartsSelected;

        setReportMetrics(prevState => {
          return {
            ...prevState,
            id: currentDash.id || 0,
            layout: layout,
            peerview_charts: peerviewCharts,
            pnl: pnlOnePageRows,
            balance_sheet: balOnePageRows,
            cash_flow: cashFlowRows,
            kfis: kfisRows,
            allSelectedMetrics: selectedItems,
            allSheetMetrics: [...peerviewCharts, ...pnlOnePageRows, ...balOnePageRows, ...cashFlowRows, ...kfisRows],
            noChangesMade: true
          }
        })

        currentDash.selected_items = selectedItems
        currentDash.dashboard_layout = layout
        setDashboardViewModalState((prevState) => {
          return {
            ...prevState,
            current_view: currentDash,
            user_views: dashBoardRes.user_views,
            firms_views: dashBoardRes.firms_views
          }
        })

        setMoneyNotes(prevState => {
          return {
            userNotes: dashBoardRes['money_notes'] ? dashBoardRes['money_notes'] : prevState.userNotes
          }
        })

      } else if (report === 'people') {
        setEditDashboard([{ sectionName: 'Peerview Metrics', sheet: 'peerview_charts', showDropdown: false }, { sectionName: 'Financial Detail', sheet: 'financial_detail', showDropdown: false }, { sectionName: `KPI`, sheet: 'kpi', showDropdown: false }])
        let layout = [];
        let peerviewCharts = null;
        let peerviewChartsSelected = [];

        if (currentDash['selected_items'] && currentDash['selected_items']['peerview_charts'] && currentDash['selected_items']['peerview_charts'].length > 0) {
          let { selectedItems } = mergeItems('people', currentDash['selected_items']['peerview_charts'], []);
          peerviewCharts = selectedItems;
          peerviewCharts.forEach((chart) => {
            if (chart.checked) {
              peerviewChartsSelected.push(chart)
            }
          })
          layout = [...currentDash['dashboard_layout']]
        } else {
          peerviewCharts = peopleCharts['peerview_charts']
          peerviewCharts.forEach((chart) => {
            if (chart.checked) {
              let defaultLayout = defaultLayouts['people_peerview_charts'].find((l) => l.i === chart.key)
              if (defaultLayout) {
                layout.push(defaultLayout)
                peerviewChartsSelected.push(chart)
              }
            }
          })
        }

        let { financialDetailRows, kpiRows } = peopleTreesBuilder(currentDash['selected_items'], newState.tree)
        let selectedItems = currentDash['selected_items']['all_selected_items'] && currentDash['selected_items']['all_selected_items'].length > 0 ? currentDash['selected_items']['all_selected_items'] : peerviewChartsSelected;

        setReportMetrics(prevState => {
          return {
            ...prevState,
            id: currentDash.id || 0,
            layout: layout,
            peerview_charts: peerviewCharts,
            financial_detail: financialDetailRows,
            kpi: kpiRows,
            allSelectedMetrics: selectedItems,
            allSheetMetrics: [...peerviewCharts, ...financialDetailRows, ...kpiRows],
            noChangesMade: true
          }
        })

        currentDash.selected_items = selectedItems
        currentDash.dashboard_layout = layout

        setDashboardViewModalState((prevState) => {
          return {
            ...prevState,
            current_view: currentDash,
            user_views: dashBoardRes.user_views,
            firms_views: dashBoardRes.firms_views
          }
        })
      } else if (report === 'customers') {
        setEditDashboard([{ sectionName: 'Peerview Metrics', sheet: 'peerview_charts', showDropdown: false }, { sectionName: 'Financial Detail', sheet: 'financial_detail', showDropdown: false }, { sectionName: `KPI`, sheet: 'kpi', showDropdown: false }])
        let finalLayout = [];
        let peerviewCharts = null;
        let peerviewChartsSelected = [];
        if (currentDash['selected_items'] && currentDash['selected_items']['peerview_charts'] && currentDash['selected_items']['peerview_charts'].length > 0) {
          let { selectedItems } = mergeItems('customers', currentDash['selected_items']['peerview_charts'], []);
          peerviewCharts = selectedItems;
          peerviewCharts.forEach((chart) => {
            if (chart.checked) {
              peerviewChartsSelected.push(chart)
            }
          })
          finalLayout = [...currentDash['dashboard_layout']]
        } else {
          peerviewCharts = customerCharts['peerview_charts']
          peerviewCharts.forEach((chart) => {
            if (chart.checked) {
              let defaultLayout = defaultLayouts['customers_peerview_charts'].find((l) => l.i === chart.key)
              if (defaultLayout) {
                finalLayout.push(defaultLayout)
                peerviewChartsSelected.push(chart)
              }
            }
          })
        }

        let kpiRows = customersKPIsFormat('Years');

        let finalSelectedItems = []
        if (currentDash['selected_items'] && !currentDash['selected_items']['all_selected_items']) {
          let currentSelectedItems = currentDash['selected_items']
          let { layout, selectedItems } = mergeItems('customers', currentSelectedItems, dashBoardRes['current_view']['dashboard_layout'])
          finalLayout = layout
          finalSelectedItems = selectedItems
        } else {
          finalSelectedItems = currentDash['selected_items']['all_selected_items']
          finalLayout = dashBoardRes['current_view']['dashboard_layout']
        }
        let finalFinancialDetailRows = updateCustomersRows(finalSelectedItems, financialDetailRows)
        let finalKpiRows = updateCustomersRows(finalSelectedItems, kpiRows)
        finalLayout = addMetricRow(finalLayout)

        setReportMetrics(prevState => {
          return {
            ...prevState,
            id: currentDash.id || 0,
            layout: finalLayout,
            peerview_charts: peerviewCharts,
            financial_detail: finalFinancialDetailRows,
            kpi: finalKpiRows,
            allSelectedMetrics: finalSelectedItems,
            allSheetMetrics: [...peerviewCharts, ...finalFinancialDetailRows, ...finalKpiRows],
            noChangesMade: true
          }
        })

        currentDash.selected_items = finalSelectedItems
        currentDash.dashboard_layout = finalLayout
        setDashboardViewModalState((prevState) => {
          return {
            ...prevState,
            current_view: currentDash,
            user_views: dashBoardRes.user_views,
            firms_views: dashBoardRes.firms_views
          }
        })
      } else if (report === 'audit') {
        let { pnlOnePageRows, balOnePageRows, kfisRows } = auditTreesBuilder(newState.tree)

        setReportMetrics(prevState => {
          return {
            ...prevState,
            pnl: pnlOnePageRows,
            balance_sheet: balOnePageRows,
            kfis: kfisRows,
            allSelectedMetrics: [],
            allSheetMetrics: [...pnlOnePageRows, ...balOnePageRows],
            noChangesMade: true
          }
        })
      } else {
        setDashboard(() => {
          return {
            id: currentDash.id,
            selectedItems: currentDash.selected_items,
            layout: currentDash.dashboard_layout,
            noChangesMade: true
          }
        })

        setDashboardViewModalState((prevState) => {
          return {
            ...prevState,
            current_view: currentDash,
            user_views: dashBoardRes.user_views,
            firms_views: dashBoardRes.firms_views
          }
        })
      }

      setState(prevState => {
        return {
          ...prevState,
          ...newState,
          filterOptions: finalFilterOptions,
          reportSpecific,
          loading: false,
          pnlTree: pnlTree,
          balTree: balTree
        }
      });

      if (calcsRes.companyReportSurvey) {
        let chartData = []
        let sectionDatas = Object.values(calcsRes.companyReportSurvey['survey_format_with_answers'])
        sectionDatas.forEach(data => {
          if (data.hasOwnProperty('sectionData')) chartData = [...chartData, ...data['sectionData']]
        })
        surveyCharts = chartData

        setSurveyData(() => {
          return {
            sections: calcsRes.companyReportSurvey['survey_format_with_answers']['sections'],
            surveyLayoutData: calcsRes.companyReportSurvey['survey_format_with_answers'],
            surveyCharts: surveyCharts,
          }
        })
      }

    } catch (error) {
      console.log(error, 'error')
      setState(prevState => {
        return {
          ...prevState,
          loading: false
        }
      });
      toast.error('Sorry something went wrong. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  const fetchMonthlyCalcs = async (args, { filters, currentNewFilters, report, ...prevState }) => {
    // save any checked metrics and dashboard changes
    if (reportMetrics.editMetrics) { handleEditMetrics() }
    let previousFilterOptions, previousNaicsCode, previousRegion, previousRevenueBand, previousYear;
    if (prevState.filterOptions) {
      previousFilterOptions = prevState.filterOptions;
      previousNaicsCode = previousFilterOptions.code;
      previousRegion = previousFilterOptions.region;
      previousRevenueBand = previousFilterOptions.revenue_band;
      previousYear = previousFilterOptions.year;
    }

    try {
      const [calculations, dashBoard] = await Promise.all([
        fetch('/api/v1/monthly_calculations', {
          method: 'POST',
          headers: {
            'X-CSRF-Token': window.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            company_id: props.id,
            filters,
            currentNewFilters,
            report,
            ...args // over write current state with new args
          }),
        }),
        fetch(`/dashboard_view/info/?report_type=${report}`, {
          headers: {
            'X-CSRF-Token': window.token,
          }
        })
      ]);

      const calcsRes = await calculations.json();
      const dashBoardRes = await dashBoard.json();
      const { reportSpecific, filterOptions, ...newState } = calcsRes;
      let currentDash = dashBoardRes['current_view'];
      const finalFilterOptions = {};
      let finalNaicsCode, finalRegion, finalRevenueBand, finalYear;
      let naicsCode = filterOptions.code;
      let region = filterOptions.region;
      let revenueBand = filterOptions.revenue_band;
      let year = filterOptions.year;

      if (filterOptions['number_of_pros']) { finalFilterOptions['number_of_pros'] = filterOptions['number_of_pros'] }

      if (prevState.filterOptions) {
        let mergedNaicsCodes = [...previousNaicsCode, ...naicsCode]
        let previousCodeNumbers = previousNaicsCode.map((n) => n[0])
        let newCodeNumbers = naicsCode.map((n) => n[0])
        let allNaicsCodeNumbers = previousCodeNumbers.concat(newCodeNumbers.filter((n) => !previousCodeNumbers.includes(n)))
        let sortedNaicsCodes = allNaicsCodeNumbers.sort()
        finalNaicsCode = sortedNaicsCodes.map((n) => {
          let naicsCode = mergedNaicsCodes.find((mc) => mc[0] === n)
          return naicsCode
        })
        finalRegion = previousRegion.concat(region.filter((r) => !previousRegion.includes(r)))
        finalRevenueBand = previousRevenueBand.concat(revenueBand.filter((rb) => !previousRevenueBand.includes(rb)))
        finalYear = previousYear.concat(year.filter((y) => !previousYear.includes(y)))
        finalFilterOptions.code = finalNaicsCode
        finalFilterOptions.region = finalRegion;
        finalFilterOptions.revenue_band = finalRevenueBand;
        finalFilterOptions.year = finalYear;
      } else {
        finalFilterOptions.code = naicsCode;
        finalFilterOptions.region = region;
        finalFilterOptions.revenue_band = revenueBand;
        finalFilterOptions.year = year;
      }

      if (report === 'money') {
        let layout = [];
        let peerviewCharts = null;
        let peerviewChartsSelected = [];
        if (currentDash['selected_items']['peerview_charts'].length > 0) {
          let { selectedItems } = mergeItems('money', currentDash['selected_items']['peerview_charts'], []);
          peerviewCharts = selectedItems;
          peerviewCharts.forEach((chart) => {
            if (chart.checked) {
              peerviewChartsSelected.push(chart)
            }
          })

          let peerviewChartKeys = currentDash['selected_items']['peerview_charts'].map(p => p.key)
          let pnlKeys = currentDash['selected_items']['pnl'].map(p => p.key)
          let balKeys = currentDash['selected_items']['balance_sheet'].map(b => b.key)
          let cashKeys = currentDash['selected_items']['cash_flow'].map(c => c.key)
          let kfisKeys = currentDash['selected_items']['kfis'].map(k => k.key)
          let allSelectedKeys = [...peerviewChartKeys, ...pnlKeys, ...balKeys, ...cashKeys, ...kfisKeys]
          let currentDashLayout = currentDash['dashboard_layout'].filter(l => allSelectedKeys.includes(l.i))

          layout = currentDashLayout
        } else {
          peerviewCharts = moneyCharts['peerview_charts']
          peerviewCharts.forEach((chart) => {
            if (chart.checked) {
              let defaultLayout = defaultLayouts['money_peerview_charts'].find((l) => l.i === chart.key)
              if (defaultLayout) {
                layout.push(defaultLayout)
                peerviewChartsSelected.push(chart)
              }
            }
          })
        }
        let displayByView = args.currentNewFilters['report_period'] === 'Months' ? 'monthsView' : 'ytdView';
        let { pnlOnePageRows, balOnePageRows, cashFlowRows, kfisRows } = moneyTreesBuilder(currentDash['selected_items'], newState.tree, displayByView)
        let selectedItems = currentDash['selected_items']['money_all_selected_items'].length > 0 ? currentDash['selected_items']['money_all_selected_items'] : peerviewChartsSelected;

        setReportMetrics(prevState => {
          return {
            ...prevState,
            id: currentDash.id,
            layout: layout,
            peerview_charts: peerviewCharts,
            pnl: pnlOnePageRows,
            balance_sheet: balOnePageRows,
            cash_flow: cashFlowRows,
            kfis: kfisRows,
            allSelectedMetrics: selectedItems,
            allSheetMetrics: [...peerviewCharts, ...pnlOnePageRows, ...balOnePageRows, ...cashFlowRows, ...kfisRows],
            noChangesMade: true
          }
        })

        currentDash.selected_items = selectedItems
        currentDash.dashboard_layout = layout
        setDashboardViewModalState((prevState) => {
          return {
            ...prevState,
            current_view: currentDash,
            user_views: dashBoardRes.user_views,
            firms_views: dashBoardRes.firms_views
          }
        })

        setMoneyNotes(prevState => {
          return {
            userNotes: dashBoardRes['money_notes'] ? dashBoardRes['money_notes'] : prevState.userNotes
          }
        })
      } else if (report === 'people') {
        setEditDashboard([{ sectionName: 'Peerview Metrics', sheet: 'peerview_charts', showDropdown: false }, { sectionName: 'Financial Detail', sheet: 'financial_detail', showDropdown: false }, { sectionName: `KPI`, sheet: 'kpi', showDropdown: false }])
        let layout = [];
        let peerviewCharts = null;
        let peerviewChartsSelected = [];

        if (currentDash['selected_items'] && currentDash['selected_items']['peerview_charts'] && currentDash['selected_items']['peerview_charts'].length > 0) {
          let { selectedItems } = mergeItems('people', currentDash['selected_items']['peerview_charts'], []);
          peerviewCharts = selectedItems;
          peerviewCharts.forEach((chart) => {
            if (chart.checked) {
              peerviewChartsSelected.push(chart)
            }
          })
          layout = [...currentDash['dashboard_layout']]
        } else {
          peerviewCharts = peopleCharts['peerview_charts']
          peerviewCharts.forEach((chart) => {
            if (chart.checked) {
              let defaultLayout = defaultLayouts['people_peerview_charts'].find((l) => l.i === chart.key)
              if (defaultLayout) {
                layout.push(defaultLayout)
                peerviewChartsSelected.push(chart)
              }
            }
          })
        }

        let { financialDetailRows, kpiRows } = peopleTreesBuilder(currentDash['selected_items'], newState.tree)
        let selectedItems = currentDash['selected_items']['all_selected_items'] && currentDash['selected_items']['all_selected_items'].length > 0 ? currentDash['selected_items']['all_selected_items'] : peerviewChartsSelected;

        setReportMetrics(prevState => {
          return {
            ...prevState,
            id: currentDash.id || 0,
            layout: layout,
            peerview_charts: peerviewCharts,
            financial_detail: financialDetailRows,
            kpi: kpiRows,
            allSelectedMetrics: selectedItems,
            allSheetMetrics: [...peerviewCharts, ...financialDetailRows, ...kpiRows],
            noChangesMade: true
          }
        })

        currentDash.selected_items = selectedItems
        currentDash.dashboard_layout = layout

        setDashboardViewModalState((prevState) => {
          return {
            ...prevState,
            current_view: currentDash,
            user_views: dashBoardRes.user_views,
            firms_views: dashBoardRes.firms_views
          }
        })
      } else if (report === 'customers') {
        setEditDashboard([{ sectionName: 'Peerview Metrics', sheet: 'peerview_charts', showDropdown: false }, { sectionName: 'Financial Detail', sheet: 'financial_detail', showDropdown: false }, { sectionName: `KPI`, sheet: 'kpi', showDropdown: false }])
        let finalLayout = [];
        let peerviewCharts = null;
        let peerviewChartsSelected = [];
        if (currentDash['selected_items'] && currentDash['selected_items']['peerview_charts']) {
          let { selectedItems } = mergeItems('customers', currentDash['selected_items']['peerview_charts'], []);
          peerviewCharts = selectedItems;
          peerviewCharts.forEach((chart) => {
            if (chart.checked) {
              peerviewChartsSelected.push(chart)
            }
          })
          finalLayout = [...currentDash['dashboard_layout']]
        } else {
          peerviewCharts = customerCharts['peerview_charts']
          peerviewCharts.forEach((chart) => {
            if (chart.checked) {
              let defaultLayout = defaultLayouts['money_peerview_charts'].find((l) => l.i === chart.key)
              if (defaultLayout) {
                finalLayout.push(defaultLayout)
                peerviewChartsSelected.push(chart)
              }
            }
          })
        }

        let kpiRows = customersKPIsFormat(args.currentNewFilters['display_columns_by'], args.currentNewFilters['report_period']);

        let finalSelectedItems = []
        if (currentDash['selected_items'] && !currentDash['selected_items']['all_selected_items']) {
          let currentSelectedItems = currentDash['selected_items']
          let { layout, selectedItems } = mergeItems('customers', currentSelectedItems, dashBoardRes['current_view']['dashboard_layout'])
          finalLayout = layout
          finalSelectedItems = selectedItems
        } else {
          finalSelectedItems = currentDash['selected_items']['all_selected_items']
          finalLayout = dashBoardRes['current_view']['dashboard_layout']
        }
        let finalFinancialDetailRows = updateCustomersRows(finalSelectedItems, financialDetailRows)
        let finalKpiRows = updateCustomersRows(finalSelectedItems, kpiRows)
        finalLayout = addMetricRow(finalLayout)

        setReportMetrics(prevState => {
          return {
            ...prevState,
            id: currentDash.id || 0,
            layout: finalLayout,
            peerview_charts: peerviewCharts,
            financial_detail: finalFinancialDetailRows,
            kpi: finalKpiRows,
            allSelectedMetrics: finalSelectedItems,
            allSheetMetrics: [...peerviewCharts, ...finalFinancialDetailRows, ...finalKpiRows],
            noChangesMade: true
          }
        })

        currentDash.selected_items = finalSelectedItems
        currentDash.dashboard_layout = finalLayout
        setDashboardViewModalState((prevState) => {
          return {
            ...prevState,
            current_view: currentDash,
            user_views: dashBoardRes.user_views,
            firms_views: dashBoardRes.firms_views
          }
        })
      } else {
        setDashboard(() => {
          return {
            id: currentDash.id,
            selectedItems: currentDash.selected_items,
            layout: currentDash.dashboard_layout,
            noChangesMade: true
          }
        })

        setDashboardViewModalState((prevState) => {
          return {
            ...prevState,
            current_view: currentDash,
            user_views: dashBoardRes.user_views,
            firms_views: dashBoardRes.firms_views
          }
        })
      }

      setState(prevState => {
        return {
          ...prevState,
          ...newState,
          filterOptions: finalFilterOptions,
          reportSpecific,
          loading: false
        }
      })

    } catch (error) {
      console.log(error)
      setState(prevState => {
        return {
          ...prevState,
          loading: false
        }
      });
      toast.error('Sorry something went wrong. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }

  async function submitFilters(event, filters, currentNewFilters) {
    event.preventDefault();
    let allFilters = {
      filters: {
        code: filters.code,
        number_of_pros: filters.number_of_pros,
        region: filters.region,
        revenue_band: filters.revenue_band,
        year: filters.year,
        datatype: filters.datatype
      },
      currentNewFilters: currentNewFilters
    }
    updateState(allFilters);
  }

  async function updateState(args = {}) {
    setState(prevState => {
      if (args.currentNewFilters) {
        if (args.currentNewFilters['display_columns_by'] === 'Years' && args.currentNewFilters['report_period'] === 'Year to Date') {
          fetchMonthlyCalcs(args, prevState);
        } else if (args.currentNewFilters['display_columns_by'] === 'Years' && args.currentNewFilters['report_period'] !== 'Year to Date') {
          fetchCalcs(args, prevState);
        } else if (args.currentNewFilters['display_columns_by'] === 'Months') {
          fetchMonthlyCalcs(args, prevState)
        } else {
          fetchCalcs(args, prevState);
        }
      } else {
        fetchCalcs(args, prevState);
      }

      if (args.report) {
        return {
          ...prevState, report: args.report, loading: true
        }
      } else {
        return {
          ...prevState, loading: true
        }
      }
    });
  };

  // fetch calculations on load if not present in props
  useEffect(() => {
    if (state.fetch) {
      updateState();
      if (props.most_recent_fdu && props.most_recent_fdu.state === 'mappered') {
        toast(`New accounts need to be mapped for this client! Please map your recent file, ${props.most_recent_fdu.document}, in the Map Files page. Then click the Generate Reports button to update your client's reports.`)
      }
    };
  }, []);

  const saveChartStyleEdits = () => {
    setEditChartModel(prevState => {
      return {
        ...prevState,
        isOpen: false
      }
    })
    updateDashBoardView()
  }

  const handleEditChartModal = (action, chartDetails = {}, highChartOptions = {}) => {
    if (action === 'close') {
      // CHECK CUSTOMERS
      if (state.report === 'money' || state.report === 'people' || state.report === 'customers') {
        setReportMetrics((prevState) => {
          let oldLayout = _.cloneDeep(prevState.layout)
          // Save old styles to parent
          let newLayout = oldLayout.map(parentItem => {
            if (parentItem.i === editChartModel.chartDetails.parentKey || parentItem.i === editChartModel.chartDetails.i) {
              parentItem['metricRow']['styles'] = editChartModel.oldStyles
            }
            return parentItem
          })

          return {
            ...prevState,
            layout: newLayout,
            noChangesMade: false
          }
        })
      } else {
        setDashboard((prevState) => {
          let oldLayout = _.cloneDeep(prevState.layout)
          let newLayout = oldLayout.map(parentItem => {
            if (parentItem.i === editChartModel.chartDetails.i) {
              parentItem['styles'] = editChartModel.oldStyles
            }
            return parentItem
          })

          return {
            ...prevState,
            layout: newLayout,
            noChangesMade: false
          }
        })
      }

      setEditChartModel(prevState => {
        return {
          ...prevState,
          isOpen: false
        }
      })
    } else {
      setEditChartModel(prevState => {
        return {
          ...prevState,
          isOpen: true,
          chartDetails: chartDetails,
          highChartOptions: highChartOptions,
          oldStyles: chartDetails.styles,
          newStyles: chartDetails.styles
        }
      })
    }
  }

  const handleEditChartStyle = (seriesOneStyle, seriesTwoStyle) => {
    let newChartStyles;
    setEditChartModel(prevState => {
      newChartStyles = _.cloneDeep(editChartModel.newStyles);
      let newHighChartOptions = _.cloneDeep(editChartModel.highChartOptions)
      newChartStyles['seriesOne']['type'] = seriesOneStyle
      newChartStyles['seriesTwo']['type'] = seriesTwoStyle
      newHighChartOptions['series'][0]['type'] = seriesOneStyle
      if (newHighChartOptions['series'][1]) {
        newHighChartOptions['series'][1]['type'] = seriesTwoStyle
      }

      return {
        ...prevState,
        highChartOptions: newHighChartOptions,
        newStyles: newChartStyles
      }
    })

    if (state.report === 'money' || state.report === 'people' || state.report === 'customers') {
      setReportMetrics((prevState) => {
        let oldLayout = _.cloneDeep(prevState.layout)
        // Save styles to parent
        let newLayout = oldLayout.map(parentItem => {
          if (parentItem.i === editChartModel.chartDetails.parentKey || parentItem.i === editChartModel.chartDetails.i) {
            parentItem['metricRow']['styles'] = newChartStyles
          }
          return parentItem
        })

        return {
          ...prevState,
          layout: newLayout,
          noChangesMade: false
        }
      })
    } else {
      setDashboard((prevState) => {
        let oldLayout = _.cloneDeep(prevState.layout)
        let newLayout = oldLayout.map(parentItem => {
          if (parentItem.i === editChartModel.chartDetails.i) {
            parentItem['styles'] = newChartStyles
          }
          return parentItem
        })

        return {
          ...prevState,
          layout: newLayout,
          noChangesMade: false
        }
      })
    }
  }

  const buildEditChartModel = () => {
    let style1 = editChartModel.newStyles['seriesOne']['type']
    let style2 = editChartModel.newStyles['seriesTwo']['type']

    return (
      <div className='edit-chart-modal-container'>
        <HighchartsReact containerProps={{ style: { width: '65%', height: '65%' }, key: 'edit-chart-in-modal', id: 'edit-chart-in-modal' }} highcharts={Highcharts} options={editChartModel.highChartOptions} />
        <div className='edit-chart-actions-container'>
          <h3 className='edit-chart-title'>Edit Chart Style</h3>
          <div className='edit-chart-style-options'>
            <div className={style1 === 'line' & style2 === 'line' ? 'active' : ''} onClick={() => handleEditChartStyle('line', 'line')}><LinesChart report={report} /> Lines</div>
            <div className={style1 === 'column' & style2 === 'column' ? 'active' : ''} onClick={() => handleEditChartStyle('column', 'column')}><ColumnsChart report={report} /> Columns</div>
            <div className={style1 === 'line' & style2 === 'column' ? 'active' : ''} onClick={() => handleEditChartStyle('line', 'column')}><LineColChart report={report} /> Line / Column</div>
            <div className={style1 === 'column' & style2 === 'line' ? 'active' : ''} onClick={() => handleEditChartStyle('column', 'line')}><ColLineChart report={report} /> Column / Line</div>
          </div>
          <div className='edit-chart-modal-btns'>
            <button className='btn btn-primary' onClick={() => saveChartStyleEdits()}>Save Changes</button>
            <button className='btn btn-danger' onClick={() => handleEditChartModal('close')}>Cancel</button>
          </div>
        </div>
      </div>
    )
  }

  const handleMetricNotes = (parentKey, text) => {
    setReportMetrics((prevState) => {
      let oldLayout = _.cloneDeep(prevState.layout)
      // Save notes to parent
      let newLayout = oldLayout.map(parentItem => {
        if (parentItem['metricRow'] && parentItem['metricRow']['relatedCheckedKey'] === parentKey) {
          parentItem['metricRow']['notes'] = text
        }
        return parentItem
      })

      return {
        ...prevState,
        layout: newLayout,
        noChangesMade: false
      }
    })
  }

  const openFiltersModal = () => {
    setFiltersModalIsOpen(true)
  }

  const closeFiltersModal = () => {
    setFiltersModalIsOpen(false)
  }

  const { loading, report, pageTitle, calendarYearEnd, fiscalYearStartMonth, peerviewMetricsYears, calcs, previousYearCalcs, yoy_calcs, previous_yoy_calcs, calcs_3_years_back, baseCalc, monthlyForecastCalcs, tree, pnlTree, balTree, yearRange, n, docs_n, filters, filterOptions, currentNewFilters, newFilterOptions, insufficient_data, insufficient_peer_data, reportSpecific, isMonthlyAvailable, fiscalYearEnd, companyYTDDate, aggregateYTDDate, companyReportSurvey, surveyTaken, surveyResults } = state;

  return (
    <div className={`${report}-content report-content`} style={{ opacity: (loading ? 0.6 : 1) }} >
      <ToastContainer
        position='top-right'
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        theme='colored'
        pauseOnHover
      />
      <Modal
        className={{
          base: 'client-modal-content',
          afterOpen: 'client-modal-content_after-open',
          beforeClose: 'client-modal-content_before-close',
        }}
        overlayClassName={{
          base: 'overlay-base',
          afterOpen: 'overlay-base_after-open',
          beforeClose: 'overlay-base_before-close',
        }}
        isOpen={filtersModalIsOpen}
        onRequestClose={closeFiltersModal}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={0}
        contentLabel='Report Filters Modal'
      >
        <div className='money-dropdown-container'>
          <header className='company-modal-header'>
            <h1 className='client-company-name'>Report Filters</h1>
          </header>
          <div className='report-filters-container'>
            <div className='client-info-div'>
              {props.name ? <p>CLIENT ID: {props.name}</p> : null}
              {props.real_name ? <p>CLIENT NAME: {props.real_name}</p> : null}
              <p>FISCAL YEAR END: {props.fiscal_year_end}</p>
              <p>NAICS: {props.code}, {props.industry}</p>
            </div>
            <DropdownFilter
              clas={'money-dropdown-filters'}
              calendarYearEnd={calendarYearEnd}
              current={filters}
              currentNewFilters={currentNewFilters}
              getMonthlyForecastScenario={getMonthlyForecastScenario}
              isMonthlyAvailable={isMonthlyAvailable}
              monthlyCompareWithOptions={state.monthlyCompareWithOptions}
              naicsCode={props.code}
              newFilterOptions={newFilterOptions}
              options={filterOptions}
              youAnnualOptions={state.youAnnualOptions}
              youMonthlyOptions={state.youMonthlyOptions}
              peerAnnualOptions={state.peerAnnualOptions}
              peerMonthlyOptions={state.peerMonthlyOptions}
              onClick={submitFilters}
              report={report}
            />
          </div>
        </div>
      </Modal>
      <Modal
        className={{
          base: 'edit-chart-modal-content hide-on-print',
          afterOpen: 'edit-chart-modal-content_after-open hide-on-print',
          beforeClose: 'edit-chart-modal-content_before-close hide-on-print',
        }}
        overlayClassName={{
          base: 'edit-chart-overlay-base hide-on-print',
          afterOpen: 'edit-chart-overlay-base_after-open hide-on-print',
          beforeClose: 'edit-chart-overlay-base_before-close hide-on-print',
        }}
        ariaHideApp={false}
        closeTimeoutMS={0}
        contentLabel='Edit Dashboard Item Modal'
        isOpen={editChartModel.isOpen}
        onRequestClose={() => handleEditChartModal('close')}
        shouldCloseOnOverlayClick={false}
      >
        {buildEditChartModel()}
      </Modal>
      <DashboardViewModal
        dashboardViewModalState={dashboardViewModalState}
        report={report}
        handleDashboardViewModal={handleDashboardViewModal}
        createDashBoardView={createDashBoardView}
        deleteDashBoardView={deleteDashBoardView}
        updateDashBoardView={updateDashBoardView}
      />
      {calcs ?
        <Data.Provider value={{ peerviewMetricsYears, calcs, previousYearCalcs, yoy_calcs, previous_yoy_calcs, calcs_3_years_back, baseCalc, monthlyForecastCalcs }} >
          <Filters.Provider value={{ filters, filterOptions, currentNewFilters, newFilterOptions }} >
            {props.children({
              ...reportSpecific,
              loading: loading,
              companyYTDDate: companyYTDDate,
              aggregateYTDDate: aggregateYTDDate,
              fiscalYearEnd: fiscalYearEnd,
              clientName: props.client_name || '',
              calendarYearEnd: calendarYearEnd,
              fiscalYearStartMonth: fiscalYearStartMonth,
              n: n,
              docs_n: docs_n,
              name: props.name,
              id: props.id,
              insufficientData: insufficient_data,
              insufficientPeerData: insufficient_peer_data,
              page: report,
              pageTitle,
              year: filters.year,
              yearRange: yearRange,
              tree,
              pnlTree,
              balTree,
              reportMetrics,
              currentNewFilters: currentNewFilters,
              calcs: calcs,
              previousYearCalcs: previousYearCalcs,
              yoy_calcs: yoy_calcs,
              previous_yoy_calcs: previous_yoy_calcs,
              dashboard: dashboard,
              tourData: props.tour_data,
              companyReportSurvey: companyReportSurvey,
              surveyTaken: surveyTaken,
              surveyResults: surveyResults,
              surveyModalIsOpen,
              surveyData: surveyData,
              moneyNotes,
              editDashboard,
              dashboardViewModalState: dashboardViewModalState,
              handleSurveyModal,
              handleDashboardSelections,
              handleSelectAll,
              handleOnLayoutChange,
              handleToggleDraggable,
              handleSurveySubmit,
              handleEditChartModal,
              handleEditMetrics,
              handleSelectAndLayout,
              handleMoneyNotes,
              handleMetricNotes,
              handleEditDashBoard,
              handleDashboardViewModal,
              getDashBoardView,
              updateDashBoardView,
              openFiltersModal,
              chatLoader,
              allChats: allChats,
              chat: chat,
              chatLoading: chatLoading,
              changeChat,
              handleOpenAiCall,
            })}
          </Filters.Provider>
        </Data.Provider>
        : <div style={{ marginBottom: '400px' }} >
          <LoaderGraphic />
        </div>}
    </div>
  )
};

export default ReportContainer;