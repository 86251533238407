import React, { useState } from 'react';
import moment from 'moment';

const ChatHistory = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleHistory = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='chat-history-container'>
            <ul>
                <li key='new-chat' onClick={(e) => props.changeChat(e, { id: null })}>
                    <div>New Chat</div>
                </li>
                <li>
                    <div
                        className='chat-history-toggle-btn'
                        onClick={toggleHistory}
                    >
                        {isExpanded ? "Hide History" : "Show History"}
                    </div>
                </li>
                {isExpanded && props.allChats && props.allChats.map(c => {
                    return (<li key={`chat-${c.id}`} className={props.chat.id === c.id ? 'active' : null} onClick={(e) => props.changeChat(e, c)} >
                        {/* <div>{c.title}</div> */}
                        <div className='chat-date'>{moment(c.created_at).calendar()}</div>
                    </li>)
                })}
                {
                    isExpanded && props.allChats.length === 0 && (<li><div>No History</div></li>)
                }
            </ul>
        </div>
    )
}

export default ChatHistory;