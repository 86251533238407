import React from 'react';
import PRPie from './PRPie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { parseMoney } from '../../utils/index';
import Tooltip from '../../utils/Tooltip';

const PRAnalysis = (props) => {
  const tab = props.tab
  const dataAverage = props.average_of_all_submitted
  let naicsAveragesData = _.cloneDeep(props.naics_averages)
  let submittedClientsData = props.submitted_children
  let unsubmittedClientsData = props.unsubmitted_children
  let tabName = props.tab === 'naics-analysis' ? 'NAICS Analysis' : 'Client Analysis';

  const sortTooltip = (text) => {
    return (
      <Tooltip
        className={'tooltip-sort-pr'}
        description={'Click to Sort'}
        position={'top'}
        positionStyle={'static'}
      >
        {text}
      </Tooltip>
    )
  }

  const hrefBuilder = (subdomain, path) => {
    const domainSplit = document.location.host.split(".");
    domainSplit[0] = subdomain;
    return location.protocol + "//" + domainSplit.join(".") + path;
  }

  const switchDataBody = (el, type = '') => {
    if (tab === 'naics-analysis') {
      return (
        <React.Fragment key={Math.random(98)}>
          <td>{el.naics ? el.naics : 'N/A'}</td>
          <td>{el.count ? el.count : '0'}</td>
          <td>{el.submitted ? el.submitted : '0'}</td>
        </React.Fragment>
      )
    } else {
      let focusedClient = props.userFocusedClients.includes(el.company_id)
      return (
        <React.Fragment key={Math.random(98)}>
          <td className='focus-client-cell'>{focusedClient ? <FontAwesomeIcon icon={faCheck} /> : ''}</td>
          <td>{el.name ? el.name : 'No Name'}</td>
          <td>{el.real_name ? el.real_name : ''}</td>
          {el.report_status === 'available' ?
            <td>
              <a href={hrefBuilder(el.subdomain, "/reports/money")} target='_blank'><i className="fa fa-bar-chart" aria-hidden="true"></i></a>
            </td> :
            <td>
              <a href={'/unlock_reports/' + el.company_id} target='_blank'><i className="fa fa-lock" aria-hidden="true"></i></a>
            </td>}
          <td>{el.naics ? el.naics : 'N/A'}</td>
          <td>{el.partners ? el.partners : '-'}</td>
        </React.Fragment>
      )
    }
  }

  const buildBody = (type) => {
    if (type === 'clients-average') {
      return (
        <tr key={Math.random(89)}>
          <td>Average</td>
          <td><a className='hide-on-print' href={'/companies'}><i className="fa fa-bar-chart" aria-hidden="true"></i></a></td>
          <td>{props.selectedFilters.naics_string}</td>
          <td>{dataAverage.total_revenue ? parseMoney(dataAverage.total_revenue) : '-'}</td>
          <td>{dataAverage.total_revenue_growth_rate ? (dataAverage.total_revenue_growth_rate * 100).toFixed(2) + '%' : '-'}</td>
          <td>{dataAverage.total_costs_common_size_revenue ? (dataAverage.total_costs_common_size_revenue * 100).toFixed(2) + '%' : '-'}</td>
          <td>{dataAverage.total_operating_expenses_common_size_revenue ? (dataAverage.total_operating_expenses_common_size_revenue * 100).toFixed(2) + '%' : '-'}</td>
          <td>{dataAverage.operating_expenses_net_income_common_size_revenue ? (dataAverage.operating_expenses_net_income_common_size_revenue * 100).toFixed(2) + '%' : '-'}</td>
          <td>{dataAverage.total_assets ? parseMoney(dataAverage.total_assets) : '-'}</td>
          <td>{dataAverage.total_current_liabilities ? parseMoney(dataAverage.total_current_liabilities) : '-'}</td>
          <td>{dataAverage.net_worth ? parseMoney(dataAverage.net_worth) : '-'}</td>
          <td>{dataAverage.growth ? dataAverage.growth.toFixed(0) : '-'}</td>
          <td>{dataAverage.profitability ? dataAverage.profitability.toFixed(0) : '-'}</td>
          <td>{dataAverage.liquidity ? dataAverage.liquidity.toFixed(0) : '-'}</td>
          <td>{dataAverage.leverage ? dataAverage.leverage.toFixed(0) : '-'}</td>
          <td>{dataAverage.cash_flow ? dataAverage.cash_flow.toFixed(0) : '-'}</td>
        </tr>
      )
    }

    if (type == 'naics-analysis-data') {
      if (naicsAveragesData.length > 1) {
        let allNAICS = {
          naics: 'ALL',
          count: 0,
          submitted: 0
        }
        naicsAveragesData.forEach(naics => {
          allNAICS.count += naics.count
          allNAICS.submitted += naics.submitted
        });
        naicsAveragesData.push(allNAICS)
      }

      return naicsAveragesData.map((el, i) => {
        let rowStyle = {}
        if (el.naics === 'ALL') {
          rowStyle['border'] = '2px dashed #69b144'
          return (
            <tr key={Math.random(89)} style={rowStyle}>
              {switchDataBody(el, type)}
              <td>{dataAverage.total_revenue ? parseMoney(dataAverage.total_revenue) : '-'}</td>
              <td>{dataAverage.total_revenue_growth_rate ? (dataAverage.total_revenue_growth_rate * 100).toFixed(2) + '%' : '-'}</td>
              <td>{dataAverage.total_costs_common_size_revenue ? (dataAverage.total_costs_common_size_revenue * 100).toFixed(2) + '%' : '-'}</td>
              <td>{dataAverage.total_operating_expenses_common_size_revenue ? (dataAverage.total_operating_expenses_common_size_revenue * 100).toFixed(2) + '%' : '-'}</td>
              <td>{dataAverage.operating_expenses_net_income_common_size_revenue ? (dataAverage.operating_expenses_net_income_common_size_revenue * 100).toFixed(2) + '%' : '-'}</td>
              <td>{dataAverage.growth ? dataAverage.growth.toFixed(0) : '-'}</td>
              <td>{dataAverage.profitability ? dataAverage.profitability.toFixed(0) : '-'}</td>
              <td>{dataAverage.liquidity ? dataAverage.liquidity.toFixed(0) : '-'}</td>
              <td>{dataAverage.leverage ? dataAverage.leverage.toFixed(0) : '-'}</td>
              <td>{dataAverage.cash_flow ? dataAverage.cash_flow.toFixed(0) : '-'}</td>
            </tr>
          )
        } else {
          return (
            <tr key={Math.random(89)} style={rowStyle}>
              {switchDataBody(el, type)}
              <td>{_.isNumber(el.total_revenue) ? parseMoney(el.total_revenue) : '-'}</td>
              <td>{_.isNumber(el.total_revenue_growth_rate) ? (el.total_revenue_growth_rate * 100).toFixed(2) + '%' : '-'}</td>
              <td>{_.isNumber(el.total_costs_common_size_revenue) ? (el.total_costs_common_size_revenue * 100).toFixed(2) + '%' : '-'}</td>
              <td>{_.isNumber(el.total_operating_expenses_common_size_revenue) ? (el.total_operating_expenses_common_size_revenue * 100).toFixed(2) + '%' : '-'}</td>
              <td>{_.isNumber(el.operating_expenses_net_income_common_size_revenue) ? (el.operating_expenses_net_income_common_size_revenue * 100).toFixed(2) + '%' : '-'}</td>
              <td>{_.isNumber(el.growth) ? el.growth.toFixed(0) : '-'}</td>
              <td>{_.isNumber(el.profitability) ? el.profitability.toFixed(0) : '-'}</td>
              <td>{_.isNumber(el.liquidity) ? el.liquidity.toFixed(0) : '-'}</td>
              <td>{_.isNumber(el.leverage) ? el.leverage.toFixed(0) : '-'}</td>
              <td>{_.isNumber(el.cash_flow) ? el.cash_flow.toFixed(0) : '-'}</td>
            </tr>
          )
        }
      })
    }


    if (type === 'clients-submitted') {
      return submittedClientsData.map((el, i) => {
        return (
          <tr key={Math.random(89)} onClick={() => props.handleFocusedClients(type, el)}>
            {switchDataBody(el, type)}
            <td>{_.isNumber(el.total_revenue) ? parseMoney(el.total_revenue) : '-'}</td>
            <td>{_.isNumber(el.total_revenue_growth_rate) ? (el.total_revenue_growth_rate * 100).toFixed(2) + '%' : '-'}</td>
            <td>{_.isNumber(el.total_costs_common_size_revenue) ? (el.total_costs_common_size_revenue * 100).toFixed(2) + '%' : '-'}</td>
            <td>{_.isNumber(el.total_operating_expenses_common_size_revenue) ? (el.total_operating_expenses_common_size_revenue * 100).toFixed(2) + '%' : '-'}</td>
            <td>{_.isNumber(el.operating_expenses_net_income_common_size_revenue) ? (el.operating_expenses_net_income_common_size_revenue * 100).toFixed(2) + '%' : '-'}</td>
            <td>{_.isNumber(el.total_assets) ? parseMoney(el.total_assets) : '-'}</td>
            <td>{_.isNumber(el.total_current_liabilities) ? parseMoney(el.total_current_liabilities) : '-'}</td>
            <td>{_.isNumber(el.net_worth) ? parseMoney(el.net_worth) : '-'}</td>
            <td>{_.isNumber(el.total_impact) ? parseMoney(el.total_impact) : '-'}</td>
            <td>{_.isNumber(el.growth) ? el.growth.toFixed(0) : '-'}</td>
            <td>{_.isNumber(el.profitability) ? el.profitability.toFixed(0) : '-'}</td>
            <td>{_.isNumber(el.liquidity) ? el.liquidity.toFixed(0) : '-'}</td>
            <td>{_.isNumber(el.leverage) ? el.leverage.toFixed(0) : '-'}</td>
            <td>{_.isNumber(el.cash_flow) ? el.cash_flow.toFixed(0) : '-'}</td>
          </tr>
        )
      })
    }

    if (type === 'clients-unsubmitted') {
      return unsubmittedClientsData.map((el, i) => {
        return (
          <tr key={Math.random(89)} onClick={() => props.handleFocusedClients(type, el)}>
            {switchDataBody(el, 'clients-unsubmitted')}
          </tr>
        )
      })
    }
  }

  const sortClientTable = (clientType, sortBy) => {
    props.setTableOrder((prevState) => {
      if (clientType === 'clients-submitted') {
        return {
          ...prevState,
          submitted: {
            sort: sortBy,
            isDesc: !prevState.submitted.isDesc,
            pageCounter: prevState.submitted.pageCounter
          }
        }
      } else {
        return {
          ...prevState,
          unsubmitted: {
            sort: sortBy,
            isDesc: !prevState.unsubmitted.isDesc,
            pageCounter: prevState.unsubmitted.pageCounter
          }
        }
      }
    })
  }

  const switchHeaders = (type) => {
    if (type === 'naics-analysis-data') {
      return (
        <React.Fragment key={Math.random(98)}>
          <td>NAICS</td>
          <td>Total Count</td>
          <td style={{ borderRight: '1px solid #d7e4cf' }}>Submitted Count</td>
        </React.Fragment>
      )
    }

    if (type === 'clients-average') {
      return (
        <React.Fragment key={Math.random(98)}>
          <td>Average</td>
          <td>Report</td>
          <td style={{ borderRight: '1px solid #d7e4cf' }}>NAICS</td>
        </React.Fragment>
      )
    }

    if (type === 'clients-submitted' || type === 'clients-unsubmitted') {
      let clientType = type === 'clients-submitted' ? 'submitted' : 'unsubmitted'
      return (
        <React.Fragment key={Math.random(98)}>
          <td>Focus</td>
          <td className='sort-table-col' onClick={() => props.handleTableChanges('sort', clientType, 'company_name')}>
            {sortTooltip('Client ID')}
          </td>
          <td className='sort-table-col' onClick={() => props.handleTableChanges('sort', clientType, 'company_name')}>
            {sortTooltip('Client Name')}
          </td>
          <td>Report</td>
          <td className='sort-table-col' onClick={() => props.handleTableChanges('sort', clientType, 'naics_string')}>
            {sortTooltip('NAICS')}
          </td>
          <td className='sort-table-col' onClick={() => props.handleTableChanges('sort', clientType, 'partner')} style={type === 'clients-submitted' ? { borderRight: '1px solid #d7e4cf' } : null}>
            {sortTooltip('Partner')}
          </td>
        </React.Fragment>
      )
    }
  }

  const metricHeaders = (type) => {
    let displaySortTooltip = type === 'clients-submitted' || type === 'clients-unsubmitted';
    let clientType = type === 'clients-submitted' ? 'submitted' : 'unsubmitted'
    return (
      <React.Fragment>
        <td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'total_revenue') : null}>
          {displaySortTooltip ? sortTooltip('Revenue') : 'Revenue'}
        </td>
        <td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'total_revenue_growth_rate') : null}>
          {displaySortTooltip ? sortTooltip('Growth%') : 'Growth%'}
        </td>
        <td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'total_costs_common_size_revenue') : null}>
          {displaySortTooltip ? sortTooltip('COGS') : 'COGS'}
        </td>
        <td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'total_operating_expenses_common_size_revenue') : null}>
          {displaySortTooltip ? sortTooltip('Total OPEX') : 'Total OPEX'}
        </td>
        <td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'operating_expenses_net_income_common_size_revenue') : null} style={type === 'naics-analysis-data' ? { borderRight: '1px solid #d7e4cf' } : {}}>
          {displaySortTooltip ? sortTooltip('Net Income') : 'Net Income'}
        </td>
        {type !== 'naics-analysis-data'
          ? (<td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'total_assets') : null}>
            {displaySortTooltip ? sortTooltip('Total Assets') : 'Total Assets'}
          </td>)
          : null
        }
        {type !== 'naics-analysis-data'
          ? (<td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'total_current_liabilities') : null}>
            {displaySortTooltip ? sortTooltip('Total Liabilities') : 'Total Liabilities'}
          </td>)
          : null
        }
        {type !== 'naics-analysis-data'
          ? (<td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'net_worth') : null} style={{ borderRight: '1px solid #d7e4cf' }}>
            {displaySortTooltip ? sortTooltip('Net Worth') : 'Net Worth'}
          </td>)
          : null
        }
        {type === 'clients-submitted'
          ? (<td style={{ borderRight: '1px solid #d7e4cf' }}>
            Top 5 Opps
          </td>)
          : null
        }
        <td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'growth') : null}>
          {displaySortTooltip ? sortTooltip('Growth') : 'Growth'}
        </td>
        <td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'profitability') : null}>
          {displaySortTooltip ? sortTooltip('Profitability') : 'Profitability'}
        </td>
        <td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'liquidity') : null}>
          {displaySortTooltip ? sortTooltip('Liquidity') : 'Liquidity'}
        </td>
        <td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'leverage') : null}>
          {displaySortTooltip ? sortTooltip('Leverage') : 'Leverage'}
        </td>
        <td className={displaySortTooltip ? 'sort-table-col' : ''} onClick={displaySortTooltip ? () => props.handleTableChanges('sort', clientType, 'cash_flow') : null}>
          {displaySortTooltip ? sortTooltip('Cash Flow') : 'Cash Flow'}
        </td>
      </React.Fragment>
    )
  }

  const buildHeaders = (type) => {
    return (
      <tr className='practice-header'>
        {switchHeaders(type)}
        {type !== 'clients-unsubmitted' ? metricHeaders(type) : null}
      </tr>
    )
  }

  const pageArrowClicked = (sign, clientType) => {
    sign === 'next' ? props.handleTableChanges('changePage', clientType, props.tableState[clientType].pageCounter + 1) : props.handleTableChanges('changePage', clientType, props.tableState[clientType].pageCounter - 1)
  }

  const pageArrows = (clientType) => {
    let numberClientsOnPage, childCount, pageCounter, tableRange, endRange
    if (clientType === 'submitted') {
      childCount = props.child_count
      numberClientsOnPage = props.child_count < 25 ? props.child_count : 25
      pageCounter = props.tableState.submitted.pageCounter
    } else {
      childCount = props.unsubmitted_child_count
      numberClientsOnPage = props.unsubmitted_child_count < 25 ? props.unsubmitted_child_count : 25
      pageCounter = props.tableState.unsubmitted.pageCounter
    }
    if (pageCounter === 1) {
      tableRange = `${pageCounter} - ${numberClientsOnPage}`
    } else if (childCount > (pageCounter * numberClientsOnPage)) {
      tableRange = `${(numberClientsOnPage * (pageCounter - 1)) + 1} - ${pageCounter * numberClientsOnPage}`
    } else {
      endRange = childCount
      tableRange = `${(numberClientsOnPage * (pageCounter - 1)) + 1} - ${endRange}`
    }

    if (childCount > 0) {
      return (
        <div className='page-arrows'>
          {pageCounter > 1 && childCount > 25 ? <div onClick={() => pageArrowClicked('previous', clientType)}><i className='fa fa-chevron-left' style={{ cursor: 'pointer' }}></i></div> : <div></div>}
          <div>{tableRange}</div>
          <div>of {childCount}</div>
          {childCount > 25 && childCount !== endRange ? <div onClick={() => pageArrowClicked('next', clientType)}><i className='fa fa-chevron-right' style={{ cursor: 'pointer' }}></i></div> : <div></div>}
        </div>
      )
    } else return null
  }

  const buildTable = (type) => {
    let tableClass = type !== 'clients-unsubmitted' ? 'group-table-pr' : 'unsubmitted-table-pr';
    return (
      <table id={type} className={tableClass} >
        <thead className='group-header-pr'>
          {buildHeaders(type)}
        </thead>
        <tbody>
          {buildBody(type)}
        </tbody>
      </table>
    )
  }

  if (tab === 'naics-analysis') {
    return (
      <div className='sheet-wrapper report-card naics-analysis-container practice-report-pdf'>
        <PRPie
          pieTopLevelArr={props.pieTopLevelArr}
          child_count={props.child_count}
          drillup={props.drillup}
          drillup_link={props.drillup_link}
          drillup_text={props.drillup_text}
          setSelectedFilters={props.setSelectedFilters}
          selectedFilters={props.selectedFilters}
          handleSubmit={props.handleSubmit}
        />
        <div className="page-break" />
        <div className='naics-analysis-table-container'>
          <h1 className="pr-table-title hide-not-on-print">{tabName} - Practice Report</h1>
          {buildTable('naics-analysis-data')}
        </div>
      </div>
    )
  } else {
    return (
      <div className='sheet-wrapper report-card client-analysis-container practice-report-pdf'>
        <h1 className="pr-table-title hide-not-on-print">{tabName} - Practice Report</h1>
        {buildTable('clients-average')}
        <h2 className='practice-table-title-submit'>Submitted Clients: {props.child_count}</h2>
        <div className='page-arrows-top-pr-container'>{pageArrows('submitted')}</div>
        {buildTable('clients-submitted')}
        <div className='page-arrows-bottom-pr-container'>{pageArrows('submitted')}</div>
        <div className="page-break" />
        <h2 className='practice-table-title-unsubmit'>Unsubmitted Clients: {props.unsubmitted_child_count}</h2>
        <div className='page-arrows-top-pr-unsubmitted-container'>{pageArrows('unsubmitted')}</div>
        {buildTable('clients-unsubmitted')}
        <div className='page-arrows-bottom-pr-unsubmitted-container'>{pageArrows('unsubmitted')}</div>
      </div>
    )
  }
}

export default PRAnalysis;