import React, { useState, useEffect } from 'react';
import peerviewLogo from '../../../assets/images/favicon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ThumbsUp, ThumbsDown } from '../utils/SVGIcons';
import LoaderGraphic from '../utils/LoaderGraphic';
import Filler from '../progress_bar/Filler'
import ChatHistory from './ChatHistory';
import { ChatTypewriter, StaticAnalysisMessage, StaticMessage } from './ChatTypewriter';

const allChatQs = {
    start: true,
    options: [
        {
            question: "Analyze My Data!",
            call: "handleOpenAiCall",
            asked: false,
            limit: 1
        },
        // {
        //     question: "I want to ask my own question.",
        //     call: "",
        //     asked: false,
        //     limit: 5,
        //     options: [
        //         {
        //             question: "Ask my question",
        //             call: "custom",
        //             asked: false,
        //             limit: 5,
        //         }
        //     ]
        // }
    ]
}

const loadingMessages = [
    "Initializing analysis process...",
    "Retrieving company and financial records...",
    "Aggregating financial data for analysis...",
    "Generating system prompt for industry-specific insights...",
    "Analyzing financial data...",
    "Finalizing analysis and recommendations...",
    "Identifying top improvement opportunities...",
    "Preparing data for display..."
]

const ChatContainer = (props) => {
    const [currentQuestion, setCurrentQuestion] = useState(allChatQs)
    const [previousQuestions, setPreviousQuestions] = useState([])
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [messageInterval, setMessageInterval] = useState(null);
    const [progressBar, setProgressBar] = useState(13);

    // Handle message cycling
    useEffect(() => {
        if (props.chatLoading) {
            // Reset index and start cycling through messages
            setCurrentMessageIndex(0);
            setProgressBar(13)
            const interval = setInterval(() => {
                setProgressBar((prevState) => {
                    if (prevState < 80) {
                        return prevState + 13
                    }
                })
                setCurrentMessageIndex((prevIndex) => {
                    // Loop through the messages
                    if (prevIndex < loadingMessages.length - 1) {
                        return prevIndex + 1;
                    } else {
                        return prevIndex; // Stay on the last message
                    }
                });
            }, 3250);

            setMessageInterval(interval);

            return () => clearInterval(interval);
        } else {
            // Clear interval when chatLoading is false
            if (messageInterval) {
                clearInterval(messageInterval);
                setMessageInterval(null);
            }
        }
    }, [props.chatLoading]);

    // Handle skipping to the last message if progress > 80
    useEffect(() => {
        if (props.chatLoader.progress > 80 && props.chatLoading) {
            setProgressBar(90)
            setCurrentMessageIndex(loadingMessages.length - 1); // Skip to the last message
            if (messageInterval) {
                clearInterval(messageInterval); // Stop the interval
                setMessageInterval(null);
            }
        }
    }, [props.chatLoader.progress, props.chatLoading]);

    const handleQ = (e, q) => {
        e.preventDefault()
        switch (q.question) {
            case "Analyze My Data!":
                setPreviousQuestions([...previousQuestions, currentQuestion])
                setCurrentQuestion(allChatQs)
                props.handleOpenAiCall(e)
                break;
            case "I want to ask my own question.":
                setPreviousQuestions([...previousQuestions, currentQuestion])
                setCurrentQuestion(q)
                break;
            case "Go Back":
                const lastQuestion = previousQuestions.pop();
                setPreviousQuestions([...previousQuestions])
                setCurrentQuestion(lastQuestion)
                break;
            default:
                break;
        }
    }

    const renderQButtons = () => {
        if (currentQuestion.start) {
            return (
                <div className='start-qs-container'>
                    {props.chat.messages && props.chat.messages.length > 0 ? null : <h2 style={{ 'textAlign': 'center' }}>Let's Get Started!</h2>}
                    {props.chat.messages ? null : (<div className='start-qs-btn-container'>
                        {
                            props.chat.id
                                ? null
                                : currentQuestion.options.map((q) => {
                                    return <button className='q-btn' onClick={(e) => handleQ(e, q)}>{q.question}</button>
                                })
                        }
                    </div>)}
                </div>
            )
        } else if (!currentQuestion.start && currentQuestion.options) {
            return (<div>
                <button className='q-btn' onClick={(e) => handleQ(e, { question: "Go Back" })}>Go Back</button>
                <p>{currentQuestion.question}</p>
                {currentQuestion.options.map((q) => {
                    return <button className='q-btn' onClick={(e) => handleQ(e, q)}>{q.question}</button>
                })}
            </div>)
        } else {
            return (<div>
                <button className='q-btn' onClick={(e) => handleQ(e, "Go Back")}>Go Back</button>
                {/* <p>{currentQuestion.question}</p> */}
            </div>)
        }
    }

    return (
        <div className='hide-on-print chat-container'>
            <ChatHistory allChats={props.allChats} chat={props.chat} changeChat={props.changeChat} />
            <div className='main-chat-container'>
                <div>
                    {
                        props.chat.messages && props.chat.messages.map((message, index) => {
                            if (message.role == "user") {
                                return <StaticMessage key={`sm-${index}`} chat={props.chat} message={message} text={message.display_content} />
                            } else if (message.role == "assistant" && message.typewriter) {
                                return <ChatTypewriter key={`ct-${index}`} chat={props.chat} message={message} text={message.display_content} />
                            } else if (message.role == 'assistant' && !message.typewriter) {
                                return <StaticAnalysisMessage key={`sam-${index}`} chat={props.chat} message={message} />
                            }
                        })
                    }
                    <div style={{ 'display': 'flex', 'justifyContent': 'center' }}>
                        {props.chatLoading
                            ? (
                                <div className='chat-progress-bar-container'>
                                    <div className='progress-percentage'>
                                        <span>{loadingMessages[currentMessageIndex]}</span>
                                        <LoaderGraphic />
                                    </div>
                                    <div className='progress-bar'>
                                        <Filler fillerClass={'filler'} percentage={progressBar} />
                                    </div>
                                </div>
                            )
                            : null
                        }
                        {
                            props.chatLoader.error
                                ? (
                                    <div className='chat-progress-bar-container'>
                                        <div className='progress-percentage'>
                                            <span>{props.chatLoader.error}</span>
                                        </div>
                                    </div>
                                )
                                :
                                null
                        }
                    </div>
                </div>
                <div className='qs-container'>
                    {renderQButtons()}
                </div>
            </div>
        </div>
    )
}


const CopilotPDF = (props) => {
    let message = props.chat && props.chat.messages ? props.chat.messages.find(m => m.role == "assistant") : null
    let filtersUsedText = ""
    let content = message ? message.json_content : null
    let analysisPage1 = null
    let analysisPage2 = null

    if (props.chat && props.chat.filters) {
        filtersUsedText = `Filters Used: Industry - ${props.chat.filters.code}, Data Type - ${props.chat.filters.datatype}, Revenue Band - ${props.chat.filters.revenue_band}, Region - ${props.chat.filters.region}, Year - ${props.chat.filters.year}`
    }

    if (content) {
        analysisPage1 = content.analysis_metrics.slice(0, 12);
        analysisPage2 = content.analysis_metrics.length > 12 ? content.analysis_metrics.slice(12) : null
    }



    return (
        <>
            {
                props.chat && props.chat.messages
                    ? (<>
                        <div className='portrait-view'>
                            <div className='message-filters'>{filtersUsedText}</div>
                            <div className='messsage-text-and-logo'>
                                <img className='peerview-logo-message' src={peerviewLogo} alt="Peerview Data Logo" />
                                <div className='message-text'>
                                    <div className={`typewriter-entry title-class`}>{content && content.analysis_metrics_title}</div>
                                    <div className={`typewriter-entry analysis-style`}>{content && content.analysis_metrics_details}</div>
                                    {content && analysisPage1.map((data, index) => (
                                        <>
                                            <div key={`${index}-ametric`} className={`typewriter-entry metric-style`} style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                                                {data.metric}
                                                {data.status && (
                                                    <span className="status-icon">
                                                        {data.status === 'good' && <span className='thumbs-up'><ThumbsUp /></span>}
                                                        {data.status === 'bad' && <span className='thumbs-down'><ThumbsDown /></span>}
                                                        {data.status === 'same' && <FontAwesomeIcon icon={faCheck} />}
                                                    </span>
                                                )}
                                            </div>
                                            <div key={`${index}-analysis`} className={`typewriter-entry analysis-style`}>{data.analysis}</div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {
                            content && analysisPage2
                                ? <div className='portrait-view'>
                                    <div className='message-filters'>{filtersUsedText}</div>
                                    <div className='messsage-text-and-logo'>
                                        <img className='peerview-logo-message' src={peerviewLogo} alt="Peerview Data Logo" />
                                        <div className='message-text'>
                                            <div className={`typewriter-entry title-class`}>{content && content.analysis_metrics_title} (Continued)</div>
                                            {content && analysisPage2.map((data, index) => (
                                                <>
                                                    <div key={`${index}-ametric`} className={`typewriter-entry metric-style`} style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                                                        {data.metric}
                                                        {data.status && (
                                                            <span className="status-icon">
                                                                {data.status === 'good' && <span className='thumbs-up'><ThumbsUp /></span>}
                                                                {data.status === 'bad' && <span className='thumbs-down'><ThumbsDown /></span>}
                                                                {data.status === 'same' && <FontAwesomeIcon icon={faCheck} />}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div key={`${index}-analysis`} className={`typewriter-entry analysis-style`}>{data.analysis}</div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        <div className='portrait-view'>
                            <div className='messsage-text-and-logo'>
                                <img className='peerview-logo-message' src={peerviewLogo} alt="Peerview Data Logo" />
                                <div className='message-text'>
                                    <div className={`typewriter-entry title-class`}>{content && content.top_5_metrics_title}</div>
                                    <div className={`typewriter-entry top5-style`}>{content && content.top_5_metrics_details}</div>
                                    <div className={`typewriter-entry top5-total-impact-style`}>{content && content.top_5_metrics_total_impact}</div>
                                    {content && content.top_5_metrics.map((data, index) => (
                                        <>
                                            <div key={`${index}-tmetric`} className={`typewriter-entry metric-style`}>{index + 1}. {data.metric}</div>
                                            <div key={`${index}-op`} className={`typewriter-entry`}>Opportunity: {data.opportunity}</div>
                                            <div key={`${index}-recom`} className={`typewriter-entry recommendation-style`}>Recommendation: {data.recommendation}</div>
                                        </>
                                    ))}
                                    <div className={`typewriter-entry summary-style`}>{content && content.top_5_summary}</div>
                                </div>
                            </div>
                        </div>
                    </>)
                    : <div className='portrait-view'>Please Select an Analysis from Peerview Copilot (Beta) History</div>
            }
        </>
    );
}
export { ChatContainer, CopilotPDF };