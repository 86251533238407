import React, { useState, useContext, useRef, useMemo } from 'react';
import { Data } from './contexts';
import { Responsive, WidthProvider } from 'react-grid-layout';
import ChildDashboard from './ChildDashboard';
import { CommonSizeArrows, MonthlyPNLandBalValues, MonthlyPNLandBalCommonSized, YTDPnlandBalCommonSizeValue, AnnualCashFlowValues, MonthlyCashFlowValues, AnnualKFIsValues, YTDPnlandBalValues, YTDCashFlowValues, MonthlyKFIsValues } from './report_components/MoneyHelpers';
import { objReducer, calculateKFIMetric, calculateSeniorDebtToEBITDA, calculateEBITDAtoAnnualInterest, monthlyTotalRevenueGRCalc, monthlyPercentOfRevenueCalc, ytdPercentOfRevenueCalc, ytdTotalRevenueGRCalc } from './report_components/MoneyHelpers';
import { buildImpactBox, impactBoxStatusColor, noSpecificTextMaker } from './report_components/ImpactBoxTextHelper';
import { ratioCalculations, ratioCalculationsMoney, calculateEBITDA, netWorkingCapitalCalculation, netWorkingCapitalRatioCalculation, trailing12MonthCalculations } from './monthly/monthy_analysis_table_trailing12_calculations';
import { peopleCustomersT12Metrics, peersAnalysisPerCustomerVals } from './report_components/PeopleCustomersHelpers';
import { COPY } from './charts/chart_helpers';
import { moneyCharts, pageCharts } from './charts';
import { gaugeKeys, getIndustryLineChartOptions, getIndustryBarChartOptions, getMultiDataBarChartOptions, get3DBubbleChartOptions, getQuadrantOptions, getScatterPlotOptions, getGaugeOptions, buildDrilldownData, getPieOptions, getDonutOptions, format, axisFormat, getLineOrColOptions, getVerticalBarChartOptions, getHorizontalBarOptions, getStackedColumnOptions, getLineChartOptions } from './charts/DashBoardHighChartsHelper';
import getUSMapOptions from './charts/HighChartsUSmap';
import WageDataScaffold from './tables/WageDataScaffold';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts.js';
import highchartsMore from 'highcharts/highcharts-more.js';
import highchartsMap from 'highcharts/modules/map';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import HC_exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import drilldown from 'highcharts/modules/drilldown';
import { capitalize, parseMoney } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen, faLock, faComment, faCommentSlash, faPencil } from '@fortawesome/free-solid-svg-icons';
const ResponsiveGridLayout = WidthProvider(Responsive);
highchartsMore(Highcharts);
highchartsMap(Highcharts);
solidGauge(Highcharts);
HC_exporting(Highcharts);
offlineExporting(Highcharts);
drilldown(Highcharts);

const ParentDashboard = (props) => {
  const { peerviewMetricsYears, calcs, previousYearCalcs, yoy_calcs, previous_yoy_calcs, calcs_3_years_back } = useContext(Data);
  const [chartRefs, setChartRefs] = useState({})
  const [layout, setLayout] = useState(props.dashboardLayout || []);
  // Get an object of all the highcharts refs so that they reflow() or force resizing when user resizes them in the react-grid-layout
  const setNewRef = (ref) => {
    if (ref) {
      let itemId = ref.container.current.id;
      chartRefs[itemId] = ref;
      setChartRefs(prevState => {
        return {
          ...prevState,
          itemId: ref
        }
      })
    }
  };

  const titleSetter = (chart) => {
    if (props.displayColumnsBy === 'Months' && chart.key === 'total_revenue_growth_rate') {
      return 'Monthly Revenue % of Annual Total Revenue'
    } else if (chart.key.includes('labor_cogs')) {
      return `Labor COGS - ${chart.copy}`
    } else if (chart.parentKey === "total_costs") {
      return `COGS-${chart.copy}`
    } else {
      return (chart.title ? chart.title : COPY[chart.key])
    }
  }

  const buildIndustryLineData = (chart) => {
    let categories;
    const barColor = {
      industryMoney: '#69B144',
      industryPeople: '#00AEEF',
      industryCustomers: '#542667'
    }
    let key = chart.key;
    if (chart.key === 'profitability') {
      key = 'profit_margin'
    }
    let seriesData = ['avg'].map((category, i) => {
      categories = Object.keys(calcs[category][key]);
      return {
        name: capitalize(category),
        title: capitalize(category),
        data: Object.values(calcs[category][key]),
        showInLegend: false,
        type: 'line',
        color: barColor[props.page],
        _colorIndex: i,
        _symbolIndex: i,
      }
    })
    return { seriesData, categories }
  }

  const buildIndustryBarData = (chart) => {
    let categories;
    const barColor = {
      industryMoney: '#69B144',
      industryPeople: '#00AEEF',
      industryCustomers: '#542667'
    }
    let key = chart.key;
    let seriesData = ['avg'].map((category, i) => {
      categories = Object.keys(calcs[category][key]);
      let dataValues = [];
      if (key === 'senior_debt_to_ebitda') {
        dataValues = categories.map(year => {
          let value;
          let longTermDebt = calcs[category]['long_term_debt'][year];
          let lineOfCredit = calcs[category]['line_of_credit'][year];
          let currentPortionOfLTD = calcs[category]['current_portion_of_ltd'][year];
          let netIncome = calcs[category]['operating_expenses_net_income'][year];
          let interest = calcs[category]['interest_and_other_expenses'][year];
          let taxes = calcs[category]['total_other_costs_taxes_- federal & state'][year];
          let deprAmor = calcs[category]['depreciation_and_amortization'][year];
          let ebitda = netIncome + interest + taxes + deprAmor;
          return value = (longTermDebt + lineOfCredit + currentPortionOfLTD) / ebitda;
        })
      } else if (key === 'ebitda_to_annual_interest') {
        dataValues = categories.map(year => {
          let value;
          let netIncome = calcs[category]['operating_expenses_net_income'][year];
          let interest = calcs[category]['interest_and_other_expenses'][year];
          let taxes = calcs[category]['total_other_costs_taxes_- federal & state'][year];
          let deprAmor = calcs[category]['depreciation_and_amortization'][year];
          let ebitda = netIncome + interest + taxes + deprAmor;
          return value = ebitda / interest;
        })
      } else {
        dataValues = Object.values(calcs[category][key]);
      }
      return {
        name: capitalize(category),
        title: capitalize(category),
        data: dataValues,
        showInLegend: false,
        type: 'column',
        color: barColor[props.page],
        _colorIndex: i % 2,
        _symbolIndex: i,
      }
    })
    return { seriesData, categories }
  }

  const buildHorizonalBarSeries = (chart) => {
    if (props.page === 'industryCustomers') {
      let vals = chart.keys.map((key) => {
        let val = calcs['avg'][key][props.year];
        return val ? (Math.round(val * 10) / 10) : null;
      })

      return [{
        data: vals,
        showInLegend: false,
        name: 'avg',
        color: '#542667',
      }]

    } else {
      if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
        return ['you', 'avg'].map((g, i) => {
          let dataName = 'You';
          if (g === 'avg') { dataName = 'Peers' }

          let vals = chart.keys.map((key) => {
            let val = calcs[g][key][props.year];
            return val ? (Math.round(val * 10) / 10) : null;
          })

          return {
            data: vals,
            showInLegend: true,
            name: dataName,
            color: dataName === 'You' ? '#542667' : '#ae6dc9',
          }
        })
      }

      return ['you', 'avg'].map((g, i) => {
        let dataName = 'You';
        if (g === 'avg') { dataName = 'Peers' }

        return {
          data: [0, 0],
          showInLegend: true,
          name: dataName,
          color: dataName === 'You' ? '#542667' : '#ae6dc9',
        }
      })
    }
  }

  const buildLineSeries = (chart) => {
    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
      return ['you', 'avg'].map((g, i) => {
        let dataName = 'You';
        if (g === 'avg') { dataName = 'Peers' }

        let vals = chart.keys.map((key) => {
          // need latest year for cltv70, 80, 90
          let val = calcs[g][key][props.year];
          return val ? val : null;
        })

        return {
          data: vals,
          showInLegend: true,
          name: dataName,
          title: dataName,
          color: dataName === 'You' ? '#542667' : '#ae6dc9',
          _symbolIndex: i,
        }
      })
    }
    return ['you', 'avg'].map((g, i) => {
      let dataName = 'You';
      if (g === 'avg') { dataName = 'Peers' }

      return {
        data: [0, 0],
        showInLegend: true,
        name: dataName,
        color: dataName === 'You' ? '#542667' : '#ae6dc9',
      }
    })
  }

  const buildVerticalBarData = (chart, set) => {
    let value = null;
    if (props.displayColumnsBy !== 'Months' && props.reportPeriod !== 'Year to Date') {
      if (set === 'you' && calcs['you']) {
        value = calcs['you'][chart.key][props.year];
      }
      if (set === 'Peers' && calcs['avg']) {
        value = calcs['avg'][chart.key][props.year];
      }
      if (set === 'YOY' && previousYearCalcs['you']) {
        value = previousYearCalcs['you'][chart.key][props.year];
      }
      if (set === 'Practice' && calcs['practice_avg']) {
        value = calcs['practice_avg'][chart.key][props.year];
      }
      if (set === 'NSCHBC' && calcs['nschbc']) {
        value = calcs['nschbc'][chart.key][props.year];
      }

    } else {
      let monthNumber = 0;
      let metrics = set === 'you' ? props.youMetrics : props.compareMetrics;
      let useableMonths;
      if (props.displayColumnsBy === 'Months') {
        useableMonths = Object.values(calcs['you']['roa']);
      } else if (props.reportPeriod === 'Year to Date') {
        useableMonths = Object.values(calcs[props.year]['you']['roa']);
      }
      useableMonths.forEach(thing => thing !== null ? monthNumber += 1 : null);
      if (metrics[chart.key]) {
        value = Math.round(metrics[chart.key][monthNumber]);
      }
    }

    return value;
  }
  // People You and Peers/Compare Stacked Column Chart
  const buildPeopleExpenses = (chart) => {
    let returnSeries;
    let firstData = [];
    let secondData = [];
    let thirdData = [];
    let fourthData = [];
    let fifthData = [];
    let sixthData = [];
    let seventhData = [];
    let eigthData = [];
    let compensation = 'total_compensation';
    let taxes = 'total_taxes_and_benefits';
    let corporateCulture = 'total_corporate_culture';
    let subcontractor = 'subcontractor_expenses';
    let professionalFees = 'people_misc_professional_fees';
    let cogsPresent = true;
    let cogsCompensation = 'labor_cogs_compensation';
    let cogsTaxes = 'labor_cogs_taxes_and_benefits';
    let cogsSubcontractor = 'cogs_subcontractors';
    let groups = ['you']
    if (props.compareWith === 'Peers') {
      groups.push('avg')
    } else if (props.compareWith === 'Practice') {
      groups.push('practice_avg')
    } else if (props.compareWith === 'NSCHBC') {
      groups.push('nschbc')
    } else {
      groups.push('yoy')
    }

    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
      groups.forEach(g => {
        firstData.push(calcs[g][compensation + '_common_size_revenue'][props.year])
        secondData.push(calcs[g][taxes + '_common_size_revenue'][props.year])
        thirdData.push(calcs[g][corporateCulture + '_common_size_revenue'][props.year])
        fourthData.push(calcs[g][subcontractor + '_common_size_revenue'][props.year])
        fifthData.push(calcs[g][professionalFees + '_common_size_revenue'][props.year])

        if (calcs[g][cogsCompensation + '_common_size_revenue'][props.year]) {
          sixthData.push(calcs[g][cogsCompensation + '_common_size_revenue'][props.year])
          seventhData.push(calcs[g][cogsTaxes + '_common_size_revenue'][props.year])
          eigthData.push(calcs[g][cogsSubcontractor + '_common_size_revenue'][props.year])
        } else {
          cogsPresent = false
        }
      })
    }

    if (props.reportPeriod === 'Year to Date') {
      groups.forEach(g => {
        let totalRevenueValue = 0;
        if (calcs[props.year][g]['total_revenue']) {
          totalRevenueValue = Object.values(calcs[props.year][g]['total_revenue']).reduce((a, b) => a + b);
          let firstVal = calcs[props.year][g][compensation] ? Object.values(calcs[props.year][g][compensation]).reduce((a, b) => a + b) : 0;
          let secondVal = calcs[props.year][g][taxes] ? Object.values(calcs[props.year][g][taxes]).reduce((a, b) => a + b) : 0;
          let thirdVal = calcs[props.year][g][corporateCulture] ? Object.values(calcs[props.year][g][corporateCulture]).reduce((a, b) => a + b) : 0;
          let fourthVal = calcs[props.year][g][subcontractor] ? Object.values(calcs[props.year][g][subcontractor]).reduce((a, b) => a + b) : 0;
          let fifthVal = calcs[props.year][g][professionalFees] ? Object.values(calcs[props.year][g][professionalFees]).reduce((a, b) => a + b) : 0;
          firstData.push(firstVal / totalRevenueValue)
          secondData.push(secondVal / totalRevenueValue)
          thirdData.push(thirdVal / totalRevenueValue)
          fourthData.push(fourthVal / totalRevenueValue)
          fifthData.push(fifthVal / totalRevenueValue)

          if (calcs[props.year][g][cogsCompensation]) {
            let sixthVal = calcs[props.year][g][cogsCompensation] ? Object.values(calcs[props.year][g][cogsCompensation]).reduce((a, b) => a + b) : 0;
            let seventhVal = calcs[props.year][g][cogsTaxes] ? Object.values(calcs[props.year][g][cogsTaxes]).reduce((a, b) => a + b) : 0;
            let eighthVal = calcs[props.year][g][cogsSubcontractor] ? Object.values(calcs[props.year][g][cogsSubcontractor]).reduce((a, b) => a + b) : 0;
            sixthData.push(sixthVal / totalRevenueValue)
            seventhData.push(seventhVal / totalRevenueValue)
            eigthData.push(eighthVal / totalRevenueValue)
          } else {
            cogsPresent = false
          }
        }
      })
    }

    if (props.displayColumnsBy === 'Months') {
      groups.forEach(g => {
        let stackedCalcs = calcs[g]
        if (g === 'yoy') { stackedCalcs = previousYearCalcs['you'] }
        let totalRevenueValue = 0;
        if (stackedCalcs['total_revenue']) {
          totalRevenueValue = Object.values(stackedCalcs['total_revenue']).reduce((a, b) => a + b);
          let firstVal = stackedCalcs[compensation] ? Object.values(stackedCalcs[compensation]).reduce((a, b) => a + b) : 0;
          let secondVal = stackedCalcs[taxes] ? Object.values(stackedCalcs[taxes]).reduce((a, b) => a + b) : 0;
          let thirdVal = stackedCalcs[corporateCulture] ? Object.values(stackedCalcs[corporateCulture]).reduce((a, b) => a + b) : 0;
          let fourthVal = stackedCalcs[subcontractor] ? Object.values(stackedCalcs[subcontractor]).reduce((a, b) => a + b) : 0;
          let fifthVal = stackedCalcs[professionalFees] ? Object.values(stackedCalcs[professionalFees]).reduce((a, b) => a + b) : 0;
          firstData.push(firstVal / totalRevenueValue)
          secondData.push(secondVal / totalRevenueValue)
          thirdData.push(thirdVal / totalRevenueValue)
          fourthData.push(fourthVal / totalRevenueValue)
          fifthData.push(fifthVal / totalRevenueValue)

          if (stackedCalcs[cogsCompensation]) {
            let sixthVal = stackedCalcs[cogsCompensation] ? Object.values(stackedCalcs[cogsCompensation]).reduce((a, b) => a + b) : 0;
            let seventhVal = stackedCalcs[cogsTaxes] ? Object.values(stackedCalcs[cogsTaxes]).reduce((a, b) => a + b) : 0;
            let eighthVal = stackedCalcs[cogsSubcontractor] ? Object.values(stackedCalcs[cogsSubcontractor]).reduce((a, b) => a + b) : 0;
            sixthData.push(sixthVal / totalRevenueValue)
            seventhData.push(seventhVal / totalRevenueValue)
            eigthData.push(eighthVal / totalRevenueValue)
          } else {
            cogsPresent = false
          }
        }
      })
    }

    let cogsData = [
      {
        name: 'COGS - Compensation',
        color: '#ffda58',
        data: sixthData
      },
      {
        name: 'COGS - Taxes & Benefits',
        color: '#f14d4d',
        data: seventhData
      },
      {
        name: 'COGS - Subcontractor',
        color: '#BA68C8',
        data: eigthData
      },
    ]

    let peopleExpensesData = [
      {
        name: 'Compensation',
        color: '#ffb74d',
        data: firstData
      },
      {
        name: 'Taxes & Benefits',
        color: '#999',
        data: secondData
      },
      {
        name: 'Corporate Culture',
        color: '#542667',
        data: thirdData
      },
      {
        name: 'Subcontractor Expenses',
        color: '#00aeef',
        data: fourthData
      },
      {
        name: 'Professional Fees - HR',
        color: '#69b144',
        data: fifthData
      },
    ];

    return cogsPresent ? [...cogsData, ...peopleExpensesData] : peopleExpensesData
  }

  // Used by AP and AR Aging Summary Reports
  const buildAPARAgingSeries = (chart) => {
    let returnSeries;
    let firstData = [];
    let secondData = [];
    let thirdData = [];
    let fourthData = [];
    let currentData = [];
    let apOrAr = chart.key === 'ap_aging_summary' ? 'ap' : 'ar';
    let agingKey91Over = apOrAr + '_91_and_over';
    let agingKey6191 = apOrAr + '_61_90';
    let agingKey3160 = apOrAr + '_31_60';
    let agingKey130 = apOrAr + '_1_30';
    let agingKeyCurrent = apOrAr + '_current';

    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
      chart.years.forEach(year => {
        firstData.push(calcs['you'][agingKey91Over][year])
        secondData.push(calcs['you'][agingKey6191][year])
        thirdData.push(calcs['you'][agingKey3160][year])
        fourthData.push(calcs['you'][agingKey130][year])
        currentData.push(calcs['you'][agingKeyCurrent][year])
      })
    }

    if (props.reportPeriod === 'Year to Date') {
      chart.years.forEach(year => {
        let firstVals = calcs[year]['you'][agingKey91Over] ? Object.values(calcs[year]['you'][agingKey91Over]) : [];
        let secondVals = calcs[year]['you'][agingKey6191] ? Object.values(calcs[year]['you'][agingKey6191]) : [];
        let thirdVals = calcs[year]['you'][agingKey3160] ? Object.values(calcs[year]['you'][agingKey3160]) : [];
        let fourthVals = calcs[year]['you'][agingKey130] ? Object.values(calcs[year]['you'][agingKey130]) : [];
        let currentVals = calcs[year]['you'][agingKeyCurrent] ? Object.values(calcs[year]['you'][agingKeyCurrent]) : [];
        firstData.push(firstVals[firstVals.length - 1])
        secondData.push(secondVals[secondVals.length - 1])
        thirdData.push(thirdVals[thirdVals.length - 1])
        fourthData.push(fourthVals[fourthVals.length - 1])
        currentData.push(currentVals[currentVals.length - 1])
      })
    }

    if (props.displayColumnsBy === 'Months') {
      firstData = Object.values(calcs['you'][agingKey91Over])
      secondData = Object.values(calcs['you'][agingKey6191])
      thirdData = Object.values(calcs['you'][agingKey3160])
      fourthData = Object.values(calcs['you'][agingKey130])
      currentData = Object.values(calcs['you'][agingKeyCurrent])
    }

    return [
      {
        name: '91 and over',
        color: '#ffb74d',
        data: firstData
      },
      {
        name: '61-90',
        color: '#999',
        data: secondData
      },
      {
        name: '31-60',
        color: '#542667',
        data: thirdData
      },
      {
        name: '1-30',
        color: '#00aeef',
        data: fourthData
      },
      {
        name: 'Current',
        color: '#69b144',
        data: currentData
      }
    ];
  }

  const peoplePercentKeys = ['sum_compensation_growth_rate', 'sum_taxes_and_benefits_growth_rate', 'sum_subcontractors_growth_rate', 'turnover_rate']
  const peopleKpiT12 = ['total_revenue_per_employee', 'operating_expenses_net_income_per_employee', 'average_wages_and_salaries', 'average_benefits', 'cost_of_turnover', 'number_of_employees_who_left', 'sum_compensation_growth_rate', 'sum_compensation_per_employee', 'sum_health_insurance_per_employee', 'sum_payroll_per_employee', 'sum_retirement_per_employee', 'sum_subcontractors_growth_rate', 'sum_taxes_and_benefits_growth_rate', 'sum_taxes_and_benefits_per_employee', 'total_number_of_employees', 'training_per_employee', 'turnover_rate']
  const customerKPIKeys = ['revenue_per_customer', 'gross_profit_per_customer', 'operating_expenses_net_income_per_customer', 'total_customer_costs_common_size_revenue', 'number_of_new_customers_needed_to_maintain_growth']
  const customerPercentKeys = ['percent_of_sales_from_the_top_10_percent', 'percent_of_sales_from_the_top_25_percent', 'percent_of_sales_from_the_top_50_percent', 'customer_roi']
  const buildYouLineOrColData = (chart, sheet = '') => {
    const key = chart.key;
    let dataMap = [];
    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
      dataMap = chart.years.map(year => {
        let value;
        try {
          if (key === 'net_working_capital') {
            let totalCurrentAssets = calcs['you']['total_current_assets'][year];
            let totalCurrentLiabilities = calcs['you']['total_current_liabilities'][year];
            value = totalCurrentAssets - totalCurrentLiabilities;
          } else if (key === 'senior_debt_to_ebitda') {
            let longTermDebt = calcs['you']['long_term_debt'][year];
            let lineOfCredit = calcs['you']['line_of_credit'][year];
            let currentPortionOfLTD = calcs['you']['current_portion_of_ltd'][year];
            let netIncome = calcs['you']['operating_expenses_net_income'][year];
            let interest = calcs['you']['interest_and_other_expenses'][year];
            let taxes = calcs['you']['total_other_costs_taxes_- federal & state'][year];
            let deprAmor = calcs['you']['depreciation_and_amortization'][year];
            let ebitda = netIncome + interest + taxes + deprAmor;
            value = (longTermDebt + lineOfCredit + currentPortionOfLTD) / ebitda;
          } else if (key === 'ebitda_to_annual_interest') {
            let netIncome = calcs['you']['operating_expenses_net_income'][year];
            let interest = calcs['you']['interest_and_other_expenses'][year];
            let taxes = calcs['you']['total_other_costs_taxes_- federal & state'][year];
            let deprAmor = calcs['you']['depreciation_and_amortization'][year];
            let ebitda = netIncome + interest + taxes + deprAmor;
            value = ebitda / interest;
          } else if (customerKPIKeys.includes(key)) {
            value = calcs['you'][key][year]
          } else if (customerPercentKeys.includes(key) || peoplePercentKeys.includes(key)) {
            value = calcs['you'][key][year] / 100
          } else if (sheet === 'pnl' || sheet === 'financial_detail' || sheet === 'kpi') {
            value = calcs['you'][key + '_common_size_revenue'][year]
          } else if (sheet === 'balance_sheet') {
            value = calcs['you'][key + '_common_size_assets'][year]
          } else {
            value = calcs.you[key][year];
          }
          return value ? value : null;
        } catch (e) {
          return null;
        }
      })
    }

    if (props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date') {
      if (props.page === 'money' || props.page === 'people' || props.page === 'customers') {
        dataMap = chart.years.map(year => {
          try {
            if (chart.percentOfRevenueKey) {
              let value = ytdPercentOfRevenueCalc(chart.percentOfRevenueKey, key, calcs[year]['you']);
              return value;
            } else if (key === 'senior_debt_to_ebitda') {
              let value = calculateSeniorDebtToEBITDA(calcs[year]['you'])
              return value;
            } else if (key === 'ebitda_to_annual_interest') {
              let value = calculateEBITDAtoAnnualInterest(calcs[year]['you'])
              return value;
            } else if (key === 'total_revenue_growth_rate') {
              let totalRevenueGR = ytdTotalRevenueGRCalc(calcs[year]['you'], previousYearCalcs['monthly'][year - 1]['you'])
              return totalRevenueGR;
            } else if (peopleKpiT12.includes(key) || customerKPIKeys.includes(key) || key === 'customer_roi' || key === 'number_of_new_customers_needed_to_maintain_growth') {
              let lastMonthNumber = 0;
              let useableMonths = Object.values(calcs[year]['you']['roa']);
              useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);
              const calcsCurrentYearYTDYou = previousYearCalcs['monthly'][year]['you']
              const previousYearCalcsYTDYou = previousYearCalcs['monthly'][year - 1]['you']
              const previous2YearsBackYTDYou = previousYearCalcs['monthly'][year - 2]['you']
              let t12Obj = peopleCustomersT12Metrics(props.page, key, calcsCurrentYearYTDYou, previousYearCalcsYTDYou, previous2YearsBackYTDYou)
              return t12Obj[key][lastMonthNumber]
            } else if (sheet === 'pnl' || sheet === 'balance_sheet' || sheet === 'financial_detail' || sheet === 'kpi') {
              const yearVals = calcs[year] ? calcs[year]['you'][key] : undefined;
              let value = YTDPnlandBalValues(sheet, key, yearVals, calcs[year]['you'])
              let finalVal = YTDPnlandBalCommonSizeValue(value, sheet, calcs[year]['you'])
              return finalVal;
            } else {
              let value = calculateKFIMetric(key, calcs[year]['you'], previousYearCalcs['monthly'][year - 1]['you'], previousYearCalcs['monthly_full_year'][year - 1]['you'])
              return value;
            }
          } catch (e) {
            return null;
          }
        })
      } else {
        let selectedYearVal, previousYearVal = null;
        let monthNumber = 0;
        let useableMonths = Object.values(calcs[props.year]['you']['roa']);
        useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthNumber += 1 : null);

        if (key === 'total_revenue' || key === 'gross_profit' || key === 'operating_expenses_net_income') {
          selectedYearVal = objReducer(calcs[props.year]['you'][key])
          previousYearVal = objReducer(calcs[props.year - 1]['you'][key])
        } else {
          if (props.youMetrics[key]) {
            selectedYearVal = props.youMetrics[key][monthNumber];
          }
          if (props.youPreviousYearMetricsYTD[key]) {
            previousYearVal = props.youPreviousYearMetricsYTD[key][monthNumber];
          }
        }
        dataMap = [previousYearVal, selectedYearVal]
      }
    }

    if (props.displayColumnsBy === 'Months') {
      let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      if (props.page === 'money' || props.page === 'people' || props.page === 'customers') {
        if (chart.trailing12Key) {
          let trailing12Obj = trailing12MonthCalculations(chart.trailing12Key, calcs.you, previousYearCalcs.you);
          dataMap = Object.values(trailing12Obj);
        } else if (chart.percentOfRevenueKey) {
          let percentOfRevenueObj = monthlyPercentOfRevenueCalc(chart.percentOfRevenueKey, key, calcs.you);
          dataMap = Object.values(percentOfRevenueObj);
        } else if (key === 'current_ratio') {
          let currentRatioObj = ratioCalculations('total_current_assets', calcs.you)
          dataMap = Object.values(currentRatioObj);
        } else if (key === 'net_working_capital_ratio') {
          let netWorkingCapRatioObj = netWorkingCapitalRatioCalculation(calcs.you)
          dataMap = Object.values(netWorkingCapRatioObj);
        } else if (key === 'net_working_capital') {
          let netWorkingCapitalObj = netWorkingCapitalCalculation(calcs.you)
          dataMap = Object.values(netWorkingCapitalObj)
        } else if (key === 'total_revenue_growth_rate') {
          let totalRevenueGR = monthlyTotalRevenueGRCalc(calcs.you, previousYearCalcs.you)
          dataMap = Object.values(totalRevenueGR);
        } else if (peopleKpiT12.includes(key) || customerKPIKeys.includes(key) || key === 'customer_roi' || key === 'number_of_new_customers_needed_to_maintain_growth') {
          let t12Obj = peopleCustomersT12Metrics(props.page, key, calcs.you, previousYearCalcs.you, previous_yoy_calcs.you)
          dataMap = Object.values(t12Obj[key])
        } else if (sheet === 'pnl' || sheet === 'balance_sheet' || sheet === 'financial_detail' || sheet === 'kpi') {
          let monthsAvailable = 0;
          let useableMonths = Object.values(calcs['you']['cash']);
          useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthsAvailable += 1 : null);
          const you = calcs['you'] ? calcs['you'][key] : undefined;
          dataMap = MonthlyPNLandBalValues(monthsAvailable, sheet, key, you, calcs['you'])
        } else {
          let returnObj = {};
          let metricValues = _.pickBy(calcs.you[key], _.isNumber);
          for (let month in metricValues) {
            returnObj[month] = metricValues[month]
          }
          dataMap = Object.values(returnObj);
        }
      } else {
        let returnObj = {};
        let metricValues = _.pickBy(props.youMetrics[key], _.isNumber);
        for (let month in metricValues) {
          returnObj[month] = metricValues[month]
        }
        dataMap = Object.values(returnObj);
      }
    }
    return dataMap;
  }

  const buildCompareLineOrColData = (chart, sheet = '') => {
    const key = chart.key;
    let analysisPerClientMetrics = ['revenue_per_customer', 'gross_profit_per_customer', 'operating_expenses_net_income_per_customer'];
    let dataMap = [];
    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
      dataMap = chart.years.map(year => {
        let value;
        let compareAvg = {}

        if (props.compareWith === 'Peers') {
          compareAvg = calcs['avg']
        } else if (props.compareWith === 'Practice') {
          compareAvg = calcs['practice_avg']
        } else if (props.compareWith === 'NSCHBC') {
          compareAvg = calcs['nschbc']
        }

        try {
          if (key === 'net_working_capital') {
            let youTotalAssets = calcs['you']['total_assets'][year]
            let totalCurrentAssetsCommonSize = compareAvg['total_current_assets_common_size_assets'][year];
            let totalCurrentLiabilitiesCommonSize = compareAvg['total_current_liabilities_common_size_assets'][year];
            value = (totalCurrentAssetsCommonSize * youTotalAssets) - (totalCurrentLiabilitiesCommonSize * youTotalAssets);
          } else if (key === 'senior_debt_to_ebitda') {
            let longTermDebt = compareAvg['long_term_debt'][year];
            let lineOfCredit = compareAvg['line_of_credit'][year];
            let currentPortionOfLTD = compareAvg['current_portion_of_ltd'][year];
            let netIncome = compareAvg['operating_expenses_net_income'][year];
            let interest = compareAvg['interest_and_other_expenses'][year];
            let taxes = compareAvg['total_other_costs_taxes_- federal & state'][year];
            let deprAmor = compareAvg['depreciation_and_amortization'][year];
            let ebitda = netIncome + interest + taxes + deprAmor;
            value = (longTermDebt + lineOfCredit + currentPortionOfLTD) / ebitda;
          } else if (key === 'ebitda_to_annual_interest') {
            let netIncome = compareAvg['operating_expenses_net_income'][year];
            let interest = compareAvg['interest_and_other_expenses'][year];
            let taxes = compareAvg['total_other_costs_taxes_- federal & state'][year];
            let deprAmor = compareAvg['depreciation_and_amortization'][year];
            let ebitda = netIncome + interest + taxes + deprAmor;
            value = ebitda / interest;
          } else if (props.page === 'customers' && analysisPerClientMetrics.includes(key)) {
            value = peersAnalysisPerCustomerVals(key, year, compareAvg)
          } else if (customerKPIKeys.includes(key)) {
            value = compareAvg[key][year]
          } else if (customerPercentKeys.includes(key) || peoplePercentKeys.includes(key)) {
            value = compareAvg[key][year] / 100
          } else if (sheet === 'pnl' || sheet === 'financial_detail' || sheet === 'kpi') {
            value = compareAvg[key + '_common_size_revenue'] ? compareAvg[key + '_common_size_revenue'][year] : null;
          } else if (sheet === 'balance_sheet') {
            value = compareAvg[key + '_common_size_assets'] ? compareAvg[key + '_common_size_assets'][year] : null;
          } else if (key === 'cash') {
            let youTotalAssets = calcs['you']['total_assets'][year]
            value = compareAvg[key + '_common_size_assets'] ? youTotalAssets * compareAvg[key + '_common_size_assets'][year] : null;
          } else {
            value = compareAvg[key][year];
          }
          return value ? value : null;
        } catch (e) {
          return null;
        }
      })
    }

    if (props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date') {
      if (props.page === 'money' || props.page === 'people' || props.page === 'customers') {
        dataMap = chart.years.map(year => {
          try {
            if (chart.percentOfRevenueKey) {
              let value = ytdPercentOfRevenueCalc(chart.percentOfRevenueKey, key, calcs[year]['avg']);
              return value;
            } else if (key === 'senior_debt_to_ebitda') {
              let value = calculateSeniorDebtToEBITDA(calcs[year]['avg'])
              return value;
            } else if (key === 'ebitda_to_annual_interest') {
              let value = calculateEBITDAtoAnnualInterest(calcs[year]['avg'])
              return value;
            } else if (key === 'total_revenue_growth_rate') {
              let totalRevenueGR = ytdTotalRevenueGRCalc(calcs[year]['avg'], previousYearCalcs['monthly'][year - 1]['avg'])
              return totalRevenueGR;
            } else if (peopleKpiT12.includes(key) || customerKPIKeys.includes(key) || key === 'customer_roi' || key === 'number_of_new_customers_needed_to_maintain_growth') {
              const compareWithYTD = previousYearCalcs['monthly'][year]['avg']
              const compareWithPreviousYearCalcsYTD = previousYearCalcs['monthly'][year - 1]['avg']
              const compareWithPreviousYOYCalcsYTD = previousYearCalcs['monthly'][year - 2]['avg']
              let lastMonthNumber = 0;
              let useableMonths = Object.values(calcs[year]['you']['roa']);
              useableMonths.forEach(thing => thing !== null ? lastMonthNumber += 1 : null);
              let t12Obj = peopleCustomersT12Metrics(props.page, key, compareWithYTD, compareWithPreviousYearCalcsYTD, compareWithPreviousYOYCalcsYTD)
              return t12Obj[key][lastMonthNumber]
            } else if (sheet === 'pnl' || sheet === 'balance_sheet' || sheet === 'financial_detail' || sheet === 'kpi') {
              const yearVals = calcs[year] ? calcs[year]['avg'][key] : undefined;
              let value = YTDPnlandBalValues(sheet, key, yearVals, calcs[year]['avg'])
              let finalVal = YTDPnlandBalCommonSizeValue(value, sheet, calcs[year]['avg'])
              return finalVal
            } else {
              let value = calculateKFIMetric(key, calcs[year]['avg'], previousYearCalcs['monthly'][year - 1]['avg'], previousYearCalcs['monthly_full_year'][year - 1]['avg'])
              return value;
            }
          } catch (e) {
            return null;
          }
        })
      } else {
        let selectedYearVal, previousYearVal = null;
        let monthNumber = 0;
        let useableMonths = Object.values(calcs[props.year]['you']['roa']);
        useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthNumber += 1 : null);

        if (key === 'total_revenue' || key === 'gross_profit' || key === 'operating_expenses_net_income') {
          selectedYearVal = objReducer(calcs[props.year]['avg'][key])
          previousYearVal = objReducer(calcs[props.year - 1]['avg'][key])
        } else {
          if (props.compareMetrics[key]) {
            selectedYearVal = props.compareMetrics[key][monthNumber];
          }
          if (props.comparePreviousYearMetricsYTD[key]) {
            previousYearVal = props.comparePreviousYearMetricsYTD[key][monthNumber];
          }
        }

        dataMap = [previousYearVal, selectedYearVal]
      }
    }

    if (props.displayColumnsBy === 'Months') {
      let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

      if (props.page === 'money' || props.page === 'people' || props.page === 'customers') {
        if (chart.compareWith === 'YOY' || chart.compareWith === 'Default:YOY') {
          if (chart.trailing12Key) {
            let trailing12Obj = trailing12MonthCalculations(chart.trailing12Key, previousYearCalcs.you, previous_yoy_calcs.you)
            dataMap = Object.values(trailing12Obj);
          } else if (chart.percentOfRevenueKey) {
            let percentOfRevenueObj = monthlyPercentOfRevenueCalc(chart.percentOfRevenueKey, key, previousYearCalcs.you);
            dataMap = Object.values(percentOfRevenueObj);
          } else if (key === 'current_ratio') {
            let currentRatioObj = ratioCalculations('total_current_assets', previousYearCalcs.you)
            dataMap = Object.values(currentRatioObj);
          } else if (key === 'net_working_capital_ratio') {
            let netWorkingCapRatioObj = netWorkingCapitalRatioCalculation(previousYearCalcs.you)
            dataMap = Object.values(netWorkingCapRatioObj);
          } else if (key === 'net_working_capital') {
            let netWorkingCapitalObj = netWorkingCapitalCalculation(previousYearCalcs.you)
            dataMap = Object.values(netWorkingCapitalObj)
          } else if (key === 'total_revenue_growth_rate') {
            let totalRevenueGR = monthlyTotalRevenueGRCalc(previousYearCalcs.you, previous_yoy_calcs.you)
            dataMap = Object.values(totalRevenueGR);
          } else if (peopleKpiT12.includes(key) || customerKPIKeys.includes(key) || key === 'customer_roi' || key === 'number_of_new_customers_needed_to_maintain_growth') {
            let t12Obj = peopleCustomersT12Metrics(props.page, key, previousYearCalcs.you, previous_yoy_calcs.you, calcs_3_years_back.you)
            dataMap = Object.values(t12Obj[key])
          } else if (sheet === 'pnl' || sheet === 'balance_sheet' || sheet === 'financial_detail' || sheet === 'kpi') {
            const avg = calcs['avg'] ? calcs['avg'][key] : undefined;
            const yoy = yoy_calcs['you'] ? yoy_calcs['you'][key] : undefined;
            let monthsAvailable = 0;
            let useableMonths = Object.values(calcs['you']['cash']);
            useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthsAvailable += 1 : null);
            dataMap = MonthlyPNLandBalValues(monthsAvailable, sheet, key, yoy, yoy_calcs['you'])
          } else {
            let returnObj = {};
            let metricValues = _.pickBy(previousYearCalcs.you[key], _.isNumber);
            for (let month in metricValues) {
              returnObj[month] = metricValues[month]
            }
            dataMap = Object.values(returnObj);
          }
        }

        if (chart.compareWith === 'Peers' || chart.compareWith === 'Default:Peers') {
          if (chart.trailing12Key) {
            let trailing12Obj = trailing12MonthCalculations(chart.trailing12Key, calcs.avg, previousYearCalcs.avg)
            dataMap = Object.values(trailing12Obj);
          } else if (chart.percentOfRevenueKey) {
            let percentOfRevenueObj = monthlyPercentOfRevenueCalc(chart.percentOfRevenueKey, key, calcs.avg);
            dataMap = Object.values(percentOfRevenueObj);
          } else if (key === 'current_ratio') {
            let currentRatioObj = ratioCalculations('total_current_assets', calcs.avg)
            dataMap = Object.values(currentRatioObj);
          } else if (key === 'net_working_capital_ratio') {
            let netWorkingCapRatioObj = netWorkingCapitalRatioCalculation(calcs.avg)
            dataMap = Object.values(netWorkingCapRatioObj);
          } else if (key === 'net_working_capital') {
            let netWorkingCapitalObj = netWorkingCapitalCalculation(calcs.avg)
            dataMap = Object.values(netWorkingCapitalObj)
          } else if (key === 'total_revenue_growth_rate') {
            let totalRevenueGR = monthlyTotalRevenueGRCalc(calcs.avg, previousYearCalcs.avg)
            dataMap = Object.values(totalRevenueGR);
          } else if (peopleKpiT12.includes(key) || customerKPIKeys.includes(key) || key === 'customer_roi' || key === 'number_of_new_customers_needed_to_maintain_growth') {
            let t12Obj = peopleCustomersT12Metrics(props.page, key, calcs.avg, previousYearCalcs.avg, previous_yoy_calcs.you)
            dataMap = Object.values(t12Obj[key])
          } else if (sheet === 'pnl' || sheet === 'balance_sheet' || sheet === 'financial_detail' || sheet === 'kpi') {
            const avg = calcs['avg'] ? calcs['avg'][key] : undefined;
            const yoy = yoy_calcs['you'] ? yoy_calcs['you'][key] : undefined;
            let monthsAvailable = 0;
            let useableMonths = Object.values(calcs['you']['cash']);
            useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthsAvailable += 1 : null);
            dataMap = MonthlyPNLandBalValues(monthsAvailable, sheet, key, avg, calcs['avg'])
          } else {
            let returnObj = {};
            let metricValues = _.pickBy(calcs.avg[key], _.isNumber);
            for (let month in metricValues) {
              returnObj[month] = metricValues[month]
            }
            dataMap = Object.values(returnObj);
          }
        }

        if (chart.compareWith.includes('Forecast')) {
          if (chart.trailing12Key) {
            let trailing12Obj = trailing12MonthCalculations(chart.trailing12Key, props.forecastVals, previousYearCalcs.you)
            dataMap = Object.values(trailing12Obj);
          } else if (chart.percentOfRevenueKey) {
            let percentOfRevenueObj = monthlyPercentOfRevenueCalc(chart.percentOfRevenueKey, key, props.forecastVals);
            dataMap = Object.values(percentOfRevenueObj);
          } else if (key === 'current_ratio') {
            let currentRatioObj = ratioCalculations('total_current_assets', props.forecastVals)
            dataMap = Object.values(currentRatioObj);
          } else if (key === 'net_working_capital_ratio') {
            let netWorkingCapRatioObj = netWorkingCapitalRatioCalculation(props.forecastVals)
            dataMap = Object.values(netWorkingCapRatioObj);
          } else if (key === 'net_working_capital') {
            let netWorkingCapitalObj = netWorkingCapitalCalculation(props.forecastVals)
            dataMap = Object.values(netWorkingCapitalObj)
          } else if (key === 'total_revenue_growth_rate') {
            let totalRevenueGR = monthlyTotalRevenueGRCalc(props.forecastVals, previousYearCalcs.you)
            dataMap = Object.values(totalRevenueGR);
          } else if (sheet === 'pnl' || sheet === 'balance_sheet') {
            const forecast = props.forecastVals ? props.forecastVals[key] : undefined;
            let monthsAvailable = 0;
            let useableMonths = Object.values(calcs['you']['cash']);
            useableMonths.forEach(thing => thing !== null && !isNaN(thing) ? monthsAvailable += 1 : null);
            dataMap = MonthlyPNLandBalValues(monthsAvailable, sheet, key, forecast, props.forecastVals)
          } else {
            let returnObj = {};
            let metricValues = _.pickBy(props.forecastVals[key], _.isNumber);
            for (let month in metricValues) {
              returnObj[month] = metricValues[month]
            }
            dataMap = Object.values(returnObj);
          }
        }
      } else {
        let returnObj = {};
        let metricValues = _.pickBy(props.compareMetrics[key], _.isNumber);
        for (let month in metricValues) {
          returnObj[month] = metricValues[month]
        }
        dataMap = Object.values(returnObj);
      }
    }
    return dataMap
  }

  const pieExpenseTitles = {
    you: 'Your Expenses',
    avg: 'Peer Expenses',
    practice_avg: 'Practice Expenses',
    yoy: 'YOY Expenses',
    nschbc: 'NSCHBC Expenses'
  }
  let NaicsCode = window.data ? window.data.code : props.naicsCode;
  let pagePies, MoneyArrayBasedOnNAICS, CustomersArrayBasedOnNAICS;
  if (props.page === 'industryMoney' || props.page === 'money' || props.page === 'people') {
    // The Pie chart on Annual Money Report needs to display different things depending on what NAICS code the company is
    let NaicsCode3digits, NaicsCode4digits;
    NaicsCode !== undefined ? NaicsCode3digits = NaicsCode.slice(0, 3) : null
    NaicsCode !== undefined ? NaicsCode4digits = NaicsCode.slice(0, 4) : null

    if (NaicsCode3digits === '621') {
      MoneyArrayBasedOnNAICS = ['automobile_expenses', 'bad_debt_expenses', 'computer_related_costs', 'contributions_and_donations', 'insurance_expenses', 'office_communications', 'office_expenses', 'other_operating_expenses', 'total_professional_fees', 'rent_and_facilities_costs', 'laboratory_fees', 'medical_supplies']
      CustomersArrayBasedOnNAICS = ['total_customer_costs_sales_& marketing', 'total_customer_costs_billings_& collections', 'travel_entertainment_and_meals', 'customers_misc_professional_fees']
    } else if (NaicsCode4digits === '6212') {
      MoneyArrayBasedOnNAICS = ['automobile_expenses', 'bad_debt_expenses', 'computer_related_costs', 'contributions_and_donations', 'insurance_expenses', 'office_communications', 'office_expenses', 'other_operating_expenses', 'total_professional_fees', 'rent_and_facilities_costs', 'laboratory_fees', 'dental_supplies']
      CustomersArrayBasedOnNAICS = ['total_customer_costs_sales_& marketing', 'total_customer_costs_billings_& collections', 'travel_entertainment_and_meals', 'customers_misc_professional_fees']
    } else if (NaicsCode === '713910') {
      MoneyArrayBasedOnNAICS = ['automobile_expenses', 'bad_debt_expenses', 'computer_related_costs', 'contributions_and_donations', 'insurance_expenses', 'office_communications', 'office_expenses', 'total_operations_ops_- golf', 'total_operations_ops_- food & bev', 'total_operations_ops_- sporting activities', 'total_operations_ops_- clubhouse', 'other_operating_expenses', 'total_professional_fees', 'rent_and_facilities_costs', 'warranty_expense']
      CustomersArrayBasedOnNAICS = ['sales_and_marketing', 'travel_entertainment_and_meals', 'customers_misc_professional_fees']
    } else {
      MoneyArrayBasedOnNAICS = ['automobile_expenses', 'bad_debt_expenses', 'computer_related_costs', 'contributions_and_donations', 'insurance_expenses', 'office_communications', 'office_expenses', 'other_operating_expenses', 'total_professional_fees', 'rent_and_facilities_costs', 'warranty_expense']
      CustomersArrayBasedOnNAICS = ['sales_and_marketing', 'travel_entertainment_and_meals', 'customers_misc_professional_fees']
    }

    pagePies = {
      money: {
        keys: {
          'Money': MoneyArrayBasedOnNAICS,
          'People': ['total_compensation', 'total_taxes_and_benefits', 'total_corporate_culture', 'subcontractor_expenses', 'people_misc_professional_fees'],
          'Customers': CustomersArrayBasedOnNAICS,
          'COGS': ['labor_costs', 'cogs_subcontractors', 'materials_costs', 'overhead', 'transportation', 'other_costs'],
          'Net Income': ['operating_expenses_net_income'],
        },
        commonSize: 'revenue',
        subtitle: '(By % of Total Revenue)'
      },

      people: {
        keys: {
          'Compensation': [{ key: 'total_compensation', title: 'OpEx Compensation' }, 'labor_cogs_compensation'],
          'Taxes and Benefits': ['labor_cogs_taxes_and_benefits', 'payroll_taxes', 'health_insurance', 'retirement_plan', 'other_taxes_and_benefits'],
          'Corporate Culture': ['recruiting', 'training', { key: 'other_corporate_culture', title: 'Other' }],
          'Subcontractor Expenses': ['cogs_subcontractors', { key: 'subcontractor_expenses', title: 'OpEx Subcontractor Expenses' }],
          'Professional Fees': ['people_misc_professional_fees'],
        },
        commonSize: 'revenue',
        subtitle: '(By % of Total Revenue)'
      }
    }

  }

  const costOfTurnover = () => {
    const lostProductivity = .2;
    const lostProductivityTime = .125;
    const lostWagesTime = .125;
    const employeeProductivityDecline = .5;
    const employeeProductivityDeclineTime = .185;

    // Cost of Turnover Formula
    // (Your Turnover Rate(year) − Peer Turnover Rate(year)) 
    // * (Number of Employees(year) 
    // * ((Revenue Per Employee(year) * Lost Productivity * Lost Productivity Time) 
    //   + (Average Wages and Salaries(year) * Lost Wages Time ) 
    //   + ((.25 * Training(year))/Number of Employees(year))
    //   + (Revenue Per Employee(year) * Employee Productivity Decline * Employee Productivity Decline Time)))

    if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
      if (calcs.you.turnover_rate[props.year] !== 0 && calcs.you.total_number_of_employees[props.year] !== 0 && calcs.you.revenue_per_employee[props.year] !== 0 && calcs.you.average_wages_and_salaries[props.year] !== 0) {
        return ((calcs.you.turnover_rate[props.year] - calcs.avg.turnover_rate[props.year])
          * (calcs.you.total_number_of_employees[props.year]
            * ((calcs.you.revenue_per_employee[props.year] * lostProductivity * lostProductivityTime)
              + (calcs.you.average_wages_and_salaries[props.year] * lostWagesTime)
              + ((calcs.you.training[props.year] * .25) / calcs.you.total_number_of_employees[props.year])
              + (calcs.you.revenue_per_employee[props.year] * employeeProductivityDecline * employeeProductivityDeclineTime))));
      } else {
        return 0;
      };
    }

    return 0;
  }

  const buildRankingTable = (chartProps, rankingInfo) => {
    let top10 = rankingInfo[chartProps.metricKey].slice(0, 10);
    return (
      <div className='snapshot-table' key={chartProps.key}>
        <table>
          <thead>
            <tr>
              <th className='snapshot-title snap-cell' colSpan='100%'>{chartProps.title}</th>
            </tr>
            <tr className='snapshot-header-cols'>
              {
                (chartProps.headerCols.map((h) => {
                  return <th key={`${chartProps.title}-${h}`}><div className='snap-cell'><p>{h}</p></div></th>
                }))
              }
            </tr>
          </thead>
          <tbody>
            {
              (top10.map((s, i) => {
                let rowColor = i % 2 === 0 ? { backgroundColor: '#F5F5F5' } : {}
                return (
                  <tr key={`${chartProps.key}-row-${i}`} style={rowColor}>
                    {chartProps.keys.map((k) => {
                      if (Array.isArray(k)) {
                        let key = k[0]
                        return <td key={`snap-cell-${k}`}><div className='snap-cell'><p>{format(k[1], s[key])}</p></div></td>
                      } else {
                        return <td key={`snap-cell-${k}`}><div className='snap-cell'><p>{s[k]}</p></div></td>
                      }
                    })}
                  </tr>
                )
              }))
            }
          </tbody>
        </table>
      </div>
    )
  }

  const MyNotes = () => {
    const [myNotesSection, setMyNotesSection] = useState({ userNotes: '' || props.myNotes.userNotes })
    const [timer, setTimer] = useState(null)
    const [count, setCount] = useState(0);

    const handleNewNotes = (newNotes) => {
      setMyNotesSection(() => {
        return { userNotes: newNotes }
      })

      clearTimeout(timer)
      const newTimer = setTimeout(() => {
        props.handleMoneyNotes(newNotes);
      }, 3000)
      setTimer(newTimer)
    }

    return (
      <>
        <h3 style={{ 'margin': 0, 'textAlign': 'center' }}>Notes</h3>
        {props.pdf
          ? <div className='my-notes-dashboard'>{myNotesSection.userNotes}</div>
          : <textarea className='my-notes-dashboard' value={myNotesSection.userNotes} onChange={(e) => handleNewNotes(e.target.value)} />
        }
      </>
    )
  }

  const MetricNotes = ({ noteProps }) => {
    const [metricNotes, setMetricNotes] = useState('' || noteProps.metricRow.notes)
    const [timer, setTimer] = useState(null)
    const [count, setCount] = useState(0);

    const handleNewNotes = (newNotes) => {
      let notes = newNotes.length >= 1100 ? newNotes.substring(0, 1100) : newNotes;
      setMetricNotes(notes)

      clearTimeout(timer)
      const newTimer = setTimeout(() => {
        props.handleMetricNotes(noteProps.metricRow.relatedCheckedKey, newNotes)
      }, 3000)
      setTimer(newTimer)
    }

    let noteTitle = `${noteProps.metricRow.copy} Notes` || 'Notes'

    return (
      <>
        <h3 style={{ 'color': '#000000', 'fontSize': '18px', 'fontWeight': 'normal', 'margin': '5px', 'textAlign': 'center' }}>{noteTitle}</h3>
        {props.pdf
          ? <div className='my-notes-dashboard'>{metricNotes}</div>
          : <textarea className='my-notes-dashboard' value={metricNotes} onChange={(e) => handleNewNotes(e.target.value)} />
        }
      </>
    )
  }

  const buildFocusedClients = () => {
    const hrefBuilder = (subdomain, path) => {
      const domainSplit = document.location.host.split(".");
      domainSplit[0] = subdomain;
      return location.protocol + "//" + domainSplit.join(".") + path;
    }
    const buildBody = () => {
      if (props.clientData && props.clientData.length > 0) {
        return props.clientData.map((client, i) => {
          let rowColor = {}
          i % 2 === 0 ? rowColor = { backgroundColor: '#F5F5F5' } : {}
          return (
            <div key={Math.random(89)} className='practice-focused-clients-row'>
              <div className='focused-client-cell'><p>Client: {client.name ? client.name : ''}</p></div>
              {client.report_status === 'available'
                ? <div className='focused-client-cell'><p>Report: <a className='hide-on-print' href={hrefBuilder(client.subdomain, "/reports/money")} target='_blank'><i className="fa fa-bar-chart" aria-hidden="true"></i></a></p></div>
                : <div className='focused-client-cell'><p>Report: <a className='hide-on-print' href={'/unlock_reports/' + client.company_id} target='_blank'><i className="fa fa-lock" aria-hidden="true"></i></a></p></div>}
              <div className='focused-client-cell'><p>NAICS: {client.naics ? client.naics : ''}</p></div>
              <div className='focused-client-cell'><p>Partner: {client.partners}</p></div>
              <div className='focused-client-cell'><p>Status: {client.status}</p></div>
            </div>
          )
        })
      } else {
        return <p>Select clients you would like to focus on in <b>Focus column</b> over in the <b>Client Analysis</b> tab.</p>
      }
    }
    return (
      <div key='practice_focused_clients' className='practice-focused-clients-item'>
        <div><h3 className='practice-focused-clients-title'>Your Focused Clients: {props.clientData.length}</h3></div>
        <div className='practice-focused-clients-container'>
          {buildBody()}
        </div>
      </div>
    )
  }

  const getPrintPositionStyles = (item) => {
    const printStyles = { position: 'absolute', backgroundColor: 'white', border: '1px solid black', borderRadius: '2px' }
    let width = item.w > 1 ? (item.w * 34) + (item.w - 1) : item.w * 34
    let setHeightNum = item.h > 6 ? 6 : item.h
    let height = setHeightNum > 1 ? (setHeightNum * 34) + (setHeightNum - 1) : setHeightNum * 34
    let posX = (item.x * 34) + ((item.x + 1) * 1)
    let posY = (item.y * 34) + ((item.y + 1) * 1)

    printStyles.width = `${width}mm`
    printStyles.height = `${height}mm`
    printStyles.left = `${posX}mm`
    printStyles.top = `${posY}mm`
    return printStyles
  }

  // react-grid-layout recommends memoizing items in layout to optimize rendering
  let layoutItems = useMemo(() => {
    return props.dashboardLayout.map((l) => {
      let positionStyles = props.pdf ? getPrintPositionStyles(l) : {}
      if (props.page === 'money' && l.i === 'my_notes') {
        return (<div key='my_notes' style={positionStyles}>
          {props.pdf ? null : <button className='lock-btn' onClick={() => props.handleToggleDraggable('my_notes', 'moneyDashboard')}>{l.isDraggable ? <FontAwesomeIcon icon={faLockOpen} /> : <FontAwesomeIcon icon={faLock} />}</button>}
          <MyNotes />
        </div>)
      } else if ((props.page === 'money' && l.i.includes('_bar_chart')) || (props.page === 'people' && l.i.includes('chart')) || (props.page === 'customers' && l.i.includes('chart'))) {
        let parentMetric = l['metricRow'];
        let chartKey = l.key
        let styles = l['metricRow'] ? l['metricRow']['styles'] : l['styles'] ? l['styles'] : {};
        let youData, compareData, chartOptions;
        let chartProps = {
          i: l.i,
          chartType: 'editableChart',
          aggregateYTDDate: props.aggregateYTDDate || null,
          companyYTDDate: props.companyYTDDate || null,
          format: parentMetric.sheet !== 'kfis' && parentMetric.sheet !== 'financial_detail' && parentMetric.sheet !== 'kpi_people' && parentMetric.sheet !== 'kpi' && props.displayColumnsBy === 'Months' ? 'money' : parentMetric.format,
          compareWith: props.compareWith || null,
          compareWithTitle: props.monthlyCompareWithTitle || null,
          displayColumnsBy: props.displayColumnsBy || null,
          fiscalYearEnd: props.fiscalYearEnd || '12/31',
          key: parentMetric.key,
          months: props.monthLabels || null,
          page: props.page,
          pdf: props.pdf,
          reportPeriod: props.reportPeriod,
          styles: styles || { seriesOne: { type: 'column' }, seriesTwo: { type: 'column' } },
          title: titleSetter(parentMetric),
          years: props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date' ? [props.year - 1, props.year] : props.yearRange,
        }

        if (parentMetric.sheet === 'pnl' || parentMetric.sheet === 'balance_sheet' || parentMetric.sheet === 'financial_detail' || parentMetric.sheet === 'kpi_people' || parentMetric.sheet === 'kpi') {
          youData = buildYouLineOrColData(chartProps, parentMetric.sheet);
          compareData = props.compareWith !== 'None' && props.compareWith !== 'Default:None' ? buildCompareLineOrColData(chartProps, parentMetric.sheet) : null;
        }

        if (parentMetric.sheet === 'cash_flow') {
          if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
            let cashValues = AnnualCashFlowValues(calcs, props.calcsAvgType, props.yearRange, parentMetric.key)
            youData = []
            compareData = []
            props.yearRange.forEach(year => {
              if (cashValues['you' + year]) { youData.push(cashValues['you' + year]) }
              if (props.compareWith !== 'None' && cashValues['avg' + year] && props.compareWith !== 'Default:None') { compareData.push(cashValues['avg' + year]) }
            })
          }

          if (props.displayColumnsBy === 'Months') {
            youData = []
            compareData = []
            const calcsYou = calcs.you;
            const previousYearCalcsYou = previousYearCalcs.you;
            let compareVals = null;
            let prevYearCompareVals = null;
            if (props.compareWith === 'Peers' || props.compareWith === 'Default:Peers') {
              compareVals = calcs.avg;
              prevYearCompareVals = previousYearCalcs.avg;
            }
            if (props.compareWith === 'YOY' || props.compareWith === 'Default:YOY') {
              compareVals = previous_yoy_calcs.you;
              prevYearCompareVals = yoy_calcs.you;
            }
            if (props.compareWith.includes('Forecast')) {
              compareVals = props.forecastVals
              prevYearCompareVals = previousYearCalcsYou;
            }
            let { youValues, compareValues } = MonthlyCashFlowValues(parentMetric.key, calcsYou, previousYearCalcsYou, compareVals, prevYearCompareVals)
            Object.values(youValues).forEach(yv => {
              youData.push(yv)
            })
            if (props.compareWith !== 'None' && props.compareWith !== 'Default:None') {
              Object.values(compareValues).forEach(cv => {
                compareData.push(cv)
              })
            }
          }

          if (props.reportPeriod === 'Year to Date') {
            function commonSizeValue(props) {
              let finalValue = null;
              if (Object.values(props.passedCalcs).length > 0) {
                const val = props.value;
                const totalRevenue = Object.values(props.passedCalcs['total_revenue']).reduce((a, b) => a + b, 0)
                finalValue = (val / totalRevenue)
              }
              return finalValue
            }
            const calcYearKeys = Object.keys(calcs);
            const previousYearCalcKeys = Object.keys(previousYearCalcs['monthly_full_year']);
            let oldYear, newYear;
            let previousOldFullYear = previousYearCalcKeys[0];
            let previousNewFullYear = previousYearCalcKeys[1];
            if (Number(calcYearKeys[1]) > Number(calcYearKeys[0])) {
              newYear = calcYearKeys[1];
              oldYear = calcYearKeys[0];
            } else {
              newYear = calcYearKeys[0];
              oldYear = calcYearKeys[1];
            }
            const calcsYou = calcs[newYear].you;
            const calcsPeers = props.compareWith === 'Peers' ? calcs[newYear].avg : null;

            const calcsYouOldYear = calcs[oldYear].you;
            const calcsPeersOldYear = props.compareWith === 'Peers' ? calcs[oldYear].avg : null;

            const previousYearCalcsYou = previousYearCalcs['monthly_full_year'][previousNewFullYear].you;
            const previousYearCalcsPeers = props.compareWith === 'Peers' ? previousYearCalcs['monthly_full_year'][previousNewFullYear].avg : null;

            const previousYearCalcsYouOldYear = previousYearCalcs['monthly_full_year'][previousOldFullYear].you;
            const previousYearCalcsPeersOldYear = props.compareWith === 'Peers' ? previousYearCalcs['monthly_full_year'][previousOldFullYear].avg : null;

            let newerYearVals = YTDCashFlowValues(parentMetric.key, calcsYou, previousYearCalcsYou, calcsPeers, previousYearCalcsPeers);
            let newerYearYouVal = newerYearVals['youValue'];
            let newerYearCompareVal = newerYearVals['compareValue'];
            let olderYearVals = YTDCashFlowValues(parentMetric.key, calcsYouOldYear, previousYearCalcsYouOldYear, calcsPeersOldYear, previousYearCalcsPeersOldYear);
            let olderYearYouVal = olderYearVals['youValue'];
            let olderYearCompareVal = olderYearVals['compareValue'];

            let valuePYMonthlyCommonSize = commonSizeValue({ value: olderYearYouVal, passedCalcs: calcsYouOldYear })
            let valuePYPeersMonthlyCommonSize = props.compareWith === 'Peers' ? commonSizeValue({ value: olderYearCompareVal, passedCalcs: calcsPeersOldYear }) : null;
            let valueCYMonthlyCommonSize = commonSizeValue({ value: newerYearYouVal, passedCalcs: calcsYou })
            let valueCYPeersMonthlyCommonSize = props.compareWith === 'Peers' ? commonSizeValue({ value: newerYearCompareVal, passedCalcs: calcsPeers }) : null;

            youData = [valuePYMonthlyCommonSize, valueCYMonthlyCommonSize]
            compareData = props.compareWith === 'Peers' ? [valuePYPeersMonthlyCommonSize, valueCYPeersMonthlyCommonSize] : null
          }
        }

        if (parentMetric.sheet === 'kfis') {
          if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
            let kfisValues = AnnualKFIsValues(calcs, props.calcsAvgType, props.yearRange, parentMetric.key)
            youData = []
            compareData = []
            props.yearRange.forEach(year => {
              if (kfisValues[year]) { youData.push(kfisValues[year]) }
              if (props.compareWith !== 'None' && kfisValues['avg' + year] && props.compareWith !== 'Default:None') { compareData.push(kfisValues['avg' + year]) }
            })
          }

          if (props.displayColumnsBy === 'Months') {
            youData = []
            compareData = []
            let numberOfMonths = 0
            let useableMonths = Object.values(calcs['you']['cash']);
            useableMonths.forEach(thing => thing !== null ? numberOfMonths += 1 : null)
            let { youValues, compareValues } = MonthlyKFIsValues(parentMetric, numberOfMonths, props.compareWith, calcs, previousYearCalcs, yoy_calcs, previous_yoy_calcs, props.forecastVals)
            Object.values(youValues).forEach(yv => {
              youData.push(yv)
            })
            if (props.compareWith !== 'None' && props.compareWith !== 'Default:None') {
              Object.values(compareValues).forEach(cv => {
                compareData.push(cv)
              })
            }
          }

          if (props.reportPeriod === 'Year to Date') {
            youData = []
            compareData = []
            chartProps.years.forEach(year => {
              let youMetric = calculateKFIMetric(parentMetric.key, calcs[year]['you'], previousYearCalcs['monthly'][year - 1]['you'], previousYearCalcs['monthly_full_year'][year - 1]['you'])
              youData.push(youMetric)
              if (props.compareWith === 'Peers') {
                let peersMetric = calculateKFIMetric(parentMetric.key, calcs[year]['avg'][year - 1]['avg'], previousYearCalcs['monthly'], previousYearCalcs['monthly_full_year'][year - 1]['avg'])
                compareData.push(peersMetric)
              }
            })
          }
        }
        chartOptions = getLineOrColOptions(chartProps, youData, compareData, props.fiscalYearEnd);
        return (
          <div key={l.i} style={positionStyles}>
            <button className='edit-dash-item-btn' onClick={() => props.handleEditChartModal('open', chartProps, chartOptions)}><FontAwesomeIcon icon={faPencil} /></button>
            <HighchartsReact ref={setNewRef} containerProps={{ style: { width: '100%', height: '100%' }, key: l.i, id: l.i }} highcharts={Highcharts} options={chartOptions} />
          </div>
        )
      } else if (l.i.includes('impact_box')) {
        let parentMetric = l['metricRow'];
        let finalCopy = parentMetric.copy;
        let boxStyle = {
          'backgroundColor': 'white',
          'display': 'flex',
          'flexDirection': 'column',
          'justifyContent': 'space-evenly',
          'alignItems': 'center',
          'height': '100%',
          'width': '100%',
          'textAlign': 'center'
        }
        let statusTextColor = {
          'fontSize': '24px',
          'fontWeight': 'bold',
        }
        let allStyles = { ...boxStyle, ...positionStyles }
        statusTextColor['fontSize'] = props.pdf ? '18px' : '24px'
        let impactTextStyle = props.pdf ? { fontSize: '0.7em' } : { fontSize: '15px' }
        if (l.i.includes('labor_cogs')) {
          finalCopy = `Labor COGS - ${parentMetric.copy}`
        }

        if (props.compareWith === 'None' || props.compareWith === 'Default:None') {
          return (
            <div key={l.i} className='impact-square' style={allStyles}>
              <div style={{ marginBottom: '5px' }}>
                <div style={impactTextStyle}>{`${finalCopy} Impact Box`}</div>
              </div>
              <div>
                <div style={impactTextStyle}>Compare with None Selected</div>
              </div>
            </div>
          )
        } else {
          let { copy, impactText, status, deltaCommonSize, impactValueFormatted } = buildImpactBox(parentMetric, parentMetric['sheet'], props.displayColumnsBy, props.reportPeriod, props.compareWith, props.year, props.yearRange, calcs, previousYearCalcs, yoy_calcs, previous_yoy_calcs, props.calcsAvgType, calcs_3_years_back, props.monthlyCompareWithTitle, props.forecastVals)
          if (!impactText) {
            impactText = noSpecificTextMaker(finalCopy, status, impactValueFormatted, deltaCommonSize, props.compareWith, props.monthlyCompareWithTitle)
          }
          let impactTextClass = impactBoxStatusColor(status)

          if (impactText) {
            return (
              <div key={l.i} className='impact-square' style={allStyles}>
                <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                  <span className={impactTextClass} style={statusTextColor}>{deltaCommonSize}</span>
                  <div style={impactTextStyle}>{impactText['text1']}</div>
                </div>
                <div style={{ fontWeight: 'bold' }}>
                  <span className={impactTextClass} style={statusTextColor}>{impactValueFormatted}</span>
                  <div style={impactTextStyle}>{impactText['textNotesSection']}</div>
                </div>
              </div>
            )
          }
        }
      } else if (l.i.includes('notes')) {
        return (
          <div key={l.i} style={positionStyles}>
            {props.pdf ? null : <button className='lock-btn' onClick={() => props.handleToggleDraggable(l.i, 'moneyDashboard')}>{l.isDraggable ? <FontAwesomeIcon icon={faLockOpen} /> : <FontAwesomeIcon icon={faLock} />}</button>}
            <MetricNotes noteProps={l} />
          </div>
        )
      } else if (l.i === 'peerview_gauges') {
        let allStyles = { ...positionStyles, display: 'flex' }
        return (
          <div key='peerview_gauges' className='dashboard-gauges' style={allStyles}>
            {gaugeKeys[props.page].map((metricKey, i) => {
              let gaugeProps = { style: { width: '100%', height: '100%' }, key: `gauge-${i}`, id: `gauge-${i}` }
              let gaugeData = 0;
              if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
                gaugeData = Math.round(calcs.you[metricKey][props.year])
              }
              if (props.displayColumnsBy === 'Months' || props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date') {
                const peerviewMetrics = peerviewMetricsYears[props.year]['you'];
                if (peerviewMetrics.hasOwnProperty(metricKey)) {
                  gaugeData = Math.round(peerviewMetrics[metricKey]);
                } else {
                  gaugeData = 0;
                }
              }
              let gaugeOptions = getGaugeOptions(metricKey, gaugeData);
              return (
                <HighchartsReact key={metricKey} ref={setNewRef} containerProps={gaugeProps} highcharts={Highcharts} options={gaugeOptions} />
              )
            })}
          </div>
        )
      } else if (l.i === 'you_pie' || l.i === 'compare_pie') {
        let group;
        let pieClass = props.page === 'money' ? 'money-expenses-charts' : '';
        let pieKey = l.i;
        if (l.i === 'compare_pie' && props.compareWith === 'None' || l.i === 'compare_pie' && props.compareWith === 'Default:None') {
          return (
            <div key={pieKey} className={pieClass} style={positionStyles}><div style={{ 'fontSize': '18px', 'color': 'black', 'padding': '5px' }}>Compare With Expenses Pie Chart</div></div>
          )
        } else {
          let pieTitle = ''
          if (l.i === 'you_pie') {
            group = 'you'
            pieTitle = pieExpenseTitles['you']
          } else {
            if (props.compareWith === 'Peers' || props.compareWith === 'Default:Peers') {
              group = 'avg'
              pieTitle = pieExpenseTitles['avg']
            } else if (props.compareWith === 'Practice') {
              group = 'practice_avg'
              pieTitle = pieExpenseTitles['practice_avg']
            } else if (props.compareWith === 'NSCHBC') {
              group = 'nschbc'
              pieTitle = pieExpenseTitles['nschbc']
            } else if (props.compareWith === 'YOY' || props.compareWith === 'Default:YOY') {
              group = 'yoy'
              pieTitle = pieExpenseTitles['yoy']
            } else if (props.compareWith.includes('Forecast')) {
              group = 'forecast'
              pieTitle = props.monthlyCompareWithTitle + ' Expenses'
            }
          }

          let pieCalcs;
          if (group === 'yoy') {
            pieCalcs = previousYearCalcs['you'];
          } else if (props.reportPeriod === 'Year to Date') {
            pieCalcs = calcs[props.year][group];
          } else if (props.reportPeriod !== 'Year to Date' && group !== 'forecast') {
            pieCalcs = calcs[group];
          } else if (group === 'forecast') {
            pieCalcs = props.forecastVals
          }

          const pieProps = {
            commonSize: pagePies[props.page].commonSize,
            data: pieCalcs,
            displayColumnsBy: props.displayColumnsBy,
            keys: pagePies[props.page].keys,
            page: props.page,
            pdf: props.pdf,
            reportPeriod: props.reportPeriod,
            subtitle: pagePies[props.page].subtitle,
            title: pieTitle,
            year: props.year,
          }

          let pieOptions = getPieOptions(pieProps);
          return (
            <div key={pieKey} className={pieClass} style={positionStyles}><HighchartsReact ref={setNewRef} containerProps={{ style: { width: '100%', height: '100%' }, key: pieKey, id: pieKey }} highcharts={Highcharts} options={pieOptions} /></div>
          )
        }
      } else if (l.i === 'you_compare_stacked_column') {
        let finalCategories = ['You']
        props.compareWith === 'Peers' ? finalCategories.push('Peers') : props.compareWith === 'Practice' ? finalCategories.push('Practice') : finalCategories.push('YOY');

        let chartProps = {
          i: l.i,
          aggregateYTDDate: props.aggregateYTDDate || null,
          companyYTDDate: props.companyYTDDate || null,
          format: 'percent',
          compareWith: props.compareWith || null,
          displayColumnsBy: props.displayColumnsBy || null,
          fiscalYearEnd: props.fiscalYearEnd || '12/31',
          key: 'you_compare_stacked_column',
          months: props.monthLabels || null,
          page: props.page,
          pdf: props.pdf || false,
          reportPeriod: props.reportPeriod,
          title: 'People Expenses Comparison',
          categories: finalCategories,
          years: props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date' ? [props.year - 1, props.year] : props.yearRange,
        }
        let stackedData = buildPeopleExpenses(chartProps)
        let chartOptions = getStackedColumnOptions(chartProps, stackedData);
        return (
          <div key={'you_compare_stacked_column'} style={positionStyles}><HighchartsReact ref={setNewRef} containerProps={{ style: { width: '100%', height: '100%' }, key: 'you_compare_stacked_column', id: 'you_compare_stacked_column' }} highcharts={Highcharts} options={chartOptions} /></div>
        )
      } else if (l.i === 'map_of_client_number') {
        let mapOptions = getUSMapOptions(props.calculations);
        return (
          <div key={'map_of_client_number'} style={positionStyles}>
            <button className='lock-btn' onClick={() => props.handleToggleDraggable('map_of_client_number')}>{l.isDraggable ? <FontAwesomeIcon icon={faLockOpen} /> : <FontAwesomeIcon icon={faLock} />}</button>
            <HighchartsReact ref={setNewRef} containerProps={{ style: { width: '100%', height: '100%' }, key: `map_of_client_number` }} highcharts={Highcharts} constructorType={'mapChart'} options={mapOptions} />
          </div>
        )
      } else if (l.i === 'donut_pie_industry_expenses') {
        let donutKey = l.i;
        const donutProps = {
          commonSize: pagePies['money'].commonSize,
          data: calcs['avg'],
          displayColumnsBy: props.displayColumnsBy,
          keys: pagePies['money'].keys,
          page: 'money',
          reportPeriod: props.reportPeriod,
          year: props.year,
        }

        let donutOptions = getDonutOptions(donutProps);

        return (
          <div key={donutKey} className={donutKey} style={positionStyles}><HighchartsReact ref={setNewRef} containerProps={{ style: { width: '100%', height: '100%' }, key: donutKey, id: donutKey }} highcharts={Highcharts} options={donutOptions} /></div>
        )
      } else if (l.layout && l.layout.length > 1) {
        let parentInfo = l['metricRow'] ? l['metricRow'] : l
        let parentStyles = l['metricRow'] ? l['metricRow']['styles'] : l['styles'] ? l['styles'] : {}
        let parentNotes = l['metricRow'] ? l['metricRow']['notes'] : ''
        return (
          <div key={l.i} style={positionStyles}>
            <ChildDashboard
              aggregateYTDDate={props.aggregateYTDDate}
              calcsAvgType={props.calcsAvgType}
              clientName={props.clientName}
              companyYTDDate={props.companyYTDDate}
              compareWith={props.compareWith}
              dashboardLayout={l.layout}
              displayColumnsBy={props.displayColumnsBy}
              monthlyCompareWithTitle={props.monthlyCompareWithTitle}
              forecastVals={props.forecastVals}
              handleEditChartModal={props.handleEditChartModal}
              handleMetricNotes={props.handleMetricNotes}
              handleOnLayoutChange={props.handleOnLayoutChange}
              handleToggleDraggable={props.handleToggleDraggable}
              calendarYearEnd={props.calendarYearEnd}
              fiscalYearEnd={props.fiscalYearEnd}
              monthLabels={props.monthLabels}
              naicsCode={props.naicsCode}
              page={props.page}
              pdf={props.pdf}
              positionStyles={positionStyles}
              rankingData={props.rankingData}
              reportPeriod={props.reportPeriod}
              year={props.year}
              yearRange={props.yearRange}
              parentDashBoardInfo={parentInfo}
              parentNotes={parentNotes}
              parentStyles={parentStyles}
            />
          </div>
        )
      } else {
        let chart;
        if (props.page === 'practice') {
          chart = pageCharts['practice']['dashboard_items'].find(c => c.key === l.i);
        } else if (props.page === 'customers') {
          chart = pageCharts['customers']['dashboard_items'].find(c => c.key === l.i);
        } else {
          chart = moneyCharts[props.page].find(c => c.key === l.i);
          if (!chart) { chart = moneyCharts['peerview_charts'].find(c => c.key === l.i) }
        }
        if (chart) {
          let chartKey = chart.key
          let youData, compareData, chartOptions;
          let chartProps = {
            i: l.i,
            aggregateYTDDate: props.aggregateYTDDate || null,
            companyYTDDate: props.companyYTDDate || null,
            format: chart.format,
            headerCols: chart.headerCols,
            compareWith: props.compareWith || null,
            displayColumnsBy: props.displayColumnsBy || null,
            fiscalYearEnd: props.fiscalYearEnd || '12/31',
            key: chart.key,
            keys: chart.keys || null,
            metricKey: chart.metricKey || null,
            months: props.monthLabels || null,
            page: props.page,
            pdf: props.pdf || false,
            percentOfRevenueKey: chart.percentOfRevenueKey || null,
            reportPeriod: props.reportPeriod,
            title: titleSetter(chart),
            subtitle: chart.subtitle,
            styles: l.styles || chart.styles,
            trailing12Key: chart.trailing12Key || null,
            x: chart.x,
            y: chart.y,
            years: props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date' ? [props.year - 1, props.year] : props.yearRange,
          }
          switch (chart.chartType) {
            case '3DBubble':
              chartOptions = get3DBubbleChartOptions(chartProps, props.calculations);
              break;
            case 'quadrantBubble':
              if (props.page === 'practice') {
                chartOptions = getQuadrantOptions(chartProps, props.calculations);
              }
              if (props.page === 'money') {
                let chartData = { name: props.naicsCode, data: [] };
                let dataObj = { name: props.clientName, color: '#69B144' }
                if (props.displayColumnsBy === 'Years' && props.reportPeriod !== 'Year to Date') {
                  dataObj.x = Math.round(calcs.you[chartProps.keys[0]][props.year])
                  dataObj.y = Math.round(calcs.you[chartProps.keys[1]][props.year])
                  dataObj.z = calcs.you['total_revenue'][props.year]
                  chartData.data.push(dataObj);
                }
                if (props.displayColumnsBy === 'Months' || props.displayColumnsBy === 'Years' && props.reportPeriod === 'Year to Date') {
                  const peerviewMetrics = peerviewMetricsYears[props.year]['you'];
                  dataObj.x = peerviewMetrics.hasOwnProperty(chartProps.keys[0]) ? peerviewMetrics[chartProps.keys[0]] : 0;
                  dataObj.y = peerviewMetrics.hasOwnProperty(chartProps.keys[1]) ? peerviewMetrics[chartProps.keys[1]] : 0;
                  dataObj.z = props.displayColumnsBy === 'Months' ? objReducer(calcs.you['total_revenue']) : objReducer(calcs[props.year]['you']['total_revenue']);
                  chartData.data.push(dataObj);
                }
                chartOptions = getQuadrantOptions(chartProps, chartData)
              }
              break;
            case 'practiceText':
              return buildFocusedClients()
              break;
            case 'multiDataBarChart':
              chartOptions = getMultiDataBarChartOptions(chartProps, props['rankingData'])
              break;
            case 'rankingTable':
              return buildRankingTable(chartProps, props['rankingData'])
              break;
            case 'scatterPlot':
              chartOptions = getScatterPlotOptions(chartProps, props.calculations);
              break;
            case 'line':
              let lineData = buildIndustryLineData(chartProps)
              chartOptions = getIndustryLineChartOptions(chartProps, lineData)
              break;
            case 'bar':
              let barData = buildIndustryBarData(chartProps)
              chartOptions = getIndustryBarChartOptions(chartProps, barData)
              break;
            case 'editableChart':
            case 'lineBar':
              youData = buildYouLineOrColData(chartProps);
              compareData = chartProps.compareWith !== 'None' && chartProps.compareWith !== 'Default:None' ? buildCompareLineOrColData(chartProps) : null;
              chartOptions = getLineOrColOptions(chartProps, youData, compareData, props.fiscalYearEnd);
              break;
            case 'verticalBars':
              youData = buildVerticalBarData(chartProps, 'you');
              compareData = chartProps.compareWith !== 'None' && chartProps.compareWith !== 'Default:None' ? buildVerticalBarData(chartProps, chartProps.compareWith) : null;
              chartOptions = getVerticalBarChartOptions(chartProps, youData, compareData);
              break;
            case 'horizontalBars':
              let xCategories = chart.keys.map((k) => COPY[k]);
              let hBarSeries = buildHorizonalBarSeries(chartProps);
              chartOptions = getHorizontalBarOptions(chartProps, xCategories, hBarSeries);
              break;
            case 'stackedBars':
              chartProps['categories'] = chartProps.displayColumnsBy === "Years" ? chartProps.years : chartProps.months,
                youData = buildAPARAgingSeries(chartProps)
              chartOptions = getStackedColumnOptions(chartProps, youData);
              break;
            case 'lines':
              let seriesTitles = chart.keys.map((k) => COPY[k])
              let lineSeries = buildLineSeries(chartProps)
              chartOptions = getLineChartOptions(chartProps, seriesTitles, lineSeries);
              break;
            case 'wageDataScaffoldTable':
              return (<div className='wage-data-scaffold' key={chartKey}><WageDataScaffold wageData={calcs.wagedata} /></div>)
            case 'noChart':
              let value = costOfTurnover();
              return (
                <div className='cost-of-turnover' key={chartKey}>
                  <h3>Cost of Turnover</h3>
                  <p>(Based on S.H.R.M. Standards)</p>
                  <div className='turnover-border'>
                    <h2 className='cost-of-turnover-value'>
                      {parseMoney(Math.abs(value))}
                    </h2>
                    <p>{value < 0 ? 'Less' : 'More'} Than Your Peers</p>
                  </div>
                </div>
              )
          }
          if (chart.dragTogglable) {
            return (
              <div key={chartKey} style={positionStyles}>
                {props.pdf ? null : <button className='lock-btn' onClick={() => props.handleToggleDraggable(chartKey)}>{l.isDraggable ? <FontAwesomeIcon icon={faLockOpen} /> : <FontAwesomeIcon icon={faLock} />}</button>}
                <HighchartsReact ref={setNewRef} containerProps={{ style: { width: '100%', height: '100%' }, key: chartKey, id: chartKey }} highcharts={Highcharts} options={chartOptions} />
              </div>
            )
          } else {
            return (
              <div className="reports-dashboard-chart" key={chartKey} style={positionStyles}>
                <button className='edit-dash-item-btn' onClick={() => props.handleEditChartModal('open', chartProps, chartOptions)}><FontAwesomeIcon icon={faPencil} /></button>
                <HighchartsReact ref={setNewRef} containerProps={{ style: { width: "100%", height: "100%" }, key: chartKey, id: chartKey }} highcharts={Highcharts} options={chartOptions} />
              </div>
            )
          }
        }
        return null
      }
    })

  }, [props.dashboardLayout, props.year, props.calculations, props.myNotes, calcs])

  if (props.pdf) {
    if (props.dashboardLayout.length > 0) {
      let heightStyle = {
        height: `248mm`
      }

      return (
        <div className='parent-dash-portrait-pdf' style={heightStyle}>
          {layoutItems}
        </div>
      )
    }
  } else {
    return (
      <ResponsiveGridLayout
        className='parent-layout'
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        layouts={{ lg: props.dashboardLayout }}
        cols={{ lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 }}
        rowHeight={210}
        isDraggable={true}
        isDroppable={true}
        isResizable={true}
        onResizeStop={(layout, oldItem, newItem) => {
          // Force reflow() on specific item when user resizes it.
          let item = oldItem ? oldItem : newItem;
          let checkChartRefs = _.isEmpty(chartRefs)
          if (!checkChartRefs && item && chartRefs[item.i] && chartRefs[item.i].chart) {
            chartRefs[item.i].chart.reflow()
          }
          window.dispatchEvent(new Event('resize'))
        }}
        onLayoutChange={(layout) => {
          props.handleOnLayoutChange('parentMyDashBoard', layout)
          // Force reflow() on highcharts when different display columns by filter submits
          // For some odd reason the highcharts are not resizing to new layout like before
          let checkChartRefs = _.isEmpty(chartRefs)
          if (!checkChartRefs) {
            for (const chartRef in chartRefs) {
              if (chartRefs[chartRef].chart) {
                let chartInfo = layout.find(l => l.i === chartRef)
                if (chartInfo) {
                  let chartWidth = chartInfo.w * 214;
                  let chartHeight = chartInfo.h * 208;
                  chartRefs[chartRef].chart.setSize(chartWidth, chartHeight)
                } else {
                  chartRefs[chartRef].chart.reflow();
                }
              }
            }
          }
        }}
      >
        {layoutItems}
      </ResponsiveGridLayout>
    )
  }
}
export default ParentDashboard;