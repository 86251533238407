import React, { useEffect, useRef, useState } from 'react';
import MoneyReport from './reports/MoneyReport';
import PeopleReport from './reports/PeopleReport';
import CustomersReport from './reports/CustomersReport';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import Modal from 'react-modal';

const naicsPNLTitle = (naicsCode) => {
    if (naicsCode.startsWith('6212')) {
        return 'Dental P&L'
    } else if (naicsCode.startsWith('6211') || naicsCode.startsWith('6213')) {
        return 'Doctor P&L'
    } else if (naicsCode.startsWith('813')) {
        return 'Functional Expenses'
    } else {
        return null
    }
}

const ExportPDFandPrint = (props) => {
    const naicsFocusedReport = naicsPNLTitle(props.naicsCode)
    const reportRefs = useRef();
    const [PDFPrintModalOpen, setPDFPrintModalOpen] = useState(false);
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [displayPages, setDisplayOptions] = useState({
        cover_page: {
            checked: true,
            label: 'Cover Page'
        }
    })
    const navbar = window.navbar.navbar;

    useEffect(() => {
        setDisplayOptions(() => {
            if (props.page === 'money') {
                if (navbar.user_type === 'Admin' || props.planAccess.money.ai_viewable) {
                    return {
                        cover_page: {
                            checked: true,
                            label: 'Cover Page'
                        },
                        ai: {
                            checked: false,
                            label: 'Peerview Copilot'
                        },
                        pnl: {
                            checked: true,
                            label: 'Profit & Loss'
                        },
                        naics_focused_report: {
                            checked: true,
                            label: naicsFocusedReport
                        },
                        balance_sheet: {
                            checked: true,
                            label: 'Balance Sheet'
                        },
                        cash_flow: {
                            checked: true,
                            label: 'Cash Flow'
                        },
                        kfis: {
                            checked: true,
                            label: 'Key Financial Indicators'
                        },
                        dashboard: {
                            checked: true,
                            label: 'Dashboard'
                        }
                    }
                } else {
                    return {
                        cover_page: {
                            checked: true,
                            label: 'Cover Page'
                        },
                        pnl: {
                            checked: true,
                            label: 'Profit & Loss'
                        },
                        naics_focused_report: {
                            checked: true,
                            label: naicsFocusedReport
                        },
                        balance_sheet: {
                            checked: true,
                            label: 'Balance Sheet'
                        },
                        cash_flow: {
                            checked: true,
                            label: 'Cash Flow'
                        },
                        kfis: {
                            checked: true,
                            label: 'Key Financial Indicators'
                        },
                        dashboard: {
                            checked: true,
                            label: 'Dashboard'
                        }
                    }
                }
            } else if (props.page === 'people' || props.page === 'customers') {
                return {
                    cover_page: {
                        checked: true,
                        label: 'Cover Page'
                    },
                    financial_detail: {
                        checked: true,
                        label: 'Financial Details'
                    },
                    kpi: {
                        checked: true,
                        label: 'KPI'
                    },
                    dashboard: {
                        checked: true,
                        label: 'Dashboard'
                    }
                }
            }
        })
    }, [props.page])

    const pdfIcon = <FontAwesomeIcon icon={faFilePdf} />

    const openVideoModal = () => {
        setVideoModalOpen(true);
    }

    const closeVideoModal = () => {
        setVideoModalOpen(false);
    }

    const closePDFPrintModal = () => {
        setPDFPrintModalOpen(false);
    }

    const VideoModal = () => {
        let videoLink = null
        let modalPic = require('../../assets/images/PremiumExcelPDF.png')

        const hrefBuilder = (subdomain, path) => {
            const domainSplit = document.location.host.split('.');
            domainSplit[0] = subdomain;
            return location.protocol + '//' + domainSplit.join('.') + path;
        };

        const manageSubscriptionsURL = hrefBuilder(navbar.subdomain, '/subscriptions')
        const unlockReportsURL = hrefBuilder(navbar.subdomain, navbar.unlock_reports_url)
        const linkText = navbar.report_credits ? "Unlock Reports" : "Buy More Report Credits"
        const purchaseText = "Buy more report credits."

        return (
            <Modal
                className={{
                    base: 'video-modal-content hide-on-print',
                    afterOpen: 'video-modal-content_after-open hide-on-print',
                    beforeClose: 'video-modal-content_before-close hide-on-print',
                }}
                overlayClassName={{
                    base: 'overlay-base hide-on-print',
                    afterOpen: 'overlay-base_after-open hide-on-print',
                    beforeClose: 'overlay-base_before-close hide-on-print',
                }}
                isOpen={videoModalOpen}
                onRequestClose={closeVideoModal}
                shouldCloseOnOverlayClick={true}
                closeTimeoutMS={0}
                contentLabel='Video About Report Features'
                ariaHideApp={false}
            >
                <div>
                    <h1>Upgrade to Access These Reports</h1>
                    <img className='premium-pic-popup' src={modalPic}></img>
                    {(props.planAccess.print_and_pdf && !navbar.reports_unlocked || props.planAccess.export_to_excel && !navbar.reports_unlocked)
                        ? (<div className='unlock-report-container'>
                            <p>{`You have ${navbar.report_credits} Report Credits left.`} <a className='btn btn-primary unlock-report-btn' href={unlockReportsURL}>{linkText}</a></p>
                        </div>)
                        : null}
                    <a className='btn btn-primary manage-subscription-btn' href={manageSubscriptionsURL}>Manage Your Subscription</a>
                    <p>Click anywhere outside to close this pop-up.</p>
                </div>
            </Modal>
        )
    }

    const handlePDFandPrintChecked = (key) => {
        setDisplayOptions((prevState) => {
            let newOptions = _.cloneDeep(prevState)
            newOptions[key]['checked'] = !newOptions[key]['checked']
            return newOptions
        })
    }

    let pdfButtonId = 'moneyPdfId';

    const getPDFButton = () => {
        if ((!props.planAccess.print_and_pdf && navbar.user_type !== 'Admin') || (!navbar.reports_unlocked && props.planAccess.print_and_pdf && navbar.user_type !== 'Admin')) {
            return (
                <span className='hide-on-print'>
                    <button id={pdfButtonId} className='print-pdf-btn' aria-hidden='true' onClick={() => openVideoModal()}>{pdfIcon}</button>
                </span>
            )
        }

        if (navbar.reports_unlocked && props.planAccess.print_and_pdf || navbar.user_type === 'Admin') {
            return (
                <span className='hide-on-print'>
                    <button id={pdfButtonId} className='print-pdf-btn' onClick={() => setPDFPrintModalOpen(true)} aria-hidden='true' >{pdfIcon}</button>
                </span>
            )
        }
    }

    const renderReport = () => {
        const Component = {
            money: MoneyReport,
            people: PeopleReport,
            customers: CustomersReport
        }[props.page];

        return (
            <Component
                ref={reportRefs}
                {...props}
                displayPages={displayPages}
                year={props.year}
                yearRange={props.yearRange}
                page={props.page}
                pdf={true}
            />
        )
    };

    const printPDFExport = () => {
        if (navbar.reports_unlocked && props.planAccess.print_and_pdf || navbar.user_type === 'Admin') {
            return (
                <Modal
                    className={{
                        base: 'pdf-print-modal-content hide-on-print',
                        afterOpen: 'pdf-print-modal-content_after-open hide-on-print',
                        beforeClose: 'pdf-print-modal-content_before-close hide-on-print',
                    }}
                    overlayClassName={{
                        base: 'overlay-base hide-on-print',
                        afterOpen: 'overlay-base_after-open hide-on-print',
                        beforeClose: 'overlay-base_before-close hide-on-print',
                    }}
                    isOpen={PDFPrintModalOpen}
                    onRequestClose={closePDFPrintModal}
                    shouldCloseOnOverlayClick={true}
                    closeTimeoutMS={0}
                    contentLabel='PDF and Print Preview'
                    ariaHideApp={false}
                >
                    <div className='report-builder-container'>
                        <h2>Report Builder</h2>
                        <p>Include: </p>
                        <div className='report-builder-all-checkboxes'>
                            <div>
                                <div key={`cover_page-checkbox`} className='page-checkbox-input'>
                                    <input id={`cover_page-checked`} type='checkbox' name={'cover_page'} value={'cover_page'} checked={displayPages['cover_page']['checked']} onChange={(e) => handlePDFandPrintChecked(e.target.value)} />
                                    <label htmlFor={`cover_page-checked`}>{displayPages['cover_page']['label']}</label>
                                </div>
                            </div>
                            <div className='report-pages-checkboxes'>
                                {Object.keys(displayPages).map((key) => {
                                    if (key === 'naics_focused_report' && !naicsFocusedReport || key === 'cover_page') {
                                        return null
                                    } else {
                                        return (
                                            <div key={`${key}-checkbox`} className='page-checkbox-input'>
                                                <input id={`${key}-checked`} type='checkbox' name={key} value={key} checked={displayPages[key]['checked']} onChange={(e) => handlePDFandPrintChecked(e.target.value)} />
                                                <label htmlFor={`${key}-checked`}>{displayPages[key]['label']}</label>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        <ReactToPrint
                            trigger={() => <button id={pdfButtonId} className='run-report-btn pdf-print-action-btn' aria-hidden='true'>Print my Client Report</button>}
                            content={() => reportRefs.current}
                            documentTitle={`${props.name} Report.pdf`}
                        />
                    </div>
                    {renderReport()}
                </Modal>
            )
        }
    }

    return (
        <>
            <VideoModal />
            {getPDFButton()}
            {printPDFExport()}
        </>
    )
};
export default ExportPDFandPrint;