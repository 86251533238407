import React, { useState, useEffect, useRef } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import MUIDataTable, { debounceSearchRender } from 'mui-datatables';
import { Resizable } from 'react-resizable';
import { ArrowUpward } from '@mui/icons-material';
import { ArrowDownward } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faAnglesLeft, faLock, faMagnifyingGlass, faPencil, faUnlock } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import moment from 'moment';
import { useTour } from '@reactour/tour';
import { toast } from 'react-toastify';
import MCDropdownFilter from './MCDropdownFilter';
import { parseMoney } from '../utils/index';
import LoaderGraphic from '../utils/LoaderGraphic';
import mountNotifications from '../utils/MountNotifications';
import ExportDropdown from '../reports/utils/ExportDropdown';
import DropzoneComponent from './DropzoneComponent';
import EditMultipleClientsForm from './EditMultipleClientsForm';
import { CheckMark, XMark } from '../utils/SVGIcons';

const numOfProsYearMaker = () => {
  const prosByYear = {};
  const currentYear = new Date().getFullYear()
  let startYear = currentYear - 3;
  while (startYear <= currentYear) {
    prosByYear[startYear] = 0;
    startYear++
  }
  return prosByYear
}
const numOfPros = numOfProsYearMaker();

const ManageClientsTable = ({ navBarInfo, naicsList, qbo_notifications, users, user_preference, tourData, xero_connect_url, plan_access, user_notifications }) => {
  const { isOpen, currentStep, setIsOpen, setCurrentStep } = useTour();
  const resizeTimeout = useRef(null); // Ref to store the timeout
  const [modalData, setModalData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editMulitpleModalIsOpen, setEditMultipleModalIsOpen] = useState(false);
  const [fileUploadsModalIsOpen, setFileUploadsModalIsOpen] = useState(false);
  const [currentFileUploads, setCurrentFileUploads] = useState([])
  const [activeTab, setActiveTab] = useState('all-clients');
  const [connectToDisplay, setConnectToDisplay] = useState(false);
  const [inputForm, setInputForm] = useState({
    name: '',
    real_name: '',
    subdomain: '',
    naics_id: '',
    number_of_pros: 0,
    number_of_pros_by_year: numOfPros,
    partners: '',
    office: '',
    status: '',
    fye_month: '',
    fye_day: ''
  });
  const [showEditProYearForm, setShowEditProYearForm] = useState(false)
  const [inputFormForAssignedTo, setInputFormForAssignedTo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company_id: '',
  });
  const [fdu, setFdu] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [columnWidths, setColumnWidths] = useState({
    name: 101,
    real_name: 127,
    subdomain: 72,
    unlocked: 24,
    full_naics: 100,
    partners: 78,
    office: 96,
    state: 70,
    total_revenue: 105,
    total_impact: 105,
    data_through_year: 105,
    balanced: 80,
    is_monthly: 80,
    status: 85
  });
  const [data, setData] = useState({
    page: 1,
    count: 100,
    totalPages: 4,
    clientsOnlyData: [],
    filterOptions: { naics_code: [['All', '']], revenue_band: ['All'], region: ['All'], partners: ['All'], office: ['All'], status: ['All'] },
    report_credits: 0
  });

  // Use to grab filterOptions above for partners and office values
  const [rowsSelectedData, setRowsSelectedData] = useState({
    rowIndexes: [],
    data: [],
    numOfRowSelected: 0,
    propertySelected: 'status',
    propertyValues: {
      status: 'Active',
      naics_id: '434',
      state: 'AL',
      partners: '',
      office: '',
      fiscal_year_end: '2022-12-31'
    },
    displayEditRowSection: false,
    error: ''
  })
  const [notificationData, setNotificationData] = useState({
    notifications: { today: [], recent: [] }
  })
  const [fetchedNaicsList, setNaicsList] = useState(naicsList || []);
  const [allTableState, setAllTableState] = useState({
    page: user_preference && user_preference.details.page ? user_preference.details.page : 1,
    rowsPerPage: user_preference && user_preference.details.rowsPerPage ? user_preference.details.rowsPerPage : 25,
    sortOrder: user_preference && user_preference.details.sortOrder ? user_preference.details.sortOrder : { name: 'name', direction: 'asc' },
    searchText: '',
    filters: ''
  });
  const [selectedFilters, setSelectedFilters] = useState({
    naics_code: 'All',
    revenue_band: 'All',
    region: 'All',
    partners: 'All',
    office: 'All',
    status: 'All',
  })

  const [companyModalTabs, setCompanyModalTabs] = useState('file-uploads')
  const [jobIDs, setJobIDs] = useState(qbo_notifications["current_job_ids"] || [])
  const [toastIDs, setToastIDs] = useState(qbo_notifications["current_job_ids"] || [])
  const [filtersModalIsOpen, setFiltersModalIsOpen] = useState(false)
  const [activePopover, setActivePopover] = useState(null)

  const TourBtn = () => {
    const questionCircle = <FontAwesomeIcon icon={faQuestionCircle} />
    return (
      <button className='hide-on-print' onClick={() => setIsOpen(true)}>{questionCircle}</button>
    )
  }

  const getClientsData = async () => {
    setIsLoading(true)
    const res = await fetch(`/api/v1/companies/?page=${allTableState.page}&rows=${allTableState.rowsPerPage}&sort=${allTableState.sortOrder.name}&direction=${allTableState.sortOrder.direction}&filters=${allTableState.filters}&search=${allTableState.searchText}`, {
      headers: {
        'X-CSRF-Token': window.token,
        'Accept': 'application/json'
      }
    })
    const json = await res.json();

    setData({
      filterOptions: json.filter_options_obj,
      page: json.page,
      totalPages: json.total_pages,
      count: json.total_companies_count,
      clientsOnlyData: json.companies_list_paginated.clients,
      report_credits: json.companies_list_paginated.report_credits,
    })

    if (json.user_preference.details && json.user_preference.details.filters) {
      setSelectedFilters(prevState => {
        let newFilters = prevState
        let filters = json.user_preference.details.filters.split(":")
        filters.forEach(f => {
          let fItems = f.split('=')
          if (fItems[1]) {
            newFilters[fItems[0]] = fItems[1].replace(/'/g, '')
          }
        })

        return newFilters
      })
    }

    if (json.user_preference.details && json.user_preference.details.search) {
      setAllTableState(prevState => {
        return {
          ...prevState,
          searchText: json.user_preference.details.search
        }
      })
    }

    if (json.user_preference.details && json.user_preference.details.columnWidths) {
      let colWidths = json.user_preference.details.columnWidths
      if (!colWidths.hasOwnProperty('total_impact')) {
        colWidths.total_impact = 105
      }
      setColumnWidths(colWidths)
    }

    setIsLoading(false)

    !tourData.tour_taken ? setIsOpen(true) : null
  }

  // const getAllDataForPrint = async () => {
  //   const res = await fetch('/api/v1/companies?page=1&rows=3000', {
  //     headers: {
  //       'X-CSRF-Token': window.token,
  //     }
  //   })
  //   const json = await res.json();
  //   setPrintData(json.companies_list_paginated.clients)
  // }

  const getSidekiqJobStatus = async (jobID, notification = {}) => {
    const response = await fetch(`/sidekiq_status/${jobID}`, {
      headers: {
        'X-CSRF-Token': window.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET',
    });
    const res = await response.json();

    console.log(res, 'sidekiqStatusRes')
    if (res['job_data']) {
      let percentage = Number(res['job_data']['at'])

      console.log(jobID, percentage, 'jobID')
      if (percentage === 100) {
        toast.update(jobID, { render: res['notification']['body'], progress: 0.99, autoClose: false, onClose: () => hideQBONotification(res['notification']['id']) });
        setJobIDs((prevState) => {
          let newJobs = jobIDs.filter(j => j !== jobID)
          return newJobs
        })
        setToastIDs((prevState) => {
          let newJobs = toastIDs.filter(j => j !== jobID)
          return newJobs
        })
        return 'stop'
      } else if (percentage >= 0) {
        toast.update(jobID, { progress: percentage / 100, onClose: () => hideQBONotification(notification['id']) });
        return 'continue'
      } else if (isNaN(percentage)) {
        setJobIDs((prevState) => {
          let newJobs = jobIDs.filter(j => j !== jobID)
          return newJobs
        })
        setToastIDs((prevState) => {
          let newJobs = toastIDs.filter(j => j !== jobID)
          return newJobs
        })
        return 'stop'
      }
    } else {
      setJobIDs((prevState) => {
        let newJobs = jobIDs.filter(j => j !== jobID)
        return newJobs
      })
      setToastIDs((prevState) => {
        let newJobs = toastIDs.filter(j => j !== jobID)
        return newJobs
      })
      console.log(res, 'nojob');
      return 'stop'
    }
  }

  useEffect(() => {
    getClientsData()

    // if (window.location.search === '?job=QuickBooksExtractorWorker') {
    //   qboSubscribe()
    //   mountNotifications()(`Working on your QuickBooks Online data! You'll be notified when it's complete.`)
    // }
    if (window.location.search === '?job=SageIntacctExtractorWorker') {
      sageSubscribe()
      mountNotifications()(`Working on your Sage Intacct data! You'll be notified when it's complete.`)
    }
    if (window.location.search === '?job=XeroExtractorWorker') {
      xeroSubscribe()
      mountNotifications()(`Working on your Xero data! You'll be notified when it's complete.`)
    }
    if (window.location.search === '?job=BulkLoadWorker') {
      mountNotifications()(`Creating your clients!`)
    }

    if (user_notifications.length > 0) {
      user_notifications.forEach((notification) => {
        mountNotifications()(notification)
      })
    }

    let checkNotificationsExist = Object.keys(qbo_notifications)
    if (checkNotificationsExist.length > 0) {
      Object.values(qbo_notifications).forEach(notification => {
        if (!Array.isArray(notification)) {
          if (notification['details']['status'] == 'complete') {
            toast(notification['body'], { progress: 0.99, onClose: () => hideQBONotification(notification['id']) })
          } else {
            toast(notification['body'], { toastId: notification['details']['sidekiq_job_id'], progress: 0 });
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    let sideKiqJobIDs = jobIDs
    if (sideKiqJobIDs.length > 0) {

      const notificationInterval = setInterval(() => {
        if (sideKiqJobIDs.length > 0) {
          sideKiqJobIDs.forEach((jobID) => {
            const res = getSidekiqJobStatus(jobID)

            res.then(function (result) {
              if (result === 'stop') {
                sideKiqJobIDs = sideKiqJobIDs.filter(i => i !== jobID)
              }
            })
          })
        } else {
          clearInterval(notificationInterval)
        }
      }, 7000);
    }
  }, [])

  const LoadingOverlay = () => {
    return (
      <div className='client-overlay'>
        <LoaderGraphic />
      </div>
    );
  };

  const ModalLoadingOverlay = () => {
    return (
      <div className='modal-overlay'>
        <LoaderGraphic />
      </div>
    );
  };

  const handleChangeClientID = (e) => {
    const lowerCaseSubdomain = e.target.value.toLowerCase().replace(/\s/g, '')
    const words = modalData.subdomain.split('-');
    const parentCompany = words[0]
    const middleParent = words[1]

    setInputForm((previousState) => {
      if (words.length > 2) {
        return {
          ...previousState,
          name: e.target.value,
          subdomain: `${parentCompany}-${middleParent}-${lowerCaseSubdomain}`
        };
      } else {
        return {
          ...previousState,
          name: e.target.value,
          subdomain: `${parentCompany}-${lowerCaseSubdomain}`
        };
      }

    });
  };

  const handleChangeRealName = (e) => {
    setInputForm((previousState) => {
      return {
        ...previousState,
        real_name: e.target.value,
      };
    });
  };

  const handleChangeNAICS = (value) => {
    let naicsDefaultOption = naicsOptions.find((option) => {
      if (option.value == value) {
        return option;
      }
    });

    setInputForm((previousState) => {
      return {
        ...previousState,
        naics_id: value,
      };
    });
    setModalData((previousState) => {
      let { full_naics, naics_description, naics_id } = previousState;
      return {
        ...previousState,
        full_naics: naicsDefaultOption.key,
        naics_description: naicsDefaultOption.name,
        naics_id: naicsDefaultOption.value,
      };
    });
  };

  const getDaysArray = (month) => {
    // Get the number of days in the month
    const daysInMonth = new Date(2024, month, 0).getDate();

    // Create an array of days
    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      let day = i
      if (i < 10) {
        day = '0' + i.toString()
      }
      daysArray.push(day);
    }

    return daysArray;
  }

  const handleFYEDate = (monthOrDay, value) => {
    setInputForm((previousState) => {

      if (monthOrDay === 'month') {
        return {
          ...previousState,
          fye_month: value,
        };
      } else {
        return {
          ...previousState,
          fye_day: value,
        };
      }
    });
  }

  const handleChangeNumOfProsByYear = (e, year) => {
    let newProVal = Number(e.target.value)

    setInputForm((prevState) => {
      let newProsByYear = prevState.number_of_pros_by_year;
      newProsByYear[year] = newProVal
      return {
        ...prevState,
        number_of_pros_by_year: newProsByYear,
      };
    });
  };

  const handleChangePartner = (e) => {
    setInputForm((previousState) => {
      return {
        ...previousState,
        partners: e.target.value,
      };
    });
  };

  const handleChangeOffice = (e) => {
    setInputForm((previousState) => {
      return {
        ...previousState,
        office: e.target.value,
      };
    });
  };

  const handleChangeStatus = (value) => {
    setInputForm((previousState) => {
      return {
        ...previousState,
        status: value,
      };
    });
  };

  const handleChangeAssignedTo = (value) => {
    let userSelected = users.find((user) => {
      if (user.email == value) {
        return user;
      }
    });
    setInputFormForAssignedTo((previousState) => {
      return {
        ...previousState,
        first_name: userSelected.first_name,
        last_name: userSelected.last_name,
        email: userSelected.email,
        company_id: modalData.id,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let companyId = backEndIdModalData
    let emailLength = inputFormForAssignedTo.email.length
    let assignedTos = inputFormForAssignedTo
    let changes = confirm('Are you sure you want to save new changes?');
    if (changes === true) {
      setIsLoading(true);

      const companyUpdates = {
        name: inputForm.name,
        real_name: inputForm.real_namename,
        subdomain: inputForm.subdomain,
        naics_id: inputForm.naics_id,
        number_of_pros: inputForm.number_of_pros,
        number_of_pros_by_year: inputForm.number_of_pros_by_year,
        partners: inputForm.partners,
        office: inputForm.office,
        status: inputForm.status,
        fiscal_year_end: '2024' + '/' + inputForm.fye_month + '/' + inputForm.fye_day
      }

      try {
        const res = await fetch(`/api/v1/companies/${companyId}`, {
          method: 'PATCH',
          headers: {
            'X-CSRF-Token': window.token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(companyUpdates),
        })

        if (!res.ok) {
          throw new Error('Network response was not ok.');
        } else {
          if (emailLength > 5) {
            const resForAssignedTo = await fetch(`/assigned_tos`, {
              method: 'POST',
              headers: {
                'X-CSRF-Token': window.token,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(assignedTos),
            })

            if (!resForAssignedTo.ok) {
              throw new Error('Network response was not ok.');
            }
            //const resJsonForAssignedTo = await resForAssignedTo.json()
          }

          if (activeTab === 'all-clients') {
            setData(prevState => {
              let newClientData = data.clientsOnlyData.map(c => {
                if (c.id === modalData.id) {
                  let selectedClient = { ...c, ...inputForm }
                  selectedClient['full_naics'] = modalData['full_naics']
                  selectedClient['naics'] = modalData['naics']
                  selectedClient['naics_description'] = modalData['naics_description']
                  selectedClient['naics_id'] = modalData['naics_id']
                  return selectedClient
                } else {
                  return c
                }
              })

              return {
                ...prevState,
                clientsOnlyData: newClientData
              }
            })
          }
          setIsLoading(false);
          alert('Changes saved!');
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        alert('Sorry something went wrong. Changes were not saved properly.');
      }
    }
  };

  const handleMultipleCompaniesUpdate = async (e) => {
    e.preventDefault()

    let changes = confirm('Are you sure you want to save new changes?');
    if (changes === true) {
      setIsLoading(true)
      const multipleData = {
        ids: rowsSelectedData.data,
        selected_property: rowsSelectedData.propertySelected,
        new_value: rowsSelectedData.propertyValues[rowsSelectedData.propertySelected]
      }

      try {
        const res = await fetch(`/api/v1/multiple_companies_update`, {
          method: 'PATCH',
          headers: {
            'X-CSRF-Token': window.token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(multipleData),
        })

        if (!res.ok) {
          throw new Error('Network response was not ok.');
        } else {
          if (activeTab === 'all-clients') {
            getClientsData()
          }

          setIsLoading(false);
          alert('Changes saved!');
        }
      } catch (error) {
        console.log(error)
        setIsLoading(false);
        alert('Sorry something went wrong. Changes were not saved properly.');
      }
    }
  };

  const deleteAssignedTo = async (assignedToId) => {
    let companyId = backEndIdModalData
    let changes = confirm('Are you sure you want to delete?');

    try {
      if (changes === true) {
        setIsLoading(true);
        const res = await fetch(`/assigned_tos/${assignedToId}`, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': window.token,
            'Content-Type': 'application/json',
          },
        })

        if (!res.ok) {
          throw new Error('Network response was not ok.');
        } else {
          const updatedCompany = await fetch(`/api/v1/companies/${companyId}`)
          const updatedCompanyRes = await updatedCompany.json()

          setModalData(prevState => {
            return {
              ...prevState,
              ...updatedCompanyRes.company
            }
          })
          getClientsData()
          setIsLoading(false);
          alert('Changes saved!');
        }

      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alert('Sorry something went wrong. Changes were not saved properly.');
    }
  };

  const usersToSelectFcn = () => {
    let usersToPickFrom = [
      { userId: '', firstName: '', lastName: '', email: '' },
    ];
    users.map((user) => {
      if (
        modalData.name !== 'DemoFirm' &&
        user.first_name !== 'Glenn' &&
        user.last_name !== 'Dunlap'
      ) {
        let helperObj = {};
        helperObj.userId = user.id;
        helperObj.firstName = user.first_name;
        helperObj.lastName = user.last_name;
        helperObj.email = user.email;
        usersToPickFrom.push(helperObj);
      } else if (modalData.name === 'DemoFirm') {
        let helperObj = {};
        helperObj.userId = user.id;
        helperObj.firstName = user.first_name;
        helperObj.lastName = user.last_name;
        helperObj.email = user.email;
        usersToPickFrom.push(helperObj);
      }
    });
    return usersToPickFrom;
  };

  const naicsToSelectFcn = () => {
    let naicsToPickFrom = [];
    fetchedNaicsList.map((element) => {
      if (
        element[1].length >= 6 &&
        element[1].length <= 11 &&
        element[1] !== 'master'
      ) {
        let helperObj = {};
        helperObj.key = element[1];
        helperObj.value = element[0];
        helperObj.name = element[2];
        naicsToPickFrom.push(helperObj);
      } else if (element[1] === 'vets' || element[1] === 'SaaS' || element[1] === 'saas') {
        let helperObj = {};
        helperObj.key = element[1];
        helperObj.value = element[0];
        helperObj.name = element[2];
        naicsToPickFrom.push(helperObj);
      }
    });
    return naicsToPickFrom;
  };

  if (modalData.pending_calcs) {
    subscribe();
  }

  const notify = (message) => {
    mountNotifications()(message);
  };

  const xeroSubscribe = () => {
    App[`job_Xero`] = App.cable.subscriptions.create(
      {
        channel: 'JobsChannel',
        job_type: 'XeroExtractorWorker',
      },
      {
        received: (res) => {
          console.log(res, 'Xerores')
          if (res.error) {
            console.log(res);
            mountNotifications('notification-container-target', false)(res.error)
          } else if (res.finish) {
            mountNotifications('notification-container-target', false)(`Xero connection successful for `);
          }
        },
      }
    );
  };

  const qboSubscribe = () => {
    App[`job_QBO`] = App.cable.subscriptions.create(
      {
        channel: 'JobsChannel',
        job_type: 'QuickBooksExtractorWorker',
      },
      {
        received: (res) => {
          console.log(res, 'QBOres')
          if (res.error) {
            console.log(res);
            mountNotifications('notification-container-target', false)(res.error)
          } else if (res.finish) {
            mountNotifications('notification-container-target', false)(res.data);
          }
        },
      }
    );
  };

  const sageSubscribe = () => {
    App[`job_SI`] = App.cable.subscriptions.create(
      {
        channel: 'JobsChannel',
        job_type: 'SageIntacctExtractorWorker',
      },
      {
        received: (res) => {
          console.log(res, 'SIres')
          if (res.error) {
            console.log(res);
            mountNotifications('notification-container-target', false)(res.error)
          } else if (res.finish) {
            mountNotifications('notification-container-target', false)(res.data);
          }
        },
      }
    );
  };

  const cleanUpClientApiConnection = (clientID) => {
    const updatedClients = (oldData) => {
      return oldData.map(c => {
        if (c.id === clientID) {
          c.api_connections = []
        }
        return c
      })
    }

    setModalData(prevState => {
      return {
        ...prevState,
        api_connections: []
      }
    })
    setData(prevState => {
      let newClientData = updatedClients(data.clientsOnlyData)
      return {
        ...prevState,
        clientsOnlyData: newClientData
      }
    })
  }

  const handleQboDisconnect = async (clientData) => {
    const resDisconnect = await fetch(`/qbo_disconnect_internal`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': window.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: clientData.id,
      }),
    })

    const resJson = await resDisconnect.json()

    if (resJson.error) {
      mountNotifications()(resJson.error)
    } else if (resJson.success) {
      mountNotifications()(resJson.success);
      cleanUpClientApiConnection(clientData.id)
    }
  }

  const handleIntacctDisconnect = async (clientData) => {
    const resDisconnect = await fetch(`/intacct_disconnect`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': window.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: clientData.id,
      }),
    })
    const resJson = await resDisconnect.json()

    if (resJson.error) {
      mountNotifications()(resJson.error)
    } else if (resJson.success) {
      mountNotifications()(resJson.success);
      cleanUpClientApiConnection(clientData.id)
    }
  }

  const handleXeroDisconnect = async (clientData) => {
    const resDisconnect = await fetch(`/xero_disconnect`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': window.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: clientData.id,
        state: `pvduniq${clientData.id}`
      }),
    })
    const resJson = await resDisconnect.json()

    if (resJson.error) {
      mountNotifications()(resJson.error)
    } else if (resJson.success) {
      mountNotifications()(resJson.success);
      cleanUpClientApiConnection(clientData.id)
    }
  }

  const subscribe = () => {
    if (modalData) {
      App[`job_${modalData.id}`] = App.cable.subscriptions.create(
        {
          channel: 'JobsChannel',
          job_type: 'CompanyCalculationWorker',
        },
        {
          received: (res) => {
            if (res.error) {
              console.log(res);
            } else if (res.finish) {
              mountNotifications()(`Calculations Finished for ${modalData.name}`);
              updateStep(modalData.id);
            }
          },
        }
      );
    }
  };

  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement(document.getElementById('client-container-target'));
  const getMuiTheme = createTheme({
    components: {
      MuiTablePagination: {
        styleOverrides: {
          root: {
            '&:last-child': {
              paddingRight: '20px',
            }
          },
          selectLabel: {
            fontSize: '12px',
            margin: 0
          },
          displayedRows: {
            fontSize: '12px',
            margin: 0
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none !important',
            display: 'flex',
            flexDirection: 'column-reverse',
            paddingLeft: '10px'
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            order: 2
          }
        }
      },
      MUIDataTableToolbarSelect: {
        styleOverrides: {
          root: {
            display: 'none'
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            order: 1
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '25px',
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: '20px'
          }
        }
      },
      MUIDataTableResize: {
        styleOverrides: {
          root: {
            zIndex: 0,
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          contentWrapper: {
            justifyContent: 'center'
          },
          data: {
            fontSize: '12px',
            paddingBottom: 0,
            textTransform: 'none'
          },
          fixedHeader: {
            zIndex: 0,
          },
          toolButton: {
            padding: 'none',
            justifyContent: 'center'
          },
          tooltip: {
            fontSize: '14px',
          }
        }
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            zIndex: 1,
          },
          fixedLeft: {
            zIndex: 0,
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            zIndex: 1,
          },
          root: {
            borderBottom: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center',
            fontSize: '12px',
            padding: '1px',
            textAlign: 'center',
          },
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '3px',
            maxWidth: '100px'
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            zIndex: 0,
            fontSize: '13px',
            minHeight: '20px',
            padding: '0 3px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
        }
      },
      MuiTableHead: {
        styleOverrides: {
          head: {
            display: 'flex'
          },
          root: {
            position: 'sticky',
            top: '0px',
            background: 'white',
            zIndex: '2'
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          head: {
            display: 'flex',
            borderTop: '1px solid #e0e0e0',
            borderBottom: '1px solid #e0e0e0'
          },
          root: {
            display: 'flex',
            '&:nth-child(even)': {
              backgroundColor: '#F5F5F5',
            },
            '&$hover:hover': {
              backgroundColor: '#BFE0AE',
            },
          },
          footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            borderBottom: '1px solid #e0e0e0'
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: '12px',
          },
          body1: {
            fontSize: '16px',
          },
          body2: {
            fontSize: '12px',
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '1.2em'
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: '0.95em'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '1.1em'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '0.9em'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: '1em'
          }
        }
      }
    }
  });

  const openModal = (rowData) => {
    let naicsDefaultOption = naicsOptions.find((option) => {
      if (option.key == rowData.full_naics) {
        return option;
      }
    });
    if (naicsDefaultOption) {
      rowData.naics_id = naicsDefaultOption.value;
    }

    setModalIsOpen(true);
    setModalData(rowData);
    setInputForm({
      name: rowData.name,
      real_name: rowData.real_name,
      subdomain: rowData.subdomain,
      naics_id: rowData.naics_id,
      number_of_pros: rowData.number_of_pros,
      number_of_pros_by_year: rowData.number_of_pros_by_year && Object.keys(rowData.number_of_pros_by_year).length > 0 ? rowData.number_of_pros_by_year : numOfPros,
      partners: rowData.partners,
      office: rowData.office,
      status: rowData.status,
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeEditMultipleModal = () => {
    setEditMultipleModalIsOpen(false);
  };

  const hrefBuilder = (subdomain, path) => {
    const domainSplit = document.location.host.split('.');
    domainSplit[0] = subdomain;
    return location.protocol + '//' + domainSplit.join('.') + path;
  };

  const updateMCColumns = async (newWidths) => {
    try {
      const response = await fetch(`/api/v1/update_mc_columns`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': window.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          columnWidths: newWidths,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // Debounce fetch to delay the call until user stops resizing
  const debounceFetch = (newWidths) => {
    if (resizeTimeout.current) {
      clearTimeout(resizeTimeout.current); // Clear the previous timeout if resizing continues
    }

    resizeTimeout.current = setTimeout(() => {
      updateMCColumns(newWidths);
    }, 1500);
  };

  const handleResize = (index, newSize) => {
    const newWidths = { ...columnWidths };
    newWidths[index] = newSize;
    setColumnWidths(newWidths);
    debounceFetch(newWidths)
  };

  const handleAllSort = (name = 'name') => {
    let newDirection = 'none'

    if (name !== allTableState.sortOrder.name || allTableState.sortOrder.direction === 'none' || (name === 'name' && allTableState.sortOrder.direction === 'desc')) {
      newDirection = 'asc'
    } else if (name === allTableState.sortOrder.name && allTableState.sortOrder.direction === 'asc') {
      newDirection = 'desc'
    } else if (name === allTableState.sortOrder.name && allTableState.sortOrder.direction === 'desc') {
      newDirection = 'none'
    }

    let sortOrder = {
      name: name,
      direction: newDirection
    }
    setAllTableState(prevState => {
      return { ...prevState, sortOrder: sortOrder }
    })
    changeSortOrder(allTableState.page, sortOrder)
  }

  const allClientColumns = [
    // column 0
    {
      name: 'name',
      label: 'Client ID',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-name'
              width={columnWidths.name}
              height={25}
              onResize={(e, { size }) => handleResize("name", size.width)}
            >
              <th
                style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.name }}
              >
                <div
                  style={{ cursor: "pointer", fontWeight: 'bold' }}
                  onClick={() => handleAllSort('name')}
                >{column.label}</div>
                {allTableState.sortOrder.name === 'name' && allTableState.sortOrder.direction === "asc" && <ArrowUpward fontSize="small" />}
                {allTableState.sortOrder.name === 'name' && allTableState.sortOrder.direction === "desc" && <ArrowDownward fontSize="small" />}
              </th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: { borderRight: '1px solid #e0e0e0', width: columnWidths.name }
        }),
        customBodyRender: (value, i) => {
          let rowData = i['rowData']
          let currentSubdomain = rowData[2];
          return value ? (
            <a className='mc-report-link' href={hrefBuilder(currentSubdomain, '/reports/money')}>
              {value}
            </a>
          ) : null
        }
      },
    },
    // column 1
    {
      name: 'real_name',
      label: 'Client Name',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-real_name'
              width={columnWidths.real_name}
              height={25}
              onResize={(e, { size }) => handleResize("real_name", size.width)}
            >
              <th
                style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.real_name }}
              >
                <div
                  style={{ cursor: "pointer", fontWeight: 'bold' }}
                  onClick={() => handleAllSort('real_name')}
                >{column.label}</div>
                {allTableState.sortOrder.name === 'real_name' && allTableState.sortOrder.direction === "asc" && <ArrowUpward fontSize="small" />}
                {allTableState.sortOrder.name === 'real_name' && allTableState.sortOrder.direction === "desc" && <ArrowDownward fontSize="small" />}
              </th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: { borderRight: '1px solid #e0e0e0', width: columnWidths.real_name }
        })
      },
    },
    //this column controls the slide out div
    // data used in 'label: 'Report Link' column as well
    // column 2
    {
      name: 'subdomain',
      label: ' ',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sort: false,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-subdomain'
              width={columnWidths.subdomain}
              height={25}
              onResize={(e, { size }) => handleResize("subdomain", size.width)}
            >
              <th style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.subdomain }}><div>{column.label}</div></th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: { borderRight: '1px solid #e0e0e0', width: columnWidths.subdomain }
        }),
        customBodyRender: (value, i) => {
          let client = data.clientsOnlyData.find(client => client.subdomain === value);
          if (client) {
            let c = i['rowIndex'] === 0 ? 'table-btn first-table-btn' : 'table-btn'
            return (
              <button className={c} onClick={() => openModal(client)}>
                Details
              </button>
            );
          }
        },
      },
    },
    // uses data from other non-displayed columns
    // column 3
    {
      name: 'unlocked',
      label: '  ',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sort: false,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-unlocked'
              width={columnWidths.unlocked}
              height={25}
              onResize={(e, { size }) => handleResize("unlocked", size.width)}
            >
              <th style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.unlocked }}><div>{column.label}</div></th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: { borderRight: '1px solid #e0e0e0', width: columnWidths.unlocked }
        }),
        customBodyRender: (value, i) => {
          let rowData = i['rowData']
          let currentBackEndID = rowData[15];
          let currentSubdomain = rowData[2];
          let firstReportLink = i['rowIndex'] === 0 ? 'report-link-btn' : ''
          return value ? (<FontAwesomeIcon icon={faUnlock} className='icon-green' />) : (
            <a
              href={
                (data.report_credits > 0
                  ? '/unlock_reports/'
                  : '/purchase_reports/') + currentBackEndID
              }
              className={firstReportLink}
            >
              <FontAwesomeIcon icon={faLock} />
            </a>
          );
        },
      },
    },
    // uses data from 'label: 'naicsDescription' column
    // column 4
    {
      name: 'full_naics',
      label: 'NAICS',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-full_naics'
              width={columnWidths.full_naics}
              height={25}
              onResize={(e, { size }) => handleResize("full_naics", size.width)}
            >
              <th
                style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.full_naics }}
              >
                <div
                  style={{ cursor: "pointer", fontWeight: 'bold' }}
                  onClick={() => handleAllSort('full_naics')}
                >{column.label}</div>
                {allTableState.sortOrder.name === 'full_naics' && allTableState.sortOrder.direction === "asc" && <ArrowUpward fontSize="small" />}
                {allTableState.sortOrder.name === 'full_naics' && allTableState.sortOrder.direction === "desc" && <ArrowDownward fontSize="small" />}
              </th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: { borderRight: '1px solid #e0e0e0', width: columnWidths.full_naics }
        }),
        customBodyRender: (value, { rowData }) => {
          const naics_description = rowData[14];
          return (
            <Tooltip
              title={<span style={{ fontSize: '14px' }}>{naics_description}</span>}
              PopperProps={{
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'window',
                    },
                  },
                ],
              }}
            >
              <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {value}
              </div>
            </Tooltip>
          );
        },
      },
    },
    //column 5
    {
      name: 'partners',
      label: 'Partner',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-partners'
              width={columnWidths.partners}
              height={25}
              onResize={(e, { size }) => handleResize("partners", size.width)}
            >
              <th
                style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.partners }}
              >
                <div
                  style={{ cursor: "pointer", fontWeight: 'bold' }}
                  onClick={() => handleAllSort('partners')}
                >{column.label}</div>
                {allTableState.sortOrder.name === 'partners' && allTableState.sortOrder.direction === "asc" && <ArrowUpward fontSize="small" />}
                {allTableState.sortOrder.name === 'partners' && allTableState.sortOrder.direction === "desc" && <ArrowDownward fontSize="small" />}
              </th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: { borderRight: '1px solid #e0e0e0', width: columnWidths.partners }
        }),
      }
    },
    // column 6
    {
      name: 'office',
      label: 'Office',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-office'
              width={columnWidths.office}
              height={25}
              onResize={(e, { size }) => handleResize("office", size.width)}
            >
              <th
                style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.office }}
              >
                <div
                  style={{ cursor: "pointer", fontWeight: 'bold' }}
                  onClick={() => handleAllSort('office')}
                >{column.label}</div>
                {allTableState.sortOrder.name === 'office' && allTableState.sortOrder.direction === "asc" && <ArrowUpward fontSize="small" />}
                {allTableState.sortOrder.name === 'office' && allTableState.sortOrder.direction === "desc" && <ArrowDownward fontSize="small" />}
              </th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: { borderRight: '1px solid #e0e0e0', width: columnWidths.office }
        }),
      },
    },
    // column 7
    {
      name: 'state',
      label: 'State',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-state'
              width={columnWidths.state}
              height={25}
              onResize={(e, { size }) => handleResize("state", size.width)}
            >
              <th
                style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.state }}
              >
                <div
                  style={{ cursor: "pointer", fontWeight: 'bold' }}
                  onClick={() => handleAllSort('state')}
                >{column.label}</div>
                {allTableState.sortOrder.name === 'state' && allTableState.sortOrder.direction === "asc" && <ArrowUpward fontSize="small" />}
                {allTableState.sortOrder.name === 'state' && allTableState.sortOrder.direction === "desc" && <ArrowDownward fontSize="small" />}
              </th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: { borderRight: '1px solid #e0e0e0', width: columnWidths.state }
        }),
      },
    },
    // column 8
    {
      name: 'total_revenue',
      label: 'Revenue',
      options: {
        filter: false,
        sortThirdClickReset: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-total_revenue'
              width={columnWidths.total_revenue}
              height={25}
              onResize={(e, { size }) => handleResize("total_revenue", size.width)}
            >
              <th
                style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.total_revenue }}
              >
                <div
                  style={{ cursor: "pointer", fontWeight: 'bold' }}
                  onClick={() => handleAllSort('total_revenue')}
                >{column.label}</div>
                {allTableState.sortOrder.name === 'total_revenue' && allTableState.sortOrder.direction === "asc" && <ArrowUpward fontSize="small" />}
                {allTableState.sortOrder.name === 'total_revenue' && allTableState.sortOrder.direction === "desc" && <ArrowDownward fontSize="small" />}
              </th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: {
            borderRight: '1px solid #e0e0e0',
            width: columnWidths.total_revenue,
            justifyContent: 'flex-end',
          },
        }),
        sort: true,
        customBodyRender: (value) => {
          return parseMoney(value);
        },
      },
    },
    // column 9
    {
      name: 'total_impact',
      label: 'Top 5 Opps',
      options: {
        filter: false,
        sortThirdClickReset: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-total_impact'
              width={columnWidths.total_impact}
              height={25}
              onResize={(e, { size }) => handleResize("total_impact", size.width)}
            >
              <th
                style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.total_impact }}
              >
                <div
                  style={{ cursor: "pointer", fontWeight: 'bold' }}
                  onClick={() => handleAllSort('total_impact')}
                >{column.label}</div>
                {allTableState.sortOrder.name === 'total_impact' && allTableState.sortOrder.direction === "asc" && <ArrowUpward fontSize="small" />}
                {allTableState.sortOrder.name === 'total_impact' && allTableState.sortOrder.direction === "desc" && <ArrowDownward fontSize="small" />}
              </th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: {
            borderRight: '1px solid #e0e0e0',
            width: columnWidths.total_impact,
            justifyContent: 'flex-end',
          },
        }),
        sort: true,
        customBodyRender: (value) => {
          return parseMoney(value);
        },
      },
    },
    // uses data from 'label: 'Type' column
    // column 10
    {
      name: 'data_through_year',
      label: 'Data Through',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-data_through_year'
              width={columnWidths.data_through_year}
              height={25}
              onResize={(e, { size }) => handleResize("data_through_year", size.width)}
            >
              <th
                style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.data_through_year }}
              >
                <div
                  style={{ cursor: "pointer", fontWeight: 'bold' }}
                  onClick={() => handleAllSort('data_through_year')}
                >{column.label}</div>
                {allTableState.sortOrder.name === 'data_through_year' && allTableState.sortOrder.direction === "asc" && <ArrowUpward fontSize="small" />}
                {allTableState.sortOrder.name === 'data_through_year' && allTableState.sortOrder.direction === "desc" && <ArrowDownward fontSize="small" />}
              </th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: { borderRight: '1px solid #e0e0e0', width: columnWidths.data_through_year }
        }),
      },
    },
    // column 11
    {
      name: 'balanced',
      label: 'Balanced',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-balanced'
              width={columnWidths.balanced}
              height={25}
              onResize={(e, { size }) => handleResize("balanced", size.width)}
            >
              <th
                style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.balanced }}
              >
                <div
                  style={{ cursor: "pointer", fontWeight: 'bold' }}
                  onClick={() => handleAllSort('balanced')}
                >{column.label}</div>
                {allTableState.sortOrder.name === 'balanced' && allTableState.sortOrder.direction === "asc" && <ArrowUpward fontSize="small" />}
                {allTableState.sortOrder.name === 'balanced' && allTableState.sortOrder.direction === "desc" && <ArrowDownward fontSize="small" />}
              </th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: { borderRight: '1px solid #e0e0e0', width: columnWidths.balanced }
        }),
        customBodyRender: (value) => {
          if (value == 'no_file') {
            return '-'
          } else {
            return value ? <div className='good-check'><CheckMark /></div> : <div className='bad-x'><XMark /></div>
          }
        },
      },
    },
    // column 12
    {
      name: 'is_monthly',
      label: 'Type',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-is_monthly'
              width={columnWidths.is_monthly}
              height={25}
              onResize={(e, { size }) => handleResize("is_monthly", size.width)}
            >
              <th
                style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.is_monthly }}
              >
                <div
                  style={{ cursor: "pointer", fontWeight: 'bold' }}
                  onClick={() => handleAllSort('is_monthly')}
                >{column.label}</div>
                {allTableState.sortOrder.name === 'is_monthly' && allTableState.sortOrder.direction === "asc" && <ArrowUpward fontSize="small" />}
                {allTableState.sortOrder.name === 'is_monthly' && allTableState.sortOrder.direction === "desc" && <ArrowDownward fontSize="small" />}
              </th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: { borderRight: '1px solid #e0e0e0', width: columnWidths.is_monthly }
        }),
        customBodyRender: (value) => {
          let dash = '-';
          if (value !== null) {
            return value ? 'Monthly' : 'Annual';
          } else if (value === null || value === undefined) {
            return dash;
          }
        },
      },
    },
    // column 13
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <Resizable
              key='mc-status'
              width={columnWidths.status}
              height={25}
              onResize={(e, { size }) => handleResize("status", size.width)}
            >
              <th
                style={{ fontSize: '12px', fontWeight: 'normal', textAlign: 'center', width: columnWidths.status }}
              >
                <div
                  style={{ cursor: "pointer", fontWeight: 'bold' }}
                  onClick={() => handleAllSort('status')}
                >{column.label}</div>
                {allTableState.sortOrder.name === 'status' && allTableState.sortOrder.direction === "asc" && <ArrowUpward fontSize="small" />}
                {allTableState.sortOrder.name === 'status' && allTableState.sortOrder.direction === "desc" && <ArrowDownward fontSize="small" />}
              </th>
            </Resizable>
          );
        },
        setCellProps: () => ({
          style: {
            borderRight: '1px solid #e0e0e0',
            width: columnWidths.status,
            textAlign: 'center',
          },
        }),
        customBodyRender: (value) => {
          let status = '-'
          if (value) {
            status = value
            return status;
          } else {
            return status;
          }
        },
      },
    },
    // column 14
    // columns bellow are not displayed, their data is used in the columns above.
    // data used in 'label: 'NAICS'' column
    {
      name: 'naics_description',
      label: 'naicsDescription',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    // column not displayed. data used in 'label: 'Report Link'' column
    // column 15
    {
      name: 'id',
      label: 'backEndID',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    // column 16
    {
      name: 'monthly_most_recent_calc',
      label: 'monthlyMostRecentCalc',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'size',
      label: 'Size',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'region',
      label: 'Region',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'api_connections',
      label: 'Connections',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    }
  ];

  const dataProps = data.clientsOnlyData;

  const changePage = async (tableType, page, rowsPerPage) => {

    setIsLoading(true);

    if (tableType === 'allClients') {
      const filtered = await fetch(`/api/v1/companies/?page=${page}&rows=${rowsPerPage}&sort=${allTableState.sortOrder.name}&direction=${allTableState.sortOrder.direction}&filters=${allTableState.filters}&search=${allTableState.searchText}`)
      const filteredRes = await filtered.json()

      if (page === 1 && filteredRes.companies_list_paginated.search_count < rowsPerPage) {
        setAllTableState(prevState => {
          return { ...prevState, page: filteredRes.page }
        })
        setData((prevState) => {
          return { ...prevState, clientsOnlyData: filteredRes.companies_list_paginated.clients, totalPages: filteredRes.total_pages, count: filteredRes.companies_list_paginated.search_count }
        })
      } else {
        setAllTableState(prevState => {
          return { ...prevState, page: filteredRes.page }
        })
        setData((prevState) => {
          return { ...prevState, clientsOnlyData: filteredRes.companies_list_paginated.clients, totalPages: filteredRes.total_pages, count: filteredRes.companies_list_paginated.client_size }
        })
      }
    }

    setIsLoading(false)
  };

  const changeSortOrder = async (page, sortOrder) => {
    setIsLoading(true);

    const filtered = await fetch(`/api/v1/companies/?page=${allTableState.page}&rows=${allTableState.rowsPerPage}&sort=${sortOrder.name}&direction=${sortOrder.direction}&filters=${allTableState.filters}&search=${allTableState.searchText}`)
    const filteredRes = await filtered.json()

    if (page === 1 && filteredRes.companies_list_paginated.search_count < allTableState.rowsPerPage) {
      setAllTableState(prevState => {
        return { ...prevState, page: filteredRes.page }
      })
      setData((prevState) => {
        return { ...prevState, clientsOnlyData: filteredRes.companies_list_paginated.clients, totalPages: filteredRes.total_pages, count: filteredRes.companies_list_paginated.search_count }
      })
    } else {
      setAllTableState(prevState => {
        return { ...prevState, page: filteredRes.page }
      })
      setData((prevState) => {
        return { ...prevState, clientsOnlyData: filteredRes.companies_list_paginated.clients, totalPages: filteredRes.total_pages, count: filteredRes.companies_list_paginated.client_size }
      })
    }

    setIsLoading(false)
  };

  const handleFilterSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true);
    let filterArr = []

    for (let filter in selectedFilters) {
      if (selectedFilters[filter] !== 'All') {
        let filterStr = filter !== 'naics_code' ? `${filter}='${selectedFilters[filter]}'` : `${filter}=${selectedFilters[filter]}`
        filterArr.push(filterStr)
      }
    }

    let filterString = filterArr.length > 0 ? filterArr.flat().join(':') : null

    setAllTableState(prevState => {
      return { ...prevState, filters: filterString }
    })

    const filtered = await fetch(`/api/v1/companies/?page=${allTableState.page}&rows=${allTableState.rowsPerPage}&sort=${allTableState.sortOrder.name}&direction=${allTableState.sortOrder.direction}&filters=${filterString}&search=${allTableState.searchText}`)
    const filteredRes = await filtered.json()

    if (filteredRes.page === 1 && filteredRes.companies_list_paginated.search_count < allTableState.rowsPerPage) {
      setAllTableState(prevState => {
        return { ...prevState, page: filteredRes.page }
      })
      setData((prevState) => {
        return { ...prevState, page: filteredRes.page, totalPages: filteredRes.total_pages, clientsOnlyData: filteredRes.companies_list_paginated.clients, count: filteredRes.companies_list_paginated.search_count }
      })
    } else {
      setAllTableState(prevState => {
        return { ...prevState, page: filteredRes.page }
      })
      setData((prevState) => {
        return { ...prevState, page: filteredRes.page, totalPages: filteredRes.total_pages, clientsOnlyData: filteredRes.companies_list_paginated.clients, count: filteredRes.companies_list_paginated.client_size }
      })
    }
    setIsLoading(false);
  };

  const handleSearch = async (tableType, page, text = null) => {
    setIsLoading(true);
    if (tableType === 'allClients') {
      let queryText = `/api/v1/companies/?page=${page}&rows=${allTableState.rowsPerPage}}&sort=${allTableState.sortOrder.name}&direction=${allTableState.sortOrder.direction}&filters=${allTableState.filters}&search=${text}`
      const filtered = await fetch(queryText)
      const filteredRes = await filtered.json()
      if (filteredRes.page === 1 && filteredRes.companies_list_paginated.search_count < allTableState.rowsPerPage) {
        setAllTableState(prevState => {
          return { ...prevState, page: filteredRes.page }
        })
        setData((prevState) => {
          return { ...prevState, clientsOnlyData: filteredRes.companies_list_paginated.clients, totalPages: filteredRes.total_pages, count: filteredRes.companies_list_paginated.search_count }
        })
      } else {
        setAllTableState(prevState => {
          return { ...prevState, page: filteredRes.page }
        })
        setData((prevState) => {
          return { ...prevState, clientsOnlyData: filteredRes.companies_list_paginated.clients, totalPages: filteredRes.total_pages, count: filteredRes.companies_list_paginated.client_size }
        })
      }
    }

    setIsLoading(false);
  }

  const handleRowsSelectionEdits = (rowsSelected) => {
    setRowsSelectedData(prevState => {
      let allSelectedData = []

      rowsSelected.forEach((rowIndex) => {
        let selectedClient = data.clientsOnlyData.find((c, i) => {
          if (i === rowIndex) {
            return c
          }
        })

        allSelectedData.push(selectedClient.id)
      })

      let displayEditSection = allSelectedData.length > 0

      return {
        ...prevState,
        rowIndexes: rowsSelected,
        data: allSelectedData,
        numOfRowSelected: allSelectedData.length,
        displayEditRowSection: displayEditSection
      }
    })
  }

  const handleApiConnectionsInfo = async (tab) => {
    if (tab) { setActiveTab(tab) }
    const res = await fetch(`/api/v1/company_connections`, {
      headers: {
        'X-CSRF-Token': window.token,
      }
    })
    const json = await res.json();

    setNotificationData(() => {
      return {
        notifications: json.notifications,
      }
    })
  }

  const allOptions = {
    searchText: allTableState.searchText,
    print: false,
    download: false,
    filter: false,
    responsive: 'standard',
    selectableRows: "multiple",
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    resizableColumns: data.clientsOnlyData[0] ? true : false,
    rowsPerPage: allTableState.rowsPerPage,
    rowsPerPageOptions: [25, 50, 100],
    count: data.count,
    page: allTableState.page - 1,
    confirmFilters: true,
    serverSide: true,
    searchPlaceholder: 'Search by Client ID/Name',
    rowsSelected: rowsSelectedData.rowIndexes,
    customSearchRender: debounceSearchRender(1000),
    customToolbarSelect: () => { },
    onTableChange: (action, allTableState) => {
      switch (action) {
        case 'changePage':
          changePage('allClients', allTableState.page + 1, allTableState.rowsPerPage);
          break;
        case 'sort':
          setAllTableState(prevState => {
            return { ...prevState, sortOrder: allTableState.sortOrder }
          })
          changeSortOrder(allTableState.page + 1, allTableState.sortOrder)
          break;
        case 'changeRowsPerPage':
          setAllTableState(prevState => {
            return { ...prevState, rowsPerPage: allTableState.rowsPerPage }
          })
          changePage('allClients', allTableState.page + 1, allTableState.rowsPerPage);
          break;
        case 'search':
          setAllTableState(prevState => {
            return { ...prevState, searchText: allTableState.searchText }
          })
          handleSearch('allClients', allTableState.page + 1, allTableState.searchText)
          break;
        default:
          break;
      }
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      // rowsSelected returns array of indexes of selected rows
      handleRowsSelectionEdits(rowsSelected)
    }
  };

  const addMap = () => {
    setModalData((prevState) => {
      return { ...prevState, step_start: 'map' };
    });
  };
  const addFdu = (fdu) => {
    addMap();
    setFdu(fdu);
  };
  const updateStep = (id) => {
    fetch(`/companies/${id}/step`)
      .then((response) => {
        return response.json();
      })
      .then(function (step) {
        setModalData((prevState) => {
          return { ...prevState, step_start: step };
        });
      });
  };

  const statusOptions = ['', 'Active', 'Prospect', 'Inactive'];

  let naicsOptions = naicsToSelectFcn();
  let clientIDModalData = modalData.name;
  let naicsCodeModalData = modalData.full_naics;
  let naicsDescriptionModalData = modalData.naics_description;
  let numOfProsModalData = modalData.number_of_pros;
  let backEndIdModalData = modalData.id;
  let isMonthlyModalData = modalData.month;
  let partnerModalData = modalData.partners;
  let fyeModalData = modalData.fiscal_year_end ? modalData.fiscal_year_end : '';
  let fyeMonth = inputForm.fye_month ? inputForm.fye_month : fyeModalData.slice(5, 7)
  let fyeDay = inputForm.fye_day ? inputForm.fye_day : fyeModalData.slice(8, 10)

  let daysInMonth = getDaysArray(Number(fyeMonth))
  let proName = naicsCodeModalData && naicsCodeModalData.startsWith('6212') ? 'Dentists' : 'Doctors'

  const Select = ({ options, selected, who }) => {
    if (who === 'naics') {
      return (
        <select
          style={{ width: '100%', fontSize: '14px' }}
          defaultValue={selected}
          onChange={({ target: { value } }) => handleChangeNAICS(value)}
        >
          {options.map((option) => (
            <option key={option.key} value={option.value}>
              {`${option.key}-${option.name}`}
            </option>
          ))}
        </select>
      );
    } else if (who === 'status') {
      return (
        <select
          style={{ width: '100%', fontSize: '14px' }}
          defaultValue={selected}
          onChange={({ target: { value } }) => handleChangeStatus(value)}
        >
          {statusOptions.map((option) => (
            <option key={Math.random(700)} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    } else if (who === 'assignedTo') {
      return (
        <select
          style={{ width: '100%', fontSize: '14px' }}
          defaultValue={selected}
          onChange={({ target: { value } }) => {
            handleChangeAssignedTo(value);
          }}
        >
          {assignedToOptions.map((option) => (
            <option key={option.userId} value={option.email}>
              {option.firstName} {option.lastName}
            </option>
          ))}
        </select>
      );
    }
  };

  const handleChangeNumOfPros = (e) => {
    let newProVal = Number(e.target.value)
    setInputForm((prevState) => {
      let newProsByYear = prevState.number_of_pros_by_year
      Object.keys(newProsByYear).forEach(year => {
        newProsByYear[year] = newProVal
      });

      return {
        ...prevState,
        number_of_pros: newProVal,
        number_of_pros_by_year: newProsByYear,
      };
    });
  }

  const prosByYearFormInputs = () => {
    return Object.entries(inputForm['number_of_pros_by_year']).map(val => {
      return (
        <div className='modal-info-number'>
          <label className='modal-label'>
            {`${val[0]}:`}
          </label>
          <input
            type='number'
            placeholder={val[1]}
            value={Number(val[1]).toString()}
            onChange={(e) => handleChangeNumOfProsByYear(e, val[0])}
          />
          <i className='fa fa-pencil edit-icon'></i>
        </div>
      )
    })
  }

  const assignedToOptions = usersToSelectFcn();

  const handleSelectedPropertyValue = (property, value) => {
    setRowsSelectedData((prevState) => {
      let newPropertyValues = prevState.propertyValues

      newPropertyValues[property] = value

      return {
        ...prevState,
        propertyValues: newPropertyValues
      }
    })
  }

  const handleSelectedPropertyForm = (property, value) => {
    setRowsSelectedData((prevState) => {
      return {
        ...prevState,
        propertySelected: property
      }
    })
  }

  const hideQBONotification = async (id) => {
    const res = await fetch(`/hide_notification/${id}`, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': window.token,
        'Content-Type': 'application/json',
      }
    })

    if (!res.ok) {
      console.log(res, 'hideQBOnotiBad')
    }
  }

  const manualRefreshConnect = async (clientData) => {
    const refreshQBO = await fetch(`/qbo_refresh/${clientData.id}`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': window.token,
        'Content-Type': 'application/json',
      }
    })

    const resJson = await refreshQBO.json()

    if (resJson.error) {
      mountNotifications()(resJson.error)
    } else if (resJson.success) {
      setJobIDs(prevState => {
        const newJobs = jobIDs
        newJobs.push(resJson['job_id'])
        return newJobs
      })

      setToastIDs(prevState => {
        const newToasts = toastIDs
        newToasts.push(resJson['job_id'])
        return newToasts
      })

      let companyFullName = clientData.real_name ? `${clientData.name} / ${clientData.real_name}` : clientData.name
      toast(`Refeshing your QuickBooks Online data for ${companyFullName}! You'll be notified when it's complete. Please wait a few minutes before trying to refresh again.`, { toastId: resJson['job_id'], progress: 0.05 });

      let sideKiqJobIDs = jobIDs
      const singleJobInterval = setInterval(() => {
        if (sideKiqJobIDs.includes(resJson['job_id'])) {
          const res = getSidekiqJobStatus(resJson['job_id'], resJson['notification'])
          res.then(function (result) {
            if (result === 'stop') {
              sideKiqJobIDs = sideKiqJobIDs.filter(i => i !== resJson['job_id'])
            }
          })
        } else {
          clearInterval(singleJobInterval)
        }
      }, 7000);
    }
  }

  const UserConnectBtns = (api_connections = []) => {
    const apis = {
      'quickbooks': 'QuickBooks Online',
      'sage_intacct': 'Sage Intacct',
      'xero': 'Xero'
    }

    let connectedToText = api_connections[0] ? apis[api_connections[0]] : 'None'

    if (connectToDisplay) {
      return (
        <div className='connect-to-btns-container'>
          <button className='green-border-small' onClick={() => setConnectToDisplay(false)}><FontAwesomeIcon icon={faArrowLeft} /> Go Back</button>
          {api_connections.includes('quickbooks')
            ? <a className='qbo-btn' onClick={() => handleQboDisconnect(modalData)}>Disconnect QuickBooks Online</a>
            : <a className='qbo-btn' href={hrefBuilder(modalData.subdomain, '/qbo_connect')}>Connect QuickBooks Online</a>}
          {api_connections.includes('sage_intacct')
            ? <a className='sage-intacct-btn' onClick={() => handleIntacctDisconnect(modalData)}>Disconnect from Sage Intacct</a>
            : <a className='sage-intacct-btn' href={hrefBuilder(modalData.subdomain, '/intacct_login')}>Connect to Sage Intacct</a>}
          {api_connections.includes('xero')
            ? <a className='xero-disconnect-btn' onClick={() => handleXeroDisconnect(modalData)}></a>
            : <a className='xero-connect-btn' href={`${xero_connect_url}${modalData.id}`}></a>}
        </div>
      )
    } else {
      return (
        <div className='connect-to-btns-container'>
          {api_connections.includes('quickbooks')
            ? (<div>
              <a className='qbo-btn' onClick={() => manualRefreshConnect(modalData)}>Refresh Connection</a>
              <a className='qbo-btn' onClick={() => handleQboDisconnect(modalData)}>Disconnect QuickBooks Online</a>
              {connectedToText !== 'None' ? <p>Connected To: {connectedToText}</p> : null}
            </div>)
            : null}
          {api_connections.includes('sage_intacct')
            ? <a className='sage-intacct-btn' onClick={() => handleIntacctDisconnect(modalData)}>Disconnect from Sage Intacct</a>
            : null}
          {api_connections.includes('xero')
            ? <a className='xero-disconnect-btn' onClick={() => handleXeroDisconnect(modalData)}></a>
            : null}
          <button className='green-background-btn' onClick={() => setConnectToDisplay(true)}>Connect to Accounting Software</button>
          {plan_access.excel_uploads ? <p style={{ 'fontWeight': 'bold' }}>OR</p> : null}
          {plan_access.excel_uploads
            ? (<DropzoneComponent
              addFdu={addFdu}
              fdu={fdu}
              financialReportType={'trial_balance'}
              hrefBuilder={hrefBuilder}
              id={modalData.id}
              mostRecentFdu={modalData.most_recent_fdu}
              name={modalData.name}
              notify={notify}
              subdomain={modalData.subdomain}
            />)
            : null
          }
        </div>
      )
    }
  }

  const gatherFDUs = async (e, companyID) => {
    e.preventDefault()
    const res = await fetch(`/file_uploads/${companyID}`, {
      method: 'GET'
    })
    const resJson = await res.json()
    if (res.ok) {
      setCurrentFileUploads(() => {
        return resJson.fdus
      })
      setFileUploadsModalIsOpen(true)
    } else {
      mountNotifications()('Something went wrong. Please try again later.')
    }
  }

  const removeFDU = async (e, id) => {
    e.preventDefault()
    const res = await fetch(`/api/v1/financial_data_uploads/${id}`, {
      method: 'DELETE'
    })
    if (res.ok) {
      mountNotifications()('File has been deleted!')
      setCurrentFileUploads(() => {
        let newFDUs = currentFileUploads.filter(f => f.id !== id)
        return newFDUs
      })
    } else {
      mountNotifications()('Sorry something went wrong. Please try again later.')
    }
  }

  const closeFileUploadsModal = () => {
    setFileUploadsModalIsOpen(false)
  }

  const handleMouseEnter = (item) => {
    setActivePopover(item);
  };

  const handleMouseLeave = (item) => {
    if (item === 'leave') {
      setActivePopover(null);
    }
  };

  const openFiltersModal = () => {
    setFiltersModalIsOpen(true)
  }

  const closeFiltersModal = () => {
    setFiltersModalIsOpen(false)
  }

  const renderAllPages = () => {
    let pages = []
    let endReached = data.totalPages > 6 && data.totalPages >= allTableState.page && allTableState.page >= (data.totalPages - 5)
    let previousPageNumber = allTableState.page === 1 ? 1 : allTableState.page - 1
    let nextPageNumber = allTableState.page === data.totalPages ? data.totalPages : allTableState.page + 1
    let firstPage = endReached ? data.totalPages - 5 : allTableState.page
    let pagesDisplay = data.totalPages > 6 ? firstPage + 5 : data.totalPages

    if (data.totalPages < 6) {
      firstPage = 1
      pagesDisplay = data.totalPages
    }

    for (let i = firstPage; i <= pagesDisplay; i++) {
      let styleName = i == allTableState.page ? 'mc-selected-page page-select-btn' : 'page-select-btn'

      pages.push(
        <div key={`page-${i}`} className={styleName} onClick={() => changePage('allClients', i, allTableState.rowsPerPage)}>{i}</div>
      )

    }

    return (
      <div className='mc-total-pages-container'>
        <div className='page-select-btn' key={`page-first`} onClick={() => changePage('allClients', 1, allTableState.rowsPerPage)}>First</div>
        <div className='page-select-btn' key={`page-prev`} onClick={() => changePage('allClients', previousPageNumber, allTableState.rowsPerPage)}>Previous</div>
        {allTableState.page > 1 && data.totalPages > 6 ? <div key={`page-prev-dots`}>...</div> : null}
        {pages}
        {allTableState.page < data.totalPages && data.totalPages > 6 && !endReached ? <div key={`page-next-dots`}>...</div> : null}
        <div className='page-select-btn' key={`page-next`} onClick={() => changePage('allClients', nextPageNumber, allTableState.rowsPerPage)}>Next</div>
        <div className='page-select-btn' key={`page-last`} onClick={() => changePage('allClients', data.totalPages, allTableState.rowsPerPage)}>Last</div>
      </div>
    )
  }

  return (
    <div className='manage-clients-container'>
      {isLoading ? <LoadingOverlay /> : null}
      <div id='sticky-topnav-left-btns'>
        <ExportDropdown
          printOnlyVisibleData={data.clientsOnlyData}
          page={'manageClients'}
          planAccess={plan_access}
          xlsx={true}
        />
      </div>
      <div id='sticky-topnav-right-btns'>
        <TourBtn />
      </div>
      <div id='sticky-side-btns'>
        <span
          className='filters-btn-span hide-on-print'
          onMouseEnter={() => handleMouseEnter('filters')}
          onMouseLeave={() => handleMouseLeave('leave')}
        >
          <button className='filters-btn'><FontAwesomeIcon icon={faAnglesLeft} onClick={() => openFiltersModal()} /></button>
          {activePopover === 'filters' && (
            <div className='filters-popover'>
              Display Filters
            </div>)}
        </span>
      </div>

      <ul id='nav-tabs-manage-clients' className='report-tab-list' role='tablist' >
        <li id='all-clients-tab' className={activeTab === 'all-clients' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => setActiveTab('all-clients')}>
          All Clients
        </li>
        <li id='notifications-tab' className={activeTab === 'notifications' ? 'report-tab-item active' : 'report-tab-item'} role='presentation' onClick={() => handleApiConnectionsInfo('notifications')}>
          Notifications
        </li>
        <div className='manage-clients-header'>
          <div>
            {navBarInfo.company_type === 'parent' && navBarInfo.user_type !== 'Client'
              ? (
                <a
                  className='btn btn-primary manage-subscription-btn'
                  href='/subscriptions'
                >
                  Manage Subscriptions
                </a>
              )
              : null
            }
            <a
              className='btn btn-primary new-client-btn'
              href='/admin/companies/new'
            >
              <i className='fa fa-plus'></i> Add One Client
            </a>
            <a
              className='btn btn-primary bulk-upload-btn'
              href='/companies/bulk_upload'
            >
              <i className='fa fa-plus'></i> Add Multiple Clients
            </a>
            {navBarInfo.user_type === 'Admin'
              ? (
                <a
                  className='btn btn-primary new-client-btn'
                  href='/cp_test_upload'
                >
                  Engagement API
                </a>
              )
              : null
            }
          </div>
          <div className='report-credits'>
            {data.report_credits || 0} Remaining Report Credits
          </div>
        </div>
      </ul>
      <Modal
        className={{
          base: 'client-modal-content',
          afterOpen: 'client-modal-content_after-open',
          beforeClose: 'client-modal-content_before-close',
        }}
        overlayClassName={{
          base: 'overlay-base',
          afterOpen: 'overlay-base_after-open',
          beforeClose: 'overlay-base_before-close',
        }}
        isOpen={filtersModalIsOpen}
        onRequestClose={closeFiltersModal}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={0}
        contentLabel='Manage Clients Filters Modal'
      >
        <div>
          <header className='company-modal-header'>
            <h1 className='client-company-name'>Filters</h1>
          </header>
          <MCDropdownFilter filterOptions={data.filterOptions} handleFilterSubmit={handleFilterSubmit} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
        </div>
      </Modal>
      <div className={activeTab === 'all-clients' ? 'manage-clients-table' : 'hide-section'}>
        {renderAllPages()}
        <Modal
          className={{
            base: 'edit-multiple-clients-content',
            afterOpen: 'edit-multiple-clients-content_after-open',
            beforeClose: 'edit-multiple-clients-content_before-close',
          }}
          overlayClassName={{
            base: 'edit-multiple-clients-overlay-base',
            afterOpen: 'edit-multiple-clients-overlay-base_after-open',
            beforeClose: 'edit-multiple-clients-overlay-base_before-close',
          }}
          isOpen={editMulitpleModalIsOpen}
          onRequestClose={closeEditMultipleModal}
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={0}
          contentLabel='Edit Multiple Clients Modal'
        >
          <EditMultipleClientsForm
            error={rowsSelectedData.error}
            propertyOptions={data.filterOptions}
            naicsOptions={naicsOptions}
            numOfClients={rowsSelectedData.numOfRowSelected}
            propertySelected={rowsSelectedData.propertySelected}
            propertyValues={rowsSelectedData.propertyValues}
            handleSelectedPropertyForm={handleSelectedPropertyForm}
            handleSelectedPropertyValue={handleSelectedPropertyValue}
            handleMultipleCompaniesUpdate={handleMultipleCompaniesUpdate}
            closeEditMultipleModal={closeEditMultipleModal}
          />
        </Modal>
        <Modal
          className={{
            base: 'file-upload-modal-content',
            afterOpen: 'file-upload-modal-content_after-open',
            beforeClose: 'file-upload-modal-content_before-close',
          }}
          overlayClassName={{
            base: 'file-upload-modal-overlay-base',
            afterOpen: 'file-upload-modal-overlay-base_after-open',
            beforeClose: 'file-upload-modal-overlay-base_before-close',
          }}
          isOpen={fileUploadsModalIsOpen}
          onRequestClose={closeFileUploadsModal}
          shouldCloseOnOverlayClick={true}
          closeTimeoutMS={0}
          contentLabel='File Uploads Modal'
        >
          <div>
            <h1>File Uploads</h1>
            <p>Click Anywhere Outside of this Pop-up to Close</p>
            <div id='file-uploads'>
              {currentFileUploads.map(f => {
                return (
                  <div className='file-upload'>
                    <div className='file-upload-attachment'>{f.filename}</div>
                    <a className='file-upload-download' download href={f.url}>Download</a>
                    <a className='file-upload-delete' onClick={(e) => removeFDU(e, f.id)}>Delete</a>
                    <div className='extracted-years'><span>Years in File: </span>{f.extracted_years}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </Modal>
        {rowsSelectedData.displayEditRowSection ?
          <div className='edit-rows-selected-controls'>
            <span>{`${rowsSelectedData.numOfRowSelected} Selected`}</span>
            <button onClick={() => setEditMultipleModalIsOpen(true)}>
              <FontAwesomeIcon icon={faPencil} /> Edit
            </button>
          </div>
          : null
        }
        <ThemeProvider theme={getMuiTheme}>
          <MUIDataTable
            data={dataProps}
            columns={allClientColumns}
            options={allOptions}
          />
        </ThemeProvider>
      </div>
      <Modal
        className={{
          base: 'client-modal-content',
          afterOpen: 'client-modal-content_after-open',
          beforeClose: 'client-modal-content_before-close',
        }}
        overlayClassName={{
          base: 'overlay-base',
          afterOpen: 'overlay-base_after-open',
          beforeClose: 'overlay-base_before-close',
        }}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={0}
        contentLabel='Company Details Modal'
      >
        {isLoading ? <ModalLoadingOverlay /> : null}
        <header className='company-modal-header'>
          <button
            type='button'
            className='btn btn-dark modal-close-btn'
            onClick={closeModal}
          >
            <i className='fa fa-times'></i>
          </button>
          <h1 className='client-company-name'>{clientIDModalData}</h1>
        </header>
        <ul id='company-modal-tabs' role='tablist' >
          <li id='company-modal-file-upload-tab' className={companyModalTabs === 'file-uploads' ? 'company-modal-tab-item active' : 'company-modal-tab-item'} role='presentation' onClick={() => setCompanyModalTabs('file-uploads')}>
            Connections / File Uploads
          </li>
          <li id='company-modal-edit-tab' className={companyModalTabs === 'edit-client' ? 'company-modal-tab-item active' : 'company-modal-tab-item'} role='presentation' onClick={() => setCompanyModalTabs('edit-client')}>
            Edit Client
          </li>
        </ul>
        <div>
          {companyModalTabs === 'file-uploads'
            ? (
              <div className='file-upload-section'>
                <div className='file-upload-process-btns'>
                  {UserConnectBtns(modalData.api_connections)}
                  {navBarInfo.user_type === 'Admin'
                    ? (<DropzoneComponent
                      addFdu={addFdu}
                      fdu={fdu}
                      financialReportType={'pnl'}
                      hrefBuilder={hrefBuilder}
                      id={modalData.id}
                      mostRecentFdu={modalData.most_recent_fdu}
                      name={modalData.name}
                      notify={notify}
                      subdomain={modalData.subdomain}
                    />)
                    : null
                  }
                  {navBarInfo.user_type === 'Admin'
                    ? (<DropzoneComponent
                      addFdu={addFdu}
                      fdu={fdu}
                      financialReportType={'balance_sheet'}
                      hrefBuilder={hrefBuilder}
                      id={modalData.id}
                      mostRecentFdu={modalData.most_recent_fdu}
                      name={modalData.name}
                      notify={notify}
                      subdomain={modalData.subdomain}
                    />)
                    : null
                  }
                  {!plan_access.file_mapping_help
                    ? <a
                      className={modalData.most_recent_fdu && modalData.most_recent_fdu.state === 'mappered' ? 'light-green-border' : 'map-file-link-modal-btn green-border'}
                      href={hrefBuilder(modalData.subdomain, '/mapping_approvals/new')}
                    >
                      {modalData.most_recent_fdu && modalData.most_recent_fdu.state === 'mappered' ? 'MAP FILE!!!' : 'Map File'}
                    </a>
                    : null
                  }
                  {modalData.unlocked ? (
                    <a
                      className='report-link-modal-btn green-border'
                      href={hrefBuilder(modalData.subdomain, '/reports/money')}
                    >
                      View Reports
                    </a>
                  ) : (
                    <a
                      className='report-link-modal-btn green-border'
                      href={
                        (data.report_credits > 0
                          ? '/unlock_reports/'
                          : '/purchase_reports/') + modalData.id
                      }
                    >
                      Unlock Reports
                    </a>
                  )}
                </div>
                <div>
                  <h2 className='modal-label'>Most Recent File:</h2>
                  <p style={{ fontSize: '14px' }}>
                    {modalData.most_recent_fdu
                      ? modalData.most_recent_fdu.document.split(`"`).join('')
                      : '-'}
                  </p>
                  <a
                    className='table-btn'
                    onClick={(e) => gatherFDUs(e, modalData.id)}
                  >
                    View Uploaded Files
                  </a>
                  <div className='upload-guide-section'>
                    <h3>Guide</h3>
                    <a href='/qbo_connect_steps' target='_blank'>How to Connect to QuickBooks Online</a>
                  </div>
                </div>
              </div>
            )
            : null
          }
          {companyModalTabs === 'edit-client'
            ? (
              <div className='company-modal-info'>
                <form className='client-modal-form' onSubmit={handleSubmit}>

                  <div className='modal-info'>
                    <label className='modal-label'>
                      Client ID <i className='fa fa-pencil edit-icon'></i>
                    </label>

                    <input
                      placeholder={clientIDModalData}
                      value={inputForm.name || ''}
                      onChange={handleChangeClientID}
                    />
                  </div>

                  <div className='modal-info'>
                    <label className='modal-label'>
                      Client Name <i className='fa fa-pencil edit-icon'></i>
                    </label>

                    <input
                      placeholder={modalData.real_name}
                      value={inputForm.real_name || ''}
                      onChange={handleChangeRealName}
                    />
                  </div>

                  <div className='modal-info'>
                    <label className='modal-label'>
                      NAICS <i className='fa fa-pencil edit-icon'></i>
                    </label>
                    <p style={{ fontSize: '14px' }}>{naicsDescriptionModalData}</p>
                    <Select
                      options={naicsOptions}
                      selected={inputForm.naics_id || '434'}
                      who='naics'
                    />
                  </div>

                  <div className='modal-info'>
                    <label className='modal-label'>
                      Fiscal Year End <i className='fa fa-pencil edit-icon'></i>
                    </label>
                    <div className='fye-date-pick-container'>
                      <label for="fye-month">Month:</label>
                      <select id="fye-month" value={fyeMonth} onChange={({ target: { value } }) => handleFYEDate('month', value)}>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                      <label className='fye-day' for="fye-day">Day:</label>
                      <select id="fye-day" value={fyeDay} onChange={({ target: { value } }) => handleFYEDate('day', value)}>
                        {daysInMonth.map((day, i) => {
                          return <option key={`${day}-${i}`} value={day}>{day}</option>
                        })}
                      </select>
                    </div>
                  </div>

                  {naicsCodeModalData && naicsCodeModalData.startsWith('62')
                    ?
                    <div>
                      <div className='modal-info-pros'>
                        <label className='modal-label'>
                          {`Number of ${proName}:`}
                        </label>
                        <input
                          type='number'
                          placeholder={numOfProsModalData}
                          value={Number(inputForm.number_of_pros).toString()}
                          onChange={(e) => handleChangeNumOfPros(e)}
                        />
                        <button type='button' onClick={() => setShowEditProYearForm(!showEditProYearForm)}>Edit Values By Years</button>
                        <i className='fa fa-pencil edit-icon'></i>
                      </div>
                      {showEditProYearForm && naicsCodeModalData && naicsCodeModalData.startsWith('62')
                        ? prosByYearFormInputs() : null}
                    </div>
                    : null}


                  <div className='modal-info'>
                    <label className='modal-label'>
                      Partner <i className='fa fa-pencil edit-icon'></i>
                    </label>

                    <input
                      placeholder={partnerModalData}
                      value={inputForm.partners || ''}
                      onChange={handleChangePartner}
                    />
                  </div>

                  <div className='modal-info'>
                    <label className='modal-label'>
                      Office <i className='fa fa-pencil edit-icon'></i>
                    </label>

                    <input
                      placeholder={modalData.office}
                      value={inputForm.office || ''}
                      onChange={handleChangeOffice}
                    />
                  </div>

                  <div className='modal-info'>
                    <label className='modal-label'>
                      Status <i className='fa fa-pencil edit-icon'></i>
                    </label>
                    <Select
                      options={statusOptions}
                      selected={inputForm.status || ''}
                      who='status'
                    />
                  </div>

                  <div className='modal-info'>
                    <label className='modal-label'>
                      Other Advisors <i className='fa fa-pencil edit-icon'></i>
                    </label>
                    <div>
                      {modalData.assigned_to
                        ? modalData.assigned_to.map((name) => (
                          <p style={{ fontSize: '14px' }} key={Math.random(7000)}>
                            {name.first_name} {name.last_name}{' '}
                            <a
                              className='fa fa-times-circle delete-fdu'
                              onClick={() => deleteAssignedTo(name.id)}
                            ></a>
                          </p>
                        ))
                        : ' '}
                    </div>
                    <Select
                      options={assignedToOptions}
                      selected={inputFormForAssignedTo.email || ''}
                      who='assignedTo'
                    />
                  </div>

                  <button type='submit' className='green-border center-btn'>
                    Save All Changes
                  </button>
                </form>

                <div className='flex-space-evenly'>
                  <a
                    className='table-btn'
                    href={hrefBuilder(modalData.subdomain, '/clients/new')}
                  >
                    Add User
                  </a>

                  <a
                    className='table-btn'
                    href={`/admin/companies/${modalData.id}/edit`}
                  >
                    Edit Client
                  </a>
                </div>
              </div>
            )
            : null
          }
        </div>
      </Modal>
      <div className={activeTab === 'notifications' ? 'reports-container' : 'hide-section'}>
        {
          notificationData.notifications['recent'].length > 0
            ? (<div className='mc-notifications-container'>
              <div>
                <h2>Today</h2>
                <ul className='mc-notifications-list'>
                  {notificationData.notifications['today'].map(n => {
                    let createdDate = moment(n.created_at).calendar()
                    return <li><span className='notification-body'>{n.body}</span><span className='notification-date'>{createdDate}</span></li>
                  })}
                </ul>
              </div>
              <div>
                <h2>Recent</h2>
                <ul className='mc-notifications-list'>
                  {notificationData.notifications['recent'].map(n => {
                    let createdDate = moment(n.created_at).calendar()
                    return <li><span className='notification-body'>{n.body}</span><span className='notification-date'>{createdDate}</span></li>
                  })}
                </ul>
              </div>
            </div>)
            : <p>Notifications will appear here.</p>
        }
      </div>
    </div>
  );
};

export default ManageClientsTable;
