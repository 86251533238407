const planDetails = {
    rowHeaders: ['', 'Number of Report Suites (Report Credits)'],
    rowHeaderKeys: ['name', 'number_of_report_suites'],
    displayPlans: ['Freeview', 'Peerview Advisor', 'Peerview Advisor+', 'Peerview Advisor Team'],
    plans: [
        {
            name: 'Freeview',
            key: 'free',
            monthly_subscription: '-',
            number_of_report_suites: 0,
            increase_reports_in_tiers: 'Max'
        },
        {
            name: 'Peerview Advisor',
            key: 'peerviewadvisor',
            monthly_subscription: '$150.00',
            number_of_report_suites: '5',
            increase_reports_in_tiers: '*increase in tiers of 5'
        },
        {
            name: 'Peerview Advisor+',
            key: 'peerviewadvisorplus',
            monthly_subscription: '$560.00',
            number_of_report_suites: '20',
            increase_reports_in_tiers: '*increase in tiers of 20'
        },
        {
            name: 'Peerview Advisor Team',
            key: 'peerviewadvisorteam',
            monthly_subscription: '$1,250.00',
            number_of_report_suites: '50',
            increase_reports_in_tiers: '*increase in tiers of 50'
        }
    ],
    details: [
        {
            copy: 'Company Reports:',
            title: true,
            padding: 0
        },
        {
            copy: 'Money',
            title: true,
            padding: 1
        },
        {
            copy: 'P&L',
            title: false,
            padding: 2,
            displayUnlimited: true,
            plans: ['free', 'peerviewadvisor', 'peerviewadvisorplus', 'peerviewadvisorteam']
        },
        {
            copy: 'Balance Sheet',
            title: false,
            padding: 2,
            displayUnlimited: true,
            plans: ['free', 'peerviewadvisor', 'peerviewadvisorplus', 'peerviewadvisorteam']
        },
        {
            copy: 'Cash Flow',
            title: false,
            padding: 2,
            displayUnlimited: true,
            plans: ['free', 'peerviewadvisor', 'peerviewadvisorplus', 'peerviewadvisorteam']
        },
        {
            copy: 'Key Financial Indicators',
            title: false,
            padding: 2,
            displayNumberOfReports: true,
            plans: ['peerviewadvisor', 'peerviewadvisorplus', 'peerviewadvisorteam']
        },
        {
            copy: 'Dashboard',
            title: false,
            padding: 1,
            displayNumberOfReports: true,
            plans: ['peerviewadvisor', 'peerviewadvisorplus', 'peerviewadvisorteam']
        },
        {
            copy: 'Forecast',
            title: true,
            padding: 1,
            displayNumberOfReports: true,
            plans: ['peerviewadvisor', 'peerviewadvisorplus', 'peerviewadvisorteam']
        },
        {
            copy: 'People & Customers',
            title: true,
            padding: 1,
            displayNumberOfReports: true,
            plans: ['peerviewadvisorplus', 'peerviewadvisorteam']
        },
        {
            copy: '',
            title: true,
            padding: 0
        },
        {
            copy: 'Practice Reports',
            title: false,
            padding: 0,
            plans: ['peerviewadvisorteam']
        },
        {
            copy: 'Industry Reports',
            title: false,
            padding: 0,
            plans: ['peerviewadvisorteam']
        },
        {
            copy: '',
            title: true,
            padding: 0
        },
        {
            copy: 'API to QuickBooks Online/Xero',
            title: false,
            padding: 0,
            plans: ['free', 'peerviewadvisor', 'peerviewadvisorplus', 'peerviewadvisorteam']
        },
        {
            copy: 'Export to Excel',
            title: false,
            padding: 0,
            plans: ['peerviewadvisor', 'peerviewadvisorplus', 'peerviewadvisorteam']
        },
        {
            copy: 'Print to Client Report',
            title: false,
            padding: 0,
            plans: ['peerviewadvisor', 'peerviewadvisorplus', 'peerviewadvisorteam']
        },
        {
            copy: 'Excel Uploads',
            title: false,
            padding: 0,
            plans: ['peerviewadvisorplus', 'peerviewadvisorteam']
        },
        {
            copy: 'File mapping',
            title: false,
            padding: 0,
            displayNA: true,
            plans: ['free', 'peerviewadvisor', 'peerviewadvisorplus', 'peerviewadvisorteam']
        },
    ]
}

export default planDetails;