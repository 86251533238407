import React from "react";
import Modal from 'react-modal';

const VideoModal = React.memo((props) => {
    let videoLink = null;
    let modalPic = '';

    const hrefBuilder = (subdomain, path) => {
        const domainSplit = document.location.host.split('.');
        domainSplit[0] = subdomain;
        return location.protocol + '//' + domainSplit.join('.') + path;
    };

    switch (props.modalReport) {
        case 'practice':
            videoLink = 'https://www.youtube.com/embed/cN5C1fyz3uk?autoplay=1&mute=1';
            break;
        case 'industry':
            videoLink = 'https://www.youtube.com/embed/FWiT4c8uwAM?autoplay=1&mute=1';
            break;
        case 'people':
            modalPic = require('../../assets/images/PremiumPeople.png');
            break;
        case 'customers':
            modalPic = require('../../assets/images/PremiumCustomers.png');
            break;
        case 'forecast':
            videoLink = 'https://www.youtube.com/embed/d1ZYd3NviP0?autoplay=1&mute=1';
            break;
        case 'audit':
            modalPic = require('../../assets/images/PremiumAuditReports.jpeg');
            break;
        default:
            break;
    }

    const manageSubscriptionsURL = hrefBuilder(props.parentSubdomain, '/subscriptions')
    const unlockReportsLink = hrefBuilder(props.parentSubdomain, props.unlockReportsURL)
    const linkText = props.reportCredits ? "Unlock Reports" : "Buy More Report Credits"
    const purchaseText = "Buy more report credits."

    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
            className={{
                base: 'video-modal-content hide-on-print',
                afterOpen: 'video-modal-content_after-open hide-on-print',
                beforeClose: 'video-modal-content_before-close hide-on-print',
            }}
            overlayClassName={{
                base: 'overlay-base hide-on-print',
                afterOpen: 'overlay-base_after-open hide-on-print',
                beforeClose: 'overlay-base_before-close hide-on-print',
            }}
            shouldCloseOnOverlayClick={true}
            closeTimeoutMS={0}
            contentLabel='Video About Report Features'
            ariaHideApp={false}
        >
            <div>
                <h1>Upgrade to Access These Reports</h1>
                {videoLink ? (
                    <iframe
                        width="560"
                        height="315"
                        src={videoLink}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                ) : (
                    <img className='premium-pic-popup' src={modalPic} alt="Modal Content" />
                )}
                 {(props.userType !== 'Admin' && props.planAccess[props.modalReport] && !props.reportsUnlocked)
                       ? (
                           <div className='unlock-report-container'>
                               <p>{`You have ${props.reportCredits} Report Credits left.`} <a className='btn btn-primary unlock-report-btn' href={props.unlockReportsURL}>{linkText}</a></p>
                           </div>
                       )
                       : null}
                <a className='btn btn-primary manage-subscription-btn' href={manageSubscriptionsURL}>Manage Your Subscription</a>
                <p>Click anywhere outside to close this pop-up.</p>
            </div>
        </Modal>
    );
});

export default VideoModal;
