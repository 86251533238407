// 6212
const dentalPNLFormat = [
    {
        alwaysDisplayRow: true,
        bold: true,
        copy: 'Gross Charges',
        key: 'total_revenue_gross_charges',
        map_keys: ['total_revenue_gross_charges'],
        padding: ''
    },
    {
        alwaysDisplayRow: true,
        bold: true,
        copy: 'Adjustments',
        key: 'total_revenue_adjustments',
        map_keys: ['total_revenue_adjustments'],
        padding: ''
    },
    {
        alwaysDisplayRow: true,
        bold: true,
        copy: 'Net Practice Charges',
        key: 'total_revenue_net_practice charges',
        map_keys: ['total_revenue_net_practice charges'],
        padding: ''
    },
    {
        alwaysDisplayRow: true,
        bold: true,
        copy: 'Collections',
        key: 'total_revenue_collections',
        map_keys: ['total_revenue_collections'],
        padding: ''
    },
    { emptyRow: true },
    {
        bold: true,
        copy: 'Gross Receipts',
        key: 'gross_receipts',
        map_keys: ['total_revenue'],
        padding: ''
    },
    { emptyRow: true },
    {
        bold: true,
        copy: 'Total Direct Expenses',
        header_key: 'direct_expenses',
        padding: ''
    },
    {
        bold: true,
        copy: 'Salaries - Associate',
        parentKey: 'total_direct_costs',
        key: 'salaries_associate',
        map_keys: ['total_compensation_associate', 'associate'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Salaries - Hygiene',
        parentKey: 'total_direct_costs',
        key: 'salaries_hygiene',
        map_keys: ['hygienist'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Salaries - Chairside/Other',
        parentKey: 'total_direct_costs',
        key: 'salaries_chairside_other',
        map_keys: ['assistant'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Salaries - Office',
        parentKey: 'total_direct_costs',
        key: 'salaries_office',
        map_keys: ['office'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Payroll Tax Expense',
        parentKey: 'total_direct_costs',
        key: 'payroll_tax_expense',
        map_keys: ['payroll_taxes'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Lab Fees',
        parentKey: 'total_direct_costs',
        key: 'lab_fees',
        map_keys: ['laboratory_fees'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Drugs & Supplies',
        parentKey: 'total_direct_costs',
        key: 'drugs_and_supplies',
        map_keys: ['dental_supplies'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Office Supplies/Expense',
        parentKey: 'total_direct_costs',
        key: 'office_supplies_expense',
        map_keys: ['office_expenses', 'computer_related_costs'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Total Direct Expenses',
        key: 'total_direct_costs',
        map_keys: [],
        topline: true,
        total: true,
        padding: ''
    },
    { emptyRow: true },
    {
        bold: true,
        copy: 'Gross Profit',
        key: 'gross_profit',
        map_keys: [],
        padding: ''
    },
    { emptyRow: true },
    {
        bold: true,
        copy: 'Total Fixed Expenses',
        header_key: 'fixed_expenses',
        padding: ''
    },
    {
        bold: true,
        copy: 'Legal & Accounting',
        parentKey: 'total_administrative_costs',
        key: 'legal_and_accounting',
        map_keys: ['total_professional_fees'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Advertising & Promotion',
        parentKey: 'total_administrative_costs',
        key: 'advertising_and_promotion',
        map_keys: ['total_customer_costs_sales_& marketing'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Continuing Education',
        parentKey: 'total_administrative_costs',
        key: 'continuing_education',
        map_keys: ['training'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Credit Card & Bank Fees',
        parentKey: 'total_administrative_costs',
        key: 'credit_card_and_bank_fees',
        map_keys: ['other_operating_expenses'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Insurance Practice',
        parentKey: 'total_administrative_costs',
        key: 'insurance_practice',
        map_keys: ['insurance_expenses'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Travel, Meals & Entertainment',
        parentKey: 'total_administrative_costs',
        key: 'travel_entertainment_and_meals',
        map_keys: ['travel_entertainment_and_meals'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Rent, Telephone & Utilities',
        parentKey: 'total_administrative_costs',
        key: 'rent_and_facilities_costs',
        map_keys: ['rent_and_facilities_costs'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Other Fixed Costs',
        parentKey: 'total_administrative_costs',
        key: 'other_fixed_costs',
        map_keys: ['contributions_and_donations', 'people_misc_professional_fees', 'retirement_plan', 'health_insurance', 'total_customer_costs_billings_& collections', 'total_other_costs_taxes_- federal & state'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Total Fixed Expenses',
        key: 'total_administrative_costs',
        map_keys: [],
        topline: true,
        total: true,
        padding: ''
    },
    { emptyRow: true },
    {
        bold: true,
        bold: true,
        copy: 'Net Income (Before Non-Operating & Doctor Costs)',
        key: 'operating_expenses_net_income',
        map_keys: [],
        total: false,
        padding: '',
        whiteSpace: 'normal'
    }
]
// 6211 & 6213
const doctorPNLFormat = [
    {
        alwaysDisplayRow: true,
        bold: true,
        copy: 'Gross Charges',
        key: 'total_revenue_gross_charges',
        map_keys: ['total_revenue_gross_charges'],
        padding: ''
    },
    {
        alwaysDisplayRow: true,
        bold: true,
        copy: 'Adjustments',
        key: 'total_revenue_adjustments',
        map_keys: ['total_revenue_adjustments'],
        padding: ''
    },
    {
        alwaysDisplayRow: true,
        bold: true,
        copy: 'Net Practice Charges',
        key: 'total_revenue_net_practice charges',
        map_keys: ['total_revenue_net_practice charges'],
        padding: ''
    },
    {
        alwaysDisplayRow: true,
        bold: true,
        copy: 'Collections',
        key: 'total_revenue_collections',
        map_keys: ['total_revenue_collections'],
        padding: ''
    },
    { emptyRow: true },
    {
        bold: true,
        copy: 'Gross Receipts',
        key: 'gross_receipts',
        map_keys: ['total_revenue'],
        padding: ''
    },
    { emptyRow: true },
    {
        bold: true,
        copy: 'Total Direct Expenses',
        header_key: 'direct_expenses',
        padding: ''
    },
    {
        bold: true,
        copy: 'Salaries - Associate',
        parentKey: 'total_direct_costs',
        key: 'salaries_associate',
        map_keys: ['total_compensation_associate'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Salaries - Nurse',
        parentKey: 'total_direct_costs',
        key: 'salaries_nurse',
        map_keys: ['total_compensation_nurse'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Salaries - Assistant',
        parentKey: 'total_direct_costs',
        key: 'salaries_assistant',
        map_keys: ['total_compensation_assistant'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Salaries - Office',
        parentKey: 'total_direct_costs',
        key: 'salaries_office',
        map_keys: ['total_compensation_office'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Payroll Tax Expense',
        parentKey: 'total_direct_costs',
        key: 'payroll_tax_expense',
        map_keys: ['payroll_taxes'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Lab Fees',
        parentKey: 'total_direct_costs',
        key: 'lab_fees',
        map_keys: ['laboratory_fees'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Medical Supplies',
        parentKey: 'total_direct_costs',
        key: 'medical_supplies',
        map_keys: ['medical_supplies'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Office Supplies/Expense',
        parentKey: 'total_direct_costs',
        key: 'office_supplies_expense',
        map_keys: ['office_expenses', 'computer_related_costs'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Total Direct Expenses',
        key: 'total_direct_costs',
        map_keys: [],
        topline: true,
        total: true,
        padding: ''
    },
    { emptyRow: true },
    {
        bold: true,
        copy: 'Gross Profit',
        key: 'gross_profit',
        map_keys: [],
        padding: ''
    },
    { emptyRow: true },
    {
        bold: true,
        copy: 'Total Fixed Expenses',
        header_key: 'fixed_expenses',
        padding: ''
    },
    {
        bold: true,
        copy: 'Legal & Accounting',
        parentKey: 'total_administrative_costs',
        key: 'legal_and_accounting',
        map_keys: ['total_professional_fees'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Advertising & Promotion',
        parentKey: 'total_administrative_costs',
        key: 'advertising_and_promotion',
        map_keys: ['total_customer_costs_sales_& marketing'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Continuing Education',
        parentKey: 'total_administrative_costs',
        key: 'continuing_education',
        map_keys: ['training'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Credit Card & Bank Fees',
        parentKey: 'total_administrative_costs',
        key: 'credit_card_and_bank_fees',
        map_keys: ['other_operating_expenses'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Insurance Practice',
        parentKey: 'total_administrative_costs',
        key: 'insurance_practice',
        map_keys: ['insurance_expenses'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Travel, Meals & Entertainment',
        parentKey: 'total_administrative_costs',
        key: 'travel_entertainment_and_meals',
        map_keys: ['travel_entertainment_and_meals'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Rent, Telephone & Utilities',
        parentKey: 'total_administrative_costs',
        key: 'rent_and_facilities_costs',
        map_keys: ['rent_and_facilities_costs'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Other Fixed Costs',
        parentKey: 'total_administrative_costs',
        key: 'other_fixed_costs',
        map_keys: ['contributions_and_donations', 'people_misc_professional_fees', 'retirement_plan', 'health_insurance', 'total_customer_costs_billings_& collections', 'total_other_costs_taxes_- federal & state'],
        padding: '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
    },
    {
        bold: true,
        copy: 'Total Fixed Expenses',
        key: 'total_administrative_costs',
        map_keys: [],
        topline: true,
        total: true,
        padding: ''
    },
    { emptyRow: true },
    {
        bold: true,
        bold: true,
        copy: 'Net Income (Before Non-Operating & Doctor Costs)',
        key: 'operating_expenses_net_income',
        map_keys: [],
        total: false,
        padding: '',
        whiteSpace: 'normal'
    }
]

const pnlRows = (naicsCode) => {
    return naicsCode.startsWith('6212') ? dentalPNLFormat : naicsCode.startsWith('621') || naicsCode.startsWith('6211') || naicsCode.startsWith('6213') ? doctorPNLFormat : null;
}

export { pnlRows, dentalPNLFormat, doctorPNLFormat };